import {
  GQComputedFilterField,
  GQFilterFieldLegacy,
} from '@watershed/shared-universal/generated/graphql-schema-types';
import { categoryDescription } from './BusinessCategory';
import { getMappingForLabel } from './labels';
import { countryByCode } from '../countryConstants';
import { smartLowerCase } from './helpers';

/*
    This file reads like it would be supporting our product analytics,
    but it's actually a set of helpers for Drilldown, Insights, and Reductions.
*/

export function displayableAttributeValue(
  value: string,
  position: 'StartSentence' | 'MidSentence' = 'StartSentence'
): string {
  const displayValue = getMappingForLabel(value) || value;
  if (position === 'MidSentence') {
    // TODO: i18n (please resolve or remove this TODO line if legit)
    // eslint-disable-next-line @watershed/require-locale-argument
    return smartLowerCase(displayValue);
  } else {
    return displayValue;
  }
}

const CATEGORY_MAPPINGS = {
  // TODO(T-23351): remove this override once T-23351 is fixed
  'shipping products': {
    label: 'Shipping products',
    description: 'Emissions from shipping products',
  },
  application_hosting: {
    label: 'Application hosting',
    description: '',
  },
  // TODO(T-23351): remove this override once T-23351 is fixed
  'application hosting': {
    label: 'Application hosting',
    description: '',
  },

  // TODO(avi): fix properly after costco demo
  'retail goods': {
    label: 'Retail goods',
    description: '',
  },
  'retail buildings': {
    label: 'Retail buildings',
    description: '',
  },
};

const ORG_SPECIFIC_LABEL_MAPPINGS: OrgLabelMapping = {
  footprintKind: {
    estimated_footprint: {
      label: 'Estimated',
      description: 'Emissions from estimated data',
    },
    corporate_footprint: {
      label: 'Corporate',
      description: 'Emissions from corporate operations',
    },
    pcp: {
      label: 'Primary care providers',
      description: 'Emissions from primary care provider operations',
    },
    square_bitcoin: {
      label: 'Bitcoin',
      description: 'Emissions from bitcoin network activities',
    },
    square_caviar: {
      label: 'Caviar',
      description: 'Emissions from caviar deliveries',
    },
    host_platform: {
      label: 'Host platform',
      description: 'Emissions from stays facilitated by Airbnb',
    },
    experience_platform: {
      label: 'Experiences platform',
      description: 'Emissions from the experiences facilitated by Airbnb',
    },
    food_production: {
      label: 'Food production',
      description: 'Emissions from food ingredients and purchases',
    },
    imperfect_food_production: {
      label: 'Food production',
      description:
        'Emissions from growing and packaging Imperfect produce and grocery items',
    },
    // Have to add this variant because there's a `str.replace('_', ' ')` happening somewhere along the line.
    'imperfect food_production': {
      label: 'Food production',
      description:
        'Emissions from growing and packaging Imperfect produce and grocery items',
    },
    accessgroup_application_hosting: {
      label: 'Application hosting',
      description: '',
    },
    'accessgroup application_hosting': {
      label: 'Application hosting',
      description: '',
    },
    doordash_restaurants: {
      label: 'Restaurants',
      description: 'Emissions from food production and preparation',
    },
    merchant_estimates: {
      label: 'Merchant',
      description:
        'Estimated emissions from packaging and food production from merchants',
    },
    shopify_shipping: {
      label: 'Shipping',
      description:
        'Emissions from shipments sent via Shopify Shipping and the Shopify Fulfillment Network',
    },
  },
  businessCategory: CATEGORY_MAPPINGS,
  categoryId: CATEGORY_MAPPINGS,
};

export function displayableAttributeLabel(
  attribute: string,
  value: string
): string {
  const attributeMapping = ORG_SPECIFIC_LABEL_MAPPINGS[attribute];
  if (attributeMapping && attributeMapping[value]) {
    return attributeMapping[value].label;
  } else if (attribute === 'locationCountry') {
    // Display country name instead of code.
    if (value === 'unknown') {
      return 'Unknown';
    } else if (countryByCode[value]) {
      return countryByCode[value].name;
    }
  }
  return displayableAttributeValue(value);
}

export function displayableAttributeDescription(
  attribute: string,
  value: string
): string {
  const attributeMapping = ORG_SPECIFIC_LABEL_MAPPINGS[attribute];
  if (attributeMapping && attributeMapping[value]) {
    return attributeMapping[value].description;
  } else {
    return categoryDescription(value, 'your');
  }
}

/**
 * Standardize filter values agnostic of common punctuation and extra spaces.
 * Used for Drilldown filtering, examples:
 * - Match "well-to-tank" to search query "well to tank"
 * - Match "transmission & distribution" to search query "transmission distribution"
 */
export function normalizeFilterValue(value: string): string {
  if (!value) return value;

  const PUNCTUATION_REGEX = /&|_|-|—/g;
  const prunePunctuation = value.replace(PUNCTUATION_REGEX, ' ');
  return prunePunctuation.replace(/\s+/g, ' ');
}

// These are the keys of FootprintRow that we allow grouping by.
export type BreakdownField =
  | Exclude<GQFilterFieldLegacy, 'FootprintKind'>
  | GQComputedFilterField
  | 'businessDescription';
export const BREAKDOWN_FIELDS = [
  ...Object.values(GQFilterFieldLegacy).filter(
    (val) => val !== GQFilterFieldLegacy.FootprintKind
  ),
  ...Object.values(GQComputedFilterField),
  'businessDescription',
] as Array<BreakdownField>;

export interface BreakdownAttribute {
  field: BreakdownField;
  name: string;
}

export interface BreakdownSeriesItem {
  x: string;
  y: number;
  label: string;
}

export interface BreakdownSeries {
  attribute: BreakdownAttribute;
  data: Array<BreakdownSeriesItem>;
}

type CustomLabel = {
  label: string;
  description: string;
};

type OrgLabelMapping = Record<string, Record<string, CustomLabel>>;
export interface PieChartDisplayData {
  fieldName: BreakdownField;
  fieldDisplayName: string;
  displayLabel: string;
  unit: any;
  totalQuantity: number;
  items: Array<{
    fieldValue: string;
    quantity: number;
    displayLabel: string;
    exportLabel: string;
  }>;
}
