// File to define select options for select fields in supplier survey's
//
// TODO: Import all options from select input components in
// engagementTasks/questionComponents/ regardless of whether
// they are being used in `QUESTION_COMPONENT_ID_TO_INPUT_TYPE` in questions.ts

import {
  GQEdciCompanyGrowthStageOption,
  GQEsgdcGhgMethodologyType,
} from '@watershed/shared-universal/generated/graphql-schema-types';

export const COMPANY_GROWTH_STAGES = [
  GQEdciCompanyGrowthStageOption.Venture,
  GQEdciCompanyGrowthStageOption.Buyout,
  GQEdciCompanyGrowthStageOption.Growth,
];

export const DECARBONIZATION_PLAN_SELECT_OPTIONS = [
  'No',
  'Yes, but without board oversight',
  'Yes, with board oversight',
];

export const GHG_METHODOLOGY_TYPES = [
  GQEsgdcGhgMethodologyType.SpendBased,
  GQEsgdcGhgMethodologyType.ActivityBased,
  GQEsgdcGhgMethodologyType.DirectEmissionsOrSupplierBased,
  GQEsgdcGhgMethodologyType.Other,
];

export const ENGAGEMENT_TASK_LONG_TERM_NET_ZERO_SELECT_OPTIONS = [
  'No - and no plan to set one (e.g. lack of viable pathway)',
  'No - but we plan to establish this in the near term (<2 years)',
  'No - we have a long-term goal but not fully aligned with a net zero pathway (i.e., NZ emissions by 2050 or sooner)',
  'Yes - aligned with a net zero pathway (i.e., NZ emissions by 2050 or sooner)',
];

export const ENGAGEMENT_TASK_SHORT_TERM_REDUCTION_TARGETS_SELECT_OPTIONS = [
  'No',
  'Yes, but it is not Paris-aligned',
  'Yes, and it is Paris–aligned (covering Scope 1, 2, & material Scope 3)',
];
