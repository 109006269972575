import { GQFootprintsForFootprintListQuery } from '@watershed/app-dashboard/generated/graphql-operations';

/**
 * Updates the caches locally after the GenerateFootprintV2 mutation gets kicked
 * off, so that we mark the footprint as generating for our main footprints list
 * view.
 */
export function updateFootprintsAfterGenerateFootprintMutation(
  data: GQFootprintsForFootprintListQuery | null,
  footprintId: string
): GQFootprintsForFootprintListQuery | null {
  if (!data) {
    return data;
  }
  // Update the edge that references this footprint
  const edges = data.footprints.edges.map((edge) => {
    if (edge?.node?.id !== footprintId) {
      return edge;
    }
    return {
      ...edge,
      node: {
        ...edge.node,
        isGenerating: true,
      },
    };
  });
  return {
    ...data,
    footprints: {
      ...data.footprints,
      edges,
    },
  };
}
