import { useState } from 'react';
import LoggedInLayout from './LoggedInLayout';
import { Box } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../components/ErrorFallback';
import { SkipNavContent } from '../components/navigation/SkipNav';
import { SettingsLayout as Settings } from '../components/settings/v2/components/SettingsLayout';
import SettingsNavigation from '../components/settings/v2/components/SettingsNavigation';

export default function SettingsLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <LoggedInLayout>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.grey10,
          height: '100dvh',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <SettingsNavigation
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        </ErrorBoundary>
        <Settings.Layout>
          <SkipNavContent />
          {children}
        </Settings.Layout>
      </Box>
    </LoggedInLayout>
  );
}
