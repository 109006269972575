import EmissionsYear from '../companyData/EmissionsYear';
import { DISPLAY_TEXT_FOR_SURVEY_STATE } from '../companySurveys/utils';
import {
  GQCompanySbtCommitmentStage,
  GQSupplierPriority,
} from '@watershed/shared-universal/generated/graphql-schema-types';

import {
  GQCompanyEngagementTaskFieldsForOverviewFragment,
  GQEmissionsYearForSuppliersTableFragment,
} from '@watershed/shared-universal/generated/graphql';

import {
  getDateDetailsForDisclosure,
  getDisclosureName,
  getDisclosureType,
} from '../utils/DisclosureUtils';
import {
  InitiativeWithDetails,
  getAllInitiativesFromSupplier,
  supplierNetZeroStatus,
} from '../utils/SuppliersUtils';
import assertNever from '@watershed/shared-util/assertNever';
import { getClimateProgressLabel } from '../utils/companyUtils';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import { SupplierColumnName } from './SupplierColumnRegistry';
import { Supplier, SupplierTableRow } from './supplierTypes';
import {
  CombinedTarget,
  getCombinedTargetFromCommitment,
  getCombinedTargetFromTarget,
} from './targetAndCommitmentDisclosureHelpers';
import flattenDeep from 'lodash/flattenDeep';
import toLower from 'lodash/toLower';
import { FootprintType } from './supplierTypes';
import { i18n } from '@watershed/intl';

export type ColumnValueGetter<R> = (supplier: Supplier | SupplierTableRow) => R;

export interface EmissionsYearWithDetails
  extends GQEmissionsYearForSuppliersTableFragment {
  name: string;
  disclosureType: string;
  footprintType: FootprintType;
}

export function getValueForField(
  field: 'supplierName' | 'surveyState'
): ColumnValueGetter<string>;
export function getValueForField(
  field: 'priority'
): ColumnValueGetter<GQSupplierPriority | null>;
export function getValueForField(
  field:
    | 'topCategorizedEmission'
    | 'notes'
    | 'surveyPortalUrl'
    | 'targets'
    | 'supplierDetailsUrl'
): ColumnValueGetter<string | null>;
export function getValueForField(
  field: 'emissionsRank' | 'latestCdpDisclosurePublishingYear' | 'percentSpend'
): ColumnValueGetter<number | null>;
export function getValueForField(
  field: 'totalEmissions' | 'spend' | 'percentEmissions' | 'emissiveSpend'
): ColumnValueGetter<number>;
export function getValueForField(
  field: 'disclosures'
): ColumnValueGetter<Array<string>>;
export function getValueForField(
  field: 'carbonNeutral' | 'netZero'
): ColumnValueGetter<{ commitmentStatus: string; targetYear: number } | null>;
export function getValueForField(field: 'cleanEnergy'): ColumnValueGetter<{
  year: number | null;
  percentage: number | null;
  companyId: string | null;
}>;
export function getValueForField(
  field: 'sbtCommitmentStage'
): ColumnValueGetter<GQCompanySbtCommitmentStage>;
export function getValueForField(
  field: 'engagementCohorts' | 'contacts' | 'ghgCategoryIds' | 'beaCodes'
): ColumnValueGetter<Array<string>>;
export function getValueForField(
  field: 'latestDisclosureDate'
): ColumnValueGetter<Date | null>;
export function getValueForField(
  field: 'engagementTasks'
): ColumnValueGetter<
  Array<GQCompanyEngagementTaskFieldsForOverviewFragment & { name: string }>
>;
export function getValueForField(
  field: 'emissionsFactors'
): ColumnValueGetter<boolean>;
export function getValueForField(
  field: 'initiatives'
): ColumnValueGetter<Array<InitiativeWithDetails>>;
export function getValueForField(
  field: 'footprints'
): ColumnValueGetter<Array<EmissionsYearWithDetails>>;
export function getValueForField(
  field: 'targetsAndCommitments'
): ColumnValueGetter<Array<CombinedTarget>>;
export function getValueForField(
  field: SupplierColumnName
): ColumnValueGetter<any>;

export function getValueForField(field: SupplierColumnName) {
  return (supplier: Supplier | SupplierTableRow): any => {
    switch (field) {
      case 'supplierName':
        return supplier.name;
      case 'priority':
        return supplier.priority;
      case 'emissionsRank':
        return supplier.emissionsRank;
      case 'totalEmissions':
        return supplier.totalKgco2e;
      case 'spend':
        return supplier.totalSpendUsd;
      case 'percentEmissions':
        return supplier.percentEmissions;
      case 'topCategorizedEmission':
        return (
          supplier.topCategorizedEmissionBusinessCategory ??
          supplier.topCategorizedEmissionBusinessSubcategory
        );
      case 'disclosures':
        return toLower(getClimateProgressLabel(supplier.climateProgress)).split(
          ', '
        );
      case 'netZero':
        return supplierNetZeroStatus(supplier);
      case 'carbonNeutral':
        if (isNotNullish(supplier.carbonNeutralCommitmentTargetYear)) {
          return {
            commitmentStatus: 'Carbon neutral',
            targetYear: supplier.carbonNeutralCommitmentTargetYear,
          };
        }
        return null;
      case 'cleanEnergy':
        return {
          year: supplier.cleanEnergyCommitmentTargetYear,
          percentage: supplier.cleanEnergyCommitmentTargetPercentage,
          companyId: supplier.companyId,
        };
      case 'sbtCommitmentStage':
        return supplier.sbtiStage;
      case 'notes':
        return supplier.notes;
      case 'engagementCohorts':
        return supplier.engagementCohortIds;
      case 'contacts':
        // hack to check if object is a SupplierTableRow
        // if it is not, then we're not in the suppliers table and this formatter should not be used
        if ('contactIds' in supplier) {
          return supplier.contactIds;
        }
        return [];
      case 'latestDisclosureDate':
        return supplier.latestDisclosureDateTime;
      case 'latestCdpDisclosurePublishingYear':
        return supplier.latestCdpDisclosurePublishingYear;
      case 'surveyState':
        // hack to check if object is a SupplierTableRow
        // if it is not, then we're not in the suppliers table and this formatter should not be used
        if ('mostActionableTaskStatus' in supplier) {
          return isNotNullish(supplier.mostActionableTaskStatus)
            ? i18n._(
                DISPLAY_TEXT_FOR_SURVEY_STATE[supplier.mostActionableTaskStatus]
              )
            : 'None';
        }
        // Choose to return empty string instead of error, since this is just a formatting thing.
        return '';
      case 'engagementTasks':
        return supplier.engagementTasks.map((et) => ({
          ...et,
          name: et.engagementTaskConfig.name,
        }));
      case 'surveyPortalUrl':
        // hack to check if object is a SupplierTableRow
        // if it is not, then we're not in the suppliers table and this formatter should not be used
        return 'surveyPortalUrl' in supplier &&
          isNotNullish(supplier.surveyPortalUrl)
          ? // eslint-disable-next-line no-restricted-globals
            `${window.location.origin}${supplier.surveyPortalUrl}`
          : null;
      case 'ghgCategoryIds':
        return supplier.ghgCategoryIds;
      case 'targets':
        return supplier.targets;
      case 'emissionsFactors':
        return supplier.climateProgress.includes('Emissions');
      case 'percentSpend':
        return supplier.percentSpend;
      case 'supplierDetailsUrl':
        return null;
      case 'beaCodes':
        return supplier.beaCodes ?? [];
      case 'emissiveSpend':
        return supplier.emissiveSpendUsd;
      case 'targetsAndCommitments':
        return flattenDeep([
          supplier.targetDisclosures.map((disclosure) =>
            getCombinedTargetFromTarget(
              disclosure,
              supplier.climateCommitmentDisclosures,
              supplier.name
            )
          ),
          supplier.climateCommitmentDisclosures.map((disclosure) =>
            getCombinedTargetFromCommitment(disclosure)
          ),
        ]);
      case 'initiatives':
        return getAllInitiativesFromSupplier(supplier);
      case 'footprints':
        return supplier.emissionsYearDisclosures.flatMap((disclosure) => {
          const { displayDate } = getDateDetailsForDisclosure(disclosure);
          const disclosureName = getDisclosureName(
            disclosure,
            displayDate,
            null
          );
          const disclosureType = getDisclosureType(disclosure);
          return (
            disclosure.historicalEmissionsYears?.map((emissionsYear) => ({
              ...emissionsYear,
              scope2: EmissionsYear.getScope2Emissions(emissionsYear),
              name: disclosureName,
              disclosureType,
              footprintType: isNotNullish(emissionsYear.expenseCategory)
                ? FootprintType.ProductLine
                : FootprintType.Corporate,
            })) ?? []
          );
        });
      default:
        assertNever(field);
    }
  };
}
