import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import HelpIcon from '@watershed/icons/components/Help';
import PrivateIcon from '@watershed/icons/components/Private';
import { useEffect } from 'react';
import PageContainer from '@watershed/shared-frontend/components/PageContainer';
import { Trans } from '@lingui/react/macro';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';

interface Props {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  Icon?: typeof HelpIcon | typeof PrivateIcon;
}

export function NotFoundPageBody({
  title = (
    <Trans context="Title of the page when it is not found">
      Page not found
    </Trans>
  ),
  subtitle,
  Icon = HelpIcon,
}: Props) {
  useEffect(() => {
    Analytics.error('notFoundPageShown');
  }, []);

  return (
    <Stack
      data-testid={TestIds.NotFoundPageBody}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Icon size={128} marginBottom={1} />
      <Typography variant="h1" align="center" paragraph>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="body2" align="center">
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
}

export default function NotFoundPage({ title, subtitle, Icon }: Props) {
  return (
    <PageContainer
      isFullPage={false}
      maxWidth="sm"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 64,
      }}
      data-testid={TestIds.NotFoundPage}
    >
      <NotFoundPageBody title={title} subtitle={subtitle} Icon={Icon} />
    </PageContainer>
  );
}

export function ViewerNoPermissionPage({ orgName }: { orgName: string }) {
  return (
    <NotFoundPage
      title={
        <Trans context="Unable to view page as in not authorized">
          Unable to view
        </Trans>
      }
      subtitle={
        <Trans>
          You don’t have access to view this page. Reach out to an administrator
          at {orgName} to request access to the Watershed dashboard.
        </Trans>
      }
      Icon={PrivateIcon}
    />
  );
}
