import { SxProps, Theme, keyframes } from '@mui/material';

export const sharedShadow =
  '0px 4px 8px 0px rgba(79, 89, 110, 0.08), 0px 0px 8px -4px rgba(79, 89, 110, 0.16), 0px 0px 16px 2px rgba(79, 89, 110, 0.06);';

export const sharedFocusShadow = (theme: Theme) =>
  `0px 0px 0px 1.5px #FFF, 0px 0px 0px 3px ${theme.palette.cobalt20}, 0px 1px 1px 0px rgba(79, 89, 110, 0.08), 0px 4px 8px 0px rgba(79, 89, 110, 0.08), 0px 0px 8px -4px rgba(79, 89, 110, 0.16), 0px 0px 16px 2px rgba(79, 89, 110, 0.06);`;

export const tooltipProps = {
  popper: {
    modifiers: [
      {
        name: 'offset',

        options: {
          offset: [0, -6],
        },
      },
    ],
    sx: {
      '& .MuiTooltip-tooltip': {
        zIndex: 2000,
        fontSize: '13px',
        fontWeight: 700,
        borderRadius: '8px',
        padding: '4px 10px',
        backgroundColor: (theme: Theme) => theme.palette.grey100,
        color: (theme: Theme) => theme.palette.common.white,
        boxShadow: sharedShadow,
      },
      '& .MuiTooltip-arrow': {
        display: 'none',
      },
    },
  },
};

export const sharedPaperSx: SxProps<Theme> = {
  backgroundColor: (theme) => theme.palette.grey05,
  boxShadow: sharedShadow,
};

export const floatingButtonSx: SxProps<Theme> = {
  position: 'absolute',
  top: 0,
  right: -40,
  borderRadius: '50%',
  transition: 'opacity 0.2s ease-in-out',
  zIndex: 1,
  '&:hover': {
    backgroundColor: (theme) => theme.palette.grey05,
  },
};

export const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(147, 174, 255, 1);
  }
  70% {
    box-shadow: 0 0 0px 4px rgba(147, 174, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(147, 174, 255, 0);
  }
`;
