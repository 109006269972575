import { PermissionType } from '@watershed/constants/permissions';

import uniq from 'lodash/uniq';
import { getObjectKeys } from '../getObjectKeys';

/**
 * This mapping is quite structural - it sets up the hierarchy of permissions,
 * such that each child gives implicit access to the permissions of its parent.
 *
 * i.e. WatershedAdmin also gives you Admin permissions, whereas Admin
 * permissions give you CorporateAdmin permissions.
 */
export const PERMISSION_PARENTS: {
  [key in PermissionType]?: Array<PermissionType>;
} = {
  // Admin magicsauce
  [PermissionType.Admin]: [PermissionType.WatershedAdmin],
  [PermissionType.CorporateAdmin]: [PermissionType.Admin],
  [PermissionType.ManageMarketplacePurchases]: [PermissionType.CorporateAdmin],
  [PermissionType.ManageCompanyTags]: [PermissionType.CorporateAdmin],
  [PermissionType.ManageOrgHierarchy]: [PermissionType.CorporateAdmin],
  [PermissionType.ViewEmployeeReport]: [PermissionType.CorporateAdmin],
  [PermissionType.ManageSingleSignOn]: [PermissionType.CorporateAdmin],
  [PermissionType.ManageMeasurement]: [PermissionType.CorporateAdmin],
  [PermissionType.ManageReductionPlans]: [PermissionType.CorporateAdmin],
  [PermissionType.ManageSuppliers]: [PermissionType.CorporateAdmin],
  [PermissionType.ManageDisclosures]: [PermissionType.CorporateAdmin],

  // Manage/View and other hierarchies
  [PermissionType.ViewFootprintDetail]: [
    PermissionType.ViewReductions,
    PermissionType.ManageMeasurement,
    // Note: This doesn't seem right but we don't want to hit permissions
    // landmines with Finance Reporting
    PermissionType.FinanceAdmin,
  ],
  [PermissionType.ViewAuditDetail]: [PermissionType.ViewFootprintDetail],
  [PermissionType.ViewReductions]: [PermissionType.ManageReductionPlans],
  [PermissionType.ManageDataset]: [PermissionType.ManageMeasurement],
  [PermissionType.ManageDatasource]: [PermissionType.ManageDataset],
  [PermissionType.ApproveDataset]: [PermissionType.ManageMeasurement],
  [PermissionType.ApproveDatasource]: [PermissionType.ApproveDataset],
  [PermissionType.EditReport]: [PermissionType.ViewFootprintDetail],
  [PermissionType.ViewReport]: [PermissionType.EditReport],
  [PermissionType.EditReportQuestionInstance]: [PermissionType.EditReport],
  [PermissionType.ViewReportQuestionInstance]: [
    PermissionType.ViewReport,
    PermissionType.EditReportQuestionInstance,
  ],

  // Specific footprint permissions
  [PermissionType.ViewFootprint]: [
    PermissionType.ApproveFootprint, // Approve grants View on the same footprint
    PermissionType.ViewFootprintDetail, // access to _all_ footprints if you have ViewFootprintDetail
    PermissionType.ManageSuppliers, // access to _all_ footprints if you have ManageSuppliers
  ],
  [PermissionType.ApproveFootprint]: [PermissionType.ManageMeasurement],

  // Finance
  [PermissionType.FinanceAdmin]: [PermissionType.Admin],
  [PermissionType.ManageFund]: [PermissionType.FinanceAdmin],
  [PermissionType.FinanceReadOnly]: [PermissionType.ManageFund],

  // Learning Hub
  // This is unique permission in that the learning hub is not actually gated by
  // any permission, but we want to allow orgs to create a user that can _exclusively_
  // access learning hub.

  // It's only part of the permission hierarchy so that admin users can grant it to others.
  [PermissionType.ViewLearningHub]: [
    PermissionType.CorporateAdmin,
    PermissionType.FinanceAdmin,
  ],
};

/**
 * Returns the hierarchy of permissions for a given permission.
 *
 * @param permission - The permission to get the hierarchy for
 * @returns An array of permissions that are ancestors of the given permission,
 * sorted by the most specific permissions first, broadest admin permissions last.
 */
function _getPermissionsHierarchy(
  permission: PermissionType
): Array<PermissionType> {
  const flattenedResult = [
    permission,
    ...(PERMISSION_PARENTS[permission] || []).map((parent) =>
      _getPermissionsHierarchy(parent)
    ),
  ].flat();
  // Ensures the most specific permissions are first, broadest admin permissions last
  return uniq(flattenedResult.reverse()).reverse();
}

/**
 * Memoized version of _getPermissionsHierarchy for all PermissionTypes.
 */
const ANCESTORS_FOR_PERMISSION = Object.fromEntries(
  getObjectKeys(PermissionType).map((permission) => [
    permission,
    _getPermissionsHierarchy(permission),
  ])
);

/**
 * Returns the hierarchy of permissions for a given permission. Uses pre-cached
 * values for performance.
 *
 * @param permission - The permission to get the hierarchy for
 * @returns An array of permissions that are ancestors of the given permission,
 * sorted by the most specific permissions first, broadest admin permissions last.
 */
export function getPermissionsHierarchy(
  permission: PermissionType
): Array<PermissionType> {
  return ANCESTORS_FOR_PERMISSION[permission];
}

const HIERARCHY_LENGTHS_FOR_PERMISSION = Object.fromEntries(
  getObjectKeys(PermissionType).map((permission) => [
    permission,
    getPermissionsHierarchy(permission).length,
  ])
);

export type SourceObjectWithPermissionDelegate = {
  id: string;
  permissionDelegateId?: string | null;
};

export interface PermissionOrigin {
  permission: string;
  objectId?: string | null;
  objectName?: string | null;
}

type PermissionChecker = (params: {
  permission: string;
  objectId?: string | null;
  source?: SourceObjectWithPermissionDelegate | null;
  allowAnyObject?: boolean;
  allowPermission: PermissionType;
}) => PermissionOrigin | null;

const PERMISSION_ORIGIN_CHECKERS = Object.fromEntries(
  getObjectKeys(PermissionType).map((permission) => {
    const permissionsToCheck = getPermissionsHierarchy(permission);

    // Create an optimized origin checker function for this permission
    const checker: PermissionChecker = ({
      permission,
      objectId,
      source,
      allowAnyObject,
    }) => {
      for (const checkPermission of permissionsToCheck) {
        if (checkPermission === permission) {
          if (!objectId) {
            return { permission: checkPermission };
          }
          if (allowAnyObject) {
            return { permission: checkPermission };
          }
          if (
            objectId === source?.id ||
            objectId === source?.permissionDelegateId
          ) {
            return { permission: checkPermission, objectId };
          }
        }
      }
      return null;
    };

    return [permission, checker];
  })
);

export function getPermissionOrigin({
  permission,
  objectId,
  source,
  allowPermission,
  allowAnyObject,
}: {
  // This is the permission that the user has
  permission: string;

  // This is the permission to check
  allowPermission: PermissionType;

  // This is the objectId associated with the permision that the user has
  objectId?: string | null;

  // This is the object being tested
  source?: SourceObjectWithPermissionDelegate | null;

  // If true, we don't check the object ID (useful for things like determining if a user can access a page)
  allowAnyObject?: boolean;
}): PermissionOrigin | null {
  const checker = PERMISSION_ORIGIN_CHECKERS[allowPermission];
  return checker({
    permission,
    objectId,
    source,
    allowAnyObject,
    allowPermission,
  });
}

/**
 * Sorts permissions so that the one with the shortest hierarchy is first. If
 * two permissions have the same hierarchy length, then the one without an
 * objectId is prioritized.
 *
 * @param permissions an array of permissions to sort
 * @returns a new array of permissions sorted by the shortest hierarchy
 */
export function sortPermissionsByShortestHierarchy<
  T extends {
    permission: PermissionType;
    objectId?: string | null;
  },
>(permissions: Array<T>): Array<T> {
  return permissions.toSorted((a, b) => {
    const aLength =
      HIERARCHY_LENGTHS_FOR_PERMISSION[a.permission] + (!!a.objectId ? 1 : 0);
    const bLength =
      HIERARCHY_LENGTHS_FOR_PERMISSION[b.permission] + (!!b.objectId ? 1 : 0);

    return aLength - bLength;
  });
}

export function getUserOrRolePermissionOrigin({
  permissions,
  allowPermission,
  source,
}: {
  // Permissions that a user or a role has
  permissions: Array<{
    permission: PermissionType;
    objectId?: string | null;
  }>;

  // This is the permission being tested
  allowPermission: PermissionType;

  // This is the object being tested
  source?: SourceObjectWithPermissionDelegate | null;
}): PermissionOrigin | null {
  for (const p of sortPermissionsByShortestHierarchy(permissions)) {
    const origin = getPermissionOrigin({
      permission: p.permission,
      objectId: p.objectId,
      allowPermission,
      source,
    });
    if (origin) return origin;
  }
  return null;
}
