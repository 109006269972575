import React, { createContext, useContext, useMemo } from 'react';
import { AIAgentRegistry } from './AIAgentRegistry';

/**
 * Interface for the AI Agent Registry Context
 * This provides global access to the AI Agent Registry throughout the application
 */
interface AIAgentRegistryContextType {
  registry: AIAgentRegistry | null;
}

// Create the context with a default undefined value
const AIAgentRegistryContext = createContext<AIAgentRegistryContextType>({
  registry: null,
});

export function AIAgentRegistryProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // Create a single instance of the registry that persists across renders
  const registry = useMemo(() => new AIAgentRegistry(), []);

  // Create the context value with the registry instance
  const contextValue = useMemo(
    () => ({
      registry,
    }),
    [registry]
  );

  return (
    <AIAgentRegistryContext.Provider value={contextValue}>
      {children}
    </AIAgentRegistryContext.Provider>
  );
}

/**
 * Custom hook to access the AI Agent Registry context
 * Components can use this to interact with the AI Agent Registry
 */
export function useAIAgentRegistry() {
  const context = useContext(AIAgentRegistryContext);

  if (context === undefined) {
    throw new Error(
      'useAIAgentRegistry must be used within an AIAgentRegistryProvider'
    );
  }

  if (context.registry === null) {
    throw new Error(
      'AIAgentRegistryProvider must be initialized before useAIAgentRegistry'
    );
  }

  return context.registry;
}
