import invariant from 'invariant';
import isEqual from 'lodash/isEqual';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import { BadInputError } from '@watershed/errors/BadInputError';
import { GrowthFactorType } from '@watershed/constants/forecasts';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import must from '../utils/must';
import {
  MonthlyTimeseries,
  YearlyPercentageTimeseries,
  FiscalYearlyTimeseries,
  FiscalYearlyPercentageTimeseries,
} from '../utils/SimpleTimeseries';
import { FiscalYear, YearMonth, YM, YMInterval } from '../utils/YearMonth';
import {
  GrowthForecastAggregatorOverFiscalYear,
  GrowthForecastConfigs,
  IntensityDenominatorBasedGrowthForecastConfigs,
  buildGrowthForecastConfigs,
} from './GrowthForecastConfig';
import { UnexpectedError } from '@watershed/errors/UnexpectedError';
import { getObjectKeys } from '../getObjectKeys';
import { GFI, GrowthFactorIdentifier } from './GrowthFactorIdentifier';
import { BusinessMetricKeyMap, BusinessMetricsKey } from './BusinessMetricsKey';
import {
  CustomIntensityConfigFields,
  ForecastFieldsForPlan,
  ForecastScenarioFields,
} from './types';

export interface RenewableElectricityBreakdown {
  yearStart: YearMonth;
  renewableEnergyFraction: number;
}

export const GrowthFactorTypes = [
  GrowthFactorType.Revenue,
  GrowthFactorType.Headcount,
  GrowthFactorType.GrossProfit,
  GrowthFactorType.NightsStayed,
  GrowthFactorType.Orders,
  GrowthFactorType.SupplierSpend,
  GrowthFactorType.Patients,
] as const;

/**
 * Index into the growthFactors record with a BusinessMetricsKey -
 * (see above for format)
 */
export type BusinessMetricsRow = {
  readonly forecasted: boolean;
  growthFactors: Record<BusinessMetricsKey, number | null>;
};

// copied from GQFootprintAnalysisTimeSeriesDataPoint
export interface BusinessMetricsFootprintActual {
  date: Date;
  headcount: number | null;
  revenue: number | null;
  customGrowthFactors: Array<{
    customIntensityId: string;
    value: number | null;
  }>;
}

// copied from GQHistoricalUserInputtedGrowthFactorDataPoint
export interface BusinessMetricsActual {
  date: Date;
  growthFactors: Array<{
    growthFactorType: GrowthFactorType;
    customIntensityConfigId: string | null;
    value: number | null;
  }>;
}

// Headcount and Revenue are required, all others are optional
// (Note: This just means we have growth percentages, not that we have actual values)
export interface GrowthFactorSeries
  extends Record<BusinessMetricsKey, YearlyPercentageTimeseries | undefined> {
  [GrowthFactorType.Revenue]: YearlyPercentageTimeseries;
  [GrowthFactorType.Headcount]: YearlyPercentageTimeseries;
  [GrowthFactorType.GrossProfit]?: YearlyPercentageTimeseries;
  [GrowthFactorType.NightsStayed]?: YearlyPercentageTimeseries;
  [GrowthFactorType.Orders]?: YearlyPercentageTimeseries;
  [GrowthFactorType.SupplierSpend]?: YearlyPercentageTimeseries;
  [GrowthFactorType.Patients]?: YearlyPercentageTimeseries;
}

interface YearlyMetric
  extends Record<'forecast' | 'historical', FiscalYearlyTimeseries<number>> {}

export class BusinessMetrics extends MonthlyTimeseries<BusinessMetricsRow> {
  // TODO: It would be better to calculate revenue / headcount growth in the
  // forecast model. This is stop-gap
  public readonly historicalTimeseries: MonthlyTimeseries<BusinessMetricsRow>;
  private readonly growthFactorSeries: GrowthFactorSeries;
  public readonly growthForecastConfigs: GrowthForecastConfigs;

  private constructor(props: {
    base: YearMonth;
    values: {
      historicalValues: Array<BusinessMetricsRow>;
      forecastValues: Array<BusinessMetricsRow>;
    };
    growthFactorSeries: GrowthFactorSeries;
    growthForecastConfigs: GrowthForecastConfigs;
  }) {
    super(props.base, props.values.forecastValues);

    this.historicalTimeseries = new MonthlyTimeseries<BusinessMetricsRow>(
      props.base,
      props.values.historicalValues
    );
    this.growthFactorSeries = props.growthFactorSeries;
    this.growthForecastConfigs = props.growthForecastConfigs;
  }

  /**
   * This is a utility function to ensure we use the right business metrics key format
   * when indexing into the growth factor series.
   * We include several type overrides to enforce that revenue and headcount forecasts
   * are always present.
   */
  public getGrowthFactorGrowthPercentageTimeseries(
    growthFactorTimeseries: 'Revenue' | 'Headcount',
    customIntensityConfigId?: undefined | null
  ): YearlyPercentageTimeseries;
  public getGrowthFactorGrowthPercentageTimeseries(
    growthFactorTimeseries: GrowthFactorType,
    customIntensityConfigId?: string | null
  ): YearlyPercentageTimeseries | undefined;
  public getGrowthFactorGrowthPercentageTimeseries(
    growthFactorType: GrowthFactorType,
    customIntensityConfigId?: string | null
  ): YearlyPercentageTimeseries | undefined {
    return this.growthFactorSeries[
      getBusinessMetricsKey(growthFactorType, customIntensityConfigId ?? null)
    ];
  }

  /**
   * Returns all possible growth factors including custom growth factors,
   * regardless of whether or not they are present.
   */
  public possibleGrowthFactors(): Array<GrowthFactorIdentifier> {
    return Object.keys(this.growthFactorSeries).map((key) =>
      GFI.fromBusinessMetricsKey(key)
    );
  }

  // Maybe De-duplicate with metricsWithPresentDataInInterval?
  public growthFactorsPresentForHistoricalInterval({
    interval,
  }: {
    interval: YMInterval;
  }): {
    present: Array<GrowthFactorIdentifier>;
    notCompletelyPresent: Array<GrowthFactorIdentifier>;
  } {
    const possibleFactors: Array<BusinessMetricsKey> = Object.keys(
      this.growthForecastConfigs
    ).concat(GrowthFactorType.Headcount, GrowthFactorType.Revenue);
    const months = interval.months();
    const { present, notCompletelyPresent } = months.reduce(
      (
        agg: {
          present: Array<BusinessMetricsKey>;
          notCompletelyPresent: Array<BusinessMetricsKey>;
        },
        ym: YearMonth
      ) => {
        const val = this.valueAt(ym);
        if (val.forecasted)
          return {
            present: [],
            notCompletelyPresent: [...agg.present, ...agg.notCompletelyPresent],
          };
        const factors: Array<BusinessMetricsKey> = [];
        for (const factor of possibleFactors) {
          if (
            isNotNullish(val.growthFactors[factor]) &&
            val.growthFactors[factor] !== 0 &&
            agg.present.includes(factor)
          )
            factors.push(factor);
        }
        return {
          present: factors,
          notCompletelyPresent: [
            ...agg.notCompletelyPresent,
            ...agg.present.filter((f) => !factors.includes(f)),
          ],
        };
      },
      {
        present: possibleFactors,
        notCompletelyPresent: [],
      }
    );
    return {
      present: present.map(GFI.fromBusinessMetricsKey),
      notCompletelyPresent: notCompletelyPresent.map(
        GFI.fromBusinessMetricsKey
      ),
    };
  }

  public static getGrowthFactorsFromCustomGrowthFactors(
    customGrowthFactors: BusinessMetricsFootprintActual['customGrowthFactors']
  ): BusinessMetricsActual['growthFactors'] {
    return customGrowthFactors
      .map(({ customIntensityId, value }) => {
        const config = IntensityDenominatorBasedGrowthForecastConfigs.find(
          (config) => config.snakeCaseId === customIntensityId
        );
        // The footprint custom growth factors contain intensities like monthly active users that we do not support in reductions
        if (!config) return null;
        return {
          growthFactorType: config.growthFactorType,
          customIntensityConfigId: null,
          value,
        };
      })
      .filter(isNotNullish);
  }

  public static getActualsFromFootprintRow(
    actuals: Array<BusinessMetricsFootprintActual>
  ): Array<BusinessMetricsActual> {
    return actuals.map((d, i) => {
      return {
        date: d.date,
        growthFactors: [
          // Footprint growth factors
          ...this.getGrowthFactorsFromCustomGrowthFactors(
            d.customGrowthFactors
          ),
          {
            growthFactorType: GrowthFactorType.Revenue,
            customIntensityConfigId: null,
            value: d.revenue,
          },
          {
            growthFactorType: GrowthFactorType.Headcount,
            customIntensityConfigId: null,
            value: d.headcount,
          },
        ],
      };
    });
  }

  private static parseFootprintGrowthFactorsToBusinessMetricsRow(
    growthFactors: BusinessMetricsActual['growthFactors']
  ): BusinessMetricsRow['growthFactors'] {
    return Object.fromEntries(
      growthFactors
        .map(({ growthFactorType, customIntensityConfigId, value }) => {
          if (value === null) return undefined;
          return [
            getBusinessMetricsKey(
              growthFactorType,
              customIntensityConfigId ?? undefined
            ),
            value,
          ] as const;
        })
        .filter(isNotNullish)
    );
  }

  public static fromActualsAndGrowth({
    actuals,
    growthFactorSeries,
    growthForecastConfigs,
    forecastIntervalStart,
    visibleIntervalStart,
  }: {
    actuals: Array<BusinessMetricsActual>;
    growthFactorSeries: GrowthFactorSeries;
    growthForecastConfigs: GrowthForecastConfigs;
    forecastIntervalStart: YearMonth;
    visibleIntervalStart: YearMonth;
  }): BusinessMetrics {
    BadInputError.invariant(
      actuals.length >= 12,
      'need at least a year of data'
    );
    const forecastValues = new Array<BusinessMetricsRow>();
    const historicalValues = new Array<BusinessMetricsRow>();
    const parsedActuals = actuals.map((actual) => ({
      date: YM.fromJSDate(actual.date),
      growthFactors: this.parseFootprintGrowthFactorsToBusinessMetricsRow(
        actual.growthFactors
      ),
    }));

    const boundActuals = parsedActuals.filter(
      ({ date }) =>
        // we only use the actuals up until a certain point for forecasting. this is so
        // the forecast is not affected as we get more and more historical data.
        date < forecastIntervalStart &&
        // Avoid errors in the case of null actuals before the visible interval
        // start (which don't matter for forecasting)
        date >= visibleIntervalStart
    );
    const boundActualsStart = must(minBy(boundActuals, (a) => a.date)).date;
    const boundActualsEnd = YM.next(
      must(maxBy(boundActuals, (a) => a.date)).date
    );

    // for all of the actuals to forecast off of, add them to our list to make a ts out of
    boundActuals.forEach((actual) => {
      const offset = YM.diff(actual.date, boundActualsStart);
      forecastValues[offset] = {
        growthFactors: actual.growthFactors,
        forecasted: false,
      };
    });

    // Clamp the end of the historical timeseries to its most recent fiscal
    // year, since partial fiscal years won't be used for anything, and add them
    // to our list to make a ts out of.
    const fiscalYearMonth = YM.month(forecastIntervalStart);
    const actualsEnd = YM.next(must(maxBy(parsedActuals, (a) => a.date)).date);
    const fyAlignedHistoricalIntervalEnd =
      FiscalYear.findStartingFiscalYearMonthForYearMonth(
        actualsEnd,
        fiscalYearMonth
      );
    parsedActuals
      .filter(({ date }) => date < fyAlignedHistoricalIntervalEnd)
      .forEach((actual) => {
        const offset = YM.diff(actual.date, boundActualsStart);
        historicalValues[offset] = {
          growthFactors: actual.growthFactors,
          forecasted: false,
        };
      });

    // Validate that we have values for the entire actuals period
    // TODO: UI could be updated to handle gaps in the actuals
    for (let i = 0; i < forecastValues.length; i++) {
      if (!forecastValues[i]) {
        const date = YM.plus(boundActualsStart, i);
        throw new Error(`missing actual: ${date}`);
      }
    }

    const revenueGrowth = growthFactorSeries[GrowthFactorType.Revenue];
    const headcountGrowth = growthFactorSeries[GrowthFactorType.Headcount];
    const revenueInterval = revenueGrowth.interval();
    const headcountInterval = headcountGrowth.interval();
    invariant(
      isEqual(revenueInterval, headcountInterval),
      'unequal growth intervals'
    );

    if (growthFactorSeries) {
      // make sure that each growth series is correctly sized
      Object.values(growthFactorSeries).map((growth) => {
        invariant(
          growth && isEqual(revenueInterval, growth.interval()),
          'unequal growth intervals'
        );
      });
    }

    const projectionInterval = new YMInterval(
      boundActualsEnd,
      revenueInterval.end
    );

    // Growth is expressed as year-over-year percentages. It would be easiest to
    // turn these into implied month-over-month multipliers and to calculate
    // future business metrics by exponentiating against a single reference
    // month, but unfortunately that doesn't produce the expected results on a
    // yearly basis. That is, if you said 2020 had 15% more revenue than 2019,
    // then adding up monthly 2020 revenue should produce a number 15% larger
    // than you'd get by adding up monthly 2019 revenue.
    //
    // Instead, we calculate each month as the yearly growth percentage times
    // the value 12 months prior.
    for (const month of projectionInterval.iter('month')) {
      const oneYearAgo = forecastValues[forecastValues.length - 12];

      const oldCustomGrowthFactorSeries: Record<
        BusinessMetricsKey,
        number | undefined
      > = {};
      if (growthFactorSeries) {
        // if custom growth factors were passed in, we continue here
        const oneYearAgoCustomGrowthFactors = oneYearAgo?.growthFactors;
        UnexpectedError.invariant(
          oneYearAgoCustomGrowthFactors,
          `last year's growth factors must exist`,
          {
            data: {
              forecastValues,
            },
          }
        );

        // Which intensities are we working with?
        getObjectKeys(growthForecastConfigs).forEach((businessMetricsKey) => {
          const forecast = growthFactorSeries[businessMetricsKey];
          if (growthFactorSeries[businessMetricsKey] === undefined) {
            return;
          }
          invariant(
            forecast,
            'this must exist because we filter out the non-existent ones'
          );
          const lastYearGrowthFactor =
            oneYearAgoCustomGrowthFactors[businessMetricsKey];
          invariant(
            isNotNullish(lastYearGrowthFactor),
            `must have previous ${businessMetricsKey}`
          );
          oldCustomGrowthFactorSeries[businessMetricsKey] =
            forecast.multiplierAt(month) * lastYearGrowthFactor;
        });
      }
      const prevRevenue = oneYearAgo.growthFactors[GrowthFactorType.Revenue];
      const revenue = isNotNullish(prevRevenue)
        ? revenueGrowth.multiplierAt(month) * prevRevenue
        : null;
      const prevHeadcount =
        oneYearAgo.growthFactors[GrowthFactorType.Headcount];
      const headcount = isNotNullish(prevHeadcount)
        ? headcountGrowth.multiplierAt(month) * prevHeadcount
        : null;
      forecastValues.push({
        growthFactors: {
          ...oldCustomGrowthFactorSeries,
          [GrowthFactorType.Headcount]: headcount,
          [GrowthFactorType.Revenue]: revenue,
        },
        forecasted: true,
      });
    }

    return new BusinessMetrics({
      base: boundActualsStart,
      values: { forecastValues, historicalValues },
      growthFactorSeries: {
        ...growthFactorSeries,
        [GrowthFactorType.Revenue]: revenueGrowth,
        [GrowthFactorType.Headcount]: headcountGrowth,
      },
      growthForecastConfigs,
    });
  }

  public static fromRawForecastScenarioFields({
    historicalContributions,
    referencePeriodInterval,
    historicalPeriodInterval,
    forecastInterval,
    customIntensityConfigs,
    forecastScenario,
    fiscalMonth,
    visibleIntervalStart,
  }: {
    historicalContributions: Array<BusinessMetricsFootprintActual>;
    referencePeriodInterval: YMInterval;
    historicalPeriodInterval: YMInterval;
    forecastInterval: YMInterval;
    customIntensityConfigs: Array<CustomIntensityConfigFields>;
    forecastScenario: ForecastScenarioFields;
    fiscalMonth: number;
    visibleIntervalStart: YearMonth;
  }): BusinessMetrics {
    invariant(
      YM.month(referencePeriodInterval.start) === fiscalMonth,
      'the reference period start must be FY aligned'
    );

    const growthForecasts = forecastScenario.growthForecasts;
    const growthForecastConfigs = buildGrowthForecastConfigs(
      customIntensityConfigs
    );

    const growthFactorSeries = Object.fromEntries(
      growthForecasts.map((forecast) => {
        const businessMetricsKey = GFI.businessMetricsKey(forecast);

        // TODO(EUR-2072): Remove special-casing of revenue and headcount.
        if (
          !(
            forecast.growthFactorType === GrowthFactorType.Revenue ||
            forecast.growthFactorType === GrowthFactorType.Headcount
          )
        ) {
          const config = growthForecastConfigs[businessMetricsKey];
          invariant(config, 'we expect this to exist: ' + businessMetricsKey);
        }
        return [
          businessMetricsKey,
          FiscalYearlyPercentageTimeseries.fromGQ(forecast.forecast),
        ];
      })
    );

    invariant(
      historicalPeriodInterval.length('month') ===
        forecastScenario.historicalUserInputtedGrowthFactorDataPoints.length,
      'historical data length mismatch'
    );

    // Combine footprint historical data with user inputted growth factors
    const actualsNew = BusinessMetrics.getActualsFromFootprintRow(
      historicalContributions
    );

    const augmentedHistoricalData = actualsNew.map((d, i) => {
      const userInputtedData =
        forecastScenario.historicalUserInputtedGrowthFactorDataPoints[i];
      BadInputError.invariant(
        userInputtedData.date.getTime() === d.date.getTime(),
        "Historical periods don't match up between footprint and forecast scenario",
        {
          data: {
            footprintDate: d.date,
            userInputtedDate: userInputtedData.date,
          },
        }
      );
      return {
        ...d,
        growthFactors: d.growthFactors.concat(userInputtedData.growthFactors),
      };
    });

    const revenueForecast = must(
      growthForecasts.find(
        (forecast) => forecast.growthFactorType === GrowthFactorType.Revenue,
        'We currently always expect a forecast to have revenue data'
      )
    );
    const headcountForecast = must(
      growthForecasts.find(
        (forecast) => forecast.growthFactorType === GrowthFactorType.Headcount,
        'We currently always expect a forecast to have headcount data'
      )
    );

    return BusinessMetrics.fromActualsAndGrowth({
      // Don't filter out years past the reference year so you can display intensity graphs
      actuals: augmentedHistoricalData,
      growthFactorSeries: {
        ...growthFactorSeries,
        [GrowthFactorType.Revenue]:
          FiscalYearlyPercentageTimeseries.fromSimpleTimeseriesForForecasting(
            revenueForecast.forecast
          ),
        [GrowthFactorType.Headcount]:
          FiscalYearlyPercentageTimeseries.fromSimpleTimeseriesForForecasting(
            headcountForecast.forecast
          ),
      },
      growthForecastConfigs,
      forecastIntervalStart: forecastInterval.start,
      visibleIntervalStart,
    });
  }

  public static fromForecastScenarioFields({
    forecast,
    forecastScenario,
    fiscalMonth,
    visibleIntervalStart,
  }: {
    forecast: Pick<
      ForecastFieldsForPlan,
      | 'referencePeriodInterval'
      | 'customIntensityConfigs'
      | 'historicalPeriod'
      | 'interval'
    >;
    forecastScenario: ForecastScenarioFields;
    fiscalMonth: number;
    visibleIntervalStart: YearMonth;
  }): BusinessMetrics {
    return BusinessMetrics.fromRawForecastScenarioFields({
      historicalContributions: forecast.historicalPeriod.data,
      referencePeriodInterval: forecast.referencePeriodInterval,
      historicalPeriodInterval: forecast.historicalPeriod.interval,
      forecastInterval: forecast.interval,
      customIntensityConfigs: forecast.customIntensityConfigs,
      forecastScenario,
      fiscalMonth,
      visibleIntervalStart,
    });
  }

  forMonth(year: number, month: number): BusinessMetricsRow {
    return this.valueAt(YM.make(year, month));
  }

  metricsWithPresentDataInInterval(
    interval: YMInterval
  ): BusinessMetricKeyMap<boolean> {
    // TODO(EUR-2072): Remove special-casing of revenue and headcount.
    const possibleFactors: Array<BusinessMetricsKey> = Object.keys(
      this.growthForecastConfigs
    ).concat(GrowthFactorType.Headcount, GrowthFactorType.Revenue);
    const result = Object.fromEntries(
      possibleFactors.map((t) => [t, true])
    ) as BusinessMetricKeyMap<boolean>;

    const allFalse = Object.fromEntries(
      possibleFactors.map((t) => [t, false])
    ) as BusinessMetricKeyMap<boolean>;

    if (!this.interval().containsInterval(interval)) {
      return allFalse;
    }
    for (const month of interval.iter('month')) {
      const businessMetricsRow = this.historicalTimeseries.valueOrDefaultAt(
        month,
        undefined
      );
      if (businessMetricsRow === undefined) {
        return allFalse;
      }
      for (const businessMetricsKey of possibleFactors) {
        result[businessMetricsKey] =
          result[businessMetricsKey] &&
          isNotNullish(businessMetricsRow.growthFactors[businessMetricsKey]);
      }
    }
    return result;
  }

  /**
   *
   * @param yearMonth starting year month
   * @returns the metrics info for the 12 month period after the
   * starting year month. similar to forFiscalYear.
   */
  forYearAfterYearMonth(
    yearMonth: YearMonth,
    opts?: { onlyHistorical?: boolean }
  ): BusinessMetricsRow {
    const yearInterval = new YMInterval(
      yearMonth,
      YM.plus(yearMonth, 1, 'year')
    ).intersect(this.interval());

    let revenue = 0;
    let headcount = 0;
    let forecasted = false;
    let periodLength = 0;
    const customGrowthFactors: {
      [T in BusinessMetricsKey]?: number;
    } = {};
    const hasNonNullCustomGrowthFactors: {
      [T in BusinessMetricsKey]?: boolean;
    } = {};
    let hasNonNullRevenue = false;
    let hasNonNullHeadcount = false;

    let shouldOnlySeeUndefinedValues = false;

    if (yearInterval) {
      for (const month of yearInterval.iter('month')) {
        // if we are only looking at historical data, we only want to look
        // at the historical time series. if we don't have any data, we move on.
        const rowValue = opts?.onlyHistorical
          ? this.historicalTimeseries.valueOrDefaultAt(month, undefined)
          : this.valueAt(month);
        if (rowValue === undefined) {
          shouldOnlySeeUndefinedValues = true;
          continue;
        }
        invariant(
          !shouldOnlySeeUndefinedValues,
          'we should never see non-undefined values after seeing 1 undefined value'
        );
        hasNonNullHeadcount =
          hasNonNullHeadcount ||
          isNotNullish(rowValue.growthFactors[GrowthFactorType.Headcount]);
        hasNonNullRevenue =
          hasNonNullRevenue ||
          isNotNullish(rowValue.growthFactors[GrowthFactorType.Revenue]);
        const currentGrowthFactors = rowValue.growthFactors;
        for (const businessMetricsKey of Object.keys(currentGrowthFactors)) {
          // Special case this until we can add revenue and headcount to the configs
          // TODO(EUR-2072): Remove this special casing
          if (
            businessMetricsKey === GrowthFactorType.Headcount ||
            businessMetricsKey === GrowthFactorType.Revenue
          ) {
            continue;
          }
          const config = this.growthForecastConfigs[businessMetricsKey];
          invariant(config, 'this must exist');
          hasNonNullCustomGrowthFactors[businessMetricsKey] =
            (hasNonNullCustomGrowthFactors[businessMetricsKey] ?? false) ||
            currentGrowthFactors[businessMetricsKey] !== null;
          customGrowthFactors[businessMetricsKey] =
            GrowthForecastAggregatorOverFiscalYear(config.aggregationKind)(
              customGrowthFactors[businessMetricsKey] ?? 0,
              rowValue.growthFactors[businessMetricsKey] ?? 0
            );
        }
        revenue += rowValue.growthFactors[GrowthFactorType.Revenue] ?? 0;
        headcount += rowValue.growthFactors[GrowthFactorType.Headcount] ?? 0;
        forecasted = rowValue.forecasted;
        periodLength += 1;
      }
      headcount /= periodLength;
    }

    const customGrowthValuesReturnObject = Object.fromEntries(
      Object.entries(customGrowthFactors).map(([key, val]) => {
        return [key, hasNonNullCustomGrowthFactors[key] ? val : null];
      })
    );

    return {
      growthFactors: {
        [GrowthFactorType.Revenue]: hasNonNullRevenue ? revenue : null,
        [GrowthFactorType.Headcount]: hasNonNullHeadcount ? headcount : null,
        ...customGrowthValuesReturnObject,
      },
      forecasted,
    };
  }

  public static divideYearlyMetric(
    yearlyMetric: YearlyMetric,
    divisor: number
  ): YearlyMetric {
    return {
      forecast: yearlyMetric.forecast.map((_ym, t) => t / divisor),
      historical: yearlyMetric.historical.map((_ym, t) => t / divisor),
    };
  }

  /**
   * Checks to see if all growth factors present in baseInterval are positive in checkInterval
   * @param baseInterval The interval to check for growth factors existence
   * @param checkInterval The interval to check for growth factors positivity
   * @returns
   */
  public allPresentValuesArePositive(
    baseInterval: YMInterval,
    checkInterval: YMInterval
  ): boolean {
    const metricTypeIsPresent =
      this.metricsWithPresentDataInInterval(baseInterval);
    const metrics = getObjectKeys(metricTypeIsPresent)
      .filter((m) => metricTypeIsPresent[m])
      .map((m) => this.toYearlyMetricForIntervalHelper(checkInterval, m));
    return metrics.every((m) =>
      [...m.historical.values, ...m.forecast.values].every((v) => v > 0)
    );
  }

  /**
   * @param interval The interval within which to return metrics. Must be aligned to the org's fiscal month
   * @param growthFactorType The growth factor type to return
   * @param customIntensityConfigId If the growth factor type is Custom, then this should be defined
   * @returns Annualized business metrics aligned to the org's fiscal month
   */
  public toYearlyMetricForInterval(
    interval: YMInterval,
    growthFactor: GrowthFactorIdentifier
  ): YearlyMetric {
    return this.toYearlyMetricForIntervalHelper(
      interval,
      GFI.businessMetricsKey(growthFactor)
    );
  }

  private toYearlyMetricForIntervalHelper(
    interval: YMInterval,
    metric: BusinessMetricsKey
  ): YearlyMetric {
    invariant(
      YM.month(interval.start) === YM.month(interval.end) &&
        interval.length('year') >= 1,
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      `interval must be yearly and have at least one year, but got ${interval.toFormat()} for metric ${metric}`
    );

    const getMetric = (row: BusinessMetricsRow): number | null => {
      return row.growthFactors[metric] ?? null;
    };

    const getHistoricalAndForecastValues = (yearStart: YearMonth) => {
      const forecastRow = this.forYearAfterYearMonth(yearStart);
      const historicalRow = this.forYearAfterYearMonth(yearStart, {
        onlyHistorical: true,
      });
      return {
        forecast: must(
          getMetric(forecastRow),
          `must have forecasted ${metric} for fiscal year starting in ${yearStart}`
        ),
        historical: getMetric(historicalRow),
      };
    };

    const values = interval.map('year', getHistoricalAndForecastValues);

    return {
      forecast: new FiscalYearlyTimeseries(
        interval.start,
        values.map(({ forecast }) => forecast)
      ),
      historical: new FiscalYearlyTimeseries(
        interval.start,
        values.map(({ historical }) => historical).filter(isNotNullish)
      ),
    };
  }
}

export function getBusinessMetricsKey(
  growthFactorType: GrowthFactorType,
  customIntensityConfigId?: string | null
): BusinessMetricsKey {
  if (customIntensityConfigId) {
    return `${growthFactorType}__${customIntensityConfigId}`;
  }
  return growthFactorType;
}
