import {
  LAUNCHED_LOCALES,
  SUPPORTED_LOCALES,
  SupportedLocale,
} from '@watershed/intl/constants';
import { Flags } from '@watershed/constants/flags';

import { useUserContext } from './UserContext';
import { useFeatureFlag } from './FeatureFlag';

export function useAvailableLocales(): Array<SupportedLocale> {
  const userContext = useUserContext();
  const showAllLanguages = useFeatureFlag(Flags.ShowAllLanguages);
  return userContext.userIsWatershedEmployee ||
    userContext.userIsWatershedContractor ||
    showAllLanguages
    ? SUPPORTED_LOCALES
    : LAUNCHED_LOCALES;
}
