import {
  GQPlan,
  GQSbtScope1And2Target,
  GQSbtScope3Target,
  GQSbtTermLength,
} from '@watershed/shared-universal/generated/graphql-schema-types';
import { PlanTargetIntensityType } from '@watershed/constants/reductions';

import { YM, FiscalYear, YearMonth } from './YearMonth';
import SBTCalculator from '../forecast/SBTCalculator';

export const PREVIEW_PLAN_NAME_PREFIX = '[PREVIEW] ';
export const LONG_FORM_YEAR_COUNT = 15;

export function getPlanNetZeroYear(plan: {
  variables: Pick<
    GQPlan['variables'],
    'commitmentsHasNetZero' | 'commitmentsNetZeroYear'
  >;
}): number | undefined {
  const variables = plan.variables;
  return variables.commitmentsHasNetZero
    ? variables.commitmentsNetZeroYear
    : undefined;
}

export interface PlanVariables {
  commitmentsHasNetZero: boolean;
  commitmentsNetZeroYear: number;
  cleanPowerStart: YearMonth;
  flightsPerEmployeeForecastEnd: number;
  percentWorkFromHomeForecastEnd: number;
  commitmentsSBTScope3: GQSbtScope3Target;
  commitmentsHasSBT: boolean;
  commitmentsSBTTargetYear: number;
  commitmentsSBTScope12: GQSbtScope1And2Target;
  commitmentsSBTTermLength: GQSbtTermLength;
  commitmentsSBTSubmissionDate: YearMonth;
  netZeroSuppliersReductionPercent: number;
}

/**
 * Return the default configuration for SBT plans based on minimal args.
 */
export function getDefaultSbtPlanVariablesConfiguration(
  termLength: GQSbtTermLength,
  args: {
    planSubmissionYearMonth: YearMonth;
    baselineYearStart: YearMonth;
  }
): Pick<
  PlanVariables,
  | 'commitmentsHasSBT'
  | 'commitmentsSBTTargetYear'
  | 'commitmentsSBTScope12'
  | 'commitmentsSBTScope3'
  | 'commitmentsSBTTermLength'
  | 'commitmentsSBTSubmissionDate'
> {
  return termLength === 'NearTerm'
    ? getNearTermSbtPlanVariablesConfiguration(args)
    : getLongTermSbtPlanVariablesConfiguration(args);
}

function getLongTermSbtPlanVariablesConfiguration(args: {
  planSubmissionYearMonth: YearMonth;
  baselineYearStart: YearMonth;
}): Pick<
  PlanVariables,
  | 'commitmentsHasSBT'
  | 'commitmentsSBTTargetYear'
  | 'commitmentsSBTScope12'
  | 'commitmentsSBTScope3'
  | 'commitmentsSBTTermLength'
  | 'commitmentsSBTSubmissionDate'
> {
  const sbtCalculator = new SBTCalculator({
    termLength: 'LongTerm',
    scope12Target: 'OnePointFiveC',
    scope3Target: 'OnePointFiveC',
    fiscalMonth: YM.month(args.baselineYearStart),
    submissionYm: args.planSubmissionYearMonth,
  });
  const longTermNonSupplierTargetEndExclusive =
    sbtCalculator.getDateBoundsForSbtTarget({
      intensityType: PlanTargetIntensityType.Absolute,
    }).end;
  return {
    commitmentsHasSBT: true,
    commitmentsSBTTargetYear: YM.year(
      FiscalYear.findStartingFiscalYearMonthForYearMonth(
        YM.minus(longTermNonSupplierTargetEndExclusive, 1, 'year'),
        YM.month(args.baselineYearStart)
      )
    ),
    commitmentsSBTScope12: 'OnePointFiveC',
    commitmentsSBTScope3: 'OnePointFiveC',
    commitmentsSBTTermLength: 'LongTerm',
    commitmentsSBTSubmissionDate: args.planSubmissionYearMonth,
  };
}

function getNearTermSbtPlanVariablesConfiguration(args: {
  planSubmissionYearMonth: YearMonth;
  baselineYearStart: YearMonth;
}): Pick<
  PlanVariables,
  | 'commitmentsHasSBT'
  | 'commitmentsSBTTargetYear'
  | 'commitmentsSBTScope12'
  | 'commitmentsSBTScope3'
  | 'commitmentsSBTTermLength'
  | 'commitmentsSBTSubmissionDate'
> {
  // The longest target will be the absolute near term target, so we'll treat that as the
  // SBT target end date.
  const sbtCalculator = new SBTCalculator({
    termLength: 'NearTerm',
    scope12Target: 'OnePointFiveC',
    scope3Target: 'WellBelowTwoC',
    fiscalMonth: YM.month(args.baselineYearStart),
    submissionYm: args.planSubmissionYearMonth,
  });
  const nearTermNonSupplierTargetEndExclusive =
    sbtCalculator.getDateBoundsForSbtTarget({
      intensityType: PlanTargetIntensityType.Absolute,
    }).end;
  return {
    commitmentsHasSBT: true,
    commitmentsSBTTargetYear: YM.year(
      FiscalYear.findStartingFiscalYearMonthForYearMonth(
        YM.minus(nearTermNonSupplierTargetEndExclusive, 1, 'year'),
        YM.month(args.baselineYearStart)
      )
    ),
    commitmentsSBTScope12: GQSbtScope1And2Target.OnePointFiveC,
    commitmentsSBTScope3: GQSbtScope3Target.WellBelowTwoC,
    commitmentsSBTTermLength: GQSbtTermLength.NearTerm,
    commitmentsSBTSubmissionDate: args.planSubmissionYearMonth,
  };
}

export function getDefaultPlanSubmissionYm(
  planCreatedAt: YearMonth
): YearMonth {
  // It usually takes CAs about a quarter to go from 0 to approved plan.
  return YM.plus(planCreatedAt, 4, 'month');
}
