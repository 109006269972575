import {
  PermissionType,
  PermissionObjectType,
} from '@watershed/constants/permissions';

import assertNever from '@watershed/shared-util/assertNever';
import mapStrToEnum from '../utils/mapStrToEnum';
import isEqual from 'lodash/isEqual';
import {
  getPermissionOrigin,
  SourceObjectWithPermissionDelegate,
} from './permissionsHierarchy';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import {
  Action,
  ResourceSpecificPermissionType,
} from '@watershed/shared-universal/permissions/permissionTypes';
import {
  getLegacyPermissionFromResourceAndAction,
  RESOURCE_LEVEL_PERMISSION_TYPES,
} from '@watershed/shared-universal/permissions/permissionsConfig';

export type PermissionSelectionItem = {
  id?: string;
  permission: PermissionType;
  objectId?: string;
  objectType?: PermissionObjectType;
  removedBy?: string;
};

export type UserPermission = {
  permission: PermissionType;
  objectId?: string | null;
  objectType?: PermissionObjectType | null;
};

export type UserPermissions = Array<UserPermission>;

/**
 * Asserts the user has any of the required permissions
 */
export function hasPermission(
  userPermissions: Readonly<UserPermissions>,
  allowList: ReadonlyArray<PermissionType>,
  {
    allowAnyObject,
    source,
  }: {
    allowAnyObject?: boolean;
    source?: SourceObjectWithPermissionDelegate;
  } = {}
): boolean {
  return allowList.some((allowPermission) =>
    userPermissions.some(
      ({ permission, objectId }) =>
        !!getPermissionOrigin({
          permission,
          allowPermission,
          objectId,
          allowAnyObject,
          source,
        })
    )
  );
}

// A permission item can either be object-specific or not, i.e. is there an
// object associated with it . For example, "Manage datasource X" and
// "Manage supplier Y" are object-specific, whereas "View reductions" is not.
export function isObjectSpecificPermission(
  permission: PermissionType
): boolean {
  switch (permission) {
    case PermissionType.ManageDataset:
    case PermissionType.ManageDatasource:
    case PermissionType.ApproveDatasource:
    case PermissionType.ApproveDataset:
    case PermissionType.ManageDisclosures:
    case PermissionType.ManageFund:
    case PermissionType.FinanceReadOnly:
    case PermissionType.EditReport:
    case PermissionType.ViewReport:
    case PermissionType.EditReportQuestionInstance:
    case PermissionType.ViewReportQuestionInstance:
    case PermissionType.ApproveFootprint:
    case PermissionType.ViewFootprint:
      return true;
    case PermissionType.Admin:
    case PermissionType.CorporateAdmin:
    case PermissionType.FinanceAdmin:
    case PermissionType.ManageMarketplacePurchases:
    case PermissionType.ManageMeasurement:
    case PermissionType.ManageCompanyTags:
    case PermissionType.ManageOrgHierarchy:
    case PermissionType.ManageReductionPlans:
    case PermissionType.ManageSingleSignOn:
    case PermissionType.ManageSuppliers:
    case PermissionType.ViewEmployeeReport:
    case PermissionType.ViewFootprintDetail:
    case PermissionType.ViewAuditDetail:
    case PermissionType.ViewLearningHub:
    case PermissionType.ViewReductions:
    case PermissionType.WatershedAdmin:
    case PermissionType.AnyUser:
      return false;
    default:
      // If we add a new permission type, make sure to update this.
      assertNever(permission);
  }
}

export function toPermissionSelectionItem(permission: {
  permission: PermissionType;
  objectId?: string | null;
}): PermissionSelectionItem {
  if (permission.objectId) {
    const resourcePermissionConfig =
      RESOURCE_LEVEL_PERMISSION_TYPES[
        permission.permission as ResourceSpecificPermissionType
      ];
    return {
      permission: permission.permission,
      objectId: permission.objectId,
      objectType: resourcePermissionConfig.resourceType,
    };
  } else {
    return {
      permission: permission.permission,
    };
  }
}

export const GlobalPermissions = pick(PermissionType, [
  PermissionType.Admin,
  PermissionType.WatershedAdmin,
]);
export const FinancePermissions = pick(PermissionType, [
  PermissionType.FinanceAdmin,
  PermissionType.ManageFund,
  PermissionType.FinanceReadOnly,
]);

export const CorporatePermissions = omit(PermissionType, [
  ...Object.values(GlobalPermissions),
  ...Object.values(FinancePermissions),
]);

export function getPermissionsToRevoke(
  currentPermissions: Array<PermissionSelectionItem>,
  permissionSelection: Array<PermissionSelectionItem>
): Array<PermissionSelectionItem> {
  return currentPermissions.filter((current) => {
    // Object-specific permission like "Manage datasource X" will always show
    // up in the AddPermissionDialog so that users can add/remove associated
    // objects. A non-object-specific permission like "View reductions" only
    // shows up in the AddPermissionDialog if the user does not already have
    // it. We only need to handle permission revocation for object-specific
    // permissions here. Non-object-specific permissions can only be revoked in
    // UserPermissionsList.
    if (!isObjectSpecificPermission(current.permission)) {
      return false;
    }

    // If the selected list doesn't have the current object-specific
    // permission, that means the user hasn't changed it, so we don't need to
    // do anything.
    if (!permissionSelection.some((p) => p.permission === current.permission)) {
      return false;
    }

    // If an object-specific permission is in the current list but not the
    // selected list, the user must have unchecked it in the dialog. In that
    // case, we should revoke it. For example, if the user has "Manage
    // datasource A, B, C" whereas the selected list only has "Manage
    // datasource A, B", we should revoke the permission for datasource C.
    return !permissionSelection.some((selected) => isEqual(selected, current));
  });
}

export function userCanGrantPermission(
  userPermissions: UserPermissions,
  permission: PermissionType
): boolean {
  return hasPermission(userPermissions, [permission], {
    allowAnyObject: true,
  });
}

// Returns the permissions that a Watershed employee should have. The context
// here is that the permissions dialog is currently shared between dashboard
// and admin, and it should only show the permissions that the current user
// have permissions to grant. On dashboard, this is the logged-in user. On
// admin, we presume that the logged-in user is a Watershed employee.
export function getPermissionsForWatershedAdminUser(): UserPermissions {
  return [{ permission: PermissionType.WatershedAdmin }];
}

// For permissions for a value mapping-related object we hardcode the object name to be "Value mappings" for now
export const VALUE_MAPPING_OBJECT_NAME = 'Value mappings';

/**
 * TODO (tzv): Remove or add unit tests if we keep this function
 * Translates the current data structure of user permissions to the new format
 * which I think is more structured and intuitive but I may be wrong.
 */

export function permissionItemToPermissionId(
  permissionItem: UserPermission
): string {
  const resourcePermissionConfig =
    RESOURCE_LEVEL_PERMISSION_TYPES[
      permissionItem.permission as ResourceSpecificPermissionType
    ];
  if (resourcePermissionConfig) {
    return `${resourcePermissionConfig.resourceType}:${
      resourcePermissionConfig.action
    }:${permissionItem.objectId ?? 'all'}`;
  }
  return permissionItem.permission;
}

export function permissionIdToPermissionItem(
  permissionId: string
): UserPermission {
  const [permissionOrResourceType, action, objectId] = permissionId.split(':');
  if (action && objectId) {
    const objectType = mapStrToEnum(
      permissionOrResourceType,
      PermissionObjectType
    );
    const permission = getLegacyPermissionFromResourceAndAction(
      objectType,
      mapStrToEnum(action, Action)
    );
    if (objectId === 'all') {
      return { permission };
    }
    return {
      permission,
      objectType,
      objectId,
    };
  }
  return {
    permission: permissionOrResourceType as PermissionType,
  };
}
