import { getTeamInfo, TeamInfo, Teams } from '@watershed/constants/teams';
import { LINKS } from '../fund/financeFieldRegistry';
import { GQFinanceTagType, GQGridColumnFormat } from '../generated/graphql';
import { makeBetterEnum } from '../utils/betterEnum';
import isIncludedIn from '../utils/isIncludedIn';
import { ReportComponentType } from './config/ReportComponentSchemas';
import { ReportingIntention } from './types/reportingIntention';

export const TRAJECTORY_PREFIXES = ['low', 'medium', 'high'] as const;
export const IMPACT_PREFIXES = ['short', 'medium', 'long'] as const;
export type TrajectoryPrefix = (typeof TRAJECTORY_PREFIXES)[number];

export const TRAJECTORY_TO_DEGREES: Record<TrajectoryPrefix, string> = {
  high: '1.5°C',
  medium: '2-3°C',
  low: '4°C',
};

export const DISPLAY_COMPONENT_IDS = [
  'TextInput',
  'YesNoInput',
  'RadioGroupInput',
  'IntervalSelector',
  'PastYearsSelector',
  'Selector',
  'CountrySelector',
  'CurrencySelector',
  'DrilldownSavedViewSelector',
  'SingleValue',
  'StandardTable',
  'StandardTableExportButton',
  'SecrStandardTable',
  'SecrInteractiveTable',
  'InteractiveTable',
  'ExpectedReductions',
  'ReductionPlanSelector',
  'TcfdRisksLibrary',
  'TcfdOpportunitiesLibrary',
  'TcfdEducation',
  'TcfdRiskImpact',
  'TcfdOpportunityImpact',
  'ReviewSection',
  'Callout',
  'SectionHeader',
  'CollapsibleInfoCard',
  'SheetInfoCard',
  'SingleFundSelector',
  'MultiFundSelector',
  'SfdrIndicatorSelector',
  'SfdrIndicatorsLibrary',
  'YearSelector',
  'EducationPillars',
  'EducationCard',
  'NumberInput',
  'CdpMinimumFiler',
  'CdpApiRegistration',
  'CdpEducation',
  'Cdp2024Education',
  'CdpC71',
  'DatePicker',
  'FootprintOverrideSelector',
  'CdpEnergyDataSetupTable',
  'Cdp2024EnergyDataSetupTable',
  'Survey',
  'NetZeroTargetYear',
  'ChecklistCallout',
  'BiQueryResult',
  'BiQueryCard',
  'CardControls',
  'ReportControls',
  'CustomReportHeading',
  'MaterialityAssessmentSelector',
] as const;
export type DisplayComponentId = (typeof DISPLAY_COMPONENT_IDS)[number];
export function isDisplayComponentId(id: string): id is DisplayComponentId {
  return isIncludedIn(id, DISPLAY_COMPONENT_IDS);
}

/**
 * The following component types support approvals.
 */
export const APPROVALS_SUPPORTED_COMPONENT_IDS = [
  'TextInput',
  'YesNoInput',
  'NumberInput',
] as const;
export function isApprovalsSupportedComponentId(
  id: string
): id is ReportComponentType {
  return isIncludedIn(id, APPROVALS_SUPPORTED_COMPONENT_IDS);
}

// Allowlist of component types that can be overriden.
const OVERRIDABLE_COMPONENT_IDS: Array<ReportComponentType> = [
  'StandardTable',
  'BiQueryResult',
  'SingleValue',
  'InteractiveTable',
] as const;
export function isOverridableComponentId(
  id: string
): id is ReportComponentType {
  return isIncludedIn(id, OVERRIDABLE_COMPONENT_IDS);
}

export const REPORT_TYPE_CUSTOM = 'Custom';

// arbitrarily pulled from here: https://www.ibisworld.com/united-kingdom/list-of-industries/
export const TCFD_SECTORS = [
  'Accommodation and Food Service Activities',
  'Electricity, Gas, Steam and Air Conditioning Supply',
  'Education',
  'Real Estate Activities',
  'Manufacturing',
  'Arts, Entertainment and Recreation',
  'Information and Communication',
  'Administrative and Support Service Activities',
  'Human Health and Social Work Activities',
  'Other Service Activities',
  'Mining and Quarrying',
  'Professional, Scientific and Technical Activities',
  'Transportation and Storage',
  'Water Supply; Sewerage, Waste Management and Remediation Activities',
  'Financial and Insurance Activities',
  'Construction',
  'Agriculture, Forestry and Fishing',
  'Wholesale and Retail Trade',
  'Advisory & Financial Services',
  'Consumer Goods & Services',
  'Online Retail',
  'Life Sciences',
  'Business Franchises',
  'Specialist Engineering, Infrastructure & Contractors',
  'Technology',
] as const;

export enum EdciReportTagNames {
  GeneralPartner = 'General partner',
  HqCountry = 'HQ country',
  CompanyStructure = 'Company structure',
  GrowthStage = 'Growth stage',
  PercentGpOwnership = 'Percent GP ownership',
  PercentFundOwnership = 'Percent fund ownership',
  PrimarySectorOfOperationsSicsCode = 'Primary sector of operations SICS code',
  PrimaryIndustryOfOperationsSicsCode = 'Primary industry of operations SICS code',
  PrimarySubIndustryOfOperationsGicsName = 'Primary sub-industry of operations GICS name',
  HeadcountInPreviousYear = 'Headcount in previous year',
  Scope1Methodology = 'Scope 1 methodology',
  Scope2Methodology = 'Scope 2 methodology',
  Scope3Methodology = 'Scope 3 methodology',
  EmissionsMethodologyCommentary = 'Emissions methodology commentary',
  EmissionsAssuranceCommentary = 'Emissions assurance commentary',
  DecarbonizationPlan = 'Decarbonization plan',
  ShortTermGhgTarget = 'Short-term GHG target',
  NetZeroGhgTarget = 'Net zero GHG target',
  GhgTargetCommentary = 'GHG target commentary',
  TotalEnergyConsumptionKwh = 'Total energy consumption (kWh)',
  RenewableEnergyConsumptionKwh = 'Renewable energy consumption (kWh)',
  TotalNumberOfBoardMembers = 'Total number of board members',
  NumberOfWomenBoardMembers = 'Number of women board members',
  NumberOfLgbtqBoardMembers = 'Number of LGBTQ board members',
  NumberOfBoardMembersFromUnderRepresentedGroups = 'Number of board members from under-represented groups',
  TotalNumberOfCSuiteEmployees = 'Total number of C-Suite employees',
  NumberOfWomenCSuiteEmployees = 'Number of women C-Suite employees',
  NumberOfWorkRelatedInjuries = 'Number of work-related injuries',
  NumberOfWorkRelatedFatalities = 'Number of work-related fatalities',
  DaysLostDueToInjury = 'Days lost due to injury',
  NetChangeInFtesDueToMAndA = 'Net change in FTEs due to M&A',
  OrganicNetNewHires = 'Organic net new hires',
  TotalNetNewHires = 'Total net new hires',
  AnnualPercentTurnoverInFtes = 'Annual percent turnover in FTEs',
  EmployeeSurvey = 'Employee survey',
  PercentOfEmployeesRespondingToSurvey = 'Percent of employees responding to survey',
}

export const EdciReportTagNameValues = Object.values(EdciReportTagNames);

export const EdciReportTags = [
  {
    name: EdciReportTagNames.HqCountry,
    columnFormat: GQGridColumnFormat.String,
    description:
      'EDCI metric. This is not used for Watershed estimations; the "Country" field in Watershed refers to primary country of operations.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.CompanyStructure,
    columnFormat: GQGridColumnFormat.SingleSelect,
    description:
      'Optional EDCI metric. Classifies the company as public or private.',
    columnConfiguration: {
      columnFormat: GQGridColumnFormat.SingleSelect,
      options: ['Public', 'Private'],
    },
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.GrowthStage,
    columnFormat: GQGridColumnFormat.SingleSelect,
    description:
      'EDCI metric. Classifies the company as venture, growth, or buyout (self-defined).',
    columnConfiguration: {
      columnFormat: GQGridColumnFormat.SingleSelect,
      options: ['Venture', 'Growth', 'Buyout'],
    },
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.PercentGpOwnership,
    description:
      'EDCI metric. Total holdings that you as a GP control through equity, between 0-100%. Note that percent GP ownership should include co-investment share with effective GP control.',
    columnFormat: GQGridColumnFormat.Float,
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.PercentFundOwnership,
    description:
      'EDCI metric. Total holdings that you as a GP control via that specific fund, between 0-100%. In the majority of cases, this will be same as the attribution factor.',
    columnFormat: GQGridColumnFormat.Float,
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.PrimarySectorOfOperationsSicsCode,
    description:
      'EDCI metric. Sector according to SASB Sustainable Industry Classification System (SICS). Note that Sector is broader than Industry. Also, note that Watershed does not use SICS codes for estimations.',
    columnFormat: GQGridColumnFormat.String,
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.PrimaryIndustryOfOperationsSicsCode,
    columnFormat: GQGridColumnFormat.String,
    description:
      'EDCI metric. Industry according to SASB Sustainable Industry Classification System (SICS). Note that Industry is nested under Sector. Also, note that Watershed does not use SICS codes for estimations.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.PrimarySubIndustryOfOperationsGicsName,
    columnFormat: GQGridColumnFormat.String,
    description:
      'EDCI metric. Sub-Industry according to Global Industry Classification Standard (GICS). Also, note that Watershed does not use GICS codes for estimations.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.HeadcountInPreviousYear,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'Number of Full Time Equivalent (FTE) employees at the end of the calendar year prior to the year for which data is being provided.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.Scope1Methodology,
    columnFormat: GQGridColumnFormat.SingleSelect,
    description:
      'EDCI metric. Classifies the scope 1 methodology as spend-based, activity-based, direct monitoring, or other.',
    columnConfiguration: {
      columnFormat: GQGridColumnFormat.SingleSelect,
      options: ['Spend-based', 'Activity-based', 'Direct Monitoring', 'Other'],
    },
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.Scope2Methodology,
    columnFormat: GQGridColumnFormat.SingleSelect,
    description:
      'EDCI metric. Classifies the scope 2 methodology as location-based, market-based, or other.',
    columnConfiguration: {
      columnFormat: GQGridColumnFormat.SingleSelect,
      options: ['Location-based', 'Market-based', 'Other'],
    },
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.Scope3Methodology,
    columnFormat: GQGridColumnFormat.SingleSelect,
    description:
      'EDCI metric. Classifies the scope 3 methodology as spend-based, activity-based, supplier-based, or other.',
    columnConfiguration: {
      columnFormat: GQGridColumnFormat.SingleSelect,
      options: [
        'Spend-based',
        'Activity-based',
        'Direct Emissions/Supplier-based',
        'Other',
      ],
    },
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.EmissionsMethodologyCommentary,
    description:
      'Optional EDCI metric. Any commentary on the emissions methodologies used.',
    columnFormat: GQGridColumnFormat.String,
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: 'Emissions assurance commentary',
    description:
      'EDCI metric. Commentary on whether emissions data has been reviewed by an external 3rd party.',
    columnFormat: GQGridColumnFormat.String,
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.DecarbonizationPlan,
    description:
      'EDCI metric. Notes whether a company has a decarbonization strategy/plan or not, and if so, whether the board has oversight.',
    columnFormat: GQGridColumnFormat.SingleSelect,
    columnConfiguration: {
      columnFormat: GQGridColumnFormat.SingleSelect,
      options: [
        'No',
        'Yes, but without board oversight',
        'Yes, with board oversight',
      ],
    },
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.ShortTermGhgTarget,
    description:
      'EDCI metric. Notes whether a company has a short-term (I.e., 5- to 10-years) GHG emission reduction target in place, and if so, whether the target is Paris-aligned.',
    columnFormat: GQGridColumnFormat.SingleSelect,
    columnConfiguration: {
      columnFormat: GQGridColumnFormat.SingleSelect,
      options: [
        'No',
        'Yes, but it is not Paris-aligned',
        'Yes, and it is Paris–aligned (covering  Scope 1, 2, & material Scope 3)',
      ],
    },
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.NetZeroGhgTarget,
    description:
      'EDCI metric. Notes whether a company has a Paris-aligned net zero goal.',
    columnFormat: GQGridColumnFormat.SingleSelect,
    columnConfiguration: {
      columnFormat: GQGridColumnFormat.SingleSelect,
      options: [
        'No - and no plan to set one (e.g. lack of viable pathway)',
        'No - but we plan to establish this in the near term (<2 years)',
        'No - we have a long-term goal but not fully aligned with a net zero pathway (i.e., NZ emissions by 2050 or sooner)',
        'Yes - aligned with a net zero pathway (i.e., NZ emissions by 2050 or sooner)',
      ],
    },
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.GhgTargetCommentary,
    description:
      'Optional EDCI metric. Any commentary on the target-setting methodologies used.',
    columnFormat: GQGridColumnFormat.String,
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.TotalEnergyConsumptionKwh,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'EDCI metric. The scope of energy consumption includes only energy directly consumed by the entity during the reporting period. The scope of energy consumption includes energy from all sources, including energy purchased from sources external to the entity and energy produced by the entity itself (self-generated). For example, direct fuel usage, purchased electricity, and heating, cooling, and steam energy are all included within the scope of energy consumption.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.RenewableEnergyConsumptionKwh,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'EDCI metric. Total renewable energy consumed from: geothermal, solar, sustainably sourced biomass (including biogas), hydropower and wind energy sources. Accounting should follow best practices outlined in RE100 and GHG Protocol Scope 2 Guidance.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.TotalNumberOfBoardMembers,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'EDCI metric. Number of people on the Board at end of the calendar year.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.NumberOfWomenBoardMembers,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'EDCI metric. Number of women on board of directors at end of the calendar year.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.NumberOfLgbtqBoardMembers,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'Optional EDCI metric. Number of people self-identified as LGBTQ on board of directors at the end of the calendar year.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.NumberOfBoardMembersFromUnderRepresentedGroups,
    columnFormat: GQGridColumnFormat.Float,
    description:
      "Optional EDCI metric. Number of people self- identified as belonging to an under-represented group (i.e. belonging to an ethnic minority within a given country's context). Given the varying local contexts, this metric is not designed to be compared across geographies, but can provide helpful insights within a given country.",
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.TotalNumberOfCSuiteEmployees,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'Optional EDCI metric. Number of people in C-suite positions at the end of the calendar year (CEO and any senior executives reporting directly to the CEO, e.g. CFO, COO, CAO, Head of HR etc.as defined in ILPA’s Diversity Metrics Template).',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.NumberOfWomenCSuiteEmployees,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'Optional EDCI metric. Number of women in C-suite positions at the end of the calendar year.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.NumberOfWorkRelatedInjuries,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'EDCI metric. Total number of work- related injuries, as defined by local jurisdiction. Injury records could come from national systems as part of a primary data source (e.g., labor inspection records and annual reports; insurance and compensation records, death registers), supplemented by surveys.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.NumberOfWorkRelatedFatalities,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'EDCI metric. Total number of work- related fatalities as defined by local jurisdiction, within the last calendar year. Fatality records could come from national systems as part of primary data source (e.g., labor inspection records and annual reports; insurance and compensation records, death registers), supplemented by surveys.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.DaysLostDueToInjury,
    columnFormat: GQGridColumnFormat.Float,
    description: 'EDCI metric. Total days lost due to work-related injury.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.NetChangeInFtesDueToMAndA,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'EDCI metric. Net change in FTEs due to mergers, acquisitions, and divestitures.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.OrganicNetNewHires,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'EDCI metric. New hires (the number of FTE joining the company, excluding hires that result from M&A) less turnover (the number of FTE leaving the business, excluding those from M&A) during a given calendar year. Excludes any FTE growth or decline due to a business acquisition or business unit divestiture.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.TotalNetNewHires,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'EDCI metric. New hires (the number of FTE joining the company, excluding hires that result from M&A) less turnover (the number of FTE leaving the business, excluding those from M&A) plus changes due to M&A (the net change in employees due to M&A) during a given calendar year.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.AnnualPercentTurnoverInFtes,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'EDCI metric. Turnover (the number of FTEs leaving the business excluding those from M&A) over the course of the calendar year divided by the total number of FTEs at the end of the previous calendar year multiplied by 100.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.EmployeeSurvey,
    columnFormat: GQGridColumnFormat.Boolean,
    description:
      'EDCI metric. Yes/no response indicating whether a company issues an employee feedback survey regularly.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.PercentOfEmployeesRespondingToSurvey,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'Optional EDCI metric. Total number of employees responding to survey divided by total number of employees surveyed.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: EdciReportTagNames.GeneralPartner,
    columnFormat: GQGridColumnFormat.String,
    description: 'EDCI metric. Name of general partner for the fund.',
    tagType: GQFinanceTagType.FundTag,
  },
];

export enum SfdrReportTagNames {
  ActiveInTheFossilFuelSector = 'Active in the fossil fuel sector',
  PercentOfNonRenewableEnergyConsumption = 'Percent of non-renewable energy consumption',
  EnergyConsumptionIntensity = 'Energy consumption intensity',
  TotalEnergyConsumptionGwh = 'Total energy consumption (Gwh)',
  HighImpactSector = 'High impact sector',
  NegativelyAffectsBiodiversitySensitiveAreas = 'Negatively affects biodiversity-sensitive areas',
  EmissionsToWater = 'Emissions to water',
  HazardousWaste = 'Hazardous waste and radioactive waste ratio',
  UNGCViolations = 'Violations of UNGC principles / OECD Guidelines for Multinational Enterprises',
  UNGCMissingCompliance = 'Compliance process for UNGC principles / OECD Guidelines for Multinational Enterprises',
  UnadjustedGenderPayGap = 'Unadjusted gender pay gap',
  BoardGenderDiversity = 'Board gender diversity',
  InvolvedInControversialWeapons = 'Involved in controversial weapons',
  SfdrFundClassification = 'SFDR fund classification',
}

export const SfdrReportTags = [
  {
    name: SfdrReportTagNames.ActiveInTheFossilFuelSector,
    columnFormat: GQGridColumnFormat.Boolean,
    description:
      'One of 14 core SFDR metrics. Used to calculate the share of investments in companies active in the fossil fuel sector. SFDR defines "active" as as any companies that derive any revenues from exploration, mining, extraction, production, processing, storage, refining or distribution, including transportation, storage and trade, of fossil fuels.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: SfdrReportTagNames.PercentOfNonRenewableEnergyConsumption,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'One of 14 core SFDR metrics. Non-renewable energy consumption and non-renewable energy production expressed as a percentage of total energy sources.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: SfdrReportTagNames.EnergyConsumptionIntensity,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'One of 14 core SFDR metrics. Used to calculate the overall energy consumption intensity (GWh per million EUR of investee revenues) for all invetments in companies that are active in high impact climate sectors.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: SfdrReportTagNames.TotalEnergyConsumptionGwh,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'One of 14 core SFDR metrics. The scope of energy consumption includes only energy directly consumed by the entity during the reporting period.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: SfdrReportTagNames.HighImpactSector,
    columnFormat: GQGridColumnFormat.Boolean,
    description:
      'One of 14 core SFDR metrics. Indicates if investee company is in a high impact climate sector.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: SfdrReportTagNames.NegativelyAffectsBiodiversitySensitiveAreas,
    columnFormat: GQGridColumnFormat.Boolean,
    description:
      'One of 14 core SFDR metrics. Used to calculate the overall share of investments in investee companies with sites/operations located in or near to biodiversity sensitive areas where activities of those investee companies negatively affect those areas.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: SfdrReportTagNames.EmissionsToWater,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'One of 14 core SFDR metrics. Used to calculate the total tonnes of emissions to water generated by investee companies per million EUR invested, expressed as a weighted average.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: SfdrReportTagNames.HazardousWaste,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'One of 14 core SFDR metrics. Used to calculate the share  of investments in investee companies that have been involved in violations of the UNGC principles or OECD Guidelines for Multinational Enterprises.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: SfdrReportTagNames.UNGCViolations,
    columnFormat: GQGridColumnFormat.Boolean,
    description:
      'One of 14 core SFDR metrics. Used to calculate the share  of investments in investee companies that have been involved in violations of the UNGC principles or OECD Guidelines for Multinational Enterprises.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: SfdrReportTagNames.UNGCMissingCompliance,
    columnFormat: GQGridColumnFormat.Boolean,
    description:
      'One of 14 core SFDR metrics. Used to calculate the share of investments in investee companies without policies to monitor compliance with the UNGC principles or OECD Guidelines for Multinational Enterprises or grievance/complaints handling mechanisms to address violations of the UNGC principles or OECD Guidelines for Multinational Enterprises.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: SfdrReportTagNames.UnadjustedGenderPayGap,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'One of 14 core SFDR metrics. Used to calculate average unadjusted gender pay gap of all investments, i.e., the difference between average gross hourly earnings of male paid employees and of female paid employees as a percentage of average gross hourly earnings of male paid employees.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: SfdrReportTagNames.BoardGenderDiversity,
    columnFormat: GQGridColumnFormat.Float,
    description:
      'One of 14 core SFDR metrics. Used to calculate the average ratio of female to male board members in investee companies, expressed as a percentage of all board members.',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: SfdrReportTagNames.InvolvedInControversialWeapons,
    columnFormat: GQGridColumnFormat.Boolean,
    description:
      'One of 14 core SFDR metrics. Used to calculate the share of investments in investee companies involved in the manufacture or selling of controversial weapons (anti-personnel mines, cluster munitions, chemical weapons and biological weapons).',
    tagType: GQFinanceTagType.AssetYearTag,
  },
  {
    name: SfdrReportTagNames.SfdrFundClassification,
    columnFormat: GQGridColumnFormat.SingleSelect,
    columnConfiguration: {
      columnFormat: GQGridColumnFormat.SingleSelect,
      options: ['Article 6', 'Article 8', 'Article 9'],
    },
    description: `SFDR is a regulation that applies to financial market participants (FMPs) and financial advisers that are based or active in the European Union. Under SFDR all funds are classified as Article 6, 8, or 9. Read more about fund classifications <a target="_blank" href="${LINKS.sfdrBlog}"><font color="blue">here</font></a>.`,
    tagType: GQFinanceTagType.FundTag,
  },
];

export const ExtraEsgReportColumnKey = makeBetterEnum(
  'carbonRemoval',
  'carbonRemovalAmount',
  'wasteManagement',
  'environmentalRiskAssessment',
  'ungcComplianceKinds',
  'livingWage',
  'healthBenefits',
  'parentalLeavePrimary',
  'parentalLeaveSecondary',
  'employeeSurveyKinds',
  'esgRiskAssessment',
  'esgBoardMember',
  'esgBoardDiscussion',
  'haveMeasuredEmissions',
  'planToMeasureEmissions',
  'EmployeeDiversity-Total-Male',
  'EmployeeDiversity-Total-Female',
  'EmployeeDiversity-White-Male',
  'EmployeeDiversity-White-Female',
  'EmployeeDiversity-Diverse-Male',
  'EmployeeDiversity-Diverse-Female',
  'CSuiteDiversity-Total-Male',
  'CSuiteDiversity-Total-Female',
  'CSuiteDiversity-White-Male',
  'CSuiteDiversity-White-Female',
  'CSuiteDiversity-Diverse-Male',
  'CSuiteDiversity-Diverse-Female',
  'BoardDiversity-Total-Male',
  'BoardDiversity-Total-Female',
  'BoardDiversity-White-Male',
  'BoardDiversity-White-Female',
  'BoardDiversity-Diverse-Male',
  'BoardDiversity-Diverse-Female'
);

export const SfdrPai = makeBetterEnum(
  'ghgScope1',
  'ghgScope2',
  'ghgScope3',
  'ghgTotal',
  'carbonFootprint',
  'ghgIntensity',
  'fossilFuelExposure',
  'nonRenewableEnergyUsage',
  'highImpactSectorEnergyIntensity',
  'negativelyImpactingBiodiversitySensitiveAreas',
  'emissionsToWater',
  'hazardousWaste',
  'ungcViolations',
  'ungcMissingCompliance',
  'genderPayGap',
  'boardGenderDiversity',
  'controversialWeapons',
  'inorganicPollutants',
  'airPollutants',
  'ozoneDepletingSubstances',
  'companiesWithoutCarbonEmissionReductionInitiatives',
  'energyConsumptionByNonRenewableEnergySources',
  'waterUsageAndRecycling',
  'companiesWithoutWaterManagementPolicies',
  'areasOfHighWaterStress',
  'companiesProducingChemicals',
  'landDegradation',
  'companiesWithoutSustainableLandPractices',
  'companiesWithoutSustainableOceanPractices',
  'nonRecycledWasteRatio',
  'naturalSpeciesAndProtectedAreas',
  'deforestation',
  'nonEnvironmentallyFriendlyBonds',
  'companiesWithoutWorkplaceAccidentPreventionPolicies',
  'rateOfAccidents',
  'daysLostToInjuries',
  'lackOfSupplierCodeOfConduct',
  'lackOfGrievanceHandlingMechanism',
  'insufficientWhistleblowerProtection',
  'incidentsOfDiscrimination',
  'excessiveCeoPayRatio',
  'lackOfHumanRightsPolicy',
  'lackOfDueDiligence',
  'lackOfHumanTraffickingPrevention',
  'operationsAndSuppliersAtRiskOfChildLabour',
  'operationsAndSuppliersAtRiskOfForcedLabour',
  'severeHumanRightsIssuesAndIncidents',
  'lackOfAntiCorruptionPolicy',
  'insufficientActionToAddressCorruption',
  'numberOfConvictionsAndFinesForCorruption'
);

export type PaiConfig = {
  key: keyof typeof SfdrPai;
  category: string;
  subcategory: string;
  metric: string;
  required: boolean;
};

export const SfdrPaiConfigs = [
  {
    key: SfdrPai.ghgScope1,
    category: 'Greenhouse gas emissions',
    subcategory: '1. GHG emissions',
    metric: 'Scope 1 GHG emissions',
    required: true,
  },
  {
    key: SfdrPai.ghgScope2,
    category: 'Greenhouse gas emissions',
    subcategory: '1. GHG emissions',
    metric: 'Scope 2 GHG emissions',
    required: true,
  },
  {
    key: SfdrPai.ghgScope3,
    category: 'Greenhouse gas emissions',
    subcategory: '1. GHG emissions',
    metric: 'Scope 3 GHG emissions',
    required: true,
  },
  {
    key: SfdrPai.ghgTotal,
    category: 'Greenhouse gas emissions',
    subcategory: '1. GHG emissions',
    metric: 'Total GHG emissions',
    required: true,
  },
  {
    key: SfdrPai.carbonFootprint,
    category: 'Greenhouse gas emissions',
    subcategory: '2. Carbon footprint',
    metric: 'Carbon footprint',
    required: true,
  },
  {
    key: SfdrPai.ghgIntensity,
    category: 'Greenhouse gas emissions',
    subcategory: '3. GHG intensity of investee companies',
    metric: 'GHG intensity of investee companies',
    required: true,
  },
  {
    key: SfdrPai.fossilFuelExposure,
    category: 'Greenhouse gas emissions',
    subcategory: '4. Exposure to companies active in the fossil fuel sector',
    metric:
      'Share of investments in companies active in the fossil fuel sector',
    required: true,
  },
  {
    key: SfdrPai.nonRenewableEnergyUsage,
    category: 'Greenhouse gas emissions',
    subcategory: '5. Share of non-renewable energy consumption and production',
    metric:
      'Share of non-renewable energy consumption and non-renewable energy production of investee companies from non-renewable energy sources compared to renewable energy sources, expressed as a percentage of total energy sources',
    required: true,
  },
  {
    key: SfdrPai.highImpactSectorEnergyIntensity,
    category: 'Greenhouse gas emissions',
    subcategory:
      '6. Energy consumption intensity per high impact climate sector',
    metric:
      'Energy consumption in GWh per million EUR of revenue of investee companies, per high impact climate sector',
    required: true,
  },
  {
    key: SfdrPai.negativelyImpactingBiodiversitySensitiveAreas,
    category: 'Biodiversity',
    subcategory:
      '7. Activities negatively affecting biodiversity-sensitive areas',
    metric:
      'Share of investments in investee companies with sites/operations located in or near to biodiversity-sensitive areas where activities of those investee companies negatively affect those areas',
    required: true,
  },
  {
    key: SfdrPai.emissionsToWater,
    category: 'Water',
    subcategory: '8. Emissions to water',
    metric:
      'Tonnes of emissions to water generated by investee companies per million EUR invested, expressed as a weighted average',
    required: true,
  },
  {
    key: SfdrPai.hazardousWaste,
    category: 'Water',
    subcategory: '9. Hazardous waste and radioactive waste ratio',
    metric:
      'Tonnes of hazardous waste and radioactive waste generated by investee companies per million EUR invested, expressed as a weighted average',
    required: true,
  },
  {
    key: SfdrPai.ungcViolations,
    category: 'Social and employee matters',
    subcategory:
      '10. Violations of UN Global Compact principles and Organisation for Economic Co-operation and Development (OECD) Guidelines for Multinational Enterprises',
    metric:
      'Share of investments in investee companies that have been involved in violations of the UNGC principles and OECD Guidelines for Multinational Enterprises',
    required: true,
  },
  {
    key: SfdrPai.ungcMissingCompliance,
    category: 'Social and employee matters',
    subcategory:
      '11. Lack of processes and compliance mechanisms to monitor compliance with UN Global Compact principles and OECD Guidelines for Multinational Enterprises',
    metric:
      'Share of investments in investee companies without policies to monitor compliance with the UNGC principles and OECD Guidelines for Multinational Enterprises or grievance/complaints handling mechanisms to address violations of the UNGC principles and OECD Guidelines for Multinational Enterprises',
    required: true,
  },
  {
    key: SfdrPai.genderPayGap,
    category: 'Social and employee matters',
    subcategory: '12. Unadjusted gender pay gap',
    metric: 'Average unadjusted gender pay gap of investee companies',
    required: true,
  },
  {
    key: SfdrPai.boardGenderDiversity,
    category: 'Social and employee matters',
    subcategory: '13. Board gender diversity',
    metric:
      'Average ratio of female to male board members in investee companies, expresses a percentage of all board members',
    required: true,
  },
  {
    key: SfdrPai.controversialWeapons,
    category: 'Social and employee matters',
    subcategory:
      '14. Exposure to controversial weapons (anti-personnel mines, cluster munitions, nuclear weapons, chemical weapons and biological weapons)',
    metric:
      'Share of investments in investee companies involved in the manufacture or selling of controversial weapons',
    required: true,
  },
  {
    key: SfdrPai.inorganicPollutants,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Emissions',
    subcategory: '1. Emissions to inorganic pollutants',
    metric:
      'Tonnes of inorganic pollutants equivalent per million EUR invested, expressed as a weighted average',
    required: false,
  },
  {
    key: SfdrPai.airPollutants,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Emissions',
    subcategory: '2. Emissions of air pollutants',
    metric:
      'Tonnes of air pollutants equivalent per million EUR invested, expressed as a weighted average',
    required: false,
  },
  {
    key: SfdrPai.ozoneDepletingSubstances,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Emissions',
    subcategory: '3. Emissions of ozone-depleting substances',
    metric:
      'Tonnes of ozone-depleting substances equivalent per million EUR invested, expressed as a weighted average',
    required: false,
  },
  {
    key: SfdrPai.companiesWithoutCarbonEmissionReductionInitiatives,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Emissions',
    subcategory:
      '4. Investments in companies without carbon emission reduction initiatives',
    metric:
      'Investments in companies without carbon emission reduction initiatives aimed at aligning with the Paris Agreement',
    required: false,
  },
  {
    key: SfdrPai.energyConsumptionByNonRenewableEnergySources,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Energy performance',
    subcategory:
      '5. Breakdown of energy consumption by type of non-renewable sources of energy',
    metric:
      'Share of energy from non-renewable sources used by investee companies broken down by each non-renewable energy source',
    required: false,
  },
  {
    key: SfdrPai.waterUsageAndRecycling,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Water, waste and material emissions',
    subcategory: '6. Water usage and recycling',
    metric:
      '1. Average amount of water consumed by the investee companies (in cubic meters) per million EUR of revenue of investee companies 2. Weighted average percentage of water recycled and reused by investee companies',
    required: false,
  },
  {
    key: SfdrPai.companiesWithoutWaterManagementPolicies,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Water, waste and material emissions',
    subcategory:
      '7. Investments in companies without water management policies',
    metric:
      'Share of investments in investee companies without water management policies',
    required: false,
  },
  {
    key: SfdrPai.areasOfHighWaterStress,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Water, waste and material emissions',
    subcategory: '8. Areas of high water stress',
    metric:
      'Share of investments in investee companies with sites located in areas of high water stress without a water management policy',
    required: false,
  },
  {
    key: SfdrPai.companiesProducingChemicals,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Water, waste and material emissions',
    subcategory: '9. Investments in companies producing chemicals',
    metric:
      'Share of investments in investee companies the activities of which fall under Division 20.2 of Annex I to Regulation (EC) No 1893/2006',
    required: false,
  },
  {
    key: SfdrPai.landDegradation,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Water, waste and material emissions',
    subcategory: '10. Land degradation, desertification, soil sealing',
    metric:
      'Share of investments in investee companies the activities of which cause land degradation, desertification or soil sealing',
    required: false,
  },
  {
    key: SfdrPai.companiesWithoutSustainableLandPractices,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Water, waste and material emissions',
    subcategory:
      '11. Investments in companies without sustainable land/agriculture practices',
    metric:
      'Share of investments in investee companies without sustainable land/agriculture practices or policies',
    required: false,
  },
  {
    key: SfdrPai.companiesWithoutSustainableOceanPractices,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Water, waste and material emissions',
    subcategory:
      '12. Investments in companies without sustainable oceans/seas practices',
    metric:
      'Share of investments in investee companies without sustainable oceans/seas practices or policies',
    required: false,
  },
  {
    key: SfdrPai.nonRecycledWasteRatio,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Water, waste and material emissions',
    subcategory: '13. Non-recycled waste ratio',
    metric:
      'Tonnes of non-recycled waste generated by investee companies per million EUR invested, expressed as a weighted average',
    required: false,
  },
  {
    key: SfdrPai.naturalSpeciesAndProtectedAreas,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Water, waste and material emissions',
    subcategory: '14.	Natural species and protected areas',
    metric:
      '14. Share of investments in investee companies whose operations affect threatened species 2.Share of investments in investee companies without a biodiversity protection policy covering operational sites owned, leased, managed in, or adjacent to, a protected area or an area of high biodiversity value outside protected areas',
    required: false,
  },
  {
    key: SfdrPai.deforestation,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Water, waste and material emissions',
    subcategory: '15. Deforestation',
    metric:
      'Share of investments in companies without a policy to address deforestation',
    required: false,
  },
  {
    key: SfdrPai.nonEnvironmentallyFriendlyBonds,
    parentCategory: 'ClimateAndEnvironment',
    category: 'Green securities',
    subcategory:
      '16. Share of securities not issued under Union legislation on environmentally sustainable bonds',
    metric:
      'Share of securities in investments not issued under Union legislation on environmentally sustainable bonds',
    required: false,
  },
  {
    key: SfdrPai.companiesWithoutWorkplaceAccidentPreventionPolicies,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Social and employee matters',
    subcategory:
      '1. Investments in companies without workplace accident prevention policies',
    metric:
      'Share of investments in investee companies without a workplace accident prevention policy',
    required: false,
  },
  {
    key: SfdrPai.rateOfAccidents,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Social and employee matters',
    subcategory: '2. Rate of accidents',
    metric:
      'Rate of accidents in investee companies expressed as a weighted average',
    required: false,
  },
  {
    key: SfdrPai.daysLostToInjuries,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Social and employee matters',
    subcategory:
      '3.	Number of days lost to injuries, accidents, fatalities or illness',
    metric:
      'Number of workdays lost to injuries, accidents, fatalities or illness of investee companies expressed as a weighted average',
    required: false,
  },
  {
    key: SfdrPai.lackOfSupplierCodeOfConduct,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Social and employee matters',
    subcategory: '4. Lack of supplier code of conduct',
    metric:
      'Share of investments in investee companies without any supplier code of conduct (against unsafe working conditions, precarious work, child labour and forced labour)',
    required: false,
  },
  {
    key: SfdrPai.lackOfGrievanceHandlingMechanism,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Social and employee matters',
    subcategory:
      '5. Lack of grievance/complaints handling mechanism related to employee matters',
    metric:
      'Share of investments in investee companies without any grievance/complaints handling mechanism related to employee matters',
    required: false,
  },
  {
    key: SfdrPai.insufficientWhistleblowerProtection,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Social and employee matters',
    subcategory: '6. Insufficient whistleblower protection',
    metric:
      'Share of investments in entities without policies on the protection of whistleblowers',
    required: false,
  },
  {
    key: SfdrPai.incidentsOfDiscrimination,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Social and employee matters',
    subcategory: '7. Incidents of discrimination',
    metric:
      '1. Number of incidents of discrimination reported in investee companies expressed as a weighted average 2. Number of incidents of discrimination leading to sanctions in investee companies expressed as a weighted average',
    required: false,
  },
  {
    key: SfdrPai.excessiveCeoPayRatio,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Social and employee matters',
    subcategory: '8. Excessive CEO pay ratio',
    metric:
      'Average ratio within investee companies of the annual total compensation for the highest compensated individual to the median annual total compensation for all employees (excluding the highest-compensated individual)',
    required: false,
  },
  {
    key: SfdrPai.lackOfHumanRightsPolicy,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Human Rights',
    subcategory: '9. Lack of human rights policy',
    metric:
      'Share of investments in entities without a due diligence process to identify, prevent, mitigate and address adverse human rights impacts',
    required: false,
  },
  {
    key: SfdrPai.lackOfDueDiligence,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Human Rights',
    subcategory: '10. Lack of due diligence',
    metric:
      'Share of investments in entities without a due diligence process to identify, prevent, mitigate and address adverse human rights impacts',
    required: false,
  },
  {
    key: SfdrPai.lackOfHumanTraffickingPrevention,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Human Rights',
    subcategory:
      '11. Lack of processes and measures for preventing trafficking in human beings',
    metric:
      'Share of investments in investee companies without policies against trafficking in human beings',
    required: false,
  },
  {
    key: SfdrPai.operationsAndSuppliersAtRiskOfChildLabour,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Human Rights',
    subcategory:
      '12. Operations and suppliers at significant risk of child labour',
    metric:
      'Share of investments in investee companies exposed to operations and suppliers at significant risk of incidents of child labour in terms of geographic areas or type of operation',
    required: false,
  },
  {
    key: SfdrPai.operationsAndSuppliersAtRiskOfForcedLabour,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Human Rights',
    subcategory:
      '13. Operations and suppliers at significant risk of forced or compulsory labour',
    metric:
      'Share of the investments in investee companies exposed to operations and suppliers at significant risk of incidents of forced or compulsory labour in terms in terms of geographic areas and/or the type of operation',
    required: false,
  },
  {
    key: SfdrPai.severeHumanRightsIssuesAndIncidents,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Human Rights',
    subcategory:
      '14. Number of identified cases of severe human rights issues and incidents',
    metric:
      'Number of cases of severe human rights issues and incidents connected to investee companies on a weighted average basis',
    required: false,
  },
  {
    key: SfdrPai.lackOfAntiCorruptionPolicy,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Anti-corruption and anti-bribery',
    subcategory: '15. Lack of anti-corruption and anti-bribery policies',
    metric:
      'Share of investments in entities without policies on anti-corruption and anti-bribery consistent with the United Nations Convention against Corruption',
    required: false,
  },
  {
    key: SfdrPai.insufficientActionToAddressCorruption,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Anti-corruption and anti-bribery',
    subcategory:
      '16. Cases of insufficient action taken to address breaches of standards of anti-corruption and anti-bribery',
    metric:
      'Share of investments in investee companies with identified insufficiencies in actions taken to address breaches in procedures and standards of anti-corruption and anti-bribery',
    required: false,
  },
  {
    key: SfdrPai.numberOfConvictionsAndFinesForCorruption,
    parentCategory: 'SocialAndEmployeeMatters',
    category: 'Anti-corruption and anti-bribery',
    subcategory:
      '17. Number of convictions and amount of fines for violation of anti-corruption and anti-bribery laws',
    metric:
      'Numbers of convictions and amount of fines for violations of anti-corruption and anti-bribery laws by investee companies',
    required: false,
  },
];

// Regulatory checkup enums

export enum ReportingJurisdiction {
  US = 'US',
  UK = 'UK',
  EU = 'EU',
  Other = 'Other',
}

export enum StockExchange {
  NYSE = 'NYSE',
  NASDAQ = 'NASDAQ',
  LSE = 'LSE',
  EEA = 'EEA',
}

export enum SecFilerStatus {
  LargeAcceleratedFiler = 'LAF',
  AcceleratedFiler = 'AF',
  NonAcceleratedFiler = 'NAF',
  NonFiler = 'NonFiler',
}

export enum LseListingStatus {
  PremiumMainMarket = 'Premium',
  StandardMainMarket = 'Standard',
  AIM = 'AIM',
  HighGrowthSegment = 'HGS',
  NotListed = 'NotListed',
}

export enum NfrdFilerStatus {
  Filer = 'Filer',
  NonFiler = 'NonFiler',
  Unknown = 'Unknown',
}

export const MAX_EXCEL_SHEET_NAME_LENGTH = 31;
export const EXCEL_PROHIBITED_CHARACTERS_PATTERN = /[\/\\?*:[]/g;

export const DEFAULT_REPORTING_INTENTION: ReportingIntention = 'IsReporting';

/**
 * Country names by code for CDP.
 *
 * These are used to map the country alpha-2 codes to the country names used in the CDP dataset.
 * We use this list instead of the COUNTRIES_CONST list because the CDP dataset has 13 countries
 * that use an alternate country name. Hardcoding the complete list here ensures that we can update
 * our formatting in other parts of our product without having to worry about the CDP dataset changing.
 * Today, we are using these in report presenter questions that get synced to CDP ORS.
 * Source: https://www.notion.so/watershedclimate/CDP-2024-countries-1996b7d454cc46009fb2b228f41901ca
 */
export const CDP_COUNTRY_NAMES_BY_CODE: Record<string, string> = {
  AF: 'Afghanistan',
  AX: 'Åland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia & Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  VG: 'British Virgin Islands',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  MO: 'China, Macao Special Administrative Region',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Côte d'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  KP: "Democratic People's Republic of Korea",
  CD: 'Democratic Republic of the Congo',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See',
  HN: 'Honduras',
  HK: 'Hong Kong SAR, China',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran (Islamic Republic of)',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia (Federated States of)',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MK: 'North Macedonia',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  KR: 'Republic of Korea',
  MD: 'Republic of Moldova',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  PS: 'State of Palestine',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen Islands',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan, China',
  TJ: 'Tajikistan',
  TZ: 'United Republic of Tanzania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom of Great Britain and Northern Ireland',
  UM: 'United States Minor Outlying Islands',
  US: 'United States of America',
  VI: 'United States Virgin Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Bolivarian Republic of)',
  VN: 'Viet Nam',
  WF: 'Wallis and Futuna Islands',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

export const REPORTING_INTENTION_REASON_CHARACTER_LIMIT = 1000;
export const REPORT_QUESTION_INSTANCE_APPROVAL_ACTION_COMMENT_CHARACTER_LIMIT = 1000;

const CSRD_TEAM = getTeamInfo(Teams.WinCsrd);
const CSRD_GOLDEN_REPORT_ID = 'rpt_2pE5kjRqJw7wv48QYk7t';

export const CONFIG_TYPES_TO_GOLDEN_REPORT_AND_TEAM: Record<
  string,
  { reportId: string; team: TeamInfo }
> = {
  CSRD: {
    reportId: CSRD_GOLDEN_REPORT_ID,
    team: CSRD_TEAM,
  },
};

// components that don't use title or subtitle in render, table view, nor export
export const COMPONENT_TITLE_SUBTITLE_BLOCK_LIST: Array<DisplayComponentId> = [
  'SheetInfoCard',
  'CustomReportHeading',
];

// components that use title in render or in table view but the component is not exported
export const RQ_TITLE_USED_COMPONENT_NOT_EXPORTED: Array<DisplayComponentId> = [
  'FootprintOverrideSelector',
  'TcfdEducation',
  'ReviewSection',
  'Callout',
  'CollapsibleInfoCard',
  'ChecklistCallout',
  'CdpEducation',
  'Cdp2024Education',
  'CdpApiRegistration',
  'CdpEnergyDataSetupTable',
  'Cdp2024EnergyDataSetupTable',
  'Survey',
  'ReportControls',
  'CardControls',
  'MaterialityAssessmentSelector',
  'EducationCard',
  'EducationPillars',
];

// components that do not use subtitle in render or export
export const RQ_SUBTITLE_NOT_USED: Array<DisplayComponentId> = [
  'SfdrIndicatorSelector',
  'SfdrIndicatorsLibrary',
  'EducationPillars',
  'EducationCard',
  'CdpMinimumFiler',
  'CdpC71',
  'Survey',
  'MultiFundSelector',
  'SingleFundSelector',
  'CollapsibleInfoCard',
  'ReviewSection',
  'PastYearsSelector',
  'Callout',
];
