import { useState, useEffect, useRef } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';
import { SxProps, Theme, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@watershed/ui-core/components/Button';
import SendIcon from '@watershed/icons/components/Send';
import { sharedShadow } from '../../utils/styles';

// Constants
const MAX_QUESTION_LENGTH = 1000;
const MIN_QUESTION_LENGTH = 2;
const ERROR_DISPLAY_DURATION = 5000;
const RATE_LIMIT_COOLDOWN = 2000;

// Types
interface QuestionInputProps {
  onSubmit: (question: string) => Promise<void>;
  isSubmitting?: boolean;
}

interface ErrorState {
  message: string | null;
  isVisible: boolean;
}

// Styles
const STYLES: Record<string, SxProps<Theme>> = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 0.25,
    px: 1,
    width: '100%',
    background: (theme: Theme) =>
      `linear-gradient(to top, ${theme.palette.grey10}, ${theme.palette.grey05})`,
  },

  inputContainer: {
    backgroundColor: (theme: Theme) => theme.palette.white,
    width: '100%',
    borderRadius: '6px',
    outline: (theme: Theme) => `0.5px solid ${theme.palette.grey30}`,
    boxShadow: (theme: Theme) => `0px 1px 0px 0px ${theme.palette.cobalt100}05`,
    px: 0.5,
    py: 0.25,
  },

  textField: {
    '& .MuiOutlinedInput-root': {
      border: 0,
      backgroundColor: 'background.grey05',
    },
    '& .MuiInputBase-root': {
      px: 1,
      height: '36px',
      maxHeight: '64px',
      overflowY: 'auto',
    },
  },

  sendButton: {
    mr: 0.25,
  },

  errorContainer: {
    position: 'absolute',
    zIndex: 999,
    margin: '16px',
    width: 'calc(100% - 128px)',
    maxWidth: 'fit-content',
    top: 56,
    transform: 'translateX(-50%)',
    backgroundColor: (theme: Theme) => theme.palette.sun05,
    py: 0.5,
    pl: 2,
    pr: 0.5,
    borderRadius: '8px',
    border: (theme: Theme) => `1px solid ${theme.palette.sun20}`,
    boxShadow: sharedShadow,
  },
};

// Utility functions
const sanitizeInput = (input: string): string => {
  return input
    .trim()
    .replace(/\s+/g, ' ')
    .replace(/[\x00-\x1F\x7F-\x9F]/g, '')
    .replace(/[<>]/g, '');
};

const isValidQuestion = (question: string): boolean => {
  const trimmed = question.trim();
  return (
    trimmed.length >= MIN_QUESTION_LENGTH &&
    trimmed.length <= MAX_QUESTION_LENGTH
  );
};

const getErrorMessage = (
  err: Error,
  t: (params: { message: string; context: string }) => string
) => {
  if ('code' in err) {
    switch (err.code) {
      case 'TOO_MANY_REQUESTS':
        return t({
          message: 'Rate limit exceeded. Please try again later.',
          context: 'Error message for Dust API rate limit',
        });
      case 'BAD_REQUEST':
        return t({
          message: 'Invalid question format. Please try rephrasing.',
          context: 'Error message for invalid Dust API request',
        });
      case 'UNAUTHORIZED':
        return t({
          message: 'Session expired. Please refresh the page.',
          context: 'Error message for unauthorized Dust API access',
        });
      case 'FORBIDDEN':
        return t({
          message: 'You do not have permission to use this feature.',
          context: 'Error message for forbidden Dust API access',
        });
      case 'NETWORK_ERROR':
        return t({
          message: 'Connection error. Please check your internet connection.',
          context: 'Error message for Dust API network error',
        });
      default:
        return t({
          message: 'Failed to send question. Please try again.',
          context: 'Error message for general Dust API failure',
        });
    }
  }
  return t({
    message: 'Failed to send question. Please try again.',
    context: 'Error message for general question submission failure',
  });
};

// Main Component
export function QuestionInput({
  onSubmit,
  isSubmitting = false,
}: QuestionInputProps) {
  const { t } = useLingui();
  const [question, setQuestion] = useState('');
  const [errorState, setErrorState] = useState<ErrorState>({
    message: null,
    isVisible: false,
  });
  const lastSubmitTime = useRef<number>(0);

  useEffect(() => {
    if (errorState.message) {
      // Show error immediately
      setErrorState((current) => ({ ...current, isVisible: true }));

      // Start fade out after duration
      const fadeTimer = setTimeout(() => {
        setErrorState((current) => ({ ...current, isVisible: false }));

        // Clear message after fade animation
        const clearTimer = setTimeout(() => {
          setErrorState({ message: null, isVisible: false });
        }, 200);

        return () => clearTimeout(clearTimer);
      }, ERROR_DISPLAY_DURATION);

      return () => clearTimeout(fadeTimer);
    }
  }, [errorState.message]);

  const setError = (message: string | null) => {
    setErrorState(
      message
        ? { message, isVisible: true }
        : { message: null, isVisible: false }
    );
  };

  const handleSubmitQuestion = async () => {
    const sanitizedQuestion = sanitizeInput(question);
    const now = Date.now();

    setError(null);

    if (!isValidQuestion(sanitizedQuestion)) {
      setError(
        sanitizedQuestion.length > MAX_QUESTION_LENGTH
          ? t({
              message: `Question is too long. Maximum ${MAX_QUESTION_LENGTH} characters allowed.`,
              context: 'Error message for question length validation',
            })
          : t({
              message: 'Question is too short. Please enter a valid question.',
              context: 'Error message for invalid question',
            })
      );
      return;
    }

    if (now - lastSubmitTime.current < RATE_LIMIT_COOLDOWN) {
      setError(
        t({
          message: 'Please wait a moment before asking another question.',
          context: 'Error message for rate limit',
        })
      );
      return;
    }

    try {
      await onSubmit(sanitizedQuestion);
      setQuestion('');
      lastSubmitTime.current = now;
    } catch (err) {
      if (err instanceof Error) {
        setError(getErrorMessage(err, t));
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      void handleSubmitQuestion();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.length <= MAX_QUESTION_LENGTH) {
      setQuestion(newValue);
      if (!newValue.trim()) {
        setError(null);
      }
    }
  };

  const isDisabled = !question.trim() || isSubmitting;

  return (
    <Box sx={STYLES.container}>
      <Stack
        sx={{
          width: '100%',
          backgroundColor: (theme) =>
            errorState.isVisible ? theme.palette.sun05 : theme.palette.grey05,
          transition: 'background-color 300ms ease 50ms',
          border: (theme) => `0.5px solid ${theme.palette.grey30}`,
          borderRadius: '8px',
          mb: 1,
          boxShadow: (theme) =>
            `0px 1px 2px 0px ${theme.palette.grey70}16, 0px 0px 16px 1px ${theme.palette.grey70}08`,
        }}
      >
        <Stack direction="row" alignItems="center" sx={STYLES.inputContainer}>
          <TextField
            fullWidth
            multiline
            value={question}
            size="small"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            disabled={isSubmitting}
            placeholder={t({
              message: 'Ask a question...',
              context: 'Placeholder text for help chat question input',
            })}
            sx={STYLES.textField}
          />
          <Button
            isIcon
            variant="text"
            onClick={handleSubmitQuestion}
            disabled={isDisabled}
            sx={STYLES.sendButton}
          >
            <SendIcon size={14} color={isDisabled ? 'grey30' : 'grey50'} />
          </Button>
        </Stack>
        <Stack
          sx={{
            pt: 0.5,
            pb: 0.5,
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Fade in={true} timeout={200}>
            <Typography
              fontSize={13}
              color={errorState.message ? 'error.dark' : 'grey50'}
            >
              {errorState.message || (
                <Trans>AI can make mistakes. Always check information.</Trans>
              )}
            </Typography>
          </Fade>
        </Stack>
      </Stack>
    </Box>
  );
}
