import { makeBetterEnum } from './betterEnum';

export const PermissionType = makeBetterEnum(
  'Admin',
  'AnyUser',
  'ApproveDataset',
  'ApproveDatasource',
  'ApproveFootprint',
  'CorporateAdmin',
  'EditReport',
  'EditReportQuestionInstance',
  'FinanceAdmin',
  'FinanceReadOnly',
  'ManageCompanyTags',
  'ManageDataset',
  'ManageDatasource',
  'ManageDisclosures',
  'ManageFund',
  'ManageMarketplacePurchases',
  'ManageMeasurement',
  'ManageOrgHierarchy',
  'ManageReductionPlans',
  'ManageSingleSignOn',
  'ManageSuppliers',
  'ViewAuditDetail',
  'ViewEmployeeReport',
  'ViewFootprint',
  'ViewFootprintDetail',
  'ViewLearningHub',
  'ViewReductions',
  'ViewReport',
  'ViewReportQuestionInstance',
  'WatershedAdmin'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PermissionType =
  (typeof PermissionType)[keyof typeof PermissionType];

export const PermissionObjectType = makeBetterEnum(
  'Dataset',
  'Datasource',
  'Footprint',
  'Fund',
  'ReportQuestionInstance',
  'Report'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PermissionObjectType =
  (typeof PermissionObjectType)[keyof typeof PermissionObjectType];
