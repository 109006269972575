import { GQDustBot } from './generated/graphql-schema-types';
import { RichHiddenContext } from '@watershed/app-dashboard/components/support/HelpPanel/utils/types';

export interface PrecannedMessage {
  title: string;
  content: string;
  hiddenContext?: string | RichHiddenContext;
}

const DEFAULT_PRECANNED_MESSAGES: Array<PrecannedMessage> = [
  {
    title: 'What is the CSRD?',
    content:
      'What is the CSRD? What are the key provisions of the directive, how has it changed over time, and how do will it impact reporting requirements?',
  },
  {
    title: 'How do I use exclusion (deduplication) rules?',
    content:
      'How do I use exclusion (deduplication) rules? What are they, and how do they work?',
  },
  {
    title: 'Types of data in GHG accounting',
    content:
      'What are the different types of data in GHG accounting? How do they relate to each other?',
  },
  {
    title: 'What are BEA codes?',
    content:
      'What are BEA codes, why are they important, and how do I use them?',
  },
];

const ORG_STRUCTURE_PRECANNED_MESSAGES: Array<PrecannedMessage> = [
  {
    title: 'What is an organizational structure?',
    content:
      'Can you explain what an organizational structure is and why it matters?',
  },
  {
    title: 'What is the difference between Org. Unit Types and Org. Units?',
    content:
      'Can you explain what the difference is between Org. Unit Types and Org. Units (e.g. Business Unit, Department, etc. vs. Business Unit 1, Department 1, etc.)?',
  },
  {
    title: 'What are relationships in the org structure?',
    content:
      'What are the different types of relationships in the org structure? Why do they matter? How do they help me?',
  },
];

export function getDustBotForRoute(pathname: string): {
  dustBot: GQDustBot;
  precannedMessages: Array<PrecannedMessage>;
} {
  if (pathname.startsWith('/org-structure')) {
    return {
      dustBot: GQDustBot.OrgStructure,
      precannedMessages: ORG_STRUCTURE_PRECANNED_MESSAGES,
    };
  }

  // Add more route-specific conditions here as needed

  return {
    dustBot: GQDustBot.LearningHub,
    precannedMessages: DEFAULT_PRECANNED_MESSAGES,
  };
}
