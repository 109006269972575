import {
  SxProps,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import {
  getRelativeTime,
  maybeGetRelativeTime,
  RelativeTimeProps,
} from '@watershed/shared-universal/utils/relativeTime';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Variant } from '@mui/material/styles/createTypography';
import useLocale from '@watershed/intl/frontend/useLocale';
import { formatDate } from '@watershed/intl/formatters';
import { tooltipDarkStyleProps } from '@watershed/style/styleUtils';

/**
 * given a nullable timestamp, return a friendly text representation
 * that auto-updates every `updateSeconds` seconds.
 */
export function useNullableRelativeTimestamp(
  timestamp: Date | null,
  updateSeconds: number
) {
  const [relativeTime, setRelativeTime] = useState(
    maybeGetRelativeTime(timestamp, { hasJust: true })
  );

  useEffect(() => {
    // set the relative time and then update every x seconds
    setRelativeTime(maybeGetRelativeTime(timestamp, { hasJust: true }));
    const intervalId = setInterval(
      () => setRelativeTime(maybeGetRelativeTime(timestamp, { hasJust: true })),
      updateSeconds * 1000
    );
    return () => clearInterval(intervalId);
  }, [timestamp, updateSeconds]);

  return relativeTime;
}

export default function RelativeTimestamp({
  timestamp,
  keepUpdated,
  streaming,
  variant = 'body2',
  color,
  placement,
  thresholds,
  sentenceCase,
  tooltipDateTimeFormatOptions = { dateStyle: 'long', timeStyle: 'medium' },
  sx,
}: {
  timestamp: Date | string;
  /**
   * If true, kicks off a useEffect that keeps the timestamp updated. Kill it by
   * killing the component.
   */
  keepUpdated?: true;
  /**
   * If true, updates the timestamp every second for a streaming clock-like experience.
   * This is most useful for recent timestamps where seconds are visible.
   */
  streaming?: boolean;
  color?: TypographyProps['color'];
  variant?: Variant | 'body3';
  placement?: TooltipProps['placement'];
  thresholds?: RelativeTimeProps['thresholds'];
  sentenceCase?: RelativeTimeProps['sentenceCase'];
  tooltipDateTimeFormatOptions?: Intl.DateTimeFormatOptions;
  sx?: SxProps<Theme>;
}) {
  const locale = useLocale();
  const timeStampAsPrimitive =
    typeof timestamp === 'string' ? timestamp : timestamp.toISOString();
  const date = useMemo(
    () => new Date(timeStampAsPrimitive),
    [timeStampAsPrimitive]
  );
  const localeString = formatDate(date, {
    ...tooltipDateTimeFormatOptions,
    locale,
  });

  const getRelativeTimeCallback = useCallback(() => {
    return getRelativeTime(date, {
      hasJust: keepUpdated,
      thresholds,
      sentenceCase,
      locale,
    });
  }, [date, keepUpdated, thresholds, sentenceCase, locale]);

  const [relativeTime, setRelativeTime] = useState(getRelativeTimeCallback());

  useEffect(() => {
    if (!keepUpdated && !streaming) {
      return;
    }

    setRelativeTime(getRelativeTimeCallback());

    // Use 1-second intervals for streaming, 15-second intervals otherwise
    const intervalTime = streaming ? 1000 : 15000;

    const intervalId = setInterval(() => {
      setRelativeTime(getRelativeTimeCallback());
    }, intervalTime);

    return () => clearInterval(intervalId);
  }, [keepUpdated, streaming, getRelativeTimeCallback]);

  return (
    <Tooltip
      slotProps={tooltipDarkStyleProps()}
      placement={placement}
      title={
        <Typography
          variant="body3"
          sx={{
            fontWeight: 600,
            minWidth: 'max-content',
            whiteSpace: 'nowrap',
          }}
        >
          {localeString}
        </Typography>
      }
    >
      <Typography
        variant={variant}
        color={color}
        sx={sx}
        component="span"
        noWrap
      >
        {relativeTime}
      </Typography>
    </Tooltip>
  );
}
