import gql from 'graphql-tag';
import { useLingui } from '@lingui/react/macro';
import { UploadFile } from '@watershed/ui-core/components/FileUpload/FileUploadDropzone';
import {
  useCreateSupportCaseAttachmentsMutation,
  useCreateSupportCaseMutation,
  useDeleteSupportCaseAttachmentsMutation,
  useFetchSupportCaseAttachmentDownloadUrlMutation,
} from '@watershed/app-dashboard/generated/urql';
import useSnackbar from '@watershed/shared-frontend/hooks/useSnackbar';

gql`
mutation CreateSupportCaseAttachments($input: CreateSupportCaseAttachmentsInput!)
  @withOwner(owner: EnterpriseFoundations) {
  createSupportCaseAttachments(input: $input) {
    files {
      signedUrl
      fileMetadataId
      fileName
    }
  }
}

mutation FetchSupportCaseAttachmentDownloadUrl($input: FetchSupportCaseAttachmentDownloadUrlInput!)
  @withOwner(owner: EnterpriseFoundations) {
  fetchSupportCaseAttachmentDownloadUrl(input: $input) {
    downloadUrl
  }
}

mutation DeleteSupportCaseAttachments($input: DeleteSupportCaseAttachmentsInput!)
  @withOwner(owner: EnterpriseFoundations) {
  deleteSupportCaseAttachments(input: $input) {
    files {
      id
    }
  }
}
`;

export default function useManageSupportCaseAttachments({
  files,
  setFiles,
}: { files: Array<UploadFile>; setFiles: (files: Array<UploadFile>) => void }) {
  const snackbar = useSnackbar();
  const { t } = useLingui();
  const [{ fetching: isSubmitting }, executeSubmit] =
    useCreateSupportCaseMutation();

  const [, executeCreateSupportCaseAttachments] =
    useCreateSupportCaseAttachmentsMutation();
  const [, executeDeleteAttachmentItems] =
    useDeleteSupportCaseAttachmentsMutation();
  const [, executeFetchDownloadUrl] =
    useFetchSupportCaseAttachmentDownloadUrlMutation();

  async function removeUpload(fileId: string) {
    const item = files.find((file) => file.fileId === fileId);
    if (!item) {
      return;
    }
    await executeDeleteAttachmentItems({
      input: { fileMetadataIds: [fileId] },
    });
    setFiles(files.filter((file) => file.fileId !== fileId));
  }

  async function removeAllUploads() {
    await executeDeleteAttachmentItems({
      input: {
        fileMetadataIds: files.map((file) => file.fileId),
      },
    });
    setFiles([]);
  }

  async function getDownloadFileUrl(
    fileMetadataId: string
  ): Promise<string | undefined> {
    const result = await executeFetchDownloadUrl({
      input: { fileMetadataId },
    });
    if (result.error) {
      snackbar.enqueueSnackbar(
        t({
          message:
            'An error occurred while downloading this file. Watershed has been notified.',
          context: 'Error message when fetching download URL',
        }),
        { variant: 'error' }
      );
    }
    return result.data?.fetchSupportCaseAttachmentDownloadUrl.downloadUrl;
  }
  return {
    executeSubmit,
    isSubmitting,
    removeUpload,
    removeAllUploads,
    getDownloadFileUrl,

    // Exported for the dialog
    executeCreateSupportCaseAttachments,
    executeDeleteAttachmentItems,
  };
}
