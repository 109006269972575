import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const MinimizeIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 12 12" fill="currentColor" {...props}>
    <path
      d="M1.5 7a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V7.5H2a.5.5 0 0 1-.5-.5ZM7 1.5a.5.5 0 0 1 .5.5v2.5H10a.5.5 0 0 1 0 1H7a.5.5 0 0 1-.5-.5V2a.5.5 0 0 1 .5-.5Z"
      clipRule="evenodd"
    />
    <path
      d="M10.854 1.146a.5.5 0 0 1 0 .708l-3.5 3.5a.5.5 0 1 1-.708-.708l3.5-3.5a.5.5 0 0 1 .708 0Zm-5.5 5.5a.5.5 0 0 1 0 .708l-3.5 3.5a.5.5 0 0 1-.708-.708l3.5-3.5a.5.5 0 0 1 .708 0Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default MinimizeIcon;
