import { useEffect } from 'react';
import {
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
} from '@watershed/shared-universal/utils/constants';
import type { CSSProperties } from '@mui/styles';

const CLASSNAME_LEFT = 'admin-chip-bar-left';

/**
 * Use this hook anywhere you'd like to move the admin chip bar to the left side
 * of the screen, usually because there's something else on the right side that
 * this would otherwise cover up.
 */
export function useAdminBarLeftPosition() {
  useEffect(() => {
    document.body.classList.add(CLASSNAME_LEFT);
    return () => {
      document.body.classList.remove(CLASSNAME_LEFT);
    };
  }, []);
}

/**
 * This should be applied one place, to a container that will render the chip
 * bar if left positioned.
 */
export const adminChipBarLeftPositionStyles: CSSProperties = {
  [`body.${CLASSNAME_LEFT}.sidebar-collapsed-no &`]: {
    left: SIDEBAR_EXPANDED_WIDTH + 8,
    right: 'unset',
  },
  [`body.${CLASSNAME_LEFT}.sidebar-collapsed-yes &`]: {
    left: SIDEBAR_COLLAPSED_WIDTH + 8,
    right: 'unset',
  },
};
