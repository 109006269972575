import { makeBetterEnum } from './betterEnum';

export const PlanTargetIntensityType = makeBetterEnum(
  'Absolute',
  // These target intensities refer to schema-defined intensity denominators.
  'GrossProfit',
  'Headcount',
  'NightsStayed',
  'Orders',
  'Patients',
  'RenewableElectricity',
  'Revenue',
  'SupplierEngagement',
  'SupplierEngagementBySpend',
  // Custom represents user-defined target intensities, and must be accompanied
  // by a custom intensity config ID.
  'Custom'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PlanTargetIntensityType =
  (typeof PlanTargetIntensityType)[keyof typeof PlanTargetIntensityType];

export const PlanTargetTargetComparisonType = makeBetterEnum(
  'BaseYear',
  'BusinessAsUsual'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PlanTargetTargetComparisonType =
  (typeof PlanTargetTargetComparisonType)[keyof typeof PlanTargetTargetComparisonType];

export const TargetKind = makeBetterEnum(
  'AvoidedEmissions',
  'EmissionsReduction',
  'NetZero',
  'Offsets'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TargetKind = (typeof TargetKind)[keyof typeof TargetKind];

export const TargetReductionRate = makeBetterEnum(
  'Custom',
  'LinearAnnualReduction',
  'YearOverYear'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TargetReductionRate =
  (typeof TargetReductionRate)[keyof typeof TargetReductionRate];

export const TargetUnit = makeBetterEnum(
  // Percent = "percent change", with an arrow
  'Percent',
  // PercentAbsolute = "percent", without an arrow
  'PercentAbsolute',
  'TCO2E',
  'Year'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TargetUnit = (typeof TargetUnit)[keyof typeof TargetUnit];

export const SbtCommitmentStage = makeBetterEnum(
  'Committed',
  'Removed',
  'TargetsSet'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SbtCommitmentStage =
  (typeof SbtCommitmentStage)[keyof typeof SbtCommitmentStage];

export const PrecomputedLever = makeBetterEnum(
  'ChangeCareLabelToAirDry',
  'ChangeCareLabelToWashCold',
  'ElectrifyGasStovesInOffices',
  'EliminateEmployeeWfhElectricityEmissions',
  'EnergyEfficiencyImprovementsHeatPumps',
  'GreenRideShareOptions',
  'GroundTransportInsteadOfAir',
  'ImplementGreenCodingPractices',
  'ImproveElectricityEfficiencyOfficeBuildings',
  'LocalizeSupply',
  'OptimizeCloudComputeType',
  'OptimizeCloudHostingLocation',
  'ProductUsersAdoptMoreEfficientWasherDryers',
  'PurchaseRECsOrEACs',
  'ReduceEmissionsFromElectricityProduction',
  'ReduceRefrigerantLeakageRate',
  'ReduceTotalTravelMiles',
  'ReplaceConventionalJetFuelWithSustainableAviationFuel',
  'RequirePassengerTrainInsteadOfShortHaulFlight',
  'SwapAirFreightToRailFreight',
  'SwapAirFreightToSeaFreight',
  'SwapEmployeeCommuteFromIceVehicleToElectricVehicle',
  'SwapEmployeeCommuteFromIceVehicleToPublicTransport',
  'SwapFlightClassFromBusinessToEconomy',
  'SwapFlightClassFromFirstToEconomy',
  'SwapGroundTransportToRailFreight',
  'SwapProductsSentToLandfillToRecycled',
  'SwapToLowerGWPRefrigerants',
  'SwapWasteFromLandfillToCompost',
  'SwapWasteFromLandfillToRecycle',
  'SwitchNaturalGasToHeatPumps',
  'SwitchToLowEmissionsCloudProvider'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PrecomputedLever =
  (typeof PrecomputedLever)[keyof typeof PrecomputedLever];

export const SbtScope1And2Target = makeBetterEnum(
  // Absolute reductions targeting 1.5C temperature goal
  'OnePointFiveC',
  // Absolute reductions targeting well-below-2C temperature goal
  'WellBelowTwoC'
  // TwoC and GEVA7 (see below) are not appropriate for scope 1 and 2
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SbtScope1And2Target =
  (typeof SbtScope1And2Target)[keyof typeof SbtScope1And2Target];

export const SbtScope3Target = makeBetterEnum(
  // GEVA is Greenhouse Gas Emissions per Value Added (i.e., an intensity
  // reduction relative to an economic metric like revenue). An intensity
  // reduction of 7% YoY.
  // NOTE(carl): I'm not sure this is a target type of its own, or if it's an
  // alternate way to qualify for one of the targets above.
  'GEVA7',
  // Absolute reductions targeting 1.5C temperature goal
  'OnePointFiveC',
  // Absolute reductions targeting 2C temperature goal
  'TwoC',
  // Absolute reductions targeting well-below-2C temperature goal
  'WellBelowTwoC'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SbtScope3Target =
  (typeof SbtScope3Target)[keyof typeof SbtScope3Target];

export const SbtTermLength = makeBetterEnum('LongTerm', 'NearTerm');

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SbtTermLength = (typeof SbtTermLength)[keyof typeof SbtTermLength];

export const SbtCommitmentType = makeBetterEnum(
  'BAOnePointFiveOptionOne',
  'BAOnePointFiveOptionOneAndTwo',
  'BAOnePointFiveOptionTwo',
  'NetZero',
  'Standard'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SbtCommitmentType =
  (typeof SbtCommitmentType)[keyof typeof SbtCommitmentType];

export const DisclosureTargetEmissionsType = makeBetterEnum(
  'GrossEmissions',
  'NetEmissions'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DisclosureTargetEmissionsType =
  (typeof DisclosureTargetEmissionsType)[keyof typeof DisclosureTargetEmissionsType];

export const DisclosureTargetIntensityType = makeBetterEnum(
  'Absolute',
  'GrossProfit',
  'Headcount',
  'Revenue',
  'SupplierEngagement',
  'Unit'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DisclosureTargetIntensityType =
  (typeof DisclosureTargetIntensityType)[keyof typeof DisclosureTargetIntensityType];

export const InitiativeKind = makeBetterEnum(
  'FootprintSlice',
  'PrecomputedActivityBased'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InitiativeKind =
  (typeof InitiativeKind)[keyof typeof InitiativeKind];

export const InitiativeType = makeBetterEnum(
  'Buildings',
  'Cloud',
  'Commute',
  'Custom',
  'Travel'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InitiativeType =
  (typeof InitiativeType)[keyof typeof InitiativeType];

export const DisclosureTargetReductionRate = makeBetterEnum(
  'LinearAnnualReduction',
  'Logarithmic',
  'YearOverYear'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DisclosureTargetReductionRate =
  (typeof DisclosureTargetReductionRate)[keyof typeof DisclosureTargetReductionRate];
