import {
  GQComputedFilterField,
  GQComputedFilterExpressionPrimitive,
  GQFilterExpressionPrimitive,
  GQFilterFieldLegacy,
  GQAggregateKind,
  GQColumnDimension,
} from '@watershed/shared-universal/generated/graphql-schema-types';
import { FilterOperator } from '@watershed/constants/filter';

import {
  IntensityDenominatorKind,
  stringToIntensityDenominatorKind,
} from './intensityDenominators';
import { DateTime } from 'luxon';
import DateTimeUtils from './DateTimeUtils';
import invariant from 'invariant';
import { YearMonth, YM, YMInterval } from './YearMonth';
import assertNever from '@watershed/shared-util/assertNever';
import { BreakdownField } from './analytics';
import must from './must';
import { FOOTPRINT_SNAPSHOT_DRILLDOWN_PARAM } from '../footprint/constants';

const DEFAULT_RANGE_IN_MONTHS = 24;

export type FootprintFilters = Array<
  GQFilterExpressionPrimitive | GQComputedFilterExpressionPrimitive
>;

export type AnyFilterField = GQFilterFieldLegacy | GQComputedFilterField;

export const PrimitiveFilterFieldNames: Array<GQFilterFieldLegacy> =
  Object.values(GQFilterFieldLegacy);
const ComputedFilterFieldNames: Array<AnyFilterField> = Object.values(
  GQComputedFilterField
);
export const AllFilterFieldNames = [
  ...PrimitiveFilterFieldNames,
  ...ComputedFilterFieldNames,
];

export function encodeOperator(field: string): string {
  return field + '_operator';
}

/**
 * FootprintFilters that are not exposed via the "Filters" section of the page
 * and get special treatment instead. Currently this is is only FootprintKind
 * which is a page-wide global option.
 */
const HIDDEN_FILTER_FIELDS: Array<AnyFilterField> = [
  GQFilterFieldLegacy.FootprintKind,
];

const DATE_URL_FORMAT = 'yyyyMM';

/**
 * AnalysisFilters is a wrapper for handling all the filtering logic
 * in the AnalyticsDrilldown page.
 *
 * It can be instantiated from and turned into URLSearchParams.
 */
class AnalysisFilters {
  // If version is left out, the server defaults to the latest user-published version
  private _version?: string | null;

  // TODO: This should be our standard: Exclusive-end
  private _interval: YMInterval;

  // This presumes for the moment that we are only aggregating across one dimension
  private _aggregateKind: string;
  private _intensityKind?: IntensityDenominatorKind;

  // This indicates whether to pull a total or a time series
  private _columnDimension: string;

  private _filterValues: FootprintFilters;
  private _filterValuesNormalized: FootprintFilters;

  constructor(args: AnalysisFiltersArgs) {
    this._version = args.version;
    this._interval = args.interval;
    this._aggregateKind = args.aggregateKind ?? GQAggregateKind.Total;
    this._intensityKind = args.intensityKind;
    this._columnDimension = args.columnDimension ?? GQColumnDimension.Summary;

    // ensure no duplicates
    const fields = args.filterValues.map((filter) => filter.field);
    invariant(
      new Set(fields).size === fields.length,
      `unexpected duplicate filter for field: ${JSON.stringify(
        args.filterValues
      )}`
    );

    // keep in sorted order
    const fieldOrder = AllFilterFieldNames;
    const sortedFilters = args.filterValues.sort(
      (a, b) => fieldOrder.indexOf(a.field) - fieldOrder.indexOf(b.field)
    );

    this._filterValues = sortedFilters;
    this._filterValuesNormalized = sortedFilters.map((primitive) => {
      return {
        ...primitive,
        value: primitive.value
          .filter((str) => !!str)
          .map((str) => str.toLowerCase()),
      };
    });
  }

  clone(overrides: Partial<AnalysisFiltersArgs>): AnalysisFilters {
    return new AnalysisFilters({
      version: this._version,
      interval: this._interval,
      aggregateKind: this._aggregateKind,
      intensityKind: this._intensityKind,
      columnDimension: this._columnDimension,
      filterValues: this._filterValues,
      ...overrides,
    });
  }

  // Mocked out for compatibility with DrilldownFilters.
  getSavedViewId(): string {
    return 'dummy';
  }

  getVersion(): string | null {
    return this._version ?? null;
  }

  setVersion(version: string): AnalysisFilters {
    return this.clone({ version });
  }

  getAggregateKind(): {
    aggregateKind: string;
    intensityKind: IntensityDenominatorKind | undefined;
  } {
    return {
      aggregateKind: this._aggregateKind,
      intensityKind: this._intensityKind,
    };
  }

  setAggregateKind({
    aggregateKind,
    intensityKind,
  }: {
    aggregateKind: string;
    intensityKind?: IntensityDenominatorKind;
  }): AnalysisFilters {
    return this.clone({ aggregateKind, intensityKind });
  }

  getColumnDimension(): string {
    return this._columnDimension;
  }

  setColumnDimension(columnDimension: string): AnalysisFilters {
    return this.clone({ columnDimension });
  }

  setMonth(year: number, month: number): AnalysisFilters {
    const yearMonthStart = YM.make(year, month);
    const yearMonthEnd = YM.plus(yearMonthStart, 1, 'month');
    return this.clone({
      interval: new YMInterval(yearMonthStart, yearMonthEnd),
    });
  }

  getInterval(): YMInterval {
    return this._interval;
  }

  setInterval(interval: YMInterval): AnalysisFilters {
    if (
      interval.length('month') > 12 &&
      this._columnDimension === GQColumnDimension.Monthly
    ) {
      return this.clone({
        interval,
        columnDimension: GQColumnDimension.Yearly,
      });
    }

    if (
      interval.length('month') <= 12 &&
      this._columnDimension === GQColumnDimension.Yearly
    ) {
      return this.clone({
        interval,
        columnDimension: GQColumnDimension.Monthly,
      });
    }

    return this.clone({ interval });
  }

  getVisibleFilterValues(): Array<
    GQFilterExpressionPrimitive | GQComputedFilterExpressionPrimitive
  > {
    return this._filterValues.filter(
      (f) => !HIDDEN_FILTER_FIELDS.includes(f.field)
    );
  }

  getActiveFilterCount(): number {
    return this.getVisibleFilterValues().filter((filter) => filter.value.length)
      .length;
  }

  getFilterValues(): FootprintFilters {
    return this._filterValues;
  }

  getFilterValuesNormalized(): FootprintFilters {
    return this._filterValuesNormalized;
  }

  getFilterFields(): Array<GQFilterFieldLegacy | GQComputedFilterField> {
    return this._filterValues.map((value) => value.field);
  }

  getFilterValue(field: AnyFilterField): Array<string> {
    const entry = this._filterValues.find(
      (option) =>
        option.field === field && option.operator === FilterOperator.in
    );
    return entry ? entry.value : [];
  }

  getFilterOperator(field: AnyFilterField): FilterOperator {
    const entry = this._filterValues.find((option) => option.field === field);
    return entry ? entry.operator : FilterOperator.in;
  }

  removeFilterValue(field: AnyFilterField, value: string): AnalysisFilters {
    const updatedOptions = this._filterValues.map((option) => {
      if (option.field !== field) {
        return option;
      }

      const updatedValues = option.value.filter((v) => v !== value);
      const updatedOption = {
        ...option,
        value: updatedValues,
      };
      return updatedOption;
    });

    return this.clone({ filterValues: updatedOptions });
  }

  removeAllVisibleFilterValues(): AnalysisFilters {
    return this.clone({
      filterValues: this._filterValues.filter((f) =>
        HIDDEN_FILTER_FIELDS.includes(f.field)
      ),
    });
  }

  setFilterValue(field: AnyFilterField, value: Array<string>): AnalysisFilters {
    const newFilterValues = this._filterValues.filter(
      (option) => option.field !== field
    );
    const replacedFilter = this._filterValues.find(
      (option) => option.field === field
    ) || {
      field,
      operator: FilterOperator.in,
      value: [],
    };
    newFilterValues.push({ ...replacedFilter, value });
    return this.clone({
      filterValues: newFilterValues,
    });
  }

  setFilterOperator(
    field: AnyFilterField,
    operator: FilterOperator
  ): AnalysisFilters {
    const newFilterValues = this._filterValues.filter(
      (option) => option.field !== field
    );
    const replacedFilter = this._filterValues.find(
      (option) => option.field === field
    ) || {
      field,
      operator,
      value: [],
    };
    newFilterValues.push({ ...replacedFilter, operator });
    return this.clone({
      filterValues: newFilterValues,
    });
  }

  toUrlSearchParams(): URLSearchParams {
    // TODO(Avi-FILTER): Add support for arrays in URL
    const params = new URLSearchParams();

    if (this._version) {
      if (this._version.startsWith('fps_')) {
        params.append(FOOTPRINT_SNAPSHOT_DRILLDOWN_PARAM, this._version);
      } else {
        // Backward compatibility with versions like `Draft`.
        params.append('version', this._version);
      }
    }
    params.append('aggregateKind', this._aggregateKind);
    if (this._intensityKind) {
      params.append('intensityKind', this._intensityKind);
    }
    params.append('columnDimension', this._columnDimension);

    function formatDate(input: DateTime) {
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      return input.toFormat(DATE_URL_FORMAT);
    }

    // NOTE: We use inclusive interval since frontend expects this.
    const interval = DateTimeUtils.ymIntervalToLegacyInclusiveInterval(
      this._interval
    );
    params.append('start', formatDate(interval.start));
    params.append('end', formatDate(interval.end));

    for (const filter of this._filterValues) {
      switch (filter.operator) {
        case FilterOperator.notIn:
          params.append(encodeOperator(filter.field), filter.operator);
          break;
        case FilterOperator.in:
          break;
        default:
          assertNever(filter.operator);
      }
      for (const str of filter.value) {
        params.append(filter.field, str.toString());
      }
    }

    return params;
  }

  static fromUrlSearchParams(
    searchParams: URLSearchParams,
    config: { footprintInterval: YMInterval; maxRangeInMonths: number }
  ): AnalysisFilters {
    // TODO(Avi-FILTER): Add support for arrays in URL

    const version = searchParams.get('version');
    const aggregateKind =
      searchParams.get('aggregateKind') ?? GQAggregateKind.Total;
    const intensityKindParam = searchParams.get('intensityKind');
    const intensityKind = intensityKindParam
      ? stringToIntensityDenominatorKind(intensityKindParam)
      : undefined;
    const columnDimension =
      searchParams.get('columnDimension') ?? GQColumnDimension.Summary;

    function parseUrlDate(urlDate: string) {
      return YM.fromYYYYMM(urlDate);
    }

    const { footprintInterval, maxRangeInMonths } = config;
    const minDateInRange = footprintInterval.start;
    const maxDateInRange = footprintInterval.end;
    let startDateFromUrl: YearMonth = YM.max(
      minDateInRange,
      YM.minus(maxDateInRange, DEFAULT_RANGE_IN_MONTHS)
    );
    let endDateFromUrl = maxDateInRange;
    if (searchParams.has('start') && searchParams.has('end')) {
      startDateFromUrl = parseUrlDate(searchParams.get('start') || '');
      // NOTE: Turn inclusive end to exclusive end.
      endDateFromUrl = YM.plus(
        parseUrlDate(searchParams.get('end') || ''),
        1,
        'month'
      );
    } else if (searchParams.has('year')) {
      const year = parseInt(must(searchParams.get('year')));
      if (!isNaN(year)) {
        // NOTE: Change for fiscal year.
        startDateFromUrl = YM.fromObject({ year, month: 1 });
        endDateFromUrl = YM.fromObject({ year: year + 1, month: 1 });
      }
    }

    let start = startDateFromUrl
      ? YM.clamp(minDateInRange, maxDateInRange, startDateFromUrl)
      : minDateInRange;
    const end = endDateFromUrl
      ? YM.clamp(minDateInRange, maxDateInRange, endDateFromUrl)
      : maxDateInRange;
    const rawInterval = new YMInterval(start, end);
    if (rawInterval.months().length > maxRangeInMonths) {
      start = YM.minus(rawInterval.end, maxRangeInMonths);
    }
    if (start === end) {
      start = YM.minus(start, 1, 'month');
    }
    const interval = new YMInterval(start, end);

    const options: FootprintFilters = [];
    for (const field of AllFilterFieldNames) {
      const operator = searchParams.has(encodeOperator(field))
        ? (searchParams.get(encodeOperator(field)) as FilterOperator)
        : FilterOperator.in;

      const value = searchParams.getAll(field);
      options.push({
        field,
        operator,
        value,
      });
    }

    return new AnalysisFilters({
      version,
      interval,
      aggregateKind,
      intensityKind,
      columnDimension,
      filterValues: options,
    });
  }

  static forYear(year: number): AnalysisFilters {
    return new AnalysisFilters({
      filterValues: [],
      // NOTE: To change in fiscal year.
      interval: new YMInterval(YM.make(year, 1), YM.make(year + 1, 1)),
    });
  }

  nextGrouping(): BreakdownField {
    const options = [
      GQFilterFieldLegacy.BusinessCategory,
      GQFilterFieldLegacy.BusinessSubcategory,
    ] as const;

    for (const option of options) {
      // Note: this will only look at In operators, which makes sense.
      // If you exclude a category, the natural breakdown is still by the
      // remaining categories.
      if (this.getFilterValue(option).length === 0) {
        return option;
      }
    }

    return 'businessDescription';
  }
}

interface AnalysisFiltersArgs {
  version?: string | null;
  interval: YMInterval;
  aggregateKind?: string;
  intensityKind?: IntensityDenominatorKind;
  columnDimension?: string;
  filterValues: FootprintFilters;
}

export default AnalysisFilters;
