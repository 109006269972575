import CommandListPage from '@watershed/shared-frontend/components/CommandPalette/kit/CommandListPage';
import { Flags } from '@watershed/constants/flags';

import {
  useFeatureFlagContext,
  useFeatureFlagDescription,
} from '../../utils/FeatureFlag';

import { useCommandPalettePageContext } from '@watershed/shared-frontend/components/CommandPalette/CommandPaletteContext';
import { Chip, Stack, Typography } from '@mui/material';
import useLocalStorageState from '@watershed/shared-frontend/hooks/useLocalStorageState';
import { memo, useMemo } from 'react';
import { getObjectKeys } from '@watershed/shared-universal/getObjectKeys';
import { Trans } from '@lingui/react/macro';
import useLocale from '@watershed/intl/frontend/useLocale';

interface FeatureFlagOverrideListPageProps {
  flagsToShow?: Array<Flags>;
}

/** A little chip that shows whether a feature flag is enabled or disabled. */
export const EnabledChip = ({ enabled }: { enabled: boolean | undefined }) => (
  <Chip
    size="small"
    label={enabled ? 'Enabled' : 'Disabled'}
    color={enabled ? 'success' : undefined}
  />
);

const FeatureFlagItem = memo(function FeatureFlagItem({
  inTopFive,
  usingRealFlagValue,
  flag,
  enabled,
}: {
  usingRealFlagValue: boolean;
  inTopFive: boolean;
  enabled: boolean;
  flag: Flags;
}) {
  const description = useFeatureFlagDescription(flag);

  return (
    <Stack
      direction="row"
      gap={1}
      flexGrow={1}
      minWidth={0}
      maxWidth="100%"
      justifyContent="space-between"
    >
      <Stack alignContent="flex-start" textAlign="left" minWidth={0}>
        <Typography
          variant="h4"
          minWidth={0}
          textOverflow="ellipsis"
          overflow="hidden"
          color={(theme) => (enabled ? undefined : theme.palette.grey70)}
          sx={{
            textOverflow: 'ellipsis',
          }}
        >
          {flag}
        </Typography>
        <Typography
          variant="body3"
          color="textSecondary"
          minWidth={0}
          sx={{
            overflowWrap: 'break-word',
            textOverflow: 'ellipsis',
          }}
        >
          {description}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={0.5}>
        <EnabledChip enabled={enabled} />
        {!usingRealFlagValue ? (
          <Chip
            size="small"
            label={<Trans context="Override a feature flag">Override</Trans>}
            color="warning"
          />
        ) : null}
        {usingRealFlagValue && inTopFive ? (
          <Chip
            size="small"
            label={
              <Trans context="Frequently used feature flag">
                Frequently used
              </Trans>
            }
            color="primary"
          />
        ) : null}
      </Stack>
    </Stack>
  );
});

export default function FeatureFlagOverrideListPage({
  flagsToShow,
}: FeatureFlagOverrideListPageProps) {
  const locale = useLocale();
  const palette = useCommandPalettePageContext();
  const { flags, flagOverrides, setOverride, originalFlags } =
    useFeatureFlagContext();
  const [overrideUsage, setOverrideUsage] = useLocalStorageState<
    Record<string, number>
  >('featureFlagOverrideUsage', {});

  const flagKeys = useMemo(
    () =>
      flagsToShow
        ? getObjectKeys(Flags).filter((flag) => flagsToShow.includes(flag))
        : getObjectKeys(Flags),
    [flagsToShow]
  );

  const commands = useMemo(() => {
    // Most frequently overridden flags
    const topFiveOverrides = new Set(
      Object.entries(overrideUsage)
        .toSorted(([, usageA], [, usageB]) => usageB - usageA)
        .slice(0, 5)
        .map((flag) => flag[0])
    );

    return flagKeys
      .map((flag) => {
        // Overrides get cleared out from flagOverrides once they're returned
        // to the real flag state. But it's still possible you'd end up with
        // an "override" that is the same as the actual flag value, if the real
        // flag value changes after an override is applied, and the page reloads.
        // In that case the override has no effect.
        const usingRealFlagValue =
          !flagOverrides.has(flag) ||
          !!originalFlags.get(flag) === flagOverrides.get(flag);

        const enabled = flags.get(flag);
        let description = enabled ? 'Enabled' : 'Disabled';

        if (!usingRealFlagValue) {
          description += ' (Overridden)';
        }

        return {
          id: flag,
          title: flag,
          keywords: [
            enabled ? 'enabled' : 'disabled',
            usingRealFlagValue ? '' : 'override',
          ],
          description,
          onSelect: () => {
            setOverride(flag, !enabled);
            setOverrideUsage((overrideUsage) => {
              const usage = { ...overrideUsage };
              usage[flag] ??= 0;
              usage[flag] += 1; // increment usage count for local storage
              return usage;
            });
            palette.close();
          },
          usingRealFlagValue,
          render: () => (
            <FeatureFlagItem
              inTopFive={topFiveOverrides.has(flag)}
              usingRealFlagValue={usingRealFlagValue}
              enabled={enabled ?? false}
              flag={flag}
            />
          ),
        };
      })
      .toSorted((a, b) => {
        // Overridden on top
        if (a.usingRealFlagValue && !b.usingRealFlagValue) {
          return 1;
        }
        if (!a.usingRealFlagValue && b.usingRealFlagValue) {
          return -1;
        }

        // Most frequently used below that, if in top 5!
        const usageA = overrideUsage[a.id] ?? 0;
        const usageB = overrideUsage[b.id] ?? 0;
        if (topFiveOverrides.has(a.id)) {
          if (!topFiveOverrides.has(b.id)) {
            return -1;
          }
          if (usageA > usageB) {
            return -1;
          }
        }
        if (topFiveOverrides.has(b.id)) {
          if (!topFiveOverrides.has(a.id)) {
            return 1;
          }
          if (usageB > usageA) {
            return 1;
          }
        }

        // Alphabetical otherwise
        return a.title.localeCompare(b.title, locale);
      });
  }, [
    flagKeys,
    flagOverrides,
    flags,
    originalFlags,
    overrideUsage,
    palette,
    setOverride,
    setOverrideUsage,
    locale,
  ]);

  return (
    <CommandListPage
      commands={commands}
      enterActionLabel="Toggle flag"
      searchMode="fuzzy"
    />
  );
}
