import { RichHiddenContext } from './types';

interface CacheEntry {
  response?: string;
  error?: Error;
  timestamp: number;
  context?: string | RichHiddenContext;
}

interface CacheConfig {
  maxSize: number;
  expirationMs: number;
  cleanupIntervalMs?: number;
}

export class QuestionCache {
  private cache: Map<string, CacheEntry>;
  private lruList: Array<string>; // Maintain LRU order
  private config: CacheConfig;
  private cleanupInterval: NodeJS.Timeout | null;

  constructor(config: Partial<CacheConfig> = {}) {
    this.cache = new Map();
    this.lruList = [];
    this.config = {
      maxSize: 100, // Maximum number of entries
      expirationMs: 24 * 60 * 60 * 1000, // 24 hours
      cleanupIntervalMs: 60 * 60 * 1000, // 1 hour
      ...config,
    };
    this.cleanupInterval = null;
    this.startPeriodicCleanup();
  }

  private serializeContext(
    context: string | RichHiddenContext | undefined
  ): string {
    if (!context) return '';
    return typeof context === 'string' ? context : JSON.stringify(context);
  }

  private deserializeContext(
    serializedContext: string
  ): string | RichHiddenContext | undefined {
    if (!serializedContext) return undefined;
    try {
      // Try to parse as JSON first
      const parsed = JSON.parse(serializedContext);
      // Validate if it matches RichHiddenContext structure
      if (parsed && typeof parsed === 'object' && Array.isArray(parsed.items)) {
        return parsed as RichHiddenContext;
      }
    } catch {
      // If parsing fails, return as string
      return serializedContext;
    }
    return undefined;
  }

  private generateCacheKey(
    question: string,
    context?: string | RichHiddenContext
  ): string {
    const normalizedQuestion = this.normalizeQuestion(question);
    const contextKey = context ? this.serializeContext(context) : '';
    return `${normalizedQuestion}:${contextKey}`;
  }

  set(
    question: string,
    response: string,
    context?: string | RichHiddenContext
  ): void {
    const cacheKey = this.generateCacheKey(question, context);

    // Update LRU order
    this.updateLRU(cacheKey);

    // If cache is full, remove least recently used entry
    if (this.cache.size >= this.config.maxSize) {
      const lruKey = this.lruList.pop();
      if (lruKey) this.cache.delete(lruKey);
    }

    this.cache.set(cacheKey, {
      response,
      timestamp: Date.now(),
      context: context ? this.serializeContext(context) : undefined,
    });
  }

  setError(
    question: string,
    error: Error,
    context?: string | RichHiddenContext
  ): void {
    const cacheKey = this.generateCacheKey(question, context);
    this.updateLRU(cacheKey);

    if (this.cache.size >= this.config.maxSize) {
      const lruKey = this.lruList.pop();
      if (lruKey) this.cache.delete(lruKey);
    }

    this.cache.set(cacheKey, {
      error,
      timestamp: Date.now(),
      context: context ? this.serializeContext(context) : undefined,
    });
  }

  get(
    question: string,
    context?: string | RichHiddenContext
  ): { response?: string; error?: Error } | null {
    const cacheKey = this.generateCacheKey(question, context);
    const entry = this.cache.get(cacheKey);

    if (!entry) {
      return null;
    }

    // Check if entry is expired
    if (this.isExpired(entry)) {
      this.cache.delete(cacheKey);
      this.removeLRU(cacheKey);
      return null;
    }

    // Update LRU order on successful get
    this.updateLRU(cacheKey);
    return {
      response: entry.response,
      error: entry.error,
    };
  }

  private updateLRU(key: string): void {
    this.removeLRU(key);
    this.lruList.unshift(key);
  }

  private removeLRU(key: string): void {
    const index = this.lruList.indexOf(key);
    if (index > -1) {
      this.lruList.splice(index, 1);
    }
  }

  private isExpired(entry: CacheEntry): boolean {
    return Date.now() - entry.timestamp > this.config.expirationMs;
  }

  private cleanExpired(): void {
    for (const [key, entry] of this.cache.entries()) {
      if (this.isExpired(entry)) {
        this.cache.delete(key);
        this.removeLRU(key);
      }
    }
  }

  private startPeriodicCleanup(): void {
    if (this.cleanupInterval) {
      clearInterval(this.cleanupInterval);
    }

    this.cleanupInterval = setInterval(() => {
      this.cleanExpired();
    }, this.config.cleanupIntervalMs);
  }

  private normalizeQuestion(question: string): string {
    return question.toLowerCase().trim().replace(/\s+/g, ' ');
  }

  clear(): void {
    this.cache.clear();
    this.lruList = [];
  }

  getSize(): number {
    return this.cache.size;
  }

  dispose(): void {
    if (this.cleanupInterval) {
      clearInterval(this.cleanupInterval);
      this.cleanupInterval = null;
    }
  }
}
