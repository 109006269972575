import {
  AICommand,
  CommandExecutionResult,
  AIAgentContext,
  ActionDefinition,
  FeatureContextForAIAgentRegistry,
} from '@watershed/shared-universal/aiAgent/types';
import { BadInputError } from '@watershed/errors/BadInputError';

/**
 * AIAgentRegistry manages registration and unregistration of features with the AI service
 */
export class AIAgentRegistry {
  private registry: Map<string, FeatureContextForAIAgentRegistry> = new Map();
  // Store pre-processed context information that doesn't change
  private contextRegistry: Map<string, Omit<AIAgentContext, 'stateSummary'>> =
    new Map();

  private listeners: Map<string, Set<(isRegistered: boolean) => void>> =
    new Map();

  /**
   * Subscribe to changes in the registry for a specific feature
   * @param featureId - The ID of the feature to subscribe to
   * @param listener - The listener function to call when the feature is registered or unregistered
   * @returns A function to unsubscribe from the registry
   */
  public subscribeToRegistry(
    featureId: string,
    listener: (isRegistered: boolean) => void
  ): () => void {
    const newSet = new Set<(isRegistered: boolean) => void>();
    const featureListeners = this.listeners.get(featureId) || newSet;
    if (!this.listeners.has(featureId)) {
      this.listeners.set(featureId, newSet);
    }
    featureListeners.add(listener);

    return () => {
      featureListeners.delete(listener);
      if (featureListeners.size === 0) {
        this.listeners.delete(featureId);
      }
    };
  }

  public registerFeature(context: FeatureContextForAIAgentRegistry): void {
    this.registry.set(context.featureId, context);

    // Pre-process and store the static parts of the context
    const staticContext: Omit<AIAgentContext, 'stateSummary'> = {
      actions: context.actions,
      stateSummaryDocumentation: context.stateSummaryDocumentation,
      featureDocumentation: context.genericComments || '',
    };

    this.contextRegistry.set(context.featureId, staticContext);

    // Notify listeners
    const featureListeners = this.listeners.get(context.featureId);
    if (featureListeners) {
      featureListeners.forEach((listener) => listener(true));
    }
  }

  public unregisterFeature(featureId: string): void {
    if (this.registry.has(featureId)) {
      this.registry.delete(featureId);
      this.contextRegistry.delete(featureId);

      // Notify listeners
      const featureListeners = this.listeners.get(featureId);
      if (featureListeners) {
        featureListeners.forEach((listener) => listener(false));
      }
    }
  }

  public getRegisteredFeatures(): Array<string> {
    return Array.from(this.registry.keys());
  }

  public getRegisteredFeature(
    featureId: string
  ): FeatureContextForAIAgentRegistry | undefined {
    return this.registry.get(featureId);
  }

  /**
   * Prepare the context for the AI
   * This includes the store state and available actions for each feature
   * Only the state is fetched at the time this function is called, the rest is from registration time
   */
  public prepareAIContext(callingFeatureId: string): AIAgentContext {
    const featureContext = this.registry.get(callingFeatureId);
    const staticContext = this.contextRegistry.get(callingFeatureId);

    if (!featureContext || !staticContext) {
      throw new BadInputError(`Feature not found: ${callingFeatureId}`);
    }

    // Always refresh the current store data, not the data from registration time
    const dynamicContext = {
      stateSummary: featureContext.getStateSummary(featureContext.store),
    };

    // Combine static context with dynamic state
    return {
      ...staticContext,
      ...dynamicContext,
    };
  }

  private async executeAction(
    actionDef: ActionDefinition,
    args: Record<string, unknown>
  ): Promise<unknown> {
    // Guard against invalid action definitions
    if (!actionDef?.function || typeof actionDef.function !== 'function') {
      throw new BadInputError(
        'Invalid action: function is required and must be callable'
      );
    }

    try {
      // If a context is provided, bind the function to it
      const boundFunction = actionDef.context
        ? actionDef.function.bind(actionDef.context)
        : actionDef.function;

      // Wrap in Promise.resolve to handle both sync and async functions uniformly
      return await Promise.resolve().then(() => boundFunction(args));
    } catch (error) {
      // Provide more context about which action failed
      const enhancedError =
        error instanceof Error
          ? error
          : new Error('Unknown error in action execution');

      enhancedError.message = `Error executing action "${actionDef.name}": ${enhancedError.message}`;
      throw enhancedError;
    }
  }

  public async executeCommand(
    command: AICommand,
    featureId: string
  ): Promise<CommandExecutionResult> {
    const { actionName, args } = command;

    // Get the registered feature
    const feature = this.getRegisteredFeature(featureId);

    if (!feature) {
      throw new BadInputError(`Feature not found: ${featureId}`);
    }

    // Find the action in the feature's actions
    const actionDef = feature.actions.find((a) => a.name === actionName);

    if (!actionDef) {
      throw new BadInputError(
        `Action not found: ${actionName} in feature ${featureId}`
      );
    }

    // Execute the action
    const result = await this.executeAction(actionDef, args);

    return {
      command,
      status: 'EXECUTED',
      result,
    };
  }

  public async executeCommands(
    commands: Array<AICommand>,
    callingFeatureId: string
  ): Promise<Array<CommandExecutionResult>> {
    const results: Array<CommandExecutionResult> = [];

    for (const command of commands) {
      const result = await this.executeCommand(command, callingFeatureId);
      results.push(result);

      // If a command fails or is rejected and not auto-approved, stop execution
      if (result.status === 'FAILED' || result.status === 'REJECTED') {
        break;
      }
    }

    return results;
  }
}
