import uniq from 'lodash/uniq';
import {
  GQCanonicalDataset,
  GQUserUploadAttempt,
  GQUserUploadProcessingMode,
  GQUserUploadedTableCell,
} from '@watershed/shared-universal/generated/graphql-schema-types';
import { CanonicalDatasetKind } from '@watershed/constants/datasets';
import { UserUploadStatus } from '@watershed/constants/userUploads';

import {
  GQUserUploadForDatasourceFragment,
  GQUserUploadedTableWithDataPreviewFragment,
} from '@watershed/shared-universal/generated/graphql';

import isNotNullish from '@watershed/shared-util/isNotNullish';
import path from 'path';

export const SUPPORTING_DOCUMENTS_DIRECTORY = 'supporting_documents';
export const TEMPORARY_OBJECT_PREFIX = 'temp_';

export function isNotProcessedOrFinalized(status: UserUploadStatus): boolean {
  return (
    status !== UserUploadStatus.Processed &&
    status !== UserUploadStatus.Finalized
  );
}

type UserUploadTaskForUserUploadDatasource = {
  id: string;
  datasource: {
    id: string;
    dataset: {
      id: string;
      canonicalDataset: Pick<GQCanonicalDataset, 'id' | 'kind'> | null;
    };
  };
};

export type UserUploadForDatasource = Pick<
  GQUserUploadForDatasourceFragment,
  'revisionRoot' | 'id' | 'processingMode' | 'status'
> & {
  userUploadTask: Pick<
    UserUploadTaskForUserUploadDatasource,
    'datasource' | 'id'
  > | null;
  userVisibleAttempt: Pick<
    GQUserUploadAttempt,
    'id' | 'name' | 'remoteWritten'
  > | null;
};

/**
 * Ideally, this is implemented within the service fn that fetches all
 * user-visible uploads that belong to a user upload task (which is the
 * primary use case for this function)
 */
export function getUserVisibleUploads<T extends UserUploadForDatasource>(
  userUploads: Array<T>
): Array<T> {
  // If an upload has a revisionRoot, then that revisionRoot is either:
  // the file pre-OneSchema, or
  // the first file in a direct entry edit chain.

  // We gather them to filter out of the list
  const revisionRootIds = uniq(
    userUploads.map((uu) => uu.revisionRoot).filter(isNotNullish)
  );

  return userUploads.filter((userUpload) => {
    // Filter out files that have not been written to GCS
    if (!userUpload.userVisibleAttempt?.remoteWritten) {
      return false;
    }

    // filter out files that are incomplete, only for utilities because when we cancel out of the
    // utilities diff dialog during edit, we have an incomplete file
    if (
      userUpload.status === UserUploadStatus.Processed &&
      userUpload.userUploadTask?.datasource?.dataset?.canonicalDataset?.kind ===
        CanonicalDatasetKind.Utilities
    ) {
      return false;
    }
    // Filter out IdiRawFiles; these files are the original pre-OneSchema transform files from IDI
    if (
      revisionRootIds.includes(userUpload.id) &&
      userUpload.processingMode === GQUserUploadProcessingMode.IdiRawFile
    ) {
      return false;
    }
    return true;
  });
}

// TODO DI-12764: This should eventually depend on new approval flow statuses
export function getUnprocessedFilesInCurrentTask<
  T extends UserUploadForDatasource &
    Pick<GQUserUploadForDatasourceFragment, 'status'>,
>(userUploads: Array<T>): Array<T> {
  return getUserVisibleUploads(userUploads).filter(
    (uu) =>
      isNotProcessedOrFinalized(uu.status) &&
      uu.status !== UserUploadStatus.Errored
  );
}

export function getUploadsRequiringReview<
  T extends UserUploadForDatasource &
    Pick<
      GQUserUploadForDatasourceFragment,
      'status' | 'processingMode' | 'isBeingValueMapped'
    >,
>(userUploads: Array<T>): Array<T> {
  const userVisible = getUserVisibleUploads(userUploads);

  return userVisible.filter(
    (u) =>
      u.status === UserUploadStatus.Processed &&
      u.processingMode !== GQUserUploadProcessingMode.Legacy
  );
}

export function getUploadsRequiringValueMapping<
  T extends UserUploadForDatasource &
    Pick<
      GQUserUploadForDatasourceFragment,
      'status' | 'processingMode' | 'isBeingValueMapped'
    >,
>(userUploads: Array<T>): Array<T> {
  const userVisible = getUserVisibleUploads(userUploads);
  return userVisible.filter(
    (u) =>
      u.status === UserUploadStatus.Processed &&
      u.processingMode !== GQUserUploadProcessingMode.Legacy &&
      u.isBeingValueMapped
  );
}

export function getIncompleteUploads<
  T extends UserUploadForDatasource &
    Pick<GQUserUploadForDatasourceFragment, 'status' | 'processingMode'>,
>(userUploads: Array<T>): Array<T> {
  const userVisible = getUserVisibleUploads(userUploads);
  return userVisible.filter(
    (u) =>
      u.status === UserUploadStatus.Uploaded &&
      u.processingMode === GQUserUploadProcessingMode.IdiRawFile
  );
}

/**
 * Returns uploads that say "Processing please wait" in the UI
 */
export function getProcessingUploads<
  T extends UserUploadForDatasource &
    Pick<GQUserUploadForDatasourceFragment, 'status'>,
>(userUploads: Array<T>): Array<T> {
  const userVisible = getUserVisibleUploads(userUploads);
  return userVisible.filter((u) => u.status === UserUploadStatus.Validated);
}

export function getErroredUploads<
  T extends UserUploadForDatasource &
    Pick<GQUserUploadForDatasourceFragment, 'status'>,
>(userUploads: Array<T>): Array<T> {
  const userVisible = getUserVisibleUploads(userUploads);
  return userVisible.filter((u) => u.status === UserUploadStatus.Errored);
}

export function supportingDocumentsDirectoryForObjectId(
  objectId: string
): string {
  return `${SUPPORTING_DOCUMENTS_DIRECTORY}/${objectId}`;
}

export function supportingDocumentsDirectoryForTemporaryObjectId(
  objectId: string
): string {
  return `${SUPPORTING_DOCUMENTS_DIRECTORY}/${TEMPORARY_OBJECT_PREFIX}${objectId}`;
}

/**
 * Parse a file name out of a path if possible:
 * "/the/path/to/a/file_name.csv" -> "file_name"
 * "file_name.csv" -> "file_name"
 * "lsrfhigskidfhg" -> "lsrfhigskidfhg"
 */
export function getUserFacingNameFromFileName(name: string): string {
  try {
    return path.parse(name)?.name ?? name;
  } catch {
    return name;
  }
}

export type UserUploadedTableForUserUploadWithNullableSchema = Pick<
  GQUserUploadedTableWithDataPreviewFragment,
  'rawDataPreview' | 'dataPreview' | 'schema' | 'numRows' | 'status'
>;

export type UserUploadedTableForUserUploadWithNonNullSchema =
  UserUploadedTableForUserUploadWithNullableSchema & {
    schema: NonNullable<
      UserUploadedTableForUserUploadWithNullableSchema['schema']
    >;
  };

/**
 * NOTE: the tables with valid preview data could still technically have
 * status === GQUserUploadedTableStatus.Error
 */
export function getTablesWithValidPreview<
  T extends UserUploadedTableForUserUploadWithNullableSchema,
>(tables: Array<T>): Array<UserUploadedTableForUserUploadWithNonNullSchema> {
  return tables.filter(
    (
      table
    ): table is typeof table & {
      schema: NonNullable<(typeof table)['schema']>;
    } =>
      table.schema !== null &&
      !!(table.rawDataPreview?.dataUntyped || table.dataPreview?.dataUntyped)
  );
}

/**
 * Get the legal tables in either the original file (if possible)
 * or the latest revision as a fallback. Original is prioritized
 * because all our data preview features show original files right now.
 */
export function getLegalTablesForUserUpload<
  T extends UserUploadedTableForUserUploadWithNullableSchema,
>({
  originalUuts,
  latestUuts,
}: { originalUuts: Array<T>; latestUuts: Array<T> }): {
  numLegalTables: number;
  table: UserUploadedTableForUserUploadWithNonNullSchema | null;
} {
  const legalOriginalTables = getTablesWithValidPreview(originalUuts);
  const legalRevisionTables = getTablesWithValidPreview(latestUuts ?? []);

  const legalTables =
    legalOriginalTables.length > 0
      ? legalOriginalTables
      : legalRevisionTables.length > 0
        ? legalRevisionTables
        : [];

  const numLegalTables = legalTables.length;
  const table = numLegalTables > 0 ? legalTables[0] : null;
  return { numLegalTables, table };
}

/**
 * Get the number of rows in either the original file (if possible)
 * or the latest revision as a fallback. Original is prioritized
 * because all our data preview features show original files right now.
 */
export function getRowCountForUserUpload<
  T extends UserUploadedTableForUserUploadWithNullableSchema,
>(props: { originalUuts: Array<T>; latestUuts: Array<T> }): number | null {
  const { table } = getLegalTablesForUserUpload(props);
  return table?.numRows ?? null;
}

export type DataPreviewUntyped = Array<Array<GQUserUploadedTableCell>>;
