import { RichHiddenContext } from './types';
import { GQDustBot } from '@watershed/shared-universal/generated/graphql-schema-types';
import { Client, gql } from 'urql';
import { WatershedError } from '@watershed/errors/WatershedError';

export class DustQueryError extends WatershedError {
  constructor(
    message: string,
    public status?: number
  ) {
    super('INVALID_API_RESPONSE', message);
  }
}

export interface DustBotQueryInput {
  message: string;
  dustBot: string;
  hiddenContext?: string | RichHiddenContext;
}

const DUST_BOT_QUERY = gql`
  query DustBotQuery($input: DustBotQueryInput!) @withOwner(owner: EnterpriseFoundations) {
    dustBotQuery(input: $input) {
      response
    }
  }
`;

export async function queryDust(
  client: Client,
  question: string,
  dustBot: GQDustBot,
  hiddenContext?: string | RichHiddenContext
) {
  const result = await client
    .query(
      DUST_BOT_QUERY,
      {
        input: {
          message: question,
          dustBot,
          hiddenContext,
        },
      },
      {
        url: '/api/graphql',
        requestPolicy: 'network-only',
      }
    )
    .toPromise();

  if (result.error) {
    throw new DustQueryError(
      result.error.message,
      result.error.response?.status
    );
  }

  if (!result.data) {
    throw new DustQueryError('No response received from server');
  }

  return result.data.dustBotQuery.response;
}
