import must from '../utils/must';
import { PermissionType } from '@watershed/constants/permissions';

import { plural, t } from '@lingui/core/macro';
import { joinWithAnd } from '../utils/helpers';
import mapStrToEnum from '../utils/mapStrToEnum';
import groupBy from 'lodash/groupBy';
import assertNever from '@watershed/shared-util/assertNever';

import { SupportedLocale } from '@watershed/intl/constants';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import { formatList } from '@watershed/intl/formatters';
import { Action } from './permissionTypes';

export function getPermissionTypeDisplayName(
  permission: PermissionType
): string {
  switch (permission) {
    case PermissionType.ManageDataset:
      return t`Manage datasets`;
    case PermissionType.ManageDatasource:
      return t`Manage data sources`;
    case PermissionType.ApproveDataset:
      return t`Approve datasets`;
    case PermissionType.ApproveDatasource:
      return t`Approve data sources`;
    case PermissionType.ManageCompanyTags:
      return t`Manage custom columns`;
    case PermissionType.ManageOrgHierarchy:
      return t`Manage organizational structure`;
    case PermissionType.Admin:
      return t`Admin`;
    case PermissionType.ManageMarketplacePurchases:
      return t`Manage Marketplace purchases`;
    case PermissionType.ManageMeasurement:
      return t`Manage measurement`;
    case PermissionType.ManageReductionPlans:
      return t`Manage reduction plans`;
    case PermissionType.ManageSingleSignOn:
      return t`Manage single sign on`;
    case PermissionType.ManageSuppliers:
      return t`Manage suppliers`;
    case PermissionType.ManageDisclosures:
      return t`Manage disclosures`;
    case PermissionType.ViewEmployeeReport:
      return t`View summary report`;
    case PermissionType.ViewFootprintDetail:
      return t({
        message: 'View footprints and manage exports, reports, and benchmarks',
        context:
          'Name of a permission that allows a user to view footprints, and view and manage footprint exports, reports, and benchmarks',
      });
    case PermissionType.ViewReductions:
      return t`View reductions`;
    case PermissionType.EditReport:
      return t({
        message: 'Edit report',
        context: 'Permission to view and manage a report',
      });
    case PermissionType.ViewReport:
      return t({
        message: 'View report',
        context: 'Permission to view a report',
      });
    case PermissionType.EditReportQuestionInstance:
      return t({
        message: 'Edit report question',
        context: 'Permission to view and edit a question in a report',
      });
    case PermissionType.ViewReportQuestionInstance:
      return t({
        message: 'View report question',
        context: 'Permission to view a specific report question',
      });
    case PermissionType.AnyUser:
      return t({
        message: 'Any user',
        context: 'Permission to view a specific report question',
      });
    case PermissionType.WatershedAdmin:
      return t`Watershed admin`;
    case PermissionType.FinanceAdmin:
      return t`Admin (Finance only)`;
    case PermissionType.ManageFund:
      return t({
        message: 'Manage fund',
        context: 'Permission to manage a finance fund',
      });
    case PermissionType.FinanceReadOnly:
      return t({
        message: 'Finance view-only',
        context: 'View-only access to the Finance product',
      });
    case PermissionType.ViewLearningHub:
      return t`View Learning Hub`;
    case PermissionType.CorporateAdmin:
      return t`Admin (no Finance access)`;
    case PermissionType.ViewAuditDetail:
      return t({
        message: 'Manage footprint exports',
        context:
          'Name of a permission that allows a user to view and manage footprint exports',
      });
    case PermissionType.ViewFootprint:
      return t({
        message: 'View footprint',
        context:
          'Name of a permission that allows a user to view a specific footprint',
      });
    case PermissionType.ApproveFootprint:
      return t({
        message: 'Approve footprint',
        context:
          'Name of a permission that allows a user to approve a specific footprint',
      });
    default:
      assertNever(permission);
  }
}

export function getActionDisplayName(action: Action): string {
  switch (action) {
    case Action.View:
      return t`View`;
    case Action.Edit:
      return t`Edit`;
    case Action.Manage:
      return t`Manage`;
    case Action.Approve:
      return t`Approve`;
  }
}

export function getPermissionDisplayName(
  permission: PermissionType,
  objectIds: Array<string | null> = [],
  objectName?: string,
  locale?: SupportedLocale
): string {
  const nonNullCount = objectIds.filter(Boolean).length;
  switch (permission) {
    case PermissionType.ManageDataset:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Manage all datasets`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Manage dataset: ${objectName}`;
      } else {
        const numDatasets = objectIds.length;
        return plural(numDatasets, {
          one: 'Manage # dataset',
          other: 'Manage # datasets',
        });
      }

    case PermissionType.ManageDatasource:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Manage all data sources`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Manage data source: ${objectName}`;
      } else {
        const numDatasources = objectIds.length;
        return plural(numDatasources, {
          one: 'Manage # data source',
          other: 'Manage # data sources',
        });
      }

    case PermissionType.ApproveDataset:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Approve all datasources`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Approve ${objectName} dataset`;
      } else {
        const numDatasources = objectIds.length;
        return plural(numDatasources, {
          one: 'Approve # dataset',
          other: 'Approve # dataset',
        });
      }

    case PermissionType.ApproveDatasource:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Approve all data sources`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Approve data source: ${objectName}`;
      } else {
        const numDatasources = objectIds.length;
        return plural(numDatasources, {
          one: 'Approve # data source',
          other: 'Approve # data sources',
        });
      }

    case PermissionType.ManageFund:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Manage all funds`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Manage fund: ${objectName}`;
      } else {
        const numFunds = objectIds.length;
        return plural(numFunds, {
          one: 'Manage # fund',
          other: 'Manage # funds',
        });
      }

    case PermissionType.FinanceReadOnly:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return `View all funds`;
      } else if (nonNullCount === 1 && objectName) {
        return t`View fund: ${objectName}`;
      } else {
        const numFunds = objectIds.length;
        return plural(numFunds, {
          one: 'View # fund',
          other: 'View # funds',
        });
      }

    case PermissionType.ManageSuppliers:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Manage all suppliers`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Manage supplier: ${objectName}`;
      } else {
        const numSuppliers = objectIds.length;
        return plural(numSuppliers, {
          one: 'Manage # supplier',
          other: 'Manage # suppliers',
        });
      }

    case PermissionType.EditReport:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Edit all reports`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Edit report: ${objectName}`;
      } else {
        const numReports = objectIds.length;
        return plural(numReports, {
          one: 'Edit # report',
          other: 'Edit # reports',
        });
      }

    case PermissionType.EditReportQuestionInstance:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Edit all questions in all reports`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Edit report question: ${objectName}`;
      } else {
        const numQuestions = objectIds.length;
        return plural(numQuestions, {
          one: 'Edit # report question',
          other: 'Edit # report questions',
        });
      }

    case PermissionType.ViewReport:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`View all reports`;
      } else if (nonNullCount === 1 && objectName) {
        return t`View report: ${objectName}`;
      } else {
        const numReports = objectIds.length;
        return plural(numReports, {
          one: 'View # report',
          other: 'View # reports',
        });
      }

    case PermissionType.ViewReportQuestionInstance:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`View all questions in all reports`;
      } else if (nonNullCount === 1 && objectName) {
        return t`View report question: ${objectName}`;
      } else {
        const numQuestions = objectIds.length;
        return plural(numQuestions, {
          one: 'View # report question',
          other: 'View # report questions',
        });
      }

    case PermissionType.ViewFootprint:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`View all footprints`;
      } else if (nonNullCount === 1 && objectName) {
        return t`View footprint: ${objectName}`;
      } else {
        const numFootprints = objectIds.length;
        return plural(numFootprints, {
          one: 'View # footprint',
          other: 'View # footprints',
        });
      }

    case PermissionType.ApproveFootprint:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Approve all footprints`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Approve footprint: ${objectName}`;
      } else {
        const numFootprints = objectIds.length;
        return plural(numFootprints, {
          one: 'Approve # footprint',
          other: 'Approve # footprints',
        });
      }

    default:
      return getPermissionTypeDisplayName(permission);
  }
}

export function getPermissionDescription(
  permission?: PermissionType | null
): string | undefined {
  switch (permission) {
    case PermissionType.ManageDataset:
      return t`Allows viewing summaries and uploading files for selected datasets`;
    case PermissionType.ManageDatasource:
      return t`Allows viewing summaries and uploading files for selected data sources`;
    case PermissionType.ApproveDataset:
      return t`Allows viewing and approving selected datasets`;
    case PermissionType.ApproveDatasource:
      return t`Allows viewing and approving data sources`;
    case PermissionType.ManageCompanyTags:
      return t`Allows viewing and approving your custom columns`;
    case PermissionType.ManageOrgHierarchy:
      return t`Allows modifying your organizational structure`;
    case PermissionType.Admin:
      return t`Full access to the platform, including inviting new users`;
    case PermissionType.CorporateAdmin:
      return t`Full access to the core product, including inviting new users`;
    case PermissionType.ManageMarketplacePurchases:
      return t`View and purchase offsets on the Marketplace`;
    case PermissionType.ManageMeasurement:
      return t`View and manage measurements`;
    case PermissionType.ManageReductionPlans:
      return t`View and update reduction plans`;
    case PermissionType.ManageSingleSignOn:
      return t`Manage single sign-on for the org`;
    case PermissionType.ManageSuppliers:
      return t`View and update suppliers`;
    case PermissionType.ManageDisclosures:
      return t`View and respond to disclosure requests (surveys) from customers`;
    case PermissionType.ViewEmployeeReport:
      return t`View the summary report if one exists`;
    case PermissionType.ViewFootprintDetail:
      return t({
        message:
          'View all footprints and their calculated emissions via drilldown and data lineage. You can also manage all footprint exports, reports, and benchmarks.',
        context: 'Description for a permission',
      });
    case PermissionType.ViewAuditDetail:
      return t({
        message:
          'View and download all footprint exports. You can only generate exports for footprints you have access to. You can access their detailed calculations by clicking on the links in the downloaded export. This permission is most commonly given to auditors and verifiers.',
        context: 'Description for a permission',
      });
    case PermissionType.ViewReductions:
      return t`View reduction plans`;
    case PermissionType.WatershedAdmin:
      return t`Superadmin permission for Watershed employees only`;
    case PermissionType.FinanceAdmin:
      return t`Full access to the Finance product, including inviting new users`;
    case PermissionType.ManageFund:
      return t`View and manage fund-level data and assets`;
    case PermissionType.FinanceReadOnly:
      return t`View-only access to the Finance product`;
    case PermissionType.ViewLearningHub:
      return t`View the Learning Hub (already accessible with any other permission)`;
    case PermissionType.AnyUser:
      return t`Permission that all users have`;
    case PermissionType.EditReport:
      return t`View and manage a report`;
    case PermissionType.ViewReport:
      return t`View a specific report. This allows a user to navigate to the report of interest and see any information in it without being able to answer questions or make changes to the report.`;
    case PermissionType.EditReportQuestionInstance:
      return t`View and edit a question in a report`;
    case PermissionType.ViewReportQuestionInstance:
      return t`View a specific question in a report. This allows a user to navigate to the report of interest and see only questions they have permissions to view.`;
    case PermissionType.ViewFootprint:
      return t({
        message:
          'View specific footprints and their calculated emissions via drilldown and data lineage.',
        context: 'Description for a permission',
      });
    case PermissionType.ApproveFootprint:
      return t({
        message:
          'View and approve specific footprints. You will also automatically be granted "View footprint" permission.',
        context: 'Description for a permission',
      });
    case null:
    case undefined:
      return undefined;
    default:
      assertNever(permission);
  }
}

export function getPermissionGroupDescription(
  permission: PermissionType,
  objectNames: Array<string | undefined>,
  locale: SupportedLocale
): string | undefined {
  const names = objectNames.filter(isNotNullish);
  const listOfNames = formatList(names, {
    type: 'conjunction',
    style: 'long',
    locale,
  });

  const objectNamesLength = objectNames.length;

  switch (permission) {
    case PermissionType.ManageDataset:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`Upload and view all dataset summaries`
        : plural(objectNamesLength, {
            one: `Upload and view summary for the ${listOfNames} dataset`,
            other: `Upload and view summaries for the ${listOfNames} datasets`,
          });

    case PermissionType.ManageDatasource:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`Upload and view all data source summaries`
        : plural(objectNamesLength, {
            one: `Upload and view summaries for the ${listOfNames} data source`,
            other: `Upload and view summaries for the ${listOfNames} data sources`,
          });

    case PermissionType.ApproveDatasource:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`View and approve all data sources`
        : plural(objectNamesLength, {
            one: `View and approve summaries for the ${listOfNames} data source`,
            other: `View and approve summaries for the ${listOfNames} data sources`,
          });

    case PermissionType.ManageSuppliers:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`View and update all suppliers`
        : t`View and update these suppliers: ${listOfNames}`;
    case PermissionType.EditReport:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`View and edit all questions in all reports`
        : plural(objectNamesLength, {
            one: 'View and edit a report',
            other: `View and edit ${objectNamesLength} reports`,
          });
    case PermissionType.ViewReport:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t({ message: 'View report', context: 'Permission to view a report' })
        : plural(objectNamesLength, {
            one: 'View a report',
            other: `View ${objectNamesLength} reports`,
          });
    case PermissionType.EditReportQuestionInstance:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`View and edit all questions in all reports`
        : plural(objectNamesLength, {
            one: 'View and edit one question in a report',
            other: `View and edit ${objectNamesLength} questions in reports`,
          });
    case PermissionType.ViewReportQuestionInstance:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`View report questions`
        : plural(objectNamesLength, {
            one: 'View a question in a report',
            other: `View ${objectNamesLength} questions in reports`,
          });
    case PermissionType.ViewFootprint:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`View all footprints`
        : plural(objectNamesLength, {
            one: 'View a footprint',
            other: `View ${objectNamesLength} footprints`,
          });
    case PermissionType.ApproveFootprint:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`Approve all footprints`
        : plural(objectNamesLength, {
            one: 'Approve a footprint',
            other: `Approve ${objectNamesLength} footprints`,
          });
    default:
      return getPermissionDescription(permission);
  }
}

export function getRolesAndPermissionsList(
  roles: ReadonlyArray<{ role: { name: string }; revokedAt: Date | null }>,
  permissions: ReadonlyArray<{
    permission: PermissionType;
    objectId: string | null;
    revokedAt: Date | null;
  }>
): [Array<string>, Array<string>] {
  const roleNames = roles
    .filter(({ revokedAt, role }) => !revokedAt && role)
    .map(({ role }) => must(role).name);
  const permissionNames = Object.entries(
    groupBy(
      permissions.filter(({ revokedAt }) => !revokedAt),
      (p) => p.permission
    )
  ).map(([permission, entries]) =>
    getPermissionDisplayName(
      mapStrToEnum(permission, PermissionType),
      entries.map((e) => e.objectId)
    )
  );
  return [roleNames, permissionNames.reverse()];
}

export function getRolesAndPermissionsSummary(
  roles: ReadonlyArray<{ role: { name: string }; revokedAt: Date | null }>,
  permissions: ReadonlyArray<{
    permission: PermissionType;
    objectId: string | null;
    revokedAt: Date | null;
  }>
): string {
  return joinWithAnd(getRolesAndPermissionsList(roles, permissions).flat());
}
