import must from '@watershed/shared-universal/utils/must';
import { Flags } from '@watershed/constants/flags';
import { Teams } from '@watershed/constants/teams';

import React, { useContext, useEffect } from 'react';

import { useSessionOverrideContext } from '../utils/SessionOverrideContext';
import { useSyncAtomFeatureFlags } from '../global-atoms/featureFlagAtoms';
import { atom, useSetAtom } from 'jotai';

/**
 * This is our very basic FeatureFlag implementation. It currently supports
 * the following modes:
 *
 *    * Enabled/Disabled (All)
 *    * Enabled for specific ids
 *
 * It's intentionally designed to be used with `useFeatureFlag` defined below
 * and the constants exposed by the backend. In the future, we'll also support
 * a local `flags.dev.yaml` file to override flags for development.
 *
 * TODOS:
 *    * Add `flags.dev.yaml` support
 *    * Auto-refresh flags every n minutes
 */

export type FeatureFlagsMap = Map<Flags, boolean>;
export type FeatureFlagMetadataMap = Map<
  Flags,
  { description: string; featureTags: Array<string>; team: Teams }
>;

// In a Login As session, we allow Watershed employees to override flags on
// client side to preview how a feature would appear to a customer.
const FeatureFlagContext = React.createContext<{
  // Flags to use, after applying overrides
  flags: FeatureFlagsMap;

  setOverride: (flag: Flags, value: boolean | null) => void;
  flagOverrides: FeatureFlagsMap;
  flagMetadata: FeatureFlagMetadataMap;
  originalFlags: FeatureFlagsMap;
}>({
  flags: new Map(),

  setOverride: () => {},
  flagOverrides: new Map(),
  flagMetadata: new Map(),
  originalFlags: new Map(),
});

export function isFlagOn(flags: FeatureFlagsMap, flag: Flags) {
  if (flags.has(flag)) {
    return must(flags.get(flag));
  }
  return false;
}

// Let's keep track of which flags are actively being checked by the currently
// mounted components. This is useful for debugging and logging.
// We need to do some kind of ref counting because the same flag could be used
// by multiple components at once.
export const mountedFlagChecksAtom = atom(new Map<Flags, number>());

export function useFeatureFlag(flag: Flags) {
  const { flags } = useContext(FeatureFlagContext);

  const setMountedFlagChecks = useSetAtom(mountedFlagChecksAtom);

  useEffect(() => {
    setMountedFlagChecks((prev) => {
      const updatedMap = new Map(prev);
      const count = updatedMap.get(flag) ?? 0;
      updatedMap.set(flag, count + 1);
      return updatedMap;
    });

    return () => {
      setMountedFlagChecks((prev) => {
        const updatedMap = new Map(prev);
        const count = updatedMap.get(flag) ?? 0;
        if (count === 1) {
          updatedMap.delete(flag);
        } else {
          updatedMap.set(flag, count - 1);
        }
        return updatedMap;
      });
    };
  }, [flag, setMountedFlagChecks]);

  return isFlagOn(flags, flag);
}

export function useFeatureFlagDescription(flag: Flags) {
  const { flagMetadata } = useContext(FeatureFlagContext);
  if (flagMetadata.has(flag)) {
    return must(flagMetadata.get(flag)).description;
  }
  return '';
}

export function useFeatureFlagContext() {
  return useContext(FeatureFlagContext);
}

export function useAllFeatureFlags() {
  const flagContext = useContext(FeatureFlagContext);
  return flagContext.flags;
}

export function useAllEnabledFlags(): Set<Flags> {
  const flagContext = useContext(FeatureFlagContext);
  return new Set(
    [...flagContext.flags.entries()]
      .filter(([_, enabled]) => enabled)
      .map(([flag, _]) => flag)
  );
}

export function FeatureFlagContextProvider({
  children,
  flags,
  flagMetadata,
  enableOverrides,
}: {
  children: React.ReactNode;
  flags: FeatureFlagsMap;
  flagMetadata: FeatureFlagMetadataMap;
  enableOverrides: boolean;
}) {
  const sessionOverrideContext = useSessionOverrideContext();
  const flagOverrides: FeatureFlagsMap = enableOverrides
    ? sessionOverrideContext.data.featureFlagOverrides
    : new Map();

  function setOverride(flag: Flags, value: boolean | null) {
    const updatedMap: FeatureFlagsMap = new Map(flagOverrides);
    if (value === null || value === flags.get(flag)) {
      updatedMap.delete(flag);
    } else {
      updatedMap.set(flag, value);
    }
    sessionOverrideContext.updateData({
      ...sessionOverrideContext.data,
      featureFlagOverrides: updatedMap,
    });
  }

  const flagsAfterOverrides: FeatureFlagsMap = new Map(
    (Object.keys(Flags) as Array<Flags>).map((flag) => [
      flag,
      flagOverrides.get(flag) ?? flags.get(flag) ?? false,
    ])
  );

  useSyncAtomFeatureFlags(flagsAfterOverrides);
  return (
    <FeatureFlagContext.Provider
      value={{
        flags: flagsAfterOverrides,
        setOverride,
        flagOverrides,
        originalFlags: flags,
        flagMetadata,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
}
