import assertNever from '@watershed/shared-util/assertNever';
import { BuildingSizeUnit } from '@watershed/constants/buildings';

import {
  GQFinanceAssetIigccStatus,
  GQEmissionsYearSource,
  GQExternalReportType,
} from '@watershed/shared-universal/generated/graphql-schema-types';
import { formatList } from '@watershed/intl/formatters';
import isNotNullish from '@watershed/shared-util/isNotNullish';

export function getBuildingSizeUnitFieldUnit(
  buildingSizeUnit: BuildingSizeUnit | null
) {
  switch (buildingSizeUnit) {
    case BuildingSizeUnit.SquareFeet:
      return 'ft²';
    case BuildingSizeUnit.SquareMeters:
      return 'm²';
    case null:
      return null;
  }
}

export function getEmissionsSourceDescriptor({
  emissionsYearSource,
  reportingYear,
  publicDisclosure,
  hasOverrides,
  isArchived,
}: {
  emissionsYearSource: GQEmissionsYearSource;
  reportingYear: number;
  publicDisclosure:
    | { publishingYear: number; reportType: GQExternalReportType }
    | null
    | undefined;
  /**
   * Only true for estimations that have overrides (i.e. always false for
   * non-estimation)
   */
  hasOverrides: boolean;
  isArchived: boolean;
}) {
  const { publishingYear, reportType } = publicDisclosure ?? {};

  const { label, inParantheses } = (() => {
    switch (emissionsYearSource) {
      case GQEmissionsYearSource.CompanyEstimatationEngine:
      case GQEmissionsYearSource.BuildingEstimationEngine: {
        if (hasOverrides) {
          return {
            label: 'Estimation with overrides',
            inParantheses: [reportingYear.toString()],
          };
        }

        return {
          label: 'Estimation',
          inParantheses: [reportingYear.toString()],
        };
      }
      case GQEmissionsYearSource.WatershedMeasurement:
        return {
          label: 'Watershed disclosure',
          inParantheses: [reportingYear.toString()],
        };
      case GQEmissionsYearSource.Survey:
        return {
          label: 'Portal responses',
          inParantheses: [reportingYear.toString()],
        };
      case GQEmissionsYearSource.PrivateCdp:
        return {
          label: 'Private CDP disclosure',
          inParantheses: [reportingYear.toString()],
        };
      case GQEmissionsYearSource.ManualEntry:
        return {
          label: 'Manual entry',
          inParantheses: [],
        };
      case GQEmissionsYearSource.SurveyEstimate:
        return {
          label: 'Portal',
          inParantheses: [reportingYear.toString()],
        };
      case GQEmissionsYearSource.PublicDisclosure:
        const publicReportLabel = reportType
          ? `${reportType} report`
          : 'Public report';

        if (reportingYear === publishingYear) {
          return {
            label: publicReportLabel,
            inParantheses: [reportingYear.toString()],
          };
        } else if (publishingYear != null) {
          return {
            label: publicReportLabel,
            inParantheses: [
              `reporting year ${reportingYear}`,
              `published ${publishingYear}`,
            ],
          };
        }
        return {
          label: publicReportLabel,
          inParantheses: [`reporting year ${reportingYear}`],
        };
      default:
        assertNever(emissionsYearSource);
    }
  })();

  const descriptor = buildDescriptor(label, [
    ...inParantheses,
    isArchived ? 'archived' : null,
  ]);

  return descriptor;
}

function buildDescriptor(
  description: string,
  inParantheses: Array<string | null>
) {
  const nonNullInParantheses = inParantheses?.filter(isNotNullish);

  if (!nonNullInParantheses || nonNullInParantheses.length === 0) {
    return description;
  }
  return `${description} (${formatList(nonNullInParantheses, { locale: 'en' })})`;
}

export const IIGCC_STATUS_LABELS: Record<GQFinanceAssetIigccStatus, string> = {
  [GQFinanceAssetIigccStatus.NotAligned]: 'Not aligned',
  [GQFinanceAssetIigccStatus.CommittedToAligning]: 'Committed to aligning',
  [GQFinanceAssetIigccStatus.Aligning]: 'Aligning',
  [GQFinanceAssetIigccStatus.Aligned]: 'Aligned',
  [GQFinanceAssetIigccStatus.AchievingNetZero]: 'Achieving net zero',
};
