import { useRef, useCallback, useEffect } from 'react';
import { QuestionCache } from './questionCache';

export function useQuestionCache() {
  const cacheRef = useRef<QuestionCache | null>(null);

  // Initialize cache if not already initialized
  if (!cacheRef.current) {
    cacheRef.current = new QuestionCache({
      maxSize: 100,
      expirationMs: 24 * 60 * 60 * 1000, // 24 hours
    });
  }

  useEffect(() => {
    return () => {
      // Cleanup when component unmounts
      cacheRef.current?.dispose();
      cacheRef.current = null;
    };
  }, []);

  const getCachedResponse = useCallback((question: string): string | null => {
    const result = cacheRef.current?.get(question);
    return result?.response ?? null;
  }, []);

  const cacheResponse = useCallback(
    (question: string, response: string): void => {
      cacheRef.current?.set(question, response);
    },
    []
  );

  return {
    getCachedResponse,
    cacheResponse,
  };
}
