import React, { useEffect, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { Trans } from '@lingui/react/macro';

import { CODE_FONT_FAMILY } from '@watershed/style/styleUtils';
import { sharedShadow } from '../../utils/styles';

interface InternalUseBannerProps {
  /**
   * Reference to the scrollable container that should trigger opacity changes
   */
  scrollContainerRef: React.RefObject<HTMLElement>;
  /**
   * Z-index for the banner
   */
  zIndex?: number;
  /**
   * Position from the top of the container
   */
  topPosition?: string | number;
}

export const InternalUseBanner: React.FC<InternalUseBannerProps> = ({
  scrollContainerRef,
  zIndex = 2500,
  topPosition = '64px',
}) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const tickingRef = useRef(false);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      // use rAF to limit updates
      if (!tickingRef.current) {
        tickingRef.current = true;
        window.requestAnimationFrame(() => {
          // Whenever a scroll event fires, mark as scrolling
          setIsScrolling(true);

          // Clear any previous timeout to detect scroll end
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          // Set a timeout to mark scrolling as stopped
          timeoutRef.current = setTimeout(() => {
            setIsScrolling(false);
          }, 500);

          // Allow future rAF calls
          tickingRef.current = false;
        });
      }
    };

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [scrollContainerRef]);

  return (
    <Stack
      sx={{
        position: 'absolute',
        top: topPosition,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex,
      }}
    >
      <Typography
        variant="caption"
        fontFamily={CODE_FONT_FAMILY}
        fontSize={11}
        textAlign="center"
        textTransform="uppercase"
        sx={{
          backgroundColor: (theme) =>
            isScrolling
              ? alpha(theme.palette.grey100, 0.1) // Reduced opacity only when scrolling
              : alpha(theme.palette.grey100, 1), // Full opacity otherwise
          backdropFilter: 'blur(8px)',
          transition: isScrolling
            ? 'background-color 0.2s ease-out'
            : 'background-color 0.4s ease-in',
          borderRadius: '0px',
          color: (theme) => theme.palette.white,
          px: 1,
          py: 0.25,
          boxShadow: sharedShadow,
        }}
      >
        <Trans comment="Label indicating that container is for internal use only">
          Internal use only
        </Trans>
      </Typography>
    </Stack>
  );
};
