import { GQCompanyClimateProgress } from '@watershed/shared-universal/generated/graphql-schema-types';

import {
  GQCompanyClimateCommitmentRawAllFieldsFragment,
  GQDisclosureInitiativeAllFieldsFragment,
  GQDisclosureTargetDetailsAllFieldsFragment,
  GQEmissionsYearAllFieldsFragment,
} from '@watershed/shared-universal/generated/graphql';

import isEmpty from 'lodash/isEmpty';

/*
    The original version of this function was on the CompanyService,
    and it consumed an array of Disclosure records, but these weren't
    universal records, so this function couldn't be used on the frontend!
*/
export default function getClimateProgressFromDisclosures({
  disclosures,
}: {
  disclosures: Array<{
    climateCommitments?: Array<GQCompanyClimateCommitmentRawAllFieldsFragment>;
    // @kangni (2/13/2025): emissionsYearSource is a newly added required field for GQEmissionsYearAllFieldsFragment.
    // Here is a quick workaround to make sure we don't break existing typecheck. If we do need to get
    // emissionsYearSource in disclosure objects in front end, we could update DisclosureService to populate
    // this field and remove this "omit" notion.
    historicalEmissionsYears?: Array<
      Omit<GQEmissionsYearAllFieldsFragment, 'emissionsYearSource'>
    >;
    targets?: Array<GQDisclosureTargetDetailsAllFieldsFragment>;
    initiatives?: Array<GQDisclosureInitiativeAllFieldsFragment>;
  }>;
}): GQCompanyClimateProgress {
  const commitments = disclosures.flatMap(
    (disclosure) => disclosure.climateCommitments ?? []
  );
  const emissions = disclosures.flatMap(
    (disclosure) => disclosure.historicalEmissionsYears ?? []
  );
  const targets = disclosures.flatMap((disclosure) => disclosure.targets ?? []);
  const initiatives = disclosures.flatMap(
    (disclosure) => disclosure.initiatives ?? []
  );

  return generateClimateProgress({
    emissions,
    targets,
    initiatives,
    commitments,
  });
}

export function generateClimateProgress({
  emissions,
  targets,
  initiatives,
  commitments,
}: {
  commitments: Array<
    Pick<
      GQCompanyClimateCommitmentRawAllFieldsFragment,
      'kind' | 'targetPercentageCleanEnergy'
    >
  >;
  emissions: Array<Partial<GQEmissionsYearAllFieldsFragment>>;
  targets: Array<Partial<GQDisclosureTargetDetailsAllFieldsFragment>>;
  initiatives: Array<Partial<GQDisclosureInitiativeAllFieldsFragment>>;
}): GQCompanyClimateProgress {
  let hasDisclosedEmissions = false;
  let hasDisclosedTargets = false;
  let hasDisclosedInitiatives = false;

  if (!isEmpty(emissions)) {
    hasDisclosedEmissions = true;
  }

  if (!isEmpty(targets) || !isEmpty(commitments)) {
    hasDisclosedTargets = true;
  }

  if (
    !isEmpty(initiatives) ||
    commitments.some(
      (commitment) =>
        commitment.kind === 'CleanEnergy' &&
        commitment.targetPercentageCleanEnergy === 100 // We only count clean energy commitments as an initiative if the target percentage is 100%
    )
  ) {
    hasDisclosedInitiatives = true;
  }

  if (hasDisclosedEmissions) {
    if (hasDisclosedTargets) {
      if (hasDisclosedInitiatives) {
        return GQCompanyClimateProgress.DisclosedEmissionsTargetsInitiatives;
      }
      return GQCompanyClimateProgress.DisclosedEmissionsAndSetCommitments;
    } else {
      if (hasDisclosedInitiatives) {
        return GQCompanyClimateProgress.DisclosedEmissionsInitiatives;
      }
      return GQCompanyClimateProgress.DisclosedEmissions;
    }
  } else {
    if (hasDisclosedTargets) {
      if (hasDisclosedInitiatives) {
        return GQCompanyClimateProgress.DisclosedTargetsInitiatives;
      }
      return GQCompanyClimateProgress.DisclosedTargets;
    }
  }
  if (hasDisclosedInitiatives) {
    return GQCompanyClimateProgress.DisclosedInitiatives;
  }
  return GQCompanyClimateProgress.None;
}
