import { FallbackProps } from 'react-error-boundary';
import { maybeNotifySentry } from '@watershed/shared-frontend/utils/errorUtils';
import { useEffect } from 'react';

/**
 * A specialized error fallback component for the DustBot feature
 * that doesn't disrupt page layout when errors occur.
 *
 * Unlike the default ErrorFallback, this component doesn't render
 * any visible UI elements, which prevents the skip-nav and other
 * layout issues when the DustBot errors.
 *
 * See image: https://share.cleanshot.com/Zk3bSxdq
 */
export function DustBotErrorFallback(props: FallbackProps) {
  const { error } = props;

  // Report the error to monitoring systems
  useEffect(() => {
    console.error('DustBot error:', error);
    maybeNotifySentry(error);
  }, [error]);

  // Don't render anything visible to avoid layout disruption
  return null;
}
