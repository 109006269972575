import gql from 'graphql-tag';
import { GQOnboardingKind } from '@watershed/app-dashboard/generated/graphql-schema-types';

import React, { useContext, useMemo, useState } from 'react';
import { useCompleteOnboardingMutation } from '@watershed/app-dashboard/generated/urql';
import { useUserContext } from './UserContext';
import useStorageState from '@watershed/shared-frontend/hooks/useStorageState';

gql`
  mutation CompleteOnboarding($userId: ID!, $kind: OnboardingKind!)
    @withOwner(owner: EnterpriseFoundations) {
    completeOnboarding(input: { userId: $userId, kind: $kind }) {
      user {
        id
      }
      userOnboardingsCompleted
    }
  }
`;

type UserOnboardingsContextType = {
  userOnboardingsCompleted: Set<GQOnboardingKind>;
  completeOnboarding: (kind: GQOnboardingKind) => void;
};

const defaults: UserOnboardingsContextType = {
  userOnboardingsCompleted: new Set([]),
  completeOnboarding: (kind: GQOnboardingKind) => {},
};

export const UserOnboardingsContext = React.createContext(defaults);

const EMPTY_STORAGE_KEY = 'user-onboardings__empty';

export function UserOnboardingsContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const userContext = useUserContext();

  // Onboarding completions are typically stored in state and mirrored to
  // userContext for persistence.
  const ownUserState = useState(userContext.userOnboardingsCompleted);

  // If we’re logged in as another user, we don’t want to impact their
  // persisted onboarding state. To hide repeated onboarding dialogs for the
  // local user, we fall back to localStorage.
  const storageKey = userContext.loginAsUser
    ? `user-onboardings-${userContext.orgId}-${userContext.loginAsUser.id}`
    : EMPTY_STORAGE_KEY;
  const proxyUserState = useStorageState<Array<GQOnboardingKind>>(
    storageKey,
    [],
    localStorage
  );

  const [, executeUpdateOnboarding] = useCompleteOnboardingMutation();

  const [onboardingsCompleted, setOnboardingsCompleted] =
    userContext.loginAsUser ? proxyUserState : ownUserState;
  const onboardingsCompletedSet = useMemo(
    () => new Set(onboardingsCompleted),
    [onboardingsCompleted]
  );

  const value = useMemo(() => {
    const completeOnboarding = async (kind: GQOnboardingKind) => {
      if (userContext.loginAsUser === null) {
        await executeUpdateOnboarding({
          userId: userContext.userId,
          kind,
        });
      }

      setOnboardingsCompleted([...onboardingsCompletedSet, kind]);
    };

    return {
      userOnboardingsCompleted: onboardingsCompletedSet,
      completeOnboarding,
    };
  }, [
    onboardingsCompletedSet,
    executeUpdateOnboarding,
    userContext.userId,
    userContext.loginAsUser,
    setOnboardingsCompleted,
  ]);

  return (
    <UserOnboardingsContext.Provider value={value}>
      {children}
    </UserOnboardingsContext.Provider>
  );
}

export function useUserOnboardings(): [
  userOnboardingsCompleted: Set<GQOnboardingKind>,
  completeOnboarding: (kind: GQOnboardingKind) => void,
] {
  const { userOnboardingsCompleted, completeOnboarding } = useContext(
    UserOnboardingsContext
  );

  return [userOnboardingsCompleted, completeOnboarding];
}

export function useUserOnboarding(
  onboardingKind: GQOnboardingKind
): [boolean, () => void] {
  const { userOnboardingsCompleted, completeOnboarding } = useContext(
    UserOnboardingsContext
  );
  const hasCompletedOnboarding = userOnboardingsCompleted.has(onboardingKind);
  return [hasCompletedOnboarding, () => completeOnboarding(onboardingKind)];
}
