import { useMemo } from 'react';

// This hook provides a function to safely use the startViewTransition method if available.
export function useViewTransition() {
  // useMemo is used to memoize the function, ensuring it doesn't change between renders unless dependencies change.
  return useMemo(() => {
    // Check if the document object is available (i.e., we're in a supported browser environment).
    if (
      typeof document !== 'undefined' &&
      // Check if the startViewTransition method exists on the document.
      'startViewTransition' in document &&
      // Ensure that startViewTransition is a function.
      typeof (document as any).startViewTransition === 'function'
    ) {
      // Return a function that calls startViewTransition with the provided callback.
      return (callback: () => void) => {
        (document as any).startViewTransition(callback);
      };
    }
    // If startViewTransition is not available, return null.
    return null;
  }, []); // The empty dependency array ensures this logic runs only once when the component mounts.
}
