import React, { useEffect, useState } from 'react';
import {
  GQActiveOrganizationFieldsFragment,
  GQFlagsInitFieldsFragment,
  GQActiveOrganizationQuery,
  GQForceRedirectQuery,
} from '@watershed/app-dashboard/generated/graphql-operations';

import invariant from 'invariant';

import {
  useActiveOrganizationQuery,
  useForceRedirectQuery,
} from '@watershed/app-dashboard/generated/urql';
import LoadingPage from '@watershed/shared-frontend/components/LoadingPage';
import SharedAppContainer from '../components/SharedAppContainer';
import useGlobalLocation from '@watershed/ui-core/hooks/useGlobalLocation';
import { routeForLogin } from '@watershed/shared-universal/dashboardRoutes';
import isFetchingOrPartial from '@watershed/shared-frontend/utils/isFetchingOrPartial';
import type { UseQueryState } from 'urql';
import Redirect from '@watershed/shared-frontend/components/Redirect';
import FailedToContactApiDevelopmentErrorBox from '@watershed/shared-frontend/components/FailedToContactApiDevelopmentErrorBox';
import { useQueryParam } from '@watershed/shared-frontend/utils/queryParamHooks';

type ActiveOrganizationState =
  | { state: 'loading' }
  | { state: 'redirecting'; redirectPath: string }
  | { state: 'loaded' };

function getActiveOrganizationState(
  result: UseQueryState<GQActiveOrganizationQuery>,
  forceRedirectResult: UseQueryState<GQForceRedirectQuery>
): ActiveOrganizationState {
  if (isFetchingOrPartial(result) || isFetchingOrPartial(forceRedirectResult)) {
    return { state: 'loading' };
  }
  // Note: This logic is duplicative to the logic in `errorExchange`,
  // and that logic & redirect often happens before this happens.
  if (result.error?.response?.status === 401) {
    const pathAndQuery = window.location.toString().replace(window.origin, '');
    return {
      state: 'redirecting',
      redirectPath: routeForLogin({ redirect: pathAndQuery }),
    };
  }
  const forceRedirect = forceRedirectResult.data?.forceRedirect;
  if (forceRedirect && !window.location.pathname.startsWith(forceRedirect)) {
    return { state: 'redirecting', redirectPath: forceRedirect };
  }
  return { state: 'loaded' };
}

type FetchActiveOrganizationResult = {
  activeOrganization: GQActiveOrganizationFieldsFragment;
  flags: Array<GQFlagsInitFieldsFragment>;
  preferredLocale: string | null;
};

function FetchActiveOrganizationQuery({
  children,
}: {
  children: (
    activeOrganizationQuery: FetchActiveOrganizationResult
  ) => React.ReactNode;
}) {
  const { location } = useGlobalLocation();
  const [loginAsRedirect] = useQueryParam('loginAsRedirect');

  const [forceRedirectValidated, setForceRedirectValidated] = useState(false);
  const [result] = useActiveOrganizationQuery();
  const [forceRedirectResult] = useForceRedirectQuery({
    variables: { pathname: location.pathname },
    pause: forceRedirectValidated,
  });
  const { data, error } = result;

  const activeOrganizationState = getActiveOrganizationState(
    result,
    forceRedirectResult
  );

  useEffect(() => {
    if (!forceRedirectValidated && forceRedirectResult.data) {
      setForceRedirectValidated(true);
    }
  }, [forceRedirectValidated, forceRedirectResult.data]);

  if (activeOrganizationState.state === 'loading') {
    return <LoadingPage />;
  }

  if (activeOrganizationState.state === 'redirecting') {
    const redirectUrl = new URL(
      activeOrganizationState.redirectPath,
      window.location.origin
    );

    // When someone logs in to dashboard from admin (using login-as), we
    // broadcast a message to all browser tabs with an active dashboard
    // session, which then shows a warning if the session's active org is
    // different from the org the user just logged in to. This logic depends on
    // having `loginAsRedirect` param set to true. Therefore we should preserve
    // the param when we force redirect. See useLoginAsCrossOrgWarning.
    if (loginAsRedirect === 'true') {
      redirectUrl.searchParams.set('loginAsRedirect', 'true');
    }

    return <Redirect to={`${redirectUrl.pathname}${redirectUrl.search}`} />;
  }

  if ((error || !data) && process.env.NODE_ENV === 'development') {
    return <FailedToContactApiDevelopmentErrorBox error={error} />;
  }
  invariant(
    error === undefined && data,
    `Failed to load activeOrganization query. Error: ${error?.message}`
  );

  return <>{children(data)}</>;
}

export default function LoggedInLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <FetchActiveOrganizationQuery>
      {(props) => (
        <SharedAppContainer {...props}>{children}</SharedAppContainer>
      )}
    </FetchActiveOrganizationQuery>
  );
}
