import { PermissionType } from '@watershed/constants/permissions';

import {
  hasPermission,
  UserPermissions,
} from '@watershed/shared-universal/permissions/permissionUtils';

/**
 * Returns true if the current user has permission to manage any suppliers.
 */
export function hasPermissionToManageAnySupplier(
  userPermissions: UserPermissions
) {
  return hasPermission(userPermissions, [PermissionType.ManageSuppliers], {
    allowAnyObject: true,
  });
}

/**
 * Returns true if the current user has permission to manage all suppliers.
 */
export function hasPermissionToManageAllSuppliers(
  userPermissions: UserPermissions
) {
  // "Manage all suppliers" is stored as "ManageSuppliers" with no object ID.
  return hasPermission(userPermissions, [PermissionType.ManageSuppliers]);
}

export const NO_ADMIN_PERMISSION_ERROR =
  'You do not have permissions to edit this.';

/**
 * Returns true if the current user has permission to edit custom columns.
 */
export function hasPermissionToEditCustomColumn(
  userPermissions: UserPermissions
) {
  return hasPermission(userPermissions, [PermissionType.Admin]);
}

export function hasPermissionToViewDrilldown(userPermissions: UserPermissions) {
  return hasPermission(userPermissions, [PermissionType.ViewFootprintDetail]);
}
