import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import { mixinSx } from '@watershed/style/styleUtils';
import { adminChipBarLeftPositionStyles } from './useAdminBarLeftPosition';
import { PrivilegedSessionIndicator } from './PrivilegedSessionIndicator';
import { CurrentEnvironmentIndicator } from './CurrentEnvironmentIndicator';
import { LoginAsUserIndicator } from './LoginAsUserIndicator';
import { FlagOverridesIndicator } from './FlagOverridesIndicator';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import { adminBarVisibilityAtom } from '../../global-atoms/adminBarVisibilityAtom';
import { useAtomValue } from 'jotai';

/**
 * Shows a bar of admin tools at the bottom of the screen. Can be disabled with
 * the NEXT_PUBLIC_DISABLE_ADMIN_CHIP environment variable, and most content
 * only shows up for Watershed employees or login as users.
 */
export default function AdminBar() {
  const adminBarVisible = useAtomValue(adminBarVisibilityAtom);

  const disableAdminChip = !!process.env.NEXT_PUBLIC_DISABLE_ADMIN_CHIP;

  if (disableAdminChip) {
    return null;
  }

  return (
    <Box
      data-testid={TestIds.AdminBar}
      sx={mixinSx(
        {
          display: 'flex',
          position: 'fixed',
          right: 8,
          bottom: 8,
          zIndex: 1299, // 1 less than MUI popovers.
          flexWrap: 'wrap',
          pointerEvents: !adminBarVisible ? 'none' : 'auto',
        },
        adminChipBarLeftPositionStyles
      )}
    >
      <Fade in={adminBarVisible}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <LoginAsUserIndicator />
          <CurrentEnvironmentIndicator />
          <PrivilegedSessionIndicator />
          <FlagOverridesIndicator />
        </Stack>
      </Fade>
    </Box>
  );
}
