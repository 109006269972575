import { makeBetterEnum } from './betterEnum';

export const FilterConjunction = makeBetterEnum('andConjunction');

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FilterConjunction =
  (typeof FilterConjunction)[keyof typeof FilterConjunction];

export const FilterOperator = makeBetterEnum('in', 'notIn');

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FilterOperator =
  (typeof FilterOperator)[keyof typeof FilterOperator];
