import isNotNullish from '@watershed/shared-util/isNotNullish';
import {
  PlanTargetIntensityType,
  PlanTargetTargetComparisonType,
  TargetKind,
} from '@watershed/constants/reductions';

import {
  GQTargetUnit,
  GQTarget,
  GQPlan,
  GQPrivateDisclosureType,
  GQExternalReportType,
  GQPlanTarget,
} from '@watershed/shared-universal/generated/graphql-schema-types';
import { formatNumber, formatPercentageNonzero } from './helpers';
import { getPlanNetZeroYear } from './PlanUtils';
import { getTargetName } from '../companySurveys/utils';
import { getReadableScopeFromFilterGroupExpression } from '../companySurveys/utils';
import { SupplierDisclosureTargetField } from '../forecast/types';

export type DisclosureTargetForSupplierChart = Pick<
  SupplierDisclosureTargetField,
  'intensityType' | 'description' | 'emissionsTarget' | 'filters' | 'baseYear'
>;

export type DisclosureTargetWithSource = Pick<
  SupplierDisclosureTargetField,
  | 'intensityType'
  | 'description'
  | 'emissionsTarget'
  | 'filters'
  | 'baseYear'
  | 'name'
  | 'id'
  | 'reductionRate'
> & {
  source: GQExternalReportType | GQPrivateDisclosureType | undefined;
};

interface TargetInfo {
  kind: TargetKind;
  title: string;
  amount: string;
  descriptor: string | null;
}

function displayAmount(amount: number, unit: GQTargetUnit): string {
  switch (unit) {
    case GQTargetUnit.Percent:
      const prefix = amount < 0 ? '↓' : '↑';
      amount = amount < 0 ? amount * -1 : amount;
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      return `${prefix}${formatPercentageNonzero(amount / 100)}`;
    case GQTargetUnit.PercentAbsolute:
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      return formatPercentageNonzero(amount / 100);
    case GQTargetUnit.Tco2E:
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      return `${formatNumber(amount)}`;
    case GQTargetUnit.Year:
      return amount.toString();
  }
}

export function parseTargets(
  targets: Array<
    Pick<GQTarget, 'kind' | 'title' | 'targetAmount' | 'unit' | 'descriptor'>
  >,
  plan: Pick<GQPlan, 'variables'> | undefined
): Array<TargetInfo> {
  let targetInfos = targets.map((t) => {
    return {
      kind: t.kind,
      title: t.title,
      amount: displayAmount(t.targetAmount, t.unit),
      descriptor: t.descriptor,
    };
  });

  // The reduction target and net zero commitment from a published plan
  // replaces any hard-coded targets.
  if (plan) {
    const planNetZeroYear = getPlanNetZeroYear(plan);
    if (planNetZeroYear !== undefined) {
      // Replace hard-coded net zero year with year from plan.
      targetInfos = targetInfos.filter(
        (targetInfo) => targetInfo.kind !== TargetKind.NetZero
      );
      targetInfos.push({
        kind: TargetKind.NetZero,
        title: 'Net zero by',
        amount: displayAmount(planNetZeroYear, GQTargetUnit.Year),
        descriptor: null,
      });
    }
  }

  return targetInfos;
}
export function supplierDisclosureTargetToPlanTarget(
  target: DisclosureTargetForSupplierChart
): Pick<
  GQPlanTarget,
  | 'filters'
  | 'intensityType'
  | 'customIntensityConfigId'
  | 'comparisonType'
  | 'emissionsTargetCustom'
  | 'baseYear'
  | 'interimTargetValue'
> {
  return {
    filters: target.filters,
    intensityType:
      // https://watershedclimate.slack.com/archives/C04464Y3RJS/p1711610079558179
      target.intensityType in PlanTargetIntensityType
        ? (target.intensityType as PlanTargetIntensityType)
        : PlanTargetIntensityType.Custom,
    // Custom intensities are not supported for supplier disclosure targets.
    customIntensityConfigId: null,
    // Supplier targets are currently all base year targets
    comparisonType: PlanTargetTargetComparisonType.BaseYear,
    emissionsTargetCustom: target.emissionsTarget,
    baseYear: target.baseYear,
    interimTargetValue: null,
  };
}

export function disclosureTargetToDisclosureTargetWithSource(
  disclosures: Array<{
    targets: Array<SupplierDisclosureTargetField> | null;
    publicDisclosure: {
      reportType: GQExternalReportType;
    } | null;
    privateDisclosure: {
      privateDisclosureType: GQPrivateDisclosureType;
    } | null;
  }>
): Array<DisclosureTargetWithSource> {
  return disclosures
    .filter(
      (disclosure) =>
        isNotNullish(disclosure.targets) && disclosure.targets.length > 0
    )
    .flatMap((disclosure) =>
      disclosure.targets?.map((target: SupplierDisclosureTargetField) => ({
        ...target,
        source:
          disclosure.publicDisclosure?.reportType ??
          disclosure.privateDisclosure?.privateDisclosureType,
        name:
          target.name ||
          getTargetName(
            target.name,
            target.baseYear,
            getReadableScopeFromFilterGroupExpression(target.filters)
          ),
      }))
    )
    .filter(isNotNullish);
}
