import {
  Action,
  ResourceSpecificPermissionType,
  ResourcePermissionConfig,
  ParentLevelResourceType,
  ChildLevelResourceType,
} from './permissionTypes';
import {
  PermissionObjectType,
  PermissionType,
} from '@watershed/constants/permissions';

import { BadInputError } from '@watershed/errors/BadInputError';

/**
 * This is a mapping of the legacy PermissionTypes that correspond
 * to a resource level permission to the resource type and action.
 */
export const RESOURCE_LEVEL_PERMISSION_TYPES: Record<
  ResourceSpecificPermissionType,
  { resourceType: PermissionObjectType; action: Action }
> = {
  [PermissionType.ManageDataset]: {
    resourceType: PermissionObjectType.Dataset,
    action: Action.Manage,
  },
  [PermissionType.ManageDatasource]: {
    resourceType: PermissionObjectType.Datasource,
    action: Action.Manage,
  },
  [PermissionType.ApproveDatasource]: {
    resourceType: PermissionObjectType.Datasource,
    action: Action.Approve,
  },
  [PermissionType.ApproveDataset]: {
    resourceType: PermissionObjectType.Dataset,
    action: Action.Approve,
  },
  [PermissionType.EditReportQuestionInstance]: {
    resourceType: PermissionObjectType.ReportQuestionInstance,
    action: Action.Edit,
  },
  [PermissionType.ViewReportQuestionInstance]: {
    resourceType: PermissionObjectType.ReportQuestionInstance,
    action: Action.View,
  },
  [PermissionType.EditReport]: {
    resourceType: PermissionObjectType.Report,
    action: Action.Edit,
  },
  [PermissionType.ViewReport]: {
    resourceType: PermissionObjectType.Report,
    action: Action.View,
  },
  [PermissionType.ManageFund]: {
    resourceType: PermissionObjectType.Fund,
    action: Action.Manage,
  },
  [PermissionType.FinanceReadOnly]: {
    resourceType: PermissionObjectType.Fund,
    action: Action.View,
  },
  [PermissionType.ViewFootprint]: {
    resourceType: PermissionObjectType.Footprint,
    action: Action.View,
  },
  [PermissionType.ApproveFootprint]: {
    resourceType: PermissionObjectType.Footprint,
    action: Action.Approve,
  },
};

export const getLegacyPermissionFromResourceAndAction = (
  resourceType: PermissionObjectType,
  action: Action
): PermissionType => {
  const permission = Object.entries(RESOURCE_LEVEL_PERMISSION_TYPES).find(
    ([_, config]) => {
      return config.resourceType === resourceType && config.action === action;
    }
  );
  if (!permission) {
    throw new BadInputError(
      `No permission found for resource type ${resourceType} and action ${action}`
    );
  }

  return permission[0] as PermissionType;
};

/**
 * Here we configure the high level resource types with their
 * - (optional) subResourceType
 * - actions that are allowed on the resource
 * - (optional) actionInheritance that allows for one action to imply another
 */
export const RESOURCE_TYPE_PERMISSION_CONFIGS: Record<
  ParentLevelResourceType,
  ResourcePermissionConfig
> = {
  [PermissionObjectType.Dataset]: {
    childResourceType: PermissionObjectType.Datasource,
    actions: [Action.Manage, Action.Approve],
  },
  [PermissionObjectType.Report]: {
    childResourceType: PermissionObjectType.ReportQuestionInstance,
    actions: [Action.View, Action.Edit],
  },
  [PermissionObjectType.Fund]: {
    actions: [Action.Manage, Action.View],
  },
  [PermissionObjectType.Footprint]: {
    actions: [Action.View, Action.Approve],
  },
};

/**
 * This points a child resource to the parent
 * whose permissions it inherits. The opposite of the above config.
 */
export const CHILD_TO_PARENT_RESOURCE_TYPE: Record<
  ChildLevelResourceType,
  ParentLevelResourceType
> = {
  [PermissionObjectType.Datasource]: PermissionObjectType.Dataset,
  [PermissionObjectType.ReportQuestionInstance]: PermissionObjectType.Report,
};
