import { makeBetterEnum } from './betterEnum';

export const UserUploadStatus = makeBetterEnum(
  'Uploading',
  'Uploaded',
  'Processing',
  'Processed',
  'Validated',
  'Errored',
  'Finalized'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UserUploadStatus =
  (typeof UserUploadStatus)[keyof typeof UserUploadStatus];
