import {
  GQFilterExpressionGroup,
  GQFilterExpressionPrimitive,
  GQFilterFieldLegacy,
  GQFilterFieldWatershed,
  GQFootprintAnalysisFilters,
  GQFootprintAnalysisInclusionKind,
  GQFootprintAnalysisTimeSeriesDataPoint,
  GQFootprintAnalysisTimeseriesSeries,
  GQFootprintSummaryFilters,
} from '@watershed/shared-universal/generated/graphql-schema-types';
import { FilterConjunction, FilterOperator } from '@watershed/constants/filter';

import { YM, YMInterval, YearMonth } from './YearMonth';
import { isVisibleFilterField } from './DrilldownFilters';
import { NONE_GROUP_NAME } from '../analysis/drilldownConstants';

export function makeFilterExpressionGroup({
  footprintKind,
  businessCategory,
  ghgCategoryId,
  ghgScope,
}: {
  footprintKind: string;
  businessCategory?: string;
  ghgCategoryId?: string;
  ghgScope?: string;
}): GQFilterExpressionGroup {
  const expressions: Array<GQFilterExpressionPrimitive> = [
    {
      operator: FilterOperator.in,
      field: GQFilterFieldLegacy.FootprintKind,
      value: [footprintKind],
    },
  ];
  if (businessCategory) {
    expressions.push({
      operator: FilterOperator.in,
      field: GQFilterFieldLegacy.BusinessCategory,
      value: [businessCategory],
    });
  }
  if (ghgCategoryId) {
    expressions.push({
      operator: FilterOperator.in,
      field: GQFilterFieldLegacy.GhgCategoryId,
      value: [ghgCategoryId],
    });
  }
  if (ghgScope) {
    expressions.push({
      operator: FilterOperator.in,
      field: GQFilterFieldLegacy.GhgScope,
      value: [ghgScope],
    });
  }
  return {
    conjunction: FilterConjunction.andConjunction,
    expressions,
  };
}

export function makeFootprintAnalysisFilters({
  interval,
  footprintKind,
  inclusionKind,
  businessCategory,
  ghgCategoryId,
  ghgScope,
}: {
  interval?: YMInterval;
  footprintKind?: string;
  inclusionKind?: GQFootprintAnalysisInclusionKind;
  businessCategory?: string;
  ghgCategoryId?: string;
  ghgScope?: string;
}): GQFootprintAnalysisFilters {
  const timeFilters = interval
    ? {
        startYearMonth: interval.start,
        endYearMonth: interval.end,
      }
    : {};
  const filterFilters = footprintKind
    ? {
        filters: makeFilterExpressionGroup({
          footprintKind,
          businessCategory,
          ghgCategoryId,
          ghgScope,
        }),
      }
    : {};
  const inclusionFilters = inclusionKind ? { inclusionKind } : {};

  return { ...timeFilters, ...filterFilters, ...inclusionFilters };
}

export function makeFootprintSummaryFilters({
  interval,
  inclusionKind,
  ghgScope,
}: {
  interval?: YMInterval;
  inclusionKind?: GQFootprintAnalysisInclusionKind;
  ghgScope?: 'Scope 1' | 'Scope 2' | 'Scope 3';
}): GQFootprintSummaryFilters {
  const timeFilters = interval
    ? {
        startYearMonth: interval.start,
        endYearMonth: interval.end,
      }
    : {};
  const filterFilters = ghgScope
    ? {
        ghgScope,
      }
    : {};
  const inclusionFilters = inclusionKind ? { inclusionKind } : {};

  return { ...timeFilters, ...filterFilters, ...inclusionFilters };
}

export type MonthInfos = Map<YearMonth, GQFootprintAnalysisTimeSeriesDataPoint>;

export function getMonthInfos(
  series: Array<Pick<GQFootprintAnalysisTimeseriesSeries, 'data'>>,
  interval?: YMInterval
): MonthInfos {
  const monthInfos = new Map<
    YearMonth,
    GQFootprintAnalysisTimeSeriesDataPoint
  >();
  if (series.length > 0) {
    for (const data of series[0].data) {
      const ym = YM.fromJSDate(data.date);
      if (!interval || interval.contains(ym)) {
        monthInfos.set(ym, data);
      }
    }
  }
  return monthInfos;
}

// catches all possible representations of "unspecified" value when filtering
export const ALL_NONE_GROUP_NAMES = ['', 'null', NONE_GROUP_NAME];

export const ALL_USER_VISIBLE_NON_CUSTOM_FIELDS = Object.values(
  GQFilterFieldWatershed
).filter(isVisibleFilterField);

// The below filters are a set of filters used in drilldown (in order in the
// 'all' key). Note that drilldown might add extra filters for custom tags.

const scopeFilters = [
  GQFilterFieldWatershed.GhgScope,
  GQFilterFieldWatershed.GhgCategoryId,
];

const categoryFilters = [
  GQFilterFieldWatershed.CategoryId,
  GQFilterFieldWatershed.SubcategoryId,
  GQFilterFieldWatershed.Description,
];

const locationFilters = [
  GQFilterFieldWatershed.LocationCountry,
  GQFilterFieldWatershed.LocationState,
  GQFilterFieldWatershed.LocationCity,
  GQFilterFieldWatershed.BuildingName,
  GQFilterFieldWatershed.Location,
];

const productFilters = [GQFilterFieldWatershed.Product];

const vendorFilters = [GQFilterFieldWatershed.Vendor];

export function getGroupedFiltersInDrilldownOrder(): Array<GQFilterFieldWatershed> {
  return [
    ...scopeFilters,
    ...categoryFilters,
    ...locationFilters,
    ...productFilters,
    ...vendorFilters,
  ];
}

type FilterGroupType = 'vendor' | 'category' | 'location' | 'product' | 'scope';

export const groupedFilters: Record<
  FilterGroupType,
  Array<GQFilterFieldWatershed>
> = {
  vendor: vendorFilters,
  category: categoryFilters,
  location: locationFilters,
  product: productFilters,
  scope: scopeFilters,
};
