import { useState, useCallback, useEffect, useRef } from 'react';
import { Box, Fade, Modal, Paper, Popper, PopperProps } from '@mui/material';
import { GlobalHelpTrigger, TRIGGER_POSITION } from './Trigger';
import HelpChat from '../shared/Chat';
import MaximizeIcon from '@watershed/icons/components/Maximize';
import MinimizeIcon from '@watershed/icons/components/Minimize';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import Button from '@watershed/ui-core/components/Button';
import { useLingui } from '@lingui/react/macro';
import { floatingButtonSx, sharedPaperSx } from '../utils/styles';
import { mixinSx, tooltipDarkStyleProps } from '@watershed/style/styleUtils';
import { useQuestionHandler } from '../utils/hooks';
import { useGlobalHelp } from '../shared/Context';
import { Tooltip } from '@mui/material';
import { GQDustBot } from '@watershed/shared-universal/generated/graphql-schema-types';
import { PrecannedMessage, HelpChatProps } from '../utils/types';
import {
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
} from '@watershed/shared-universal/utils/constants';

// Define the OpenSource enum
enum OpenSource {
  GLOBAL_TRIGGER = 'global-trigger',
  IN_CONTEXT = 'in-context',
}

// Types
interface GlobalHelpProps {
  dustBot: GQDustBot;
  sidebarCollapsed: boolean;
  sidebarVisible: boolean;
  precannedMessages?: Array<PrecannedMessage>;
}

// Helper type for HelpChat props in this context
type GlobalHelpChatProps = Omit<HelpChatProps, 'dustBot'>;

interface SafeZoneProps {
  isHovering: boolean;
  setIsHovering: (value: boolean) => void;
}

interface ExpandCollapseButtonProps {
  isExpanded: boolean;
  isHovering: boolean;
  onClick: () => void;
  showButton: boolean;
}

// SafeZone component to extend the hover area for better UX
const SafeZone = ({ isHovering, setIsHovering }: SafeZoneProps) => (
  <Box
    sx={{
      position: 'absolute',
      width: '48px',
      height: '100%',
      right: '-48px',
      top: 0,
      zIndex: 0,
      pointerEvents: isHovering ? 'auto' : 'none',
    }}
    onMouseEnter={() => setIsHovering(true)}
  />
);

// ExpandCollapseButton component
const ExpandCollapseButton = ({
  isExpanded,
  isHovering,
  onClick,
  showButton,
}: ExpandCollapseButtonProps) => {
  if (!showButton) return null;

  return (
    <Fade in={isHovering}>
      <Tooltip
        slotProps={tooltipDarkStyleProps()}
        title={isExpanded ? 'Collapse' : 'Expand'}
        placement="top"
      >
        <Button
          isIcon
          onClick={onClick}
          data-testid={
            isExpanded
              ? TestIds.GlobalHelpCollapseButton
              : TestIds.GlobalHelpExpandButton
          }
          sx={mixinSx(floatingButtonSx, {
            opacity: isHovering ? 1 : 0,
            zIndex: 1,
            position: 'absolute',
            top: 0,
            right: -40,
            transition: 'opacity 200ms ease-in-out',
          })}
        >
          {isExpanded ? (
            <MinimizeIcon size={14} color="grey50" />
          ) : (
            <MaximizeIcon size={14} color="grey50" />
          )}
        </Button>
      </Tooltip>
    </Fade>
  );
};

export function GlobalHelpWrapper({
  dustBot,
  sidebarCollapsed = false,
  sidebarVisible = true,
  precannedMessages,
}: GlobalHelpProps) {
  const { t } = useLingui();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [openSource, setOpenSource] = useState<OpenSource | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);
  const [shouldAnimateTransform, setShouldAnimateTransform] = useState(false);
  const prevSidebarCollapsed = useRef(sidebarCollapsed);

  const { activeMessages, isTyping, handleQuestionSubmit, resetChat } =
    useQuestionHandler(dustBot, precannedMessages);

  const { registerExpandHandler, unregisterExpandHandler } = useGlobalHelp();

  // Toggle the chat open/closed from the global trigger
  const handleToggleOpen = useCallback(
    (event?: React.MouseEvent<HTMLElement>) => {
      if (event && !anchorEl) {
        setAnchorEl(event.currentTarget);
        setOpenSource(OpenSource.GLOBAL_TRIGGER);
      } else {
        setAnchorEl(null);
        setIsModalOpen(false);
        setOpenSource(null);
      }
    },
    [anchorEl]
  );

  // Adapter function to handle onCollapsedChange which receives a boolean
  const handleCollapsedChange = useCallback((_collapsed: boolean) => {
    setAnchorEl(null);
    setIsModalOpen(false);
    setOpenSource(null);
  }, []);

  // Helper function to get common HelpChat props
  const getHelpChatProps = useCallback(
    (): GlobalHelpChatProps => ({
      title: t({
        message: `Ask Watershed`,
        context: 'Title for the help chat panel',
      }),
      isCollapsed: false,
      onCollapsedChange: handleCollapsedChange,
      enableQuestions: true,
      activeMessages,
      isTyping,
      onQuestionSubmit: handleQuestionSubmit,
      precannedMessages,
      onResetChat: resetChat,
    }),
    [
      t,
      handleCollapsedChange,
      activeMessages,
      isTyping,
      handleQuestionSubmit,
      precannedMessages,
      resetChat,
    ]
  );

  // Handle expanding from popper to modal
  const handleExpandToModal = useCallback(() => {
    setAnchorEl(null);
    setIsModalOpen(true);
  }, []);

  // Register the expand handler for context access
  useEffect(() => {
    const expandFunction = () => {
      if (!anchorEl && !isModalOpen) {
        // Open directly as modal when triggered from context
        setIsModalOpen(true);
        setOpenSource(OpenSource.IN_CONTEXT);
      }
    };

    registerExpandHandler(expandFunction);
    return () => unregisterExpandHandler();
  }, [registerExpandHandler, unregisterExpandHandler, anchorEl, isModalOpen]);

  // Initialize triggerRef when component mounts
  useEffect(() => {
    const findAndSetTriggerRef = () => {
      const trigger = document.querySelector(
        `[data-testid="GlobalHelpTrigger"]`
      );
      if (trigger) {
        triggerRef.current = trigger as HTMLDivElement;
        return true;
      }
      return false;
    };

    // Try immediately
    if (!findAndSetTriggerRef()) {
      // If not found, try again after a short delay
      const timeoutId = setTimeout(() => {
        findAndSetTriggerRef();
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, []);

  // Handle clicks outside the popper
  useEffect(() => {
    if (!anchorEl) return; // Only add listener when popper is open

    const handleClickOutside = (event: MouseEvent) => {
      // Don't close if clicking on the trigger element
      if (triggerRef.current?.contains(event.target as Node)) {
        return;
      }

      // Don't close if clicking inside the popper
      if (popperRef.current?.contains(event.target as Node)) {
        return;
      }

      // Otherwise, close the popper
      setAnchorEl(null);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl]);

  // Handle sidebar state changes for animation.
  // This makes sure that the left transition is animated in-line
  // with the sidebar state, but only if the popper is open.
  useEffect(() => {
    if (anchorEl && prevSidebarCollapsed.current !== sidebarCollapsed) {
      setShouldAnimateTransform(true);
    } else {
      setShouldAnimateTransform(false);
    }

    prevSidebarCollapsed.current = sidebarCollapsed;
  }, [sidebarCollapsed, anchorEl]);

  // Configure popper behavior and positioning
  const popperModifiers: PopperProps['modifiers'] = [
    {
      name: 'offset',
      options: {
        offset: [0, 12],
      },
    },
    {
      // Ensure popper stays within viewport bounds
      name: 'preventOverflow',
      options: {
        boundary: 'viewport',
        padding: 8, // Minimum distance from viewport edges
      },
    },
    {
      // Enable smooth transitions and performance optimizations
      // using hardware acceleration and adaptive positioning.
      name: 'computeStyles',
      options: {
        gpuAcceleration: true,
        adaptive: true,
      },
    },
    {
      // Custom modifier to handle sidebar-aware positioning
      name: 'sidebarAware',
      enabled: true,
      phase: 'main', // Run during the main positioning phase
      requires: ['computeStyles'],
      fn: ({ state }) => {
        // Calculate the left position based on sidebar state:
        // - When sidebar is visible and collapsed: SIDEBAR_COLLAPSED_WIDTH + TRIGGER_POSITION
        // - When sidebar is visible and expanded: SIDEBAR_EXPANDED_WIDTH + TRIGGER_POSITION
        // - When sidebar is hidden: TRIGGER_POSITION
        const triggerLeft = sidebarVisible
          ? sidebarCollapsed
            ? SIDEBAR_COLLAPSED_WIDTH + TRIGGER_POSITION
            : SIDEBAR_EXPANDED_WIDTH + TRIGGER_POSITION
          : TRIGGER_POSITION;

        // Update popper's x-coordinate to align with trigger
        // This ensures the left edge of the popper aligns with the trigger button
        if (state.modifiersData.popperOffsets) {
          state.modifiersData.popperOffsets.x = triggerLeft;
        }

        return state;
      },
    },
  ];

  // Only show expand/collapse button when opened from global trigger
  const showExpandCollapseButton = openSource === OpenSource.GLOBAL_TRIGGER;

  return (
    <>
      {/* Fixed-position trigger button that follows sidebar state */}
      <GlobalHelpTrigger
        ref={triggerRef}
        onExpand={handleToggleOpen}
        sidebarCollapsed={sidebarCollapsed}
        sidebarVisible={sidebarVisible}
      />

      {/* Render either the Popper or the Modal, but not both */}
      {Boolean(anchorEl) && !isModalOpen ? (
        <Popper
          ref={popperRef}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          transition
          placement="top-start"
          modifiers={popperModifiers}
          sx={{
            zIndex: 1400,
            transition: shouldAnimateTransform
              ? 'transform 0.2s ease-out'
              : undefined,
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={300}>
              <Paper
                elevation={6}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                sx={mixinSx(sharedPaperSx, {
                  width: '512px',
                  height: '512px',
                  position: 'relative',
                  borderRadius: '12px',

                  willChange: 'transform',
                })}
                data-testid={TestIds.GlobalHelpPopper}
              >
                {/* Main chat interface */}
                <HelpChat {...getHelpChatProps()} />

                {/* Button to expand popper into modal */}
                <ExpandCollapseButton
                  isExpanded={false}
                  isHovering={isHovering}
                  onClick={handleExpandToModal}
                  showButton={showExpandCollapseButton}
                />

                {/* Extended hover area for better UX */}
                <SafeZone
                  isHovering={isHovering}
                  setIsHovering={setIsHovering}
                />
              </Paper>
            </Fade>
          )}
        </Popper>
      ) : isModalOpen ? (
        <Modal
          open={isModalOpen}
          onClose={handleCollapsedChange}
          closeAfterTransition
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: (theme) => `${theme.palette.grey50}20`,
          }}
        >
          <Fade in={isModalOpen} timeout={300}>
            <Paper
              elevation={24}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              sx={mixinSx(sharedPaperSx, {
                width: '90%',
                maxWidth: '800px',
                height: '80vh',
                maxHeight: '800px',
                position: 'relative',
                borderRadius: '12px',
              })}
              data-testid={TestIds.GlobalHelpModal}
            >
              <HelpChat {...getHelpChatProps()} />

              {openSource === OpenSource.GLOBAL_TRIGGER && (
                <ExpandCollapseButton
                  isExpanded={true}
                  isHovering={isHovering}
                  onClick={() => {
                    setIsModalOpen(false);
                    setAnchorEl(triggerRef.current);
                  }}
                  showButton={showExpandCollapseButton}
                />
              )}

              <SafeZone isHovering={isHovering} setIsHovering={setIsHovering} />
            </Paper>
          </Fade>
        </Modal>
      ) : null}
    </>
  );
}
