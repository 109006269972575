import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { mixinSx } from '@watershed/style/styleUtils';
import { HelpHeader } from './Header';
import { HelpTrigger } from '../inModal/Trigger';
import { useUserContext } from '@watershed/app-dashboard/utils/UserContext';
import { HelpChatProps, Message } from '../utils/types';
import { useQuestionCache } from '../utils/useQuestionCache';

// Import components from the components folder
import { QuestionInput } from './components/QuestionInput';
import { ThinkingTime } from './components/ThinkingTime';
import { InternalUseBanner } from './components/InternalUseBanner';
import { PrecannedMessageSuggestions } from './components/PrecannedMessageSuggestions';
import { MessageBubble } from './components/MessageBubble';

// Styles
type ChatStyleKeys =
  | 'helpChat'
  | 'chatContent'
  | 'timelineIndicator'
  | 'precannedMessagesContainer';

type ChatStyleFn = {
  [K in ChatStyleKeys]: SxProps<Theme>;
} & {
  content: (visible: boolean) => SxProps<Theme>;
};

const STYLES: ChatStyleFn = {
  helpChat: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    borderRadius: '10px',
    border: (theme) => `0.5px solid ${theme.palette.grey30}`,
    overflow: 'hidden',
  },

  content: (visible: boolean): SxProps<Theme> => ({
    height: '100%',
    opacity: visible ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',
  }),

  chatContent: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: 2,
  },

  timelineIndicator: {
    height: '16px',
    ml: 2.5,
    width: '1px',
    borderRadius: '50%',
    backgroundColor: 'grey30',
  },

  precannedMessagesContainer: {
    maxWidth: '80%',
    margin: '0 auto',
    mb: 1.5,
    position: 'relative',
    zIndex: 1,
  },
};

export default function HelpChat({
  title,
  isCollapsed = true,
  onCollapsedChange,
  sx,
  activeMessages,
  isTyping,
  onQuestionSubmit,
  precannedMessages,
  onResetChat,
}: HelpChatProps) {
  const { userName } = useUserContext();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [contentVisible, setContentVisible] = useState(false);
  const { getCachedResponse } = useQuestionCache();
  const retryCount = useRef<number>(0);
  const MAX_RETRIES = 2;

  // Memoized values and handlers
  const contentStyle = useMemo(
    () => STYLES.content(contentVisible),
    [contentVisible]
  );

  const hasStreamingMessage = useMemo(
    () => activeMessages.some((message) => !message.complete),
    [activeMessages]
  );

  const showPrecannedMessages = useMemo(
    () =>
      activeMessages.length === 0 &&
      !isTyping &&
      precannedMessages &&
      precannedMessages.length > 0,
    [activeMessages.length, isTyping, precannedMessages]
  );

  const showResetButton = useMemo(
    () => !!onResetChat && activeMessages.length > 0,
    [onResetChat, activeMessages.length]
  );

  const shouldShowTimelineIndicator = useCallback(
    (index: number) => {
      return index > 0 && activeMessages[index].isUser;
    },
    [activeMessages]
  );

  const shouldShowThinkingTime = useCallback(
    (index: number) => {
      if (index >= activeMessages.length - 1) return false;

      const currentMessage = activeMessages[index];
      const nextMessage = activeMessages[index + 1];

      return (
        currentMessage.isUser &&
        !nextMessage.isUser &&
        typeof currentMessage.timestamp === 'number' &&
        typeof nextMessage.timestamp === 'number'
      );
    },
    [activeMessages]
  );

  const renderMessageItem = useCallback(
    (message: Message, index: number) => {
      return (
        <React.Fragment key={`message-group-${index}`}>
          {shouldShowTimelineIndicator(index) && (
            <Box
              component="span"
              sx={STYLES.timelineIndicator}
              key={`timeline-indicator-${index}`}
            />
          )}
          <MessageBubble
            key={`message-${index}`}
            message={message}
            userName={userName}
          />
          {shouldShowThinkingTime(index) && (
            <ThinkingTime
              key={`thinking-${index}`}
              startTime={activeMessages[index].timestamp as number}
              endTime={activeMessages[index + 1].timestamp as number}
            />
          )}
        </React.Fragment>
      );
    },
    [
      shouldShowTimelineIndicator,
      shouldShowThinkingTime,
      userName,
      activeMessages,
    ]
  );

  // Effects
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [activeMessages, isTyping]);

  useEffect(() => {
    if (!isCollapsed) {
      const timer = setTimeout(() => setContentVisible(true), 150);
      return () => clearTimeout(timer);
    } else {
      setContentVisible(false);
    }
  }, [isCollapsed]);

  const handleQuestionSubmit = useCallback(
    async (question: string) => {
      try {
        const cachedResponse = getCachedResponse(question);
        if (cachedResponse) {
          await onQuestionSubmit(cachedResponse);
          return;
        }

        await onQuestionSubmit(question);
      } catch (err) {
        if (
          err instanceof Error &&
          err.message.toLowerCase().includes('network') &&
          retryCount.current < MAX_RETRIES
        ) {
          retryCount.current += 1;
          setTimeout(async () => {
            try {
              await handleQuestionSubmit(question);
            } catch (err) {
              console.error('Retry attempt failed:', err);
            }
          }, 1000);
          return;
        }

        retryCount.current = 0;
        throw err;
      }
    },
    [getCachedResponse, onQuestionSubmit]
  );

  useEffect(() => {
    if (activeMessages.length === 0) {
      retryCount.current = 0;
    }
  }, [activeMessages.length]);

  if (isCollapsed) {
    return <HelpTrigger onExpand={() => onCollapsedChange?.(false)} />;
  }

  return (
    <Box sx={mixinSx(STYLES.helpChat, sx)}>
      <Stack sx={contentStyle}>
        <HelpHeader
          title={title}
          isCollapsed={isCollapsed}
          onCollapsedChange={onCollapsedChange ?? (() => {})}
          onResetChat={onResetChat}
          showResetButton={showResetButton}
        />
        <InternalUseBanner scrollContainerRef={scrollRef} />
        <Box sx={STYLES.chatContent} ref={scrollRef}>
          <Stack alignItems="flex-start">
            {activeMessages.map(renderMessageItem)}
            {isTyping && !hasStreamingMessage && (
              <ThinkingTime startTime={Date.now()} />
            )}
          </Stack>
        </Box>

        {showPrecannedMessages && (
          <Box sx={STYLES.precannedMessagesContainer}>
            <PrecannedMessageSuggestions
              messages={precannedMessages}
              onSelect={onQuestionSubmit}
            />
          </Box>
        )}

        <QuestionInput
          onSubmit={handleQuestionSubmit}
          isSubmitting={isTyping}
        />
      </Stack>
    </Box>
  );
}
