import Disclosure from './Disclosure';
import {
  GQCompanyClimateCommitment,
  GQCompanyClimateProgress,
  GQCompanySbtCommitmentStage,
  GQDisclosureQualityScore,
  GQExternalReportType,
  GQSbtCommitmentStage,
} from '@watershed/shared-universal/generated/graphql-schema-types';
import { DateTime } from 'luxon';
import { getSbtCommitmentStageFromCommitments } from './companyClimateCommitmentsUtils';
import getClimateProgressFromDisclosures from './getClimateProgressFromDisclosures';
import {
  COMMITMENT_KINDS,
  getCompanyCommitmentByKind,
} from '../utils/companyUtils';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import orderBy from 'lodash/orderBy';
import isNullish from '@watershed/shared-util/isNullish';
import {
  isCarbonNeutralCommitment,
  isCleanEnergyCommitment,
  isNetZeroCommitment,
  isScienceBasedTargetCommitment,
} from '../utils/climateCommitmentUtils';
import assertNever from '@watershed/shared-util/assertNever';
import {
  CompanyFieldsTemp,
  EngagementTaskFieldsTemp,
  PublicDisclosureFieldsTemp,
} from '../finance/types/FinanceFragments';
import mapStrToEnum from '../utils/mapStrToEnum';
import { t } from '@lingui/core/macro';

export type ClimateEngagementBulletPointProps = {
  key: string;
  title: string;
  date?: DateTime;
  body: string | null;
  externalUrl: string | null;
  qualityScore?: GQDisclosureQualityScore;
  internalUrl: string | null;
};

export default class Company {
  public companyFragment: CompanyFieldsTemp;
  public disclosures: Array<Disclosure>;
  public tasks: Array<EngagementTaskFieldsTemp>;

  public constructor({
    company,
    disclosures,
    tasks,
  }: {
    company: CompanyFieldsTemp;
    disclosures: Array<Disclosure>;
    tasks: Array<EngagementTaskFieldsTemp>;
  }) {
    this.companyFragment = company;
    this.disclosures = disclosures;
    this.tasks = tasks;
  }

  get id(): string {
    return this.companyFragment.id;
  }

  get climateProgress(): GQCompanyClimateProgress {
    return getClimateProgressFromDisclosures({ disclosures: this.disclosures });
  }

  get sbtiStage(): GQCompanySbtCommitmentStage {
    const commitments = this.disclosures.flatMap(
      (disclosure) => disclosure.climateCommitments ?? []
    );
    return getSbtCommitmentStageFromCommitments(commitments);
  }

  get publicDisclosures(): Array<Disclosure> {
    return this.disclosures.filter(
      (disclosure) => !!disclosure.publicDisclosure
    );
  }

  get commitmentItems(): Array<ClimateEngagementBulletPointProps> {
    const commitments =
      this.publicDisclosures.flatMap(
        (disclosure) => disclosure.climateCommitments ?? []
      ) ?? [];
    const disclosurePublicUrlByCommitmentId = Object.fromEntries(
      this.publicDisclosures.flatMap(
        (disclosure) =>
          disclosure.climateCommitments?.map((commitment) => [
            commitment.id,
            disclosure.publicDisclosure?.publicUrl,
          ]) ?? []
      ) ?? []
    );

    // Show at most one commitment for each commitment kind.
    const commitmentItemsRaw = COMMITMENT_KINDS.map((kind) => {
      const commitment = getCompanyCommitmentByKind(kind, commitments);
      return commitment
        ? Company.commitmentToBulletPoint(
            commitment,
            disclosurePublicUrlByCommitmentId[commitment.id] ?? null
          )
        : null;
    }).filter(isNotNullish);

    const commitmentItems = orderBy(
      commitmentItemsRaw,
      (item: ClimateEngagementBulletPointProps) => item.date ?? 0,
      'desc'
    );
    return commitmentItems;
  }

  static getStartDateFromCommitment(
    commitment: GQCompanyClimateCommitment
  ): DateTime | undefined {
    if (commitment.commitmentPeriodStart) {
      return DateTime.fromJSDate(commitment.commitmentPeriodStart);
    }
    if (commitment.commitmentMadeDate) {
      return DateTime.fromJSDate(commitment.commitmentMadeDate);
    }
    return undefined;
  }

  static commitmentToBulletPoint(
    commitment: GQCompanyClimateCommitment,
    disclosurePublicUrl: string | null
  ): ClimateEngagementBulletPointProps | null {
    const makeCommitmentItem = (
      title: string,
      body: string | null = null,
      externalUrl: string | null = null
    ) => ({
      key: commitment.id,
      title,
      // Prefer to show the period start date if available (the commitment may
      // have been made publicly available after the actual period start)
      date: this.getStartDateFromCommitment(commitment),
      body,
      description: commitment.description,
      externalUrl,
      internalUrl: null,
    });

    const commitmentTitle = Company.getCommitmentTitle(commitment);
    if (isNullish(commitmentTitle)) {
      return null;
    }

    return makeCommitmentItem(
      commitmentTitle,
      commitment.description,
      commitment.externalUrl ?? disclosurePublicUrl
    );
  }

  static sustainabilityWebsiteToBulletPoint(
    urlString: string | null | undefined
  ): ClimateEngagementBulletPointProps | null {
    if (!urlString) {
      return null;
    }

    return {
      key: 'sutainability-website',
      title: 'Sustainability website',
      body: `Webpage at ${new URL(urlString).host}`,
      externalUrl: urlString,
      internalUrl: null,
    };
  }

  static getCommitmentTitle(commitment: GQCompanyClimateCommitment): string {
    if (isNetZeroCommitment(commitment)) {
      return commitment.targetYear
        ? `Committed to net zero by ${commitment.targetYear}`
        : 'Committed to net zero';
    }

    if (isCarbonNeutralCommitment(commitment)) {
      return commitment.targetYear
        ? `Committed to carbon neutral by ${commitment.targetYear}`
        : 'Committed to carbon neutral';
    }

    if (isScienceBasedTargetCommitment(commitment)) {
      const netZeroCommittedStr = commitment.commitment?.netZeroCommitted
        ? ', committed to net zero'
        : '';
      if (commitment.commitment?.stage === GQSbtCommitmentStage.Committed) {
        return `Committed to setting science-based targets${netZeroCommittedStr}`;
      } else if (
        commitment.commitment?.stage === GQSbtCommitmentStage.TargetsSet
      ) {
        if (commitment.submittedToSBTi) {
          return `Science-based targets validated by SBTi${netZeroCommittedStr}`;
        } else {
          return `Science-aligned targets set${netZeroCommittedStr}`;
        }
      } else if (
        commitment.commitment?.stage === GQSbtCommitmentStage.Removed
      ) {
        return 'SBTi commitment removed';
      } else {
        return 'SBTi commitment';
      }
    }

    if (isCleanEnergyCommitment(commitment)) {
      const year = commitment.targetYear;
      const percentage = commitment.targetPercentageCleanEnergy;
      if (isNotNullish(year) && isNotNullish(percentage)) {
        return `Committed to ${percentage}% renewable energy by ${year}`;
      } else if (isNotNullish(percentage)) {
        return `Committed to ${percentage}% renewable energy`;
      } else if (isNotNullish(year)) {
        return `Committed to renewable energy by ${year}`;
      }
    }

    return 'Commitment';
  }

  static getPublicDisclosureTitle(
    disclosure: Pick<PublicDisclosureFieldsTemp, 'reportType'>
  ): string {
    const reportType = mapStrToEnum(
      disclosure.reportType,
      GQExternalReportType
    );
    switch (reportType) {
      case GQExternalReportType.Carb:
        return t({ message: 'CARB', context: 'Public disclosure title' });
      case GQExternalReportType.Cdp:
        return t({
          message: 'CDP Climate Change',
          context: 'Public disclosure title',
        });
      case GQExternalReportType.Sbt:
        return t({ message: 'SBT', context: 'Public disclosure title' });
      case GQExternalReportType.BCorp:
        return t({ message: 'BCorp', context: 'Public disclosure title' });
      case GQExternalReportType.Csrd:
        return t({ message: 'CSRD', context: 'Public disclosure title' });
      case GQExternalReportType.Djsi:
        return t({ message: 'DJSI', context: 'Public disclosure title' });
      case GQExternalReportType.Esg:
        return t({ message: 'ESG', context: 'Public disclosure title' });
      case GQExternalReportType.Impact:
        return t({ message: 'Impact', context: 'Public disclosure title' });
      case GQExternalReportType.Marketing:
        return t({
          message: 'Marketing',
          context: 'Public disclosure title',
        });
      case GQExternalReportType.Msci:
        return t({ message: 'MSCI', context: 'Public disclosure title' });
      case GQExternalReportType.Sasb:
        return t({ message: 'SASB', context: 'Public disclosure title' });
      case GQExternalReportType.Sec:
        return t({ message: 'SEC', context: 'Public disclosure title' });
      case GQExternalReportType.Secr:
        return t({ message: 'SECR', context: 'Public disclosure title' });
      case GQExternalReportType.Sustainalytics:
        return t({
          message: 'Sustainalytics',
          context: 'Public disclosure title',
        });
      case GQExternalReportType.Tcfd:
        return t({ message: 'TCFD', context: 'Public disclosure title' });
      case GQExternalReportType.UkTender:
        return t({
          message: 'UK Tender Document',
          context: 'Public disclosure title',
        });
      case GQExternalReportType.Edci:
        return t({ message: 'EDCI', context: 'Public disclosure title' });
      case GQExternalReportType.Sfdr:
        return t({ message: 'SFDR', context: 'Public disclosure title' });
      case GQExternalReportType.Sb253:
        return t({ message: 'SB 253', context: 'Public disclosure title' });
      case GQExternalReportType.Unfccc:
        return t({ message: 'UNFCCC', context: 'Public disclosure title' });
      case GQExternalReportType.Edgar:
        return t({ message: 'EDGAR', context: 'Public disclosure title' });
      case GQExternalReportType.Issb:
        return t({ message: 'ISSB', context: 'Public disclosure title' });
      case GQExternalReportType.Other:
        return t({ message: 'Other', context: 'Public disclosure title' });

      default:
        assertNever(reportType);
    }
  }
}
