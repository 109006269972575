import { useAtom } from 'jotai';
import { selectionAtom } from './atoms';
import { CommandPaletteSelection, CommandPaletteSelectionItems } from './types';
import { useCallback, useMemo } from 'react';

/**
 * Hook to manage the command palette selection of a specific type. e.g
 * useCommandPaletteSelectionOfType('ReportQuestionInstance') will return the
 * selection items if and only if the selection type is 'ReportQuestionInstance'
 *
 * Likewise, the returned setter function will only allow setting a selection
 * of the specified type.
 *
 * @param type
 * @returns [selection, setSelection]
 */
export function useCommandPaletteSelectionOfType<
  T extends keyof CommandPaletteSelectionTypes,
>(type: T) {
  const [selection, setSelection] = useAtom(selectionAtom);

  const setSelectionOfType = useCallback(
    (selection: CommandPaletteSelectionItems<T>) => {
      setSelection({
        type,
        items: selection,
      } as CommandPaletteSelection<T>);
    },
    [type, setSelection]
  );

  return useMemo(
    () =>
      [
        selection && selection.type === type
          ? (selection.items as CommandPaletteSelectionItems<T>)
          : null,
        setSelectionOfType,
      ] as const,
    [selection, setSelectionOfType, type]
  );
}
