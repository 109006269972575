import { Cache } from '@urql/exchange-graphcache';
import { GQCreateWatershedFootprintReviewRequestPayload } from '@watershed/app-dashboard/generated/graphql-schema-types';
import {
  GQFootprintsForFootprintSelectorQuery,
  GQGetFootprintMetadataForSnapshotQuery,
  type GQFootprintDetailQuery,
} from '@watershed/app-dashboard/generated/graphql-operations';

import {
  FootprintDetailDocument,
  FootprintsForFootprintSelectorDocument,
  GetFootprintMetadataForSnapshotDocument,
} from '@watershed/app-dashboard/generated/urql';

/**
 * Cache update utilities for when a new footprint review request is created.
 * Updates three different UI locations that display footprint review status:
 * - Footprint selector dropdown
 * - Footprint metadata view
 * - Review page
 *
 * Each merge function handles a different part of the cache:
 * - Selector
 * - Metadata
 * - Review
 */

/**
 * Updates footprint selector cache, handling nested edges/nodes structure
 * to find and update the specific footprint snapshot
 */
export function mergeFootprintReviewRequestIntoFootprintSelector(
  data: GQFootprintsForFootprintSelectorQuery | null,
  payload: GQCreateWatershedFootprintReviewRequestPayload
): GQFootprintsForFootprintSelectorQuery | null {
  if (data === null) {
    return null;
  }

  const updatedFootprints = data.footprints.edges.map((edge) => {
    if (!edge?.node) {
      return null;
    }

    const updatedSnapshots = edge.node.footprintSnapshots.map((snapshot) => {
      if (snapshot.id === payload.footprintSnapshot.id) {
        return {
          ...snapshot,
          ...payload.footprintSnapshot,
        };
      }
      return snapshot;
    });

    return {
      ...edge,
      node: {
        ...edge.node,
        footprintSnapshots: updatedSnapshots,
      },
    };
  });

  return {
    ...data,
    footprints: {
      ...data.footprints,
      edges: updatedFootprints,
    },
  };
}

/**
 * Updates footprint metadata cache with new review request info
 */
export function mergeFootprintReviewRequestIntoFootprintMetadata(
  data: GQGetFootprintMetadataForSnapshotQuery | null,
  payload: GQCreateWatershedFootprintReviewRequestPayload
): GQGetFootprintMetadataForSnapshotQuery | null {
  if (data === null || !data.footprintAnalysis) {
    return data;
  }

  return {
    ...data,
    footprintAnalysis: {
      ...data.footprintAnalysis,
      footprintSnapshot: data.footprintAnalysis.footprintSnapshot
        ? {
            ...data.footprintAnalysis.footprintSnapshot,
            ...payload.footprintSnapshot,
          }
        : null,
    },
  };
}

export function mergeFootprintReviewRequestIntoFootprintDetailData(
  data: GQFootprintDetailQuery | null,
  payload: GQCreateWatershedFootprintReviewRequestPayload
): GQFootprintDetailQuery | null {
  if (data === null || !data.footprintSnapshotInDashboard) {
    return data;
  }

  return {
    ...data,
    footprintSnapshotInDashboard: {
      ...data.footprintSnapshotInDashboard,
      ...payload.footprintSnapshot,
    },
  };
}

/**
 * Updates all relevant caches when a new footprint review request is created
 */
export function updateCacheAfterCreateWatershedFootprintReviewRequest(
  cache: Cache,
  payload: GQCreateWatershedFootprintReviewRequestPayload
) {
  cache.updateQuery(
    {
      query: FootprintsForFootprintSelectorDocument,
      variables: {},
    },
    (data: GQFootprintsForFootprintSelectorQuery | null) =>
      mergeFootprintReviewRequestIntoFootprintSelector(data, payload)
  );

  cache.updateQuery(
    {
      query: GetFootprintMetadataForSnapshotDocument,
      variables: {
        footprintSnapshotId: payload.footprintSnapshot.id,
      },
    },
    (data: GQGetFootprintMetadataForSnapshotQuery | null) =>
      mergeFootprintReviewRequestIntoFootprintMetadata(data, payload)
  );

  cache.updateQuery(
    {
      query: FootprintDetailDocument,
      variables: {
        footprintSnapshotId: payload.footprintSnapshot.id,
      },
    },
    (data: GQFootprintDetailQuery | null) =>
      mergeFootprintReviewRequestIntoFootprintDetailData(data, payload)
  );
}
