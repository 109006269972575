import { ReactNode } from 'react';
import { Theme } from '@mui/material';
import { Trans } from '@lingui/react/macro';

import GroupsIcon from '@watershed/icons/components/Groups';
import HelpIcon from '@watershed/icons/components/Help';
import CityIcon from '@watershed/icons/components/City';
import {
  routeForApiSettings,
  routeForIntegrationsSettings,
  routeForOrganizationPermissions,
  routeForOrganizationRoles,
  routeForOrganizationUsers,
  routeForWorkspaceSettings,
  routeForFinanceSettings,
  routeForAccessSettings,
  routeForLearningHub,
  routeForSupport,
  routeForPublicApiDocs,
} from '@watershed/shared-universal/dashboardRoutes';

export type SettingsNavigationItemType = {
  id: string;
  label: ReactNode;
  href: string;
  newTab?: boolean;
};

export type SettingsNavigationGroupType = {
  label: string;
  icon: ReactNode;
  items: Array<SettingsNavigationItemType>;
};

/**
 * @TODO Remove this when we remove the feature flag.
 */
export function appendV2ToRoute(route: string): string {
  // Split the path at the first segment after /settings/
  const parts = route.split('/');
  if (parts[1] === 'settings') {
    // Insert 'v2' after 'settings'
    return `/settings/v2/${parts.slice(2).join('/')}`;
  }

  return route;
}

// Helper function to create icon with consistent styling
const createIcon = (Icon: typeof GroupsIcon) => (
  <Icon size={14} color={(theme: Theme) => theme.palette.grey50} />
);

/**
 * Factory function to create settings navigation groups
 * This allows for better reusability and customization
 */
export function createSettingsNavigationGroups({
  canAccessFinance = false,
}: {
  canAccessFinance?: boolean;
}): Array<SettingsNavigationGroupType> {
  return [
    {
      label: 'User management',
      icon: createIcon(GroupsIcon),
      items: [
        {
          id: 'users',
          label: (
            <Trans context="Settings Navigation user management users">
              Users
            </Trans>
          ),
          href: appendV2ToRoute(routeForOrganizationUsers()),
        },
        {
          id: 'roles',
          label: (
            <Trans context="Settings Navigation user management roles">
              Roles
            </Trans>
          ),
          href: appendV2ToRoute(routeForOrganizationRoles()),
        },
        {
          id: 'permissions',
          label: (
            <Trans context="Settings Navigation user management permissions">
              Permissions
            </Trans>
          ),
          href: appendV2ToRoute(routeForOrganizationPermissions()),
        },
      ],
    },
    {
      label: 'Workspace',
      icon: createIcon(CityIcon),
      items: [
        {
          id: 'preferences',
          label: (
            <Trans context="Settings Navigation workspace preferences">
              Preferences
            </Trans>
          ),
          href: appendV2ToRoute(routeForWorkspaceSettings()),
        },
        ...(canAccessFinance
          ? [
              {
                id: 'finance',
                label: (
                  <Trans context="Settings Navigation finance">Finance</Trans>
                ),
                href: appendV2ToRoute(routeForFinanceSettings()),
              },
            ]
          : []),
        {
          id: 'access',
          label: (
            <Trans context="Settings Navigation access and security">
              Access and security
            </Trans>
          ),
          href: appendV2ToRoute(routeForAccessSettings()),
        },
        {
          id: 'integrations',
          label: (
            <Trans context="Settings Navigation integrations">
              Integrations
            </Trans>
          ),
          href: appendV2ToRoute(routeForIntegrationsSettings()),
        },
        {
          id: 'api',
          label: <Trans context="Settings Navigation api">API</Trans>,
          href: appendV2ToRoute(routeForApiSettings()),
        },
      ],
    },
    {
      label: 'Help',
      icon: createIcon(HelpIcon),
      items: [
        {
          id: 'learning-hub',
          label: (
            <Trans context="Settings Navigation learning hub">
              Learning hub
            </Trans>
          ),
          href: routeForLearningHub({ from: 'Sidebar' }),
        },
        {
          id: 'support',
          label: <Trans context="Settings Navigation support">Support</Trans>,
          href: routeForSupport(),
        },
        {
          id: 'api-docs',
          label: <Trans context="Settings Navigation api docs">API docs</Trans>,
          href: routeForPublicApiDocs(),
          newTab: true,
        },
        // TODO: Add Canny
        // {
        //   label: 'Product roadmap',
        //   href: '/product-roadmap',
        //   newTab: true,
        // },
      ],
    },
  ];
}
