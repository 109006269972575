import { forwardRef } from 'react';
import { Box } from '@mui/material';
import Button from '@watershed/ui-core/components/Button';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import { Flags } from '@watershed/constants/flags';
import { useFeatureFlag } from '@watershed/app-dashboard/utils/FeatureFlag';
import Avatar from '@watershed/ui-core/components/Avatar';
import {
  sharedShadow,
  sharedFocusShadow,
  pulseAnimation,
} from '../utils/styles';
import {
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
} from '@watershed/shared-universal/utils/constants';

export const TRIGGER_POSITION = 16;

interface GlobalHelpTriggerProps {
  onExpand: (event: React.MouseEvent<HTMLElement>) => void;
  isCollapsed?: boolean;
  sidebarCollapsed?: boolean;
  sidebarVisible?: boolean;
}

export const GlobalHelpTrigger = forwardRef<
  HTMLDivElement,
  GlobalHelpTriggerProps
>(function GlobalHelpTrigger(
  { onExpand, sidebarCollapsed, sidebarVisible },
  ref
) {
  const isEnabled = useFeatureFlag(Flags.DustBotInProductPrototype);

  if (!isEnabled) {
    return null;
  }

  return (
    <Box
      ref={ref}
      sx={{
        position: 'fixed',
        bottom: 16,
        left: () => {
          if (!sidebarVisible) {
            return TRIGGER_POSITION; // When sidebar is hidden
          }

          if (sidebarCollapsed) {
            return SIDEBAR_COLLAPSED_WIDTH + TRIGGER_POSITION; // When sidebar is visually collapsed
          }

          return SIDEBAR_EXPANDED_WIDTH + TRIGGER_POSITION; // When sidebar is expanded
        },
        transition: 'left 0.2s ease-out', // Add transition for smooth movement
        zIndex: 1300,
      }}
    >
      <Button
        data-testid={TestIds.GlobalHelpTrigger}
        onClick={onExpand}
        variant="contained"
        sx={{
          gap: 1,
          height: 'fit-content',
          minWidth: 'fit-content',
          p: 1,
          borderRadius: '100px',
          border: (theme) => `0.5px solid ${theme.palette.grey40}`,
          boxShadow: sharedShadow,
          animation: `${pulseAnimation} 2s infinite`, // Add the pulse animation
          '&:hover': {
            backgroundColor: (theme) => theme.palette.grey05,
            boxShadow: sharedShadow,
            animation: `${pulseAnimation} 2s infinite`, // Add the pulse animation
          },
          '&.active, &:focus': {
            backgroundColor: (theme) => theme.palette.white,
            boxShadow: sharedFocusShadow,
            animation: 'none', // Stop the animation when active/focused
          },
        }}
      >
        <Avatar size="small" variant="entity" name="Watershed" />
      </Button>
    </Box>
  );
});
