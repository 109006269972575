import { Stack, Box, SxProps, Theme, Typography } from '@mui/material';
import Button from '@watershed/ui-core/components/Button';
import Tooltip from '@mui/material/Tooltip';
import { Trans, useLingui } from '@lingui/react/macro';
import {
  tooltipDarkStyleProps,
  CODE_FONT_FAMILY,
} from '@watershed/style/styleUtils';
import CloseIcon from '@watershed/icons/components/Close';
import BrowserRefreshIcon from '@watershed/icons/components/BrowserRefresh';

export interface HelpHeaderProps {
  title?: string;
  internalOnly?: boolean;
  isCollapsed: boolean;
  onCollapsedChange: (collapsed: boolean) => void;
  onResetChat?: () => void;
  showResetButton?: boolean;
  sx?: SxProps<Theme>;
}

const headerSx: SxProps<Theme> = {
  zIndex: 1000,
  paddingY: 1.25,
  pl: 2,
  pr: 1.5,
  backgroundColor: (theme) => theme.palette.white,
  boxShadow: (theme) => `inset 0 -1px 0px 0px ${theme.palette.grey20}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTopRightRadius: '10px',
  borderTopLeftRadius: '10px',
  gap: 1,
};

const STYLES: Record<string, SxProps<Theme>> = {
  header: headerSx,

  title: {
    color: 'grey70',
    flexGrow: 1,
    gap: 1,
    display: 'flex',
    alignItems: 'center',
  },

  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },

  actionButton: {
    color: 'grey50',
    '&:hover': {
      color: 'grey70',
    },
  },
};

const AiIconWrapper = () => (
  <Box
    display="flex"
    alignItems="center"
    gap={0.5}
    sx={{
      p: 0.5,
      borderRadius: '1px',
      backgroundColor: (theme) => `${theme.palette.cobalt}05`,
      border: (theme) => `0.5px dashed ${theme.palette.cobalt100}`,
      outline: (theme) => `0.5px dashed ${theme.palette.cobalt40}`,
      outlineOffset: '1px',
      boxShadow: (theme) => `inset 0 0 6px 1px ${theme.palette.cobalt}30`,
      userSelect: 'none',
    }}
  >
    <Typography
      variant="caption"
      lineHeight={1}
      fontSize={12}
      fontFamily={CODE_FONT_FAMILY}
      color="cobalt"
      sx={{ userSelect: 'none' }}
    >
      <Trans comment="Label for AI icon">AI</Trans>
    </Typography>
  </Box>
);

export function HelpHeader({
  title,
  onCollapsedChange,
  onResetChat,
  showResetButton = false,
}: HelpHeaderProps) {
  const { t } = useLingui();

  const handleReset = () => {
    if (onResetChat) {
      onResetChat();
    }
  };

  return (
    <Box sx={STYLES.header}>
      <Stack direction="row" gap={1} alignItems="center">
        <AiIconWrapper />
        <Typography variant="h4" sx={STYLES.title}>
          {title}
        </Typography>
      </Stack>

      <Box sx={STYLES.buttonsContainer}>
        {showResetButton && onResetChat && (
          <Tooltip
            slotProps={tooltipDarkStyleProps()}
            title={t({
              message: 'Reset',
              context: 'Tooltip text to reset the help chat conversation',
            })}
            arrow={false}
            placement="top"
          >
            <Button
              isIcon
              variant="text"
              onClick={handleReset}
              sx={STYLES.actionButton}
            >
              <BrowserRefreshIcon size={14} />
            </Button>
          </Tooltip>
        )}

        <Tooltip
          slotProps={tooltipDarkStyleProps()}
          title={t({
            message: 'Close',
            context: 'Tooltip text to close help panel',
          })}
          arrow={false}
          placement="top"
        >
          <Button
            isIcon
            variant="text"
            onClick={() => onCollapsedChange(true)}
            sx={STYLES.actionButton}
          >
            <CloseIcon size={14} color="grey50" />
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
}
