// Utils for interacting with the suppliers urql cache
import { Cache } from '@urql/exchange-graphcache';
import { GQSupplierFieldsFragment } from '@watershed/app-dashboard/generated/graphql-operations';

import {
  SupplierFieldsFragmentDoc,
  SuppliersDataFieldsFragmentDoc,
} from '@watershed/app-dashboard/generated/urql';
import { Supplier } from '@watershed/shared-universal/suppliers/supplierTypes';
import { parseSupplierRowId } from '@watershed/shared-universal/utils/SuppliersUtils';

export function patchSupplierInCache(
  cache: Cache,
  supplierId: string | undefined,
  patchGQLSupplier: (
    current: GQSupplierFieldsFragment
  ) => GQSupplierFieldsFragment,
  patchSupplierInSuppliersTable: (current: Supplier) => Supplier
) {
  if (!supplierId) {
    // No supplier ID, so we can't update the cache
    return;
  }
  // 1. Update any existing Supplier and SuppliersData fragments in the cache with the specified id
  // Do this explicitly in case the ids in step 2 get messed up
  updateSupplierFragmentById(cache, supplierId, patchGQLSupplier);
  updateSuppliersDataFragment(
    cache,
    { supplierId },
    patchSupplierInSuppliersTable
  );

  const parseResult = parseSupplierRowId(supplierId);
  if (!parseResult || parseResult.type === 'name') {
    return;
  }
  const { companyId } = parseResult;

  // 2. Update any existing Supplier and SuppliersData fragments in the cache with the same supplier prefix
  // I.e. this is the same supplier, but uses a different footprint interval/snapshot/view
  // This is only necessary when a supplier has been mapped (i.e has companyId) because you cannot modify custom columns or priority on non-mapped suppliers
  updateSupplierFragmentByCompanyId(cache, companyId, patchGQLSupplier);
  updateSuppliersDataFragment(
    cache,
    { companyId },
    patchSupplierInSuppliersTable
  );
}

// Patch a single GQL Supplier fragment in response to a mutation
function updateSupplierFragmentById(
  cache: Cache,
  id: string,
  patch: (current: GQSupplierFieldsFragment) => GQSupplierFieldsFragment
) {
  const currentValue = cache.readFragment<GQSupplierFieldsFragment>(
    SupplierFieldsFragmentDoc,
    {
      __typename: 'Supplier',
      id,
    }
  );
  if (currentValue) {
    cache.writeFragment(SupplierFieldsFragmentDoc, patch(currentValue));
  }
}
// Patches GQL Supplier fragments for a given companyId in response to a mutation
function updateSupplierFragmentByCompanyId(
  cache: Cache,
  companyId: string,
  patch: (current: GQSupplierFieldsFragment) => GQSupplierFieldsFragment
) {
  const allFields = cache.inspectFields('Query');
  const suppliersDataFields = allFields.filter(
    (x) => x.fieldName === 'supplier'
  );
  for (const field of suppliersDataFields) {
    const { fieldKey } = field;
    const id = cache.resolve({ __typename: 'Query' }, fieldKey);
    if (typeof id !== 'string') {
      continue;
    }
    const currentValue = cache.readFragment<GQSupplierFieldsFragment>(
      SupplierFieldsFragmentDoc,
      id
    );
    if (currentValue?.company?.id === companyId) {
      cache.writeFragment(SupplierFieldsFragmentDoc, patch(currentValue));
    }
  }
}

// Patch suppliers table data in response to a mutation
export function updateSuppliersDataFragment(
  cache: Cache,
  // Can update by either supplierId or companyId
  { supplierId, companyId }: { supplierId?: string; companyId?: string },
  patch: (current: Supplier) => Supplier
) {
  // Update all SuppliersData fragments that contain this supplier
  const allFields = cache.inspectFields('Query');
  const suppliersDataFields = allFields.filter(
    (x) => x.fieldName === 'suppliersV2'
  );
  for (const field of suppliersDataFields) {
    const { fieldKey } = field;
    const id = cache.resolve({ __typename: 'Query' }, fieldKey);
    if (typeof id !== 'string') {
      continue;
    }
    const currentValue = cache.readFragment<{
      data: { rows: Array<Supplier> };
    }>(SuppliersDataFieldsFragmentDoc, id);
    if (!currentValue) {
      continue;
    }
    cache.writeFragment(SuppliersDataFieldsFragmentDoc, {
      ...currentValue,
      data: {
        ...currentValue.data,
        rows: currentValue.data.rows.map((row) => {
          if (
            (supplierId && row.id === supplierId) ||
            (companyId && row.companyId === companyId)
          ) {
            return patch(row);
          }
          return row;
        }),
      },
    });
  }
}
