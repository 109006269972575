import {
  useGetOrgEngagementTasksForOverviewQuery,
  useGetCompanyAndOrgEngagementTasksForDetailsQuery,
  useGetCompanyAndOrgEngagementTasksForOverviewQuery,
  useGetEngagementTasksAssignedTypesQuery,
  useGetEngagementTasksPageDataQuery,
} from '@watershed/app-dashboard/generated/urql';
import { PermissionType } from '@watershed/constants/permissions';

import {
  GQCompanyEngagementTaskFieldsForOverviewFragment,
  GQEngagementTaskContentsFragment,
  GQEngagementTaskFieldsFragment,
  GQEngagementTaskTypeAndStatusFieldsFragment,
} from '@watershed/app-dashboard/generated/graphql-operations';

import {
  WsError,
  getGqlResultDataBang,
} from '@watershed/shared-frontend/utils/errorUtils';
import { ForbiddenError } from '@watershed/errors/ForbiddenError';

import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';
import isFetchingOrStale from '@watershed/shared-frontend/utils/isFetchingOrStale';
import gql from 'graphql-tag';
import { useCallback, useMemo } from 'react';
import { CombinedError } from 'urql';
import { useUserContext } from '../../../../utils/UserContext';
import useHasPermission from '../../../../utils/useHasPermission';

gql`
  fragment CompanyEngagementTaskFieldsForOverview on EngagementTask {
    id
    createdAt
    approvedAt
    submittedAt
    companyId
    dueDate
    rootAssignee
    rootAssigneeDisplayName
    company {
      id
      name
    }
    rootCompanyId
    status
    statusChangedAt
    engagementTaskConfigId
    engagementTaskConfig {
      id
      name
      category
      rootFacingDescription
      baseConfig {
        id
      }
      archivedAt
    }
    engagementTaskBatchId
    initialSupplierAssigneeEmail
  }

  fragment EngagementTaskTypeAndStatusFields on EngagementTask {
    id
    submittedAt
    engagementTaskConfig {
      type
    }
  }

  query GetOrgEngagementTasksForOverview(
    $rootOnly: Boolean
    $ignoreLearningTasks: Boolean
  ) @withOwner(owner: SupplyChain) {
    engagementTasks(
      rootOnly: $rootOnly
      ignoreLearningTasks: $ignoreLearningTasks
    ) {
      edges {
        node {
          ...CompanyEngagementTaskFieldsForOverview
        }
      }
    }
  }

  query GetCompanyAndOrgEngagementTasksForOverview(
    $companyId: ID!
    $rootOnly: Boolean
  ) @withOwner(owner: SupplyChain) {
    engagementTasksForCompanyAndOrgV1(
      companyId: $companyId
      rootOnly: $rootOnly
    ) {
      ...CompanyEngagementTaskFieldsForOverview
    }
  }

  query GetCompanyAndOrgEngagementTasksForDetails(
    $companyId: ID!
    $rootOnly: Boolean
  ) @withOwner(owner: SupplyChain) {
    engagementTasksForCompanyAndOrgV1(
      companyId: $companyId
      rootOnly: $rootOnly
    ) {
      ...EngagementTaskFields
    }
  }

  query GetEngagementTasksAssignedTypes @withOwner(owner: SupplyChain) {
    userHasPermission(permission: ManageDisclosures, allowAnyObject: true)
    engagementTasksAssignedToOrg {
      ...EngagementTaskTypeAndStatusFields
    }
  }

  query GetEngagementTasksPageData($companyId: ID!) @withOwner(owner: SupplyChain) {
    companyForDashboard(id: $companyId) {
      ...CompanyFieldsForEngagementTasks
    }
    engagementTasksAssignedToOrg {
      ...EngagementTaskContents
    }
    organization {
      id
      company {
        ...CompanyFieldsForEngagementTasks
      }
      users {
        edges {
          node {
            id
            name
            displayName
          }
        }
      }
    }
    companyPortalSettings(rootCompanyId: $companyId) {
      ...CompanyPortalSettingsFields
    }
  }
`;

type EngagementTasksResult = {
  fetching: boolean;
  stale: boolean;
  error?: CombinedError;
  engagementTasks: Array<GQEngagementTaskFieldsFragment>;
};

type EngagementTasksOverviewResult = {
  fetching: boolean;
  stale: boolean;
  error?: CombinedError;
  engagementTasks: Array<GQCompanyEngagementTaskFieldsForOverviewFragment>;
};

// Use this method when you need high-level data for all tasks under an org (for
// multiple companies)
export function useRootOrgEngagementTasksForOverview(
  ignoreLearningTasks?: boolean
): [EngagementTasksOverviewResult, () => void] {
  const [state, reexecuteQuery] = useGetOrgEngagementTasksForOverviewQuery({
    variables: {
      rootOnly: true,
      ignoreLearningTasks: ignoreLearningTasks ?? true,
    },
  });

  const refresh = useCallback(() => {
    reexecuteQuery({ requestPolicy: 'network-only' });
  }, [reexecuteQuery]);

  const result = useMemo(() => {
    if (isFetchingOrStale(state)) {
      return {
        fetching: state.fetching,
        stale: state.stale,
        error: state.error,
        engagementTasks: [],
      };
    }

    const data = getGqlResultDataBang(state);
    const engagementTasks = flattenConnection(data.engagementTasks);

    return {
      fetching: state.fetching,
      stale: state.stale,
      error: state.error,
      engagementTasks,
    };
  }, [state]);

  return [result, refresh];
}

// Use this method when you need high-level task data for company-level tasks
export function useCompanyAndOrgEngagementTasksForOverview(
  companyId: string
): EngagementTasksOverviewResult {
  const [state] = useGetCompanyAndOrgEngagementTasksForOverviewQuery({
    variables: { companyId, rootOnly: true },
  });

  const result = useMemo(() => {
    if (state.fetching) {
      return {
        fetching: state.fetching,
        stale: state.stale,
        error: state.error,
        engagementTasks: [],
      };
    }

    const data = getGqlResultDataBang(state);
    const engagementTasks = data.engagementTasksForCompanyAndOrgV1;

    return {
      fetching: state.fetching,
      stale: state.stale,
      error: state.error,
      engagementTasks,
    };
  }, [state]);

  return result;
}

// Use this method when you need granular detail for tasks (i.e. all the data
// that lives on the CompanySurveyFields fragment)
export function useCompanyAndOrgEngagementTasksForDetails(
  maybeCompanyId?: string
): [EngagementTasksResult, () => void] {
  const shouldNotFetchTasks = !maybeCompanyId;
  const [state, reexecuteQuery] =
    useGetCompanyAndOrgEngagementTasksForDetailsQuery({
      // @ts-expect-error: query will be skipped if !companyId
      variables: { companyId: maybeCompanyId, rootOnly: true },
      pause: shouldNotFetchTasks,
    });

  const refresh = useCallback(() => {
    reexecuteQuery({ requestPolicy: 'network-only' });
  }, [reexecuteQuery]);

  const result = useMemo(() => {
    if (isFetchingOrStale(state) || shouldNotFetchTasks) {
      return {
        fetching: state.fetching,
        stale: state.stale,
        error: state.error,
        engagementTasks: [],
      };
    }

    const data = getGqlResultDataBang(state);
    const engagementTasks = data.engagementTasksForCompanyAndOrgV1;

    return {
      fetching: state.fetching,
      stale: state.stale,
      error: state.error,
      engagementTasks,
    };
  }, [state, shouldNotFetchTasks]);

  return [result, refresh];
}

// Use this method when you need tasks from the customer perspective
export function useGetEngagementTasksTypesAndStatus() {
  const shouldPause = !useHasPermission(PermissionType.ManageDisclosures);

  const [state] = useGetEngagementTasksAssignedTypesQuery({
    pause: shouldPause,
  });

  const result = useMemo(() => {
    if (isFetchingOrStale(state) || shouldPause) {
      return {
        fetching: state.fetching,
        stale: state.stale,
        error: state.error,
        engagementTasks: [],
      };
    }

    let engagementTasks: Array<GQEngagementTaskTypeAndStatusFieldsFragment> =
      [];
    try {
      const data = getGqlResultDataBang(state, {
        requireUserHasPermission: true,
      });
      engagementTasks = data.engagementTasksAssignedToOrg;
    } catch (error) {
      // We should not throw an error if a user is approving a footprint but
      // does not have sufficient permissions to view disclosures
      // i.e. they have ManageMeasurement but not ManageDisclosures
      if (
        error instanceof ForbiddenError ||
        (error instanceof WsError && error.code === 'FORBIDDEN')
      ) {
        return {
          fetching: state.fetching,
          stale: state.stale,
          error,
          engagementTasks,
        };
      } else {
        throw error;
      }
    }
    return {
      fetching: state.fetching,
      stale: state.stale,
      error: state.error,
      engagementTasks,
    };
  }, [state, shouldPause]);

  return result;
}

// Use this method when you need tasks from the customer perspective
export function useGetEngagementTasksData() {
  const { companyId } = useUserContext();
  const [state] = useGetEngagementTasksPageDataQuery({
    variables: {
      companyId,
    },
  });

  const result = useMemo(() => {
    if (isFetchingOrStale(state)) {
      return {
        fetching: state.fetching,
        stale: state.stale,
        error: state.error,
        engagementTasks: [],
        users: [],
        rootCompany: null,
      };
    }

    let engagementTasks: Array<GQEngagementTaskContentsFragment> = [];
    let users: Array<{ id: string; name: string; displayName: string }> = [];
    let rootCompany = null;

    try {
      const data = getGqlResultDataBang(state);
      engagementTasks = data.engagementTasksAssignedToOrg;
      users = flattenConnection(data?.organization?.users);
      rootCompany = data?.companyForDashboard;
    } catch (error) {
      // We should not throw an error if a user is approving a footprint but
      // does not have sufficient permissions to view disclosures
      // i.e. they have ManageMeasurement but not ManageDisclosures
      if (
        error instanceof ForbiddenError ||
        (error instanceof WsError && error.code === 'FORBIDDEN')
      ) {
        return {
          fetching: state.fetching,
          stale: state.stale,
          error,
          engagementTasks,
          users,
          rootCompany,
        };
      } else {
        throw error;
      }
    }
    return {
      fetching: state.fetching,
      stale: state.stale,
      error: state.error,
      engagementTasks,
      users,
      rootCompany,
    };
  }, [state]);

  return result;
}
