import { useCallback } from 'react';
import introJson from '../json/BigLoading/intro.json';
import loopJson from '../json/BigLoading/loop.json';
import failJson from '../json/BigLoading/fail.json';
import successJson from '../json/BigLoading/success.json';
import { Box, SxProps, Theme } from '@mui/material';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import Lottie from '../Lottie';
import { mixinSx } from '@watershed/style/styleUtils';

export enum AnimationState {
  InProgress = 'InProgress',
  Complete = 'Complete',
  Error = 'Error',
}

type BigLoadingProps = {
  animationState?: AnimationState;
  sx?: SxProps<Theme>;
  onAnimationComplete?: () => void;
};

export default function BigLoading({
  animationState = AnimationState.InProgress,
  sx,
  onAnimationComplete,
}: BigLoadingProps) {
  const handleIntroComplete = useCallback(() => {
    if (animationState === AnimationState.InProgress && onAnimationComplete) {
      onAnimationComplete();
    }
  }, [animationState, onAnimationComplete]);

  const handleFinalAnimationComplete = useCallback(() => {
    if (onAnimationComplete) {
      onAnimationComplete();
    }
  }, [onAnimationComplete]);

  // Determine which animation to show based on the animationState
  const renderAnimation = () => {
    switch (animationState) {
      case AnimationState.InProgress:
        return (
          <Lottie
            key={`animation-${AnimationState.InProgress}`}
            animation={{
              in: {
                load: async () => introJson,
                loop: false,
              },
              ambient: {
                load: async () => loopJson,
                loop: true,
              },
            }}
            style={{ width: '100%', height: '100%' }}
            onComplete={handleIntroComplete}
          />
        );
      case AnimationState.Complete:
        return (
          <Lottie
            key={`animation-${AnimationState.Complete}`}
            animation={{
              ambient: {
                load: async () => successJson,
                loop: false,
              },
            }}
            style={{ width: '100%', height: '100%' }}
            onComplete={handleFinalAnimationComplete}
          />
        );
      case AnimationState.Error:
        return (
          <Lottie
            key={`animation-${AnimationState.Error}`}
            animation={{
              ambient: {
                load: async () => failJson,
                loop: false,
              },
            }}
            style={{ width: '100%', height: '100%' }}
            onComplete={handleFinalAnimationComplete}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={mixinSx({ position: 'relative', width: '100%', height: '100%' }, sx)}
      data-testid={TestIds.LoadingStateBigLoading}
    >
      {renderAnimation()}
    </Box>
  );
}
