import HomeIcon from '@watershed/icons/components/Home';
import { Flags } from '@watershed/constants/flags';
import { PermissionType } from '@watershed/constants/permissions';

import { GQFinanceSnapshotForContextFragment } from '@watershed/app-dashboard/generated/graphql-operations';

import {
  routeForFinanceMeasurement,
  routeForBenchmarks,
  routeForFormalReporting,
  routeForFinanceYearOverYear,
  routeForFinanceDueDiligence,
  routeForFinanceHome,
} from '@watershed/shared-universal/dashboardRoutes';

import isNotNullish from '@watershed/shared-util/isNotNullish';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { NavSectionData, SideBarVariant } from '../utils';
import { ParsedUrlQuery } from 'querystring';
import omit from 'lodash/omit';
import SetSquareIcon from '@watershed/icons/components/SetSquare';
import {
  HAS_ACCESS_TO_BENCHMARKS,
  checkAccessChecks,
} from '../../../utils/featureAccess';
import { useUserContext, UserContextProps } from '../../../utils/UserContext';
import {
  FeatureFlagsMap,
  useAllFeatureFlags,
} from '../../../utils/FeatureFlag';
import { BENCHMARKS_TITLE } from '@watershed/shared-frontend/utils/benchmarks';
import { makeNavItemLocationFromRoute } from './SideBarStandardVariant';
import SimulateIcon from '@watershed/icons/components/Simulate';
import ReportIcon from '@watershed/icons/components/Report';
import JudicialScalesIcon from '@watershed/icons/components/JudicialScales';
import GavelIcon from '@watershed/icons/components/Gavel';
import DatabaseIcon from '@watershed/icons/components/Database';
import { routeForFinanceDataExplorerOverview } from '@watershed/shared-universal/dashboardRoutes';
import { useLatestFinanceSnapshotWithFootprint } from '../../../utils/finance/FinanceSnapshotsContext';
import useHasPermission from '../../../utils/useHasPermission';

/**
 * This is a rare moment where we strip out a bunch of known query params
 *
 * Selecting Overview from the sidebar is a signal to clear these query params
 * but not necessarily financeSnapshotId or low level filters
 */
const ROUTE_LEVEL_FILTERS = [
  'dataFormat',
  'fundId',
  'fundGroup',
  'fundCategory',
  'assetId',
  'viewId',
  'activeTab',
  'model',
  'category',
  'title',
];

export default function useSidebarFinanceVariant(): SideBarVariant {
  const userContext = useUserContext();
  const featureFlags = useAllFeatureFlags();

  const canAccessDataExplorer = useHasPermission(
    PermissionType.ManageFund,
    // If BiV2 is enabled, we have fund-level filtering in data
    // explorer
    { allowAnyObject: true }
  );
  const hasFinanceAdminPermissions = useHasPermission(
    PermissionType.FinanceAdmin
  );

  const router = useRouter();
  const { query } = router;
  const latestSnapshotWithFootprint = useLatestFinanceSnapshotWithFootprint();
  return useMemo(() => {
    const navEntries = generateNavEntries({
      userContext,
      canAccessDataExplorer,
      hasFinanceAdminPermissions,
      featureFlags,
      query,
      latestSnapshotWithFootprint,
    });
    return {
      navEntries,
    };
  }, [
    userContext,
    canAccessDataExplorer,
    hasFinanceAdminPermissions,
    featureFlags,
    query,
    latestSnapshotWithFootprint,
  ]);
}

function generateNavEntries({
  userContext,
  canAccessDataExplorer,
  hasFinanceAdminPermissions,
  featureFlags,
  query,
  latestSnapshotWithFootprint,
}: {
  userContext: UserContextProps;
  canAccessDataExplorer: boolean;
  hasFinanceAdminPermissions: boolean;
  featureFlags: FeatureFlagsMap;
  query: ParsedUrlQuery;
  latestSnapshotWithFootprint?: GQFinanceSnapshotForContextFragment;
}) {
  // TODO: Remove this once we have a better way to check access
  const benchmarksAccessCheck = checkAccessChecks(
    HAS_ACCESS_TO_BENCHMARKS,
    userContext,
    featureFlags
  );

  const yearOverYear = featureFlags.get(
    Flags.AssetManagerYearOverYearComparison
  );
  const hasDueDiligence = featureFlags.get(Flags.AssetManagerDueDiligence);
  const hasReportingPlan = userContext.watershedPlanIncludesReporting;

  const navSections: Array<NavSectionData> = [
    {
      subentries: [
        {
          name: 'Home',
          location: routeForFinanceHome(omit(query, ROUTE_LEVEL_FILTERS)),
          icon: HomeIcon,
          hasPermission: true,
          disabled: !latestSnapshotWithFootprint,
          disabledTooltip: 'Create a footprint to get started',
        },
        yearOverYear
          ? {
              name: 'Comparison',
              location: routeForFinanceYearOverYear(
                omit(query, ROUTE_LEVEL_FILTERS)
              ),
              icon: JudicialScalesIcon,
              hasPermission: true,
            }
          : null,
        {
          name: 'Data management',
          location: routeForFinanceMeasurement(
            omit(query, ROUTE_LEVEL_FILTERS)
          ),
          icon: SetSquareIcon,
          hasPermission: true,
        },
        {
          name: 'Data explorer',
          location: {
            pathname: routeForFinanceDataExplorerOverview(),
            query: omit(query, ROUTE_LEVEL_FILTERS),
          },
          icon: DatabaseIcon,
          hasPermission: canAccessDataExplorer,
          disabled: !latestSnapshotWithFootprint,
          disabledTooltip: 'Create a footprint to use Data Explorer',
        },
        benchmarksAccessCheck.hasAccess
          ? {
              name: BENCHMARKS_TITLE,
              location: makeNavItemLocationFromRoute(routeForBenchmarks()),
              icon: SimulateIcon,
              hasPermission: true,
            }
          : null,
        {
          name: 'Reporting',
          location: {
            pathname: routeForFormalReporting(),
          },
          icon: ReportIcon,
          hasPermission: hasFinanceAdminPermissions,
          disabled: !hasReportingPlan,
          disabledTooltip: 'Your organization does not have a reporting plan',
        },
        hasDueDiligence
          ? {
              name: 'Due diligence',
              location: routeForFinanceDueDiligence(
                omit(query, ROUTE_LEVEL_FILTERS)
              ),
              icon: GavelIcon,
              hasPermission: true,
            }
          : null,
      ].filter(isNotNullish),
    },
  ];

  return navSections;
}
