import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const CityIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M9 6a1 1 0 0 0 0 2h1a1 1 0 1 0 0-2H9Zm-1 5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm6-5a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2h-1Zm-1 5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1Z" />
    <path
      fillRule="evenodd"
      d="M5 3.5A2.5 2.5 0 0 1 7.5 1h9A2.5 2.5 0 0 1 19 3.5V8h1.5a2.5 2.5 0 0 1 2.5 2.5V22a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-8.5A2.5 2.5 0 0 1 3.5 11H5V3.5ZM3 21h2v-8H3.5a.5.5 0 0 0-.5.5V21Zm18-10.5V21h-2V10h1.5a.5.5 0 0 1 .5.5ZM9 21v-5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v5h2V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5V21h2Zm4 0v-5h-2v5h2Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default CityIcon;
