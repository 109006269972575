import { Pill, PillProps } from '@watershed/ui-core/components/Pill';
import {
  getCurrentDevEnv,
  getCurrentDevEnvLabel,
} from '@watershed/shared-frontend/utils/devEnv';

/**
 * If we're in an environment other than production, show a pill with the
 * current dev environment. The color of the pill scales with scariness of the
 * environment we're using:
 * - local-dev: green
 * - anything else: red
 *
 * Can be used full width if you'd like to provide something other than just a
 * standalone pill.
 */
export function CurrentEnvironmentIndicator({
  size,
  fullWidth,
}: {
  size?: PillProps['size'];
  fullWidth?: boolean;
}) {
  const devEnv = getCurrentDevEnv();

  // Nothing for prod!
  if (devEnv === 'production') {
    return null;
  }

  // Otherwise, show a pill with the current dev environment
  return (
    <Pill
      sx={(theme) => ({
        backgroundColor:
          devEnv === 'local-dev'
            ? theme.palette.grass
            : theme.palette.error.main,
        color: theme.palette.primary.contrastText,
        width: fullWidth ? '100%' : undefined,
      })}
      size={size}
      label={getCurrentDevEnvLabel()}
    />
  );
}
