import {
  GQCompanyClimateCommitmentKind,
  GQCompanyEmissionsUnits,
  GQCustomIntensityConfigQuantityType,
  GQDisclosureInitiativeType,
  GQDisclosureQualityScore,
  GQEmissionsSource,
  GQExternalReportType,
  GQFilterFieldLegacy,
  GQPrivateDisclosureType,
  GQSbtCommitmentStage,
  GQSbtCommitmentType,
  GQSbtScope1And2Target,
  GQSbtScope3Target,
  GQSbtTargetClassification,
  GQSbtTermLength,
  GQScope3EvaluationStatus,
  GQTimeseriesFrequency,
} from '@watershed/shared-universal/generated/graphql-schema-types';
import { FilterConjunction, FilterOperator } from '@watershed/constants/filter';

import {
  PlanTargetIntensityType,
  DisclosureTargetIntensityType,
  DisclosureTargetEmissionsType,
  DisclosureTargetReductionRate,
  PlanTargetTargetComparisonType,
  TargetReductionRate,
} from '@watershed/constants/reductions';

import { GrowthFactorType } from '@watershed/constants/forecasts';

import { ReductionFilter } from '../reductions/ReductionFilter';
import { YearlyPercentageTimeseries } from '../utils/SimpleTimeseries';
import { FiscalYear, YearMonth, YMInterval } from '../utils/YearMonth';

export enum AsyncDataStatus {
  Loading, // Data is still loading, but will load eventually
  Paused, // Data will never load, don't hold your breath!
  Ready, // Data has loaded
}

export type AsyncData<T> =
  | { status: AsyncDataStatus.Loading }
  | { status: AsyncDataStatus.Paused }
  | { status: AsyncDataStatus.Ready; data: T };

export type SuppliersForForecasting = AsyncData<
  Array<SupplierForReductionsForecast>
>;

export interface FootprintForecastRowFields extends Record<string, any> {
  categoryId: string | undefined;
  subcategoryId: string | undefined;
  location: string | undefined;
  locationCountry: string | undefined;
  vendor: string | undefined;
  product: string | undefined;
  ghgScope: string | undefined;
  ghgCategoryId: string | undefined;
  buildingName: string | undefined;
  electricityType: string | undefined;
  description: string | undefined;
}

// TODO: pull this enum up into the gql layer (we call it ghgCategory there,
// which is another thing we should fix)
export const FootprintScopes = ['scope 1', 'scope 2', 'scope 3'] as const;
export type FootprintScope = (typeof FootprintScopes)[number];

export type FootprintForecastRow = FootprintForecastRowFields & {
  yearMonth: YearMonth;
  kgco2e: number;
};

export type EmissionsTargetWithFilters = {
  id: string;
  scope?: FootprintScope;
  targets: YearlyPercentageTimeseries;
  comparisonType: PlanTargetTargetComparisonType;
  intensityType: PlanTargetIntensityType;
  customIntensityConfigId: string | null;
  baseYear: YearMonth;
  filters: ReductionFilter;
};

// Looks silly, but serves a real purpose: it discards any other properties that
// happen to be lying around
export function toFootprintForecastRowFields(
  f: FootprintForecastRowFields,
  footprintTags: Array<string>
): FootprintForecastRowFields {
  return {
    categoryId: f.categoryId,
    subcategoryId: f.subcategoryId,
    location: f.location,
    locationCountry: f.locationCountry,
    vendor: f.vendor,
    product: f.product,
    entity: f.entity,
    buildingName: f.buildingName,
    electricityType: f.electricityType,
    ghgScope: f.ghgScope,
    ghgCategoryId: f.ghgCategoryId,
    description: f.description,
    // Add in the footprint tags
    ...Object.fromEntries(footprintTags.map((key) => [key, f[key]])),
  };
}

export function mapKeyForFootprintForecastRowFields(
  fields: FootprintForecastRowFields,
  footprintTags: Array<string>
): string {
  // This should be in-sync with REDUCTION_FILTER_FIELDS
  // We don't use spread because of performance issues.
  // TODO - should add type-checking such that this stays in sync with REDUCTION_FILTER_FIELDS

  const valueList = [
    fields.categoryId,
    fields.subcategoryId,
    fields.location,
    fields.vendor,
    fields.product,
    fields.buildingName,
    fields.electricityType,
    fields.entity,
    fields.ghgScope,
    fields.ghgCategoryId,
    fields.description,
    fields.locationCountry,
  ];
  footprintTags.forEach((key) => valueList.push(fields[key]));

  const key = valueList.join('||');
  return key;
}

export type ActualAndNecessaryReduction = {
  actualReduction: number;
  necessaryReduction: number;
};

export type ActualAndNecessaryRemoval = {
  actualRemoval: number;
  necessaryRemoval: number;
};

export enum TargetValidity {
  Valid,
  Invalid,
  Undetermined,
}

type BaseTargetValidity<T> = {
  target: T;
  validity: TargetValidity;
  reason: string;
};

type EmissionsTargetValidity<T> = {
  type: 'emissions';
  reduction?: ActualAndNecessaryReduction;
} & BaseTargetValidity<T>;

type SupplierEngagementTargetValidity<T> = {
  type: 'supplierEngagement';
  reduction?: ActualAndNecessaryReduction;
} & BaseTargetValidity<T>;

type RETargetValidity<T> = {
  type: 'renewableElectricity';
  reduction?: ActualAndNecessaryReduction;
  interimReduction?: ActualAndNecessaryReduction;
  interimTargetYearInclusive?: number;
  targetYearInclusive?: number;
} & BaseTargetValidity<T>;

type LongTermNetZeroTargetValidity<T> = {
  type: 'longTermNetZero';
  reduction?: ActualAndNecessaryReduction;
  removal?: ActualAndNecessaryRemoval;
} & BaseTargetValidity<T>;

export type SbtTargetValidity<T> =
  | EmissionsTargetValidity<T>
  | RETargetValidity<T>
  | SupplierEngagementTargetValidity<T>
  | LongTermNetZeroTargetValidity<T>;

export type TargetsValidByScopeResult = {
  scope12: boolean;
  scope3: boolean;
};

export type ReduxTargetChartSeriesDatum = {
  // year
  x: number;
  y: number;
  actualXRange: FiscalYear;
};

// The following types are extracted from GQL fragments as part of a refactor
// away from their use in shared-universal

export type CustomIntensityConfigFields = {
  id: string;
  name: string;
  description: string;
  unit: string | null;
  humanReadable: boolean;
  quantityType: GQCustomIntensityConfigQuantityType;
  isSbtValidated: boolean | null;
};

export type ForecastScenarioFields = {
  id: string;
  name: string;
  description: string | null;
  isDefault: boolean;
  updatedByName: string | null;
  updatedAt: Date;
  historicalUserInputtedGrowthFactorDataPoints: Array<{
    date: Date;
    growthFactors: Array<{
      growthFactorType: GrowthFactorType;
      customIntensityConfigId: string | null;
      value: number | null;
    }>;
  }>;
  growthForecasts: Array<{
    id: string;
    growthFactorType: GrowthFactorType;
    customIntensityConfigId: string | null;
    isDefaultScalingFactor: boolean;
    hasUserInputtedHistoricalPeriod: boolean;
    forecast: {
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
    scalingFactorFilters: {
      conjunction: FilterConjunction;
      expressions: Array<{
        field: string;
        operator: FilterOperator;
        value: Array<string>;
      }>;
    } | null;
  }>;
};

export type ForecastFieldsForPlan = {
  id: string;
  interval: YMInterval;
  referencePeriodInterval: YMInterval;
  overrideFootprintKind: string | null;
  validReferenceInterval: YMInterval;
  footprintSnapshotId: string;
  footprintInterval: YMInterval;
  scenarios: Array<{
    id: string;
    name: string;
    description: string | null;
    isDefault: boolean;
    updatedByName: string | null;
    updatedAt: Date;
    historicalUserInputtedGrowthFactorDataPoints: Array<{
      date: Date;
      growthFactors: Array<{
        growthFactorType: GrowthFactorType;
        customIntensityConfigId: string | null;
        value: number | null;
      }>;
    }>;
    growthForecasts: Array<{
      id: string;
      growthFactorType: GrowthFactorType;
      customIntensityConfigId: string | null;
      isDefaultScalingFactor: boolean;
      hasUserInputtedHistoricalPeriod: boolean;
      forecast: {
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      scalingFactorFilters: {
        conjunction: FilterConjunction;
        expressions: Array<{
          field: string;
          operator: FilterOperator;
          value: Array<string>;
        }>;
      } | null;
    }>;
  }>;
  historicalPeriod: {
    interval: YMInterval;
    data: Array<{
      date: Date;
      headcount: number | null;
      revenue: number | null;
      customGrowthFactors: Array<{
        customIntensityId: string;
        value: number | null;
      }>;
    }>;
  };
  customIntensityConfigs: Array<{
    id: string;
    name: string;
    description: string;
    unit: string | null;
    humanReadable: boolean;
    quantityType: GQCustomIntensityConfigQuantityType;
    isSbtValidated: boolean | null;
  }>;
  businessCategories: Array<{
    businessCategory: string;
  }>;
};

export type GrowthForecastFields = {
  id: string;
  growthFactorType: GrowthFactorType;
  customIntensityConfigId: string | null;
  isDefaultScalingFactor: boolean;
  hasUserInputtedHistoricalPeriod: boolean;
  forecast: {
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  scalingFactorFilters: {
    conjunction: FilterConjunction;
    expressions: Array<{
      field: string;
      operator: FilterOperator;
      value: Array<string>;
    }>;
  } | null;
};

export type SupplierForReductionsForecast = {
  id: string;
  name: string;
  footprintVendorNames: Array<string>;
  cleanedFootprintVendorNames: Array<string>;
  totalSpending: number | null;
  company: { id: string } | null;
  disclosures: Array<{
    id: string;
    company: { id: string } | null;
    publicDisclosure: {
      id: string;
      publishingYear: number;
      reportType: GQExternalReportType;
      publicUrl: string;
      qualityScore: GQDisclosureQualityScore | null;
      cdpVendorData: {
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    privateDisclosure: {
      id: string;
      companyId: string | null;
      orgId: string;
      orgName: string;
      surveyId: string | null;
      privateDisclosureType: GQPrivateDisclosureType;
      createdAt: Date;
      disclosureQualityScore: GQDisclosureQualityScore | null;
      disclosureQualityExplanation: string | null;
      thirdPartyVerified: boolean;
      cdpVendorData: {
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    historicalEmissionsYears: Array<{
      id: string;
      publicDisclosureId: string | null;
      reportingYear: number;
      numEmployees: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      revenueUsd: number | null;
      percentageCleanEnergy: number | null;
      totalMwh: number | null;
      units: GQCompanyEmissionsUnits;
      expenseCategory: string | null;
      scope1: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
    }> | null;
    targets: Array<{
      id: string;
      name: string;
      description: string;
      baseYear: YearMonth;
      emissionsType: DisclosureTargetEmissionsType;
      reductionRate: DisclosureTargetReductionRate;
      intensityType: DisclosureTargetIntensityType;
      unit: string | null;
      unitDescription: string | null;
      filters: {
        conjunction: FilterConjunction;
        expressions: Array<{
          field: GQFilterFieldLegacy;
          operator: FilterOperator;
          value: Array<string>;
        }>;
      };
      emissionsTarget: {
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
    }> | null;
    initiatives: Array<{
      id: string;
      name: string | null;
      initiativeType: GQDisclosureInitiativeType;
      description: string | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      intensityType: DisclosureTargetIntensityType | null;
      emissionsTimeseries: {
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      } | null;
      filters: {
        conjunction: FilterConjunction;
        expressions: Array<{
          field: GQFilterFieldLegacy;
          operator: FilterOperator;
          value: Array<string>;
        }>;
      } | null;
    }> | null;
    climateCommitments: Array<
      | {
          __typename?: 'CarbonNeutralCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'CleanEnergyCommitment';
          targetYear: number | null;
          targetPercentageCleanEnergy: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'NetZeroCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'ScienceBasedTargetCommitment';
          submittedToSBTi: boolean;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
          commitment: {
            id: string;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
            nearTermTargetYear: number | null;
            longTermTargetYear: number | null;
            baselineYear: number | null;
            netZeroCommitted: boolean | null;
            commitmentType: GQSbtCommitmentType | null;
            commitmentDeadline: Date | null;
            netZeroTargetYear: number | null;
          };
        }
    > | null;
  }>;
  industryAverageAllocatedEmissions: {
    scope1: number | null;
    scope2: number | null;
    scope3: number | null;
    scope301: number | null;
    scope302: number | null;
    scope303: number | null;
    scope304: number | null;
    scope305: number | null;
    scope306: number | null;
    scope307: number | null;
    scope308: number | null;
    scope309: number | null;
    scope310: number | null;
    scope311: number | null;
    scope312: number | null;
    scope313: number | null;
    scope314: number | null;
    scope315: number | null;
    scope316: number | null;
    scope317: number | null;
    scope1Ratio: number | null;
    scope2Ratio: number | null;
    scope3Ratio: number | null;
    publishingYear: number | null;
    reportingYear: number;
    surveyId: string | null;
    source: GQEmissionsSource;
    revenueUsd: number | null;
    revenue: number | null;
    revenueCurrency: string | null;
    publicUrl: string | null;
    units: GQCompanyEmissionsUnits;
    expenseCategory: string | null;
  } | null;
};

export type PlanVariablesFields = {
  commitmentsHasNetZero: boolean;
  commitmentsNetZeroYear: number;
  cleanPowerStart: YearMonth;
  commitmentsHasSBT: boolean;
  commitmentsSBTTargetYear: number;
  commitmentsSBTScope12: GQSbtScope1And2Target;
  commitmentsSBTScope3: GQSbtScope3Target;
  commitmentsSBTTermLength: GQSbtTermLength;
  commitmentsSBTSubmissionDate: YearMonth;
  flightsPerEmployeeForecastEnd: number;
  percentWorkFromHomeForecastEnd: number;
  netZeroSuppliersReductionPercent: number;
};

export type SbtiTargetExclusionFields = {
  id: string;
  filters: {
    conjunction: FilterConjunction;
    expressions: Array<{
      field: string;
      operator: FilterOperator;
      value: Array<string>;
    }>;
  };
};

export type SupplierDisclosureInitiativeFields = {
  id: string;
  name: string | null;
  initiativeType: GQDisclosureInitiativeType;
  description: string | null;
  startYearMonth: YearMonth | null;
  endYearMonth: YearMonth | null;
  intensityType: DisclosureTargetIntensityType | null;
  emissionsTimeseries: {
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  } | null;
  filters: {
    conjunction: FilterConjunction;
    expressions: Array<{
      field: GQFilterFieldLegacy;
      operator: FilterOperator;
      value: Array<string>;
    }>;
  } | null;
};

export type SupplierDisclosureTargetField = {
  id: string;
  name: string;
  description: string;
  baseYear: YearMonth;
  emissionsType: DisclosureTargetEmissionsType;
  reductionRate: DisclosureTargetReductionRate;
  intensityType: DisclosureTargetIntensityType;
  unit: string | null;
  unitDescription: string | null;
  filters: {
    conjunction: FilterConjunction;
    expressions: Array<{
      field: GQFilterFieldLegacy;
      operator: FilterOperator;
      value: Array<string>;
    }>;
  };
  emissionsTarget: {
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
};

export type SupplierHistoricalEmissionsFields = {
  scope1: number | null;
  scope2: number | null;
  scope3: number | null;
  scope301: number | null;
  scope302: number | null;
  scope303: number | null;
  scope304: number | null;
  scope305: number | null;
  scope306: number | null;
  scope307: number | null;
  scope308: number | null;
  scope309: number | null;
  scope310: number | null;
  scope311: number | null;
  scope312: number | null;
  scope313: number | null;
  scope314: number | null;
  scope315: number | null;
  scope316: number | null;
  scope317: number | null;
  scope1Ratio: number | null;
  scope2Ratio: number | null;
  scope3Ratio: number | null;
  publishingYear: number | null;
  reportingYear: number;
  surveyId: string | null;
  source: GQEmissionsSource;
  revenueUsd: number | null;
  revenue: number | null;
  revenueCurrency: string | null;
  publicUrl: string | null;
  units: GQCompanyEmissionsUnits;
  expenseCategory: string | null;
};

export type PlanTargetFields = {
  id: string;
  createdAt: Date | null;
  description: string;
  reportDescription: string;
  customNotes: string;
  baseYear: YearMonth;
  intensityType: PlanTargetIntensityType;
  customIntensityConfigId: string | null;
  comparisonType: PlanTargetTargetComparisonType;
  reductionRate: TargetReductionRate;
  deletedAt: Date | null;
  assignedTo: string | null;
  parentTargetId: string | null;
  interimTargetDate: YearMonth | null;
  interimTargetValue: number | null;
  emissionsTargetCustom: {
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  filters: {
    conjunction: FilterConjunction;
    expressions: Array<{
      field: GQFilterFieldLegacy;
      operator: FilterOperator;
      value: Array<string>;
    }>;
  };
};
