// #region stationary vs. mobile

// https://www.notion.so/watershedclimate/The-MARTA-queries-for-stationary-vs-mobile-combustion-suck-d3bca7fa3b744caa991a7d2a8c8b6e30
// Please, dear god, do not change these constants. They are a one-time
// snapshot of legacy data that we'll support.
export const OLD_RAW_DESCRIPTIONS_STATIONARY_LOWERCASE = [
  'Asphalt and Road Oil',
  'Biodiesel (100%)',
  'Biofuel',
  'Biofuel and waste',
  'Blast Furnace Gas',
  'buildings',
  'Buildings, Natural gas',
  'Butane',
  'Butylene',
  'Coal',
  'Coke Oven Gas',
  'Compressed Natural Gas (CNG)',
  'Crude Oil',
  'Diesel Fuel',
  'Diesel fuel',
  'Diesel generators',
  'Distillate Fuel Oil No. 1',
  'Distillate Fuel Oil No. 2',
  'Distillate Fuel Oil No. 4',
  'District heat',
  'Ethane',
  'Ethanol (100%)',
  'Ethylene',
  'facilities',
  'Firewood',
  'Fuel Gas',
  'Heavy Gas Oils',
  'Isobutane',
  'Isobutylene',
  'Kerosene',
  'Kerosene consumption',
  'Landfill Gas',
  'Liquefied Natural Gas (LNG)',
  'Liquefied Petroleum Gases (LPG)',
  'Lubricants',
  'Methane',
  'Naphtha (<401 deg F)',
  'Natural Gas',
  'Natural Gasoline',
  'Natural gas',
  'Oil',
  'Other Biomass Gases',
  'Other Oil (>401 deg F)',
  'Other stationary fuels',
  'Pentanes Plus',
  'Petrochemical Feedstocks',
  'Propane',
  'Propane Gas',
  'Propylene',
  'Rendered Animal Fat',
  'Residual Fuel Oil No. 5',
  'Residual Fuel Oil No. 6',
  'Special Naphtha',
  'Stationary Combustion - Diesel Fuel',
  'Stationary Combustion - Distillate Fuel Oil No. 1',
  'Stationary Combustion - Distillate Fuel Oil No. 4',
  'Stationary Combustion - Liquefied Petroleum Gases (LPG)',
  'Stationary combustion - diesel fuel',
  'Steam heat',
  'Unfinished Oils',
  'Used Oil',
  'Vegetable Oil',
].map((s) => s.toLowerCase());

export const OLD_RAW_DESCRIPTIONS_MOBILE_LOWERCASE = [
  'Air Travel - Long Haul',
  'Air Travel - Medium Haul',
  'Air Travel - Short Haul',
  'Air Travel - Private',
  'Air Travel - Private WTT',
  'Aviation Gasoline',
  'Car',
  'Company leased vehicles',
  'Company vehicles',
  // For Vertiv, I promise this is mobile
  'Compressed natural gas (cng)',
  'Delivery',
  'fuel car',
  'Hybrid vehicles',
  'Kerosene-Type Jet Fuel',
  'Last mile delivery',
  'Line haul emissions',
  'Mobile Combustion - Diesel Fuel',
  'Mobile combustion - diesel fuel',
  'Mobile combustion - gasoline',
  'Motor Gasoline',
  'Nitrous oxide',
  'Packaging return program',
  'Private Aviation',
  'XB-1 fuel use',
].map((s) => s.toLowerCase());

// #endregion stationary vs. mobile

// #region fuelKind

export const OLD_RAW_DESCRIPTIONS_DIESEL_LOWERCASE = [
  'Diesel',
  'Diesel Fuel',
  'Diesel generators',
  'Stationary combustion - diesel fuel',
  'Mobile combustion - diesel fuel',
].map((s) => s.toLowerCase());

export const OLD_RAW_DESCRIPTIONS_GAS_LOWERCASE = [
  'Natural gas',
  'Buildings, Natural gas',
].map((s) => s.toLowerCase());

export const OLD_RAW_DESCRIPTIONS_AVIATION_FUEL_LOWERCASE = [
  'Air Travel - Long Haul',
  'Air Travel - Medium Haul',
  'Air Travel - Short Haul',
  'Air Travel - Private',
  'Air Travel - Private WTT',
  'Aviation Gasoline',
  'Kerosene-Type Jet Fuel',
].map((s) => s.toLowerCase());

export const OLD_RAW_DESCRIPTIONS_SOLAR_LOWERCASE = [
  'Solar',
  'Electricity production (onsite renewable)',
].map((s) => s.toLowerCase());

export const OLD_RAW_DESCRIPTIONS_OIL_LOWERCASE = ['Oil', 'Heavy gas oils'].map(
  (s) => s.toLowerCase()
);

export const OLD_RAW_DESCRIPTIONS_COAL_LOWERCASE = [
  'Coal',
  'Anthracite coal',
].map((s) => s.toLowerCase());

export const OLD_RAW_DESCRIPTIONS_BIOMASS_LOWERCASE = [
  'Biofuel',
  'Biofuels and waste',
].map((s) => s.toLowerCase());

// #endregion fuelKind

// #region energyKind

export const OLD_RAW_DESCRIPTIONS_HEAT_LOWERCASE = [
  ...OLD_RAW_DESCRIPTIONS_AVIATION_FUEL_LOWERCASE,
  'Building heating',
  'Buildings, Natural gas',
  'District heat',
  'Mobile Combustion - Diesel Fuel',
  'Mobile combustion - diesel fuel',
  'Mobile combustion - gasoline',
  'Motor Gasoline',
  'Private Aviation',
].map((s) => s.toLowerCase());

export const OLD_RAW_DESCRIPTIONS_COOLING_LOWERCASE = [
  'District chilled water',
  'District cooling',
].map((s) => s.toLowerCase());

export const OLD_RAW_DESCRIPTIONS_STEAM_LOWERCASE = [
  'Buildings, Steam',
  'Steam heat',
].map((s) => s.toLowerCase());

export const OLD_RAW_DESCRIPTIONS_ELECTRICITY_LOWERCASE = [
  'Buildings, Electricity',
  'Data centres, Electricity',
  'Diesel generators',
  'Electricity',
  'Vehicle electricity',
].map((s) => s.toLowerCase());

// #endregion energyKind

export const OLD_RAW_DESCRIPTIONS_ONSITE_LOWERCASE = [
  'Diesel generators',
  'Onsite solar',
  'Solar',
].map((s) => s.toLowerCase());

export const ROW_COUNT_COLUMN = 'rowCount';
export const DEFAULT_PREFERRED_CURRENCY = 'USD';

// Please maintain alphabetical order when adding to this list
export const MARTA_SUPPORTED_BART_TABLES = [
  'activity_with_custom_ef',
  'anti_bribery_and_corruption_incidents',
  'building_occupation',
  'building_stationary_combustion',
  'building_waste',
  'carbon_credit_retirements',
  'cloud_compute_usage',
  'cloud_spend',
  'cloud_usage_and_spend',
  'consumer_goods_ecoinvent_material',
  'consumer_goods_material_assessment',
  'consumer_goods_packaging_material',
  'consumer_goods_textile_product_use_phase',
  'corporate_315_investments',
  'direct_ghg_emissions',
  'electricity_consumption_datacenter',
  'electricity_consumption',
  'employees',
  'expenditure_mapped',
  'expenditure_unmapped',
  'expenditure',
  'food_mass',
  'food_spend',
  'fuel_combustion',
  'ghg_removal',
  'health_and_safety_incidents',
  'historical_emissions',
  'intensity_denominator_custom',
  'intensity_denominator_monetary',
  'lifetime_fuel_and_electricity_use_of_sold_products',
  'lobbying',
  'lodging',
  'logistics',
  'microplastics_outflow',
  'non_building_electricity_consumption',
  'payment_orders',
  'refrigerants',
  'severe_human_rights_incidents',
  'site_land_use',
  'sold_product_circularity',
  'sold_product_recyclability',
  'sold_refrigerant',
  'sold_retail_good',
  'sold_vehicle',
  'substances', // TODO(CSRD-2027) deprecate this BAT
  'substance_outflow',
  'tier_1_factories_activity_data',
  'tier_1_factories_spend',
  'travel_air',
  'travel_private_jets',
  'travel_rail',
  'vehicles',
  'waste',
  'waste_products',
  'water_consumption',
  'water_discharge',
  'water_recycling',
  'water_storage',
  'water_usage',
  'workers_social',
  'workplace_reports',
] as const;

export type MartaSupportedBartTable =
  (typeof MARTA_SUPPORTED_BART_TABLES)[number];

const MARTA_SUPPORTED_BART_TABLES_SET: Set<string> = new Set(
  MARTA_SUPPORTED_BART_TABLES
);

export function isMartaSupportedBartTable(
  table: string
): table is MartaSupportedBartTable {
  return MARTA_SUPPORTED_BART_TABLES_SET.has(table);
}
