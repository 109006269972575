import { useIdleTimer } from 'react-idle-timer';
import { doLogout } from './useLogout';
import { useEffect } from 'react';
import invariant from 'invariant';
import { plural } from '@lingui/core/macro';

const ONE_SECOND_MS = 1_000;
const ONE_MINUTE_MS = 60 * ONE_SECOND_MS;
const DEFAULT_SESSION_TIMEOUT_MINUTES = 30;

/**
 * This idle logout hook is designed to log out users after a period of inactivity.
 *
 * For now we have a default of 30 minutes, and this is configurable per org. If the
 * application has been idle for that number of minutes, we log the user out.
 */
export default function useIdleLogout({
  sessionTimeoutMinutes,
  isEnabled,
}: { sessionTimeoutMinutes: number | null; isEnabled: boolean }) {
  const finalTimeoutMinutes =
    sessionTimeoutMinutes || DEFAULT_SESSION_TIMEOUT_MINUTES;
  const finalTimeoutMs = finalTimeoutMinutes * ONE_MINUTE_MS;

  invariant(
    !isEnabled || finalTimeoutMinutes > 0,
    'Idle session timeout must be positive or null'
  );

  useEffect(() => {
    if (!isEnabled) {
      return;
    }
    console.info(
      `Idle session timeout set to ${finalTimeoutMinutes} ${plural(
        finalTimeoutMinutes,
        {
          one: 'minute',
          other: 'minutes',
        }
      )}`
    );
  }, [isEnabled, finalTimeoutMinutes]);

  useIdleTimer({
    onIdle: () => {
      if (!isEnabled) {
        return;
      }
      console.info('Logging out due to idle timeout');
      void doLogout();
    },
    crossTab: true,
    leaderElection: true,
    timeout: finalTimeoutMs,
    throttle: ONE_SECOND_MS,
  });
}
