import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const SupportNewMessageIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => (
    <Icon ref={ref} viewBox="0 0 16 17" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M1.92 2.42a2 2 0 0 1 1.413-.587H7.8a.667.667 0 0 1 0 1.334H3.334a.667.667 0 0 0-.667.666v9.057l1.528-1.528a.667.667 0 0 1 .472-.195h8a.667.667 0 0 0 .667-.667V8.7a.667.667 0 0 1 1.333 0v1.8a2 2 0 0 1-2 2H4.943l-2.471 2.471a.667.667 0 0 1-1.139-.471V3.833A2 2 0 0 1 1.92 2.42Z"
        clipRule="evenodd"
      />
      <path d="M12 6.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
      <path
        fillRule="evenodd"
        d="M12 3.167a1.333 1.333 0 1 0 0 2.666 1.333 1.333 0 0 0 0-2.666ZM9.334 4.5a2.667 2.667 0 1 1 5.333 0 2.667 2.667 0 0 1-5.333 0Z"
        clipRule="evenodd"
      />
    </Icon>
  )
);
export default SupportNewMessageIcon;
