import Button from '@watershed/ui-core/components/Button';
import Tooltip from '@mui/material/Tooltip';
import { useLingui } from '@lingui/react/macro';
import { Flags } from '@watershed/constants/flags';
import { useFeatureFlag } from '@watershed/app-dashboard/utils/FeatureFlag';
import {
  pulseAnimation,
  sharedShadow,
  sharedFocusShadow,
} from '../utils/styles';
import Logo from '@watershed/ui-core/components/Logo';
import { tooltipDarkStyleProps } from '@watershed/style/styleUtils';
export function HelpTrigger({ onExpand }: { onExpand: () => void }) {
  const { t } = useLingui();
  const isEnabled = useFeatureFlag(Flags.DustBotInProductPrototype);

  if (!isEnabled) {
    return null;
  }

  return (
    <Tooltip
      title={t({
        message: 'Get help instantly...',
        context: 'Button text to open help panel',
      })}
      arrow={false}
      placement="top"
      slotProps={tooltipDarkStyleProps()}
    >
      <Button
        onClick={onExpand}
        variant="contained"
        sx={{
          gap: 1,
          height: 'fit-content',
          minWidth: 'fit-content',
          p: 1,
          borderRadius: '100px',
          border: (theme) => `0.5px solid ${theme.palette.grey40}`,
          boxShadow: sharedShadow,
          animation: `${pulseAnimation} 2s infinite`, // Add the pulse animation
          '&:hover': {
            backgroundColor: (theme) => theme.palette.grey05,
            boxShadow: sharedShadow,
            animation: `${pulseAnimation} 2s infinite`, // Add the pulse animation
          },
          '&.active, &:focus': {
            backgroundColor: (theme) => theme.palette.white,
            boxShadow: sharedFocusShadow,
            animation: 'none', // Stop the animation when active/focused
          },
        }}
      >
        <Logo size={16} />
      </Button>
    </Tooltip>
  );
}
