import gql from 'graphql-tag';
import {
  PermissionType,
  PermissionObjectType,
} from '@watershed/constants/permissions';
import { Flags } from '@watershed/constants/flags';

import {
  GQOnboardingKind,
  GQWatershedPlanLegacy,
  GQRegion,
  GQUser,
  GQWatershedPlan,
  GQOrgLimitedProfile,
} from '@watershed/app-dashboard/generated/graphql-schema-types';
import {
  GQActiveOrganizationFieldsFragment,
  GQFundAllFieldsFragment,
  GQActiveOrganizationSavedViewFragment,
} from '@watershed/app-dashboard/generated/graphql-operations';

import React, { useState, useEffect, useContext } from 'react';
import { datadogLogs } from '@datadog/browser-logs';

import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import {
  watershedPlanIncludesMeasurement,
  watershedPlanIncludesReductions,
} from '@watershed/shared-universal/utils/watershedPlanUtils';
import { getCurrencyForOrg } from '@watershed/shared-universal/utils/currencies';
import { CurrencyCode } from '@watershed/constants/currency';
import type { FeatureFlagsMap } from './FeatureFlag';

interface UserContextData {
  orgId: string;
  orgName: string;
  orgHelpChannel: string | null;
  orgCountry: string | null;
  demoOrg: boolean;
  testOrg: boolean;
  stagingOrg: boolean | null;
  region: GQRegion;
  watershedPlanLegacy: GQWatershedPlanLegacy;
  watershedPlan: GQWatershedPlan;
  watershedPlanIncludesMeasurement: boolean;
  watershedPlanIncludesReductions: boolean;
  watershedPlanIncludesDatasetCadences: boolean;
  watershedPlanIncludesReporting: boolean;
  userId: string;
  createdAt: Date;
  accessibleOrgs: Array<Pick<GQOrgLimitedProfile, 'companyId' | 'id' | 'name'>>;
  userName: string;
  userEmail: string;
  userIsWatershedEmployee: boolean;
  userIsWatershedContractor: boolean;
  userIsE2ETester: boolean;
  permissions: Array<{
    permission: PermissionType;
    objectId?: string | null;
    objectType?: PermissionObjectType | null;
  }>;
  userOnboardingsCompleted: Array<GQOnboardingKind>;
  logoUrl: string | null;
  iconUrl: string | null;
  loginAsUser: Pick<GQUser, 'id' | 'name'> | null;
  isCustomer: boolean;
  hasInitialized: boolean;

  // #WinFinance global values for Sidebar
  funds: ReadonlyArray<GQFundAllFieldsFragment>;
  financeSavedViews: ReadonlyArray<GQActiveOrganizationSavedViewFragment>;

  orgFiscalYearStartMonth: number | null;
  canAccessCorporate: boolean;
  canAccessFinance: boolean;
  currency: CurrencyCode | null;
  companyId: string;
  domains: Array<string>;
  sessionId: string;
  preferredLocale: string | null;
  sessionTimeoutMinutes: number | null;
}

export interface UserContextProps extends UserContextData {}

const UserContextDataDefaults: UserContextData = {
  orgId: 'string',
  orgName: 'string',
  orgHelpChannel: null,
  orgCountry: null,
  demoOrg: false,
  testOrg: false,
  stagingOrg: false,
  region: GQRegion.Us,
  watershedPlan: GQWatershedPlan.Standard,
  watershedPlanLegacy: GQWatershedPlanLegacy.Standard,
  watershedPlanIncludesMeasurement: false,
  watershedPlanIncludesReductions: false,
  watershedPlanIncludesDatasetCadences: false,
  watershedPlanIncludesReporting: false,
  userId: 'string',
  createdAt: new Date(),
  accessibleOrgs: [],
  userName: 'string',
  userEmail: 'string',
  userIsWatershedEmployee: false,
  userIsWatershedContractor: false,
  userIsE2ETester: false,
  permissions: [],
  userOnboardingsCompleted: [],
  logoUrl: null,
  iconUrl: null,
  loginAsUser: null,
  isCustomer: true,
  hasInitialized: false,
  funds: [],
  financeSavedViews: [],
  orgFiscalYearStartMonth: null,
  canAccessCorporate: true,
  canAccessFinance: false,
  currency: null,
  companyId: '',
  domains: [],
  sessionId: '',
  preferredLocale: null,
  sessionTimeoutMinutes: null,
};

const UserContextDefaults = UserContextDataDefaults;

const UserContext = React.createContext<UserContextProps>(UserContextDefaults);

/**
 * If you're using this from a test, instead use `MockUserContextProvider` to
 * better provide defaults and overrides.
 */
export function UserContextProvider({
  parsedUserContext,
  children,
}: React.PropsWithChildren<{ parsedUserContext: UserContextProps }>) {
  return (
    <UserContext.Provider value={parsedUserContext}>
      {children}
    </UserContext.Provider>
  );
}

/**
 * Used from Storybook + unit tests to mock out user context provider with the
 * defaults, but allows override values. DO NOT use from production code.
 */
function MockUserContextProvider({
  overrideUserContext,
  children,
}: {
  overrideUserContext?: Partial<UserContextProps>;
  children: React.ReactNode;
}) {
  return (
    <UserContext.Provider
      value={{
        ...UserContextDataDefaults,
        ...overrideUserContext,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}

// This giant list of stuff seems suspicious,
// as if pieces belong closer to where they are used…
gql`
  query ActiveOrganization @withOwner(owner: EnterpriseFoundations) {
    activeOrganization {
      ...ActiveOrganizationFields
    }
    flags {
      ...FlagsInitFields
    }
    preferredLocale
  }

  query ForceRedirect($pathname: String)
  @withOwner(owner: EnterpriseFoundations) {
    forceRedirect(pathname: $pathname)
  }

  # This minimal query is used simply to check whether or not the user
  # is authenticated: the request will succeed it they are, get a 401
  # error they are not.
  query CheckUserAuthentication @withOwner(owner: EnterpriseFoundations) {
    activeOrganization {
      id
    }
  }

  fragment UserPermissionFields on UserPermission {
    id
    permission
    objectId
    objectType
  }

  fragment PermissionDetails on PermissionItem {
    id
    permission
    object {
      __typename
      id
    }
  }

  fragment ActiveOrganizationSavedView on FinanceSavedView {
    id
    name
  }

  fragment ActiveOrganizationFund on Fund {
    id
    name
    nameSortable
    fundGroup
    fundCategory
    excludeAsSandbox
  }

  # Be careful about adding new fields to this fragment or child-fragments: this is a blocking query on every page load.
  fragment ActiveOrganizationFields on ActiveOrganization {
    # Simple things on the org model or context
    id
    name
    helpChannel
    demoOrg
    testOrg
    stagingOrg
    region
    watershedPlan
    watershedPlanLegacy
    canAccessFinance
    canAccessCorporate
    fiscalYearStartMonth
    currency
    country
    companyId
    domains
    logoUrl
    iconUrl
    sessionId
    sessionTimeoutMinutes

    # User and logged in user (one step more complicated)
    user {
      id
      createdAt
      name
      accessibleOrgs {
        id
        name
        companyId
      }
      email
      isWatershedEmployee
      isWatershedContractor
      isE2ETester
    }
    loginAsUser {
      id
      name
      isWatershedEmployee
      isWatershedContractor
      isE2ETester
    }

    # Used all over the place. Onboarding could use more ownership? :shrug:
    userOnboardingsCompleted

    userPermissionsV2 {
      # synthetic id to avoid GQL warnings
      id
      ...UserPermissionFields
    }

    # TODO: Move to separate query https://linear.app/watershed/issue/FIN-6659/split-out-finance-sidebar-query
    funds {
      id
      ...FundAllFields
    }
    financeSavedViews {
      id
      ...ActiveOrganizationSavedView
    }
  }

  fragment FlagsInitFields on FeatureFlag {
    id
    name
    description
    team
    featureTags
    activated
  }

  mutation ThrowError @withOwner(owner: EnterpriseFoundations) {
    throwError(input: { fooString: "abcd", password: "1234" }) {
      message
    }
  }
`;

function parseUserContextData(
  activeOrg: GQActiveOrganizationFieldsFragment,
  flags: FeatureFlagsMap,
  preferredLocale: string | null
): UserContextData {
  const user = activeOrg.user;

  // Is this a real customer session?
  const isCustomer =
    !activeOrg.demoOrg &&
    !activeOrg.testOrg &&
    !activeOrg.loginAsUser &&
    !user.isWatershedEmployee &&
    !user.isWatershedContractor &&
    !user.isE2ETester;

  // TODO (reorganize all this code in a sane way)
  datadogLogs.setLoggerGlobalContext({
    orgId: activeOrg.id,
    orgName: activeOrg.name,
    demoOrg: activeOrg.demoOrg,
    testOrg: activeOrg.testOrg,
    stagingOrg: activeOrg.stagingOrg,
    watershedPlanLegacy: activeOrg.watershedPlanLegacy,
    userId: user.id,
    userName: user.name,
    loginAsUserName: activeOrg.loginAsUser?.name,
    isCustomer,
  });

  const currencyCode = getCurrencyForOrg(activeOrg);

  return {
    orgId: activeOrg.id,
    orgName: activeOrg.name,
    orgHelpChannel: activeOrg.helpChannel,
    orgCountry: activeOrg.country,
    demoOrg: activeOrg.demoOrg,
    testOrg: activeOrg.testOrg,
    stagingOrg: activeOrg.stagingOrg,
    region: activeOrg.region,
    watershedPlan: activeOrg.watershedPlan,
    watershedPlanLegacy: activeOrg.watershedPlanLegacy,
    watershedPlanIncludesMeasurement: watershedPlanIncludesMeasurement({
      watershedPlan: activeOrg.watershedPlan,
      watershedPlanLegacy: activeOrg.watershedPlanLegacy,
    }),
    watershedPlanIncludesReductions: watershedPlanIncludesReductions({
      watershedPlan: activeOrg.watershedPlan,
      watershedPlanLegacy: activeOrg.watershedPlanLegacy,
      overrideFlag: flags.get(Flags.PricingFy25ReductionsModuleTemp),
    }),
    watershedPlanIncludesReporting:
      !!flags.get(Flags.PricingFy25ReportingModuleTemp) ||
      !!flags.get(Flags.PricingFy25CsrdReportBuilderModuleTemp),
    watershedPlanIncludesDatasetCadences:
      activeOrg.watershedPlanLegacy === GQWatershedPlanLegacy.Pro ||
      activeOrg.watershedPlanLegacy === GQWatershedPlanLegacy.NetZero,
    userId: user.id,
    createdAt: user.createdAt,
    accessibleOrgs: user.accessibleOrgs,
    userName: user.name,
    userEmail: user.email,
    userIsWatershedEmployee: user.isWatershedEmployee,
    userIsWatershedContractor: user.isWatershedContractor,
    userIsE2ETester: user.isE2ETester,
    permissions: activeOrg.userPermissionsV2,
    userOnboardingsCompleted: activeOrg.userOnboardingsCompleted,
    logoUrl: activeOrg.logoUrl,
    iconUrl: activeOrg.iconUrl,
    loginAsUser: activeOrg.loginAsUser,
    isCustomer,
    hasInitialized: true,
    funds: activeOrg.funds ?? [],
    financeSavedViews: activeOrg.financeSavedViews ?? [],
    orgFiscalYearStartMonth: activeOrg.fiscalYearStartMonth,
    canAccessCorporate: activeOrg.canAccessCorporate,
    canAccessFinance: activeOrg.canAccessFinance,
    currency: currencyCode,
    companyId: activeOrg.companyId,
    domains: activeOrg.domains,
    sessionId: activeOrg.sessionId,
    sessionTimeoutMinutes: activeOrg.sessionTimeoutMinutes,
    preferredLocale,
  };
}

export function useParsedUserContext(
  activeOrganization: GQActiveOrganizationFieldsFragment,
  flags: FeatureFlagsMap,
  preferredLocale: string | null
): UserContextProps {
  const [parsedContext, setParsedContext] = useState(() =>
    parseUserContextData(activeOrganization, flags, preferredLocale)
  );

  //  data in the context has changed (including overrides).
  // If it has, we'll generate a new context so the app re-renders itself correctly.
  const newParsedContextData = parseUserContextData(
    activeOrganization,
    flags,
    preferredLocale
  );
  const oldParsedContextData = omit(parsedContext, ['operations', 'overrides']);
  const shouldUpdateContext = !isEqual(
    newParsedContextData,
    oldParsedContextData
  );

  let updatedParsedContext: UserContextProps;
  if (shouldUpdateContext) {
    updatedParsedContext = newParsedContextData;
  } else {
    updatedParsedContext = parsedContext;
  }

  useEffect(() => {
    if (shouldUpdateContext) {
      setParsedContext(updatedParsedContext);
    }
  }, [shouldUpdateContext, updatedParsedContext]);

  return updatedParsedContext;
}

export function useUserIsWatershedEmployeeOrContractor() {
  const { userIsWatershedContractor, userIsWatershedEmployee } =
    useUserContext();
  return userIsWatershedContractor || userIsWatershedEmployee;
}

export const testingExports = {
  MockUserContextProvider,
};
