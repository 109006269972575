import { atomWithStorage } from 'jotai/utils';

/**
 * This atom is used to enable or disable the display of the admin bar at the
 * bottom of the page that shows up for employees. Should only be togglable by
 * employees. The toggle for it is available in the command palette. Turned on
 * by default, but it has no effect unless you're an admin user with access to
 * see data in the bar.
 */
export const adminBarVisibilityAtom = atomWithStorage(
  'adminBarVisibility',
  true,
  undefined,
  { getOnInit: true }
);
