import { useRef } from 'react';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@watershed/ui-core/components/Avatar';
import Logo from '@watershed/ui-core/components/Logo';
import CustomReactMarkdown from '@watershed/ui-core/components/CustomReactMarkdown';
import RelativeTimestamp from '@watershed/ui-core/components/RelativeTimestamp';
import { Message } from '../../../HelpPanel/utils/types';
import { CODE_FONT_FAMILY } from '@watershed/style/styleUtils';

interface MessageBubbleProps {
  message: Message & {
    conversationId?: string;
    messageId?: string;
  };
  userName: string;
  onFeedback?: (isPositive: boolean) => void;
}

// Styles
const getBackgroundColor = (
  theme: Theme,
  isUserMessage: boolean,
  complete?: boolean
) => {
  if (isUserMessage) {
    return `${theme.palette.cobalt05}80`;
  }

  if (!complete) {
    return `linear-gradient(to bottom, ${theme.palette.white}, transparent)`;
  }

  return theme.palette.white;
};

const getBorder = (
  theme: Theme,
  isUserMessage: boolean,
  complete?: boolean
) => {
  if (isUserMessage) {
    return `0.5px solid ${theme.palette.cobalt20}`;
  }

  if (!complete) {
    return '0';
  }

  return `0.5px solid ${theme.palette.grey30}`;
};

// Static style keys
type MessageBubbleStyleKeys = 'avatarContainer' | 'userAvatar' | 'botAvatar';

type MessageBubbleStyleFn = {
  [K in MessageBubbleStyleKeys]: SxProps<Theme>;
} & {
  messageBubble: (isUserMessage: boolean, complete?: boolean) => SxProps<Theme>;
  messageContent: (complete: boolean) => SxProps<Theme>;
};

const STYLES: MessageBubbleStyleFn = {
  messageBubble: (
    isUserMessage: boolean,
    complete?: boolean
  ): SxProps<Theme> => ({
    py: 1,
    px: 1.5,
    borderRadius: '8px',
    background: (theme) => getBackgroundColor(theme, isUserMessage, complete),
    border: (theme) => getBorder(theme, isUserMessage, complete),
    width: 'fit-content',
    minWidth: '256px',
    boxShadow: complete
      ? (theme) => `0px 1px 0px 0px ${theme.palette.grey70}10`
      : 'none',
    gap: 0.75,
    transition: 'background 0.5s ease-in-out',
  }),

  avatarContainer: {
    flexShrink: 0,
    alignItems: 'center',
  },

  userAvatar: {
    flexShrink: 0,
    marginTop: 0.5,
    marginBottom: 0.5,
  },

  botAvatar: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
  },

  messageContent: (_complete: boolean): SxProps<Theme> => ({
    transition: 'opacity 0.2s',
    alignItems: 'flex-end',
    position: 'relative',
  }),
};

export function MessageBubble({ message, userName }: MessageBubbleProps) {
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <Stack gap={1} sx={STYLES.messageBubble(message.isUser, message.complete)}>
      <Stack direction="row" gap={1} sx={STYLES.avatarContainer}>
        {message.isUser ? (
          <Avatar
            variant="person"
            size="small"
            name={userName}
            sx={STYLES.userAvatar}
          />
        ) : (
          <Box sx={STYLES.botAvatar}>
            <Logo size={16} />
          </Box>
        )}
        <Stack direction="row" gap={1} alignItems="center" width="100%">
          <Typography variant="body2">
            {message.isUser ? 'You' : 'Watershed'}
          </Typography>
          {message.timestamp && (
            <RelativeTimestamp
              timestamp={new Date(message.timestamp)}
              keepUpdated
              sentenceCase
              sx={{
                fontFamily: CODE_FONT_FAMILY,
                fontSize: 12,
                color: 'grey50',
                mt: '1px',
              }}
            />
          )}
        </Stack>
      </Stack>

      <Box ref={contentRef} sx={STYLES.messageContent(message.complete)}>
        <CustomReactMarkdown source={message.content} />
      </Box>
    </Stack>
  );
}
