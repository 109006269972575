import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const StatusMissingIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 12 12" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M6.976 10.905a5.02 5.02 0 0 1-1.952 0l.353-1.784a3.206 3.206 0 0 0 1.246 0l.353 1.784Zm-3.754-.747 1.011-1.511a3.21 3.21 0 0 1-.88-.88l-1.51 1.011a5.027 5.027 0 0 0 1.379 1.38ZM1.095 6.976l1.784-.353a3.206 3.206 0 0 1 0-1.246l-1.784-.353a5.024 5.024 0 0 0 0 1.952Zm.747-3.754 1.511 1.011a3.21 3.21 0 0 1 .88-.88l-1.011-1.51a5.027 5.027 0 0 0-1.38 1.379Zm3.182-2.127a5.024 5.024 0 0 1 1.952 0L6.623 2.88a3.206 3.206 0 0 0-1.246 0l-.353-1.784Zm3.754.747L7.767 3.353c.347.233.647.533.88.88l1.51-1.011a5.027 5.027 0 0 0-1.379-1.38Zm2.127 3.182-1.784.353a3.206 3.206 0 0 1 0 1.246l1.784.353a5.02 5.02 0 0 0 0-1.952Zm-.747 3.754L8.647 7.767a3.21 3.21 0 0 1-.88.88l1.011 1.51a5.026 5.026 0 0 0 1.38-1.379Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default StatusMissingIcon;
