/*
  This file is semi-automatically generated from
  cliq/taxonomy/scripts/generate_anzsic_rev2__ceda_5_weighted_mapping.py in the cliq repo
  Do not manually modify this file
 */

import type { WeightedBeaCodes } from './industryCodeUtils';

export const ANZSIC_CODES: {
  [key: string]: {
    label: string;
    BEA_CODES: WeightedBeaCodes;
  };
} = {
  ['0111']: {
    label: 'Nursery Production (Under Cover)',
    BEA_CODES: { '111400': 0.51598008, '111900': 0.48401992 },
  },
  ['0112']: {
    label: 'Nursery Production (Outdoors)',
    BEA_CODES: { '111400': 0.51598008, '111900': 0.48401992 },
  },
  ['0113']: { label: 'Turf Growing', BEA_CODES: { '111400': 1.0 } },
  ['0114']: {
    label: 'Floriculture Production (Under Cover)',
    BEA_CODES: {
      '111200': 0.28511205,
      '111400': 0.36886794,
      '111900': 0.34602,
    },
  },
  ['0115']: {
    label: 'Floriculture Production (Outdoors)',
    BEA_CODES: {
      '111200': 0.28511205,
      '111400': 0.36886794,
      '111900': 0.34602,
    },
  },
  ['0121']: {
    label: 'Mushroom Growing',
    BEA_CODES: {
      '111200': 0.28511205,
      '111400': 0.36886794,
      '111900': 0.34602,
    },
  },
  ['0122']: {
    label: 'Vegetable Growing (Under Cover)',
    BEA_CODES: {
      '1111A0': 0.27953014,
      '1111B0': 0.60537856,
      '111900': 0.11509131,
    },
  },
  ['0123']: {
    label: 'Vegetable Growing (Outdoors)',
    BEA_CODES: {
      '1111A0': 0.27953014,
      '1111B0': 0.60537856,
      '111900': 0.11509131,
    },
  },
  ['0131']: { label: 'Grape Growing', BEA_CODES: { '111300': 1.0 } },
  ['0132']: { label: 'Kiwifruit Growing', BEA_CODES: { '111300': 1.0 } },
  ['0133']: { label: 'Berry Fruit Growing', BEA_CODES: { '111300': 1.0 } },
  ['0134']: { label: 'Apple and Pear Growing', BEA_CODES: { '111300': 1.0 } },
  ['0135']: { label: 'Stone Fruit Growing', BEA_CODES: { '111300': 1.0 } },
  ['0136']: { label: 'Citrus Fruit Growing', BEA_CODES: { '111300': 1.0 } },
  ['0137']: { label: 'Olive Growing', BEA_CODES: { '111300': 1.0 } },
  ['0139']: {
    label: 'Other Fruit and Tree Nut Growing',
    BEA_CODES: { '111300': 0.57851446, '111400': 0.42148554 },
  },
  ['0141']: {
    label: 'Sheep Farming (Specialised)',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['0142']: {
    label: 'Beef Cattle Farming (Specialised)',
    BEA_CODES: { '112120': 0.3193225, '1121A0': 0.6806775 },
  },
  ['0143']: {
    label: 'Beef Cattle Feedlots (Specialised)',
    BEA_CODES: { '112120': 0.3193225, '1121A0': 0.6806775 },
  },
  ['0144']: {
    label: 'Sheep-Beef Cattle Farming',
    BEA_CODES: { '112120': 0.3193225, '1121A0': 0.6806775 },
  },
  ['0145']: {
    label: 'Grain-Sheep or Grain-Beef Cattle Farming',
    BEA_CODES: { '111900': 0.30461802, '112A00': 0.69538198 },
  },
  ['0146']: { label: 'Rice Growing', BEA_CODES: { '1111B0': 1.0 } },
  ['0149']: {
    label: 'Other Grain Growing',
    BEA_CODES: {
      '1111A0': 0.27953014,
      '1111B0': 0.60537856,
      '111900': 0.11509131,
    },
  },
  ['0151']: { label: 'Sugar Cane Growing', BEA_CODES: { '111900': 1.0 } },
  ['0152']: { label: 'Cotton Growing', BEA_CODES: { '111900': 1.0 } },
  ['0159']: {
    label: 'Other Crop Growing n.e.c.',
    BEA_CODES: {
      '1111A0': 0.27953014,
      '1111B0': 0.60537856,
      '111900': 0.11509131,
    },
  },
  ['0160']: {
    label: 'Dairy Cattle Farming',
    BEA_CODES: { '112120': 0.3193225, '1121A0': 0.6806775 },
  },
  ['0171']: { label: 'Poultry Farming (Meat)', BEA_CODES: { '112300': 1.0 } },
  ['0172']: { label: 'Poultry Farming (Eggs)', BEA_CODES: { '112300': 1.0 } },
  ['0180']: {
    label: 'Deer Farming',
    BEA_CODES: { '112300': 0.48335198, '112A00': 0.51664802 },
  },
  ['0191']: { label: 'Horse Farming', BEA_CODES: { '112A00': 1.0 } },
  ['0192']: { label: 'Pig Farming', BEA_CODES: { '112A00': 1.0 } },
  ['0193']: {
    label: 'Beekeeping',
    BEA_CODES: { '112300': 0.48335198, '112A00': 0.51664802 },
  },
  ['0199']: {
    label: 'Other Livestock Farming n.e.c.',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['0201']: {
    label: 'Offshore Longline and Rack Aquaculture',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['0202']: {
    label: 'Offshore Caged Aquaculture',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['0203']: { label: 'Onshore Aquaculture', BEA_CODES: { '112A00': 1.0 } },
  ['0301']: { label: 'Forestry', BEA_CODES: { '113000': 1.0 } },
  ['0302']: { label: 'Logging', BEA_CODES: { '113000': 1.0 } },
  ['0411']: {
    label: 'Rock Lobster and Crab Potting',
    BEA_CODES: { '114000': 0.46985434, '311700': 0.53014566 },
  },
  ['0412']: {
    label: 'Prawn Fishing',
    BEA_CODES: { '114000': 0.46985434, '311700': 0.53014566 },
  },
  ['0413']: {
    label: 'Line Fishing',
    BEA_CODES: { '114000': 0.46985434, '311700': 0.53014566 },
  },
  ['0414']: {
    label: 'Fish Trawling, Seining and Netting',
    BEA_CODES: { '114000': 0.46985434, '311700': 0.53014566 },
  },
  ['0419']: {
    label: 'Other Fishing',
    BEA_CODES: { '114000': 0.46985434, '311700': 0.53014566 },
  },
  ['0420']: { label: 'Hunting and Trapping', BEA_CODES: { '114000': 1.0 } },
  ['0510']: {
    label: 'Forestry Support Services',
    BEA_CODES: { '113000': 1.0 },
  },
  ['0521']: { label: 'Cotton Ginning', BEA_CODES: { '115000': 1.0 } },
  ['0522']: { label: 'Shearing Services', BEA_CODES: { '115000': 1.0 } },
  ['0529']: {
    label: 'Other Agriculture and Fishing Support Services',
    BEA_CODES: { '115000': 1.0 },
  },
  ['0600']: {
    label: 'Coal Mining',
    BEA_CODES: { '211000': 0.9257065, '212100': 0.0742935 },
  },
  ['0700']: { label: 'Oil and Gas Extraction', BEA_CODES: { '211000': 1.0 } },
  ['0801']: { label: 'Iron Ore Mining', BEA_CODES: { '2122A0': 1.0 } },
  ['0802']: {
    label: 'Bauxite Mining',
    BEA_CODES: { '212230': 0.33940503, '2122A0': 0.66059497 },
  },
  ['0803']: {
    label: 'Copper Ore Mining',
    BEA_CODES: { '212230': 0.33940503, '2122A0': 0.66059497 },
  },
  ['0804']: {
    label: 'Gold Ore Mining',
    BEA_CODES: { '212230': 0.33940503, '2122A0': 0.66059497 },
  },
  ['0805']: {
    label: 'Mineral Sand Mining',
    BEA_CODES: { '212230': 0.33940503, '2122A0': 0.66059497 },
  },
  ['0806']: {
    label: 'Nickel Ore Mining',
    BEA_CODES: { '212230': 0.33940503, '2122A0': 0.66059497 },
  },
  ['0807']: {
    label: 'Silver-Lead-Zinc Ore Mining',
    BEA_CODES: { '212230': 0.33940503, '2122A0': 0.66059497 },
  },
  ['0809']: { label: 'Other Metal Ore Mining', BEA_CODES: { '2122A0': 1.0 } },
  ['0911']: {
    label: 'Gravel and Sand Quarrying',
    BEA_CODES: { '212310': 0.47046995, '2123A0': 0.52953005 },
  },
  ['0919']: {
    label: 'Other Construction Material Mining',
    BEA_CODES: { '212310': 0.47046995, '2123A0': 0.52953005 },
  },
  ['0990']: {
    label: 'Other Non-Metallic Mineral Mining and Quarrying',
    BEA_CODES: { '212310': 0.47046995, '2123A0': 0.52953005 },
  },
  ['1011']: {
    label: 'Petroleum Exploration',
    BEA_CODES: {
      '213111': 0.12123704,
      '21311A': 0.32370667,
      '48A000': 0.55505629,
    },
  },
  ['1012']: { label: 'Mineral Exploration', BEA_CODES: { '21311A': 1.0 } },
  ['1090']: {
    label: 'Other Mining Support Services',
    BEA_CODES: {
      '213111': 0.12123704,
      '21311A': 0.32370667,
      '48A000': 0.55505629,
    },
  },
  ['1111']: {
    label: 'Meat Processing',
    BEA_CODES: {
      '311410': 0.12674167,
      '311615': 0.24178932,
      '31161A': 0.58738349,
      '311700': 0.04408551,
    },
  },
  ['1112']: {
    label: 'Poultry Processing',
    BEA_CODES: {
      '311410': 0.12674167,
      '311615': 0.24178932,
      '31161A': 0.58738349,
      '311700': 0.04408551,
    },
  },
  ['1113']: {
    label: 'Cured Meat and Smallgoods Manufacturing',
    BEA_CODES: {
      '311410': 0.12674167,
      '311615': 0.24178932,
      '31161A': 0.58738349,
      '311700': 0.04408551,
    },
  },
  ['1120']: {
    label: 'Seafood Processing',
    BEA_CODES: { '114000': 0.46985434, '311700': 0.53014566 },
  },
  ['1131']: {
    label: 'Milk and Cream Processing',
    BEA_CODES: {
      '311513': 0.36558961,
      '311514': 0.19639071,
      '31151A': 0.36737765,
      '311520': 0.07064203,
    },
  },
  ['1132']: {
    label: 'Ice Cream Manufacturing',
    BEA_CODES: {
      '311513': 0.36558961,
      '311514': 0.19639071,
      '31151A': 0.36737765,
      '311520': 0.07064203,
    },
  },
  ['1133']: {
    label: 'Cheese and Other Dairy Product Manufacturing',
    BEA_CODES: {
      '311513': 0.36558961,
      '311514': 0.19639071,
      '31151A': 0.36737765,
      '311520': 0.07064203,
    },
  },
  ['1140']: {
    label: 'Fruit and Vegetable Processing',
    BEA_CODES: {
      '311210': 0.11335899,
      '311410': 0.17580176,
      '311420': 0.22114304,
      '311910': 0.18724719,
      '311930': 0.05546541,
      '311940': 0.1121478,
      '311990': 0.13483582,
    },
  },
  ['1150']: {
    label: 'Oil and Fat Manufacturing',
    BEA_CODES: {
      '311221': 0.18900323,
      '311224': 0.45115125,
      '311225': 0.2308858,
      '311700': 0.12895972,
    },
  },
  ['1161']: {
    label: 'Grain Mill Product Manufacturing',
    BEA_CODES: {
      '311210': 0.35184819,
      '311230': 0.17341488,
      '3118A0': 0.47473694,
    },
  },
  ['1162']: {
    label: 'Cereal, Pasta and Baking Mix Manufacturing',
    BEA_CODES: {
      '311210': 0.35184819,
      '311230': 0.17341488,
      '3118A0': 0.47473694,
    },
  },
  ['1171']: {
    label: 'Bread Manufacturing (Factory based)',
    BEA_CODES: {
      '311210': 0.35184819,
      '311230': 0.17341488,
      '3118A0': 0.47473694,
    },
  },
  ['1172']: {
    label: 'Cake and Pastry Manufacturing (Factory based)',
    BEA_CODES: {
      '311410': 0.22399102,
      '311810': 0.342558,
      '3118A0': 0.19487721,
      '311910': 0.23857377,
    },
  },
  ['1173']: {
    label: 'Biscuit Manufacturing (Factory based)',
    BEA_CODES: {
      '311410': 0.22399102,
      '311810': 0.342558,
      '3118A0': 0.19487721,
      '311910': 0.23857377,
    },
  },
  ['1174']: {
    label: 'Bakery Product Manufacturing (Non-factory based)',
    BEA_CODES: {
      '311410': 0.22399102,
      '311810': 0.342558,
      '3118A0': 0.19487721,
      '311910': 0.23857377,
    },
  },
  ['1181']: {
    label: 'Sugar Manufacturing',
    BEA_CODES: { '111900': 0.37396013, '311300': 0.62603987 },
  },
  ['1182']: {
    label: 'Confectionery Manufacturing',
    BEA_CODES: { '311300': 1.0 },
  },
  ['1191']: {
    label: 'Potato, Corn and Other Crisp Manufacturing',
    BEA_CODES: {
      '311210': 0.11335899,
      '311410': 0.17580176,
      '311420': 0.22114304,
      '311910': 0.18724719,
      '311930': 0.05546541,
      '311940': 0.1121478,
      '311990': 0.13483582,
    },
  },
  ['1192']: {
    label: 'Prepared Animal and Bird Feed Manufacturing',
    BEA_CODES: { '311111': 0.33825338, '311119': 0.66174662 },
  },
  ['1199']: {
    label: 'Other Food Product Manufacturing n.e.c.',
    BEA_CODES: {
      '311210': 0.11335899,
      '311410': 0.17580176,
      '311420': 0.22114304,
      '311910': 0.18724719,
      '311930': 0.05546541,
      '311940': 0.1121478,
      '311990': 0.13483582,
    },
  },
  ['1211']: {
    label: 'Soft Drink, Cordial and Syrup Manufacturing',
    BEA_CODES: {
      '311210': 0.11335899,
      '311410': 0.17580176,
      '311420': 0.22114304,
      '311910': 0.18724719,
      '311930': 0.05546541,
      '311940': 0.1121478,
      '311990': 0.13483582,
    },
  },
  ['1212']: {
    label: 'Beer Manufacturing',
    BEA_CODES: { '311210': 0.38649701, '312120': 0.61350299 },
  },
  ['1213']: {
    label: 'Spirit Manufacturing',
    BEA_CODES: { '312130': 0.45389594, '312140': 0.54610406 },
  },
  ['1214']: {
    label: 'Wine and Other Alcoholic Beverage Manufacturing',
    BEA_CODES: {
      '311410': 0.08198764,
      '311420': 0.10313318,
      '311513': 0.10112511,
      '311514': 0.0543233,
      '31151A': 0.1016197,
      '31161A': 0.37997119,
      '311700': 0.02851838,
      '311920': 0.03413719,
      '311940': 0.05230171,
      '311990': 0.06288259,
    },
  },
  ['1220']: {
    label: 'Cigarette and Tobacco Product Manufacturing',
    BEA_CODES: { '312200': 1.0 },
  },
  ['1311']: {
    label: 'Wool Scouring',
    BEA_CODES: {
      '313100': 0.19863352,
      '313300': 0.37092526,
      '314900': 0.43044122,
    },
  },
  ['1312']: {
    label: 'Natural Textile Manufacturing',
    BEA_CODES: {
      '313100': 0.19863352,
      '313300': 0.37092526,
      '314900': 0.43044122,
    },
  },
  ['1313']: {
    label: 'Synthetic Textile Manufacturing',
    BEA_CODES: {
      '313100': 0.19863352,
      '313300': 0.37092526,
      '314900': 0.43044122,
    },
  },
  ['1320']: {
    label: 'Leather Tanning, Fur Dressing and Leather Product Manufacturing',
    BEA_CODES: { '313200': 0.44318606, '315000': 0.55681394 },
  },
  ['1331']: {
    label: 'Textile Floor Covering Manufacturing',
    BEA_CODES: { '314110': 0.46487233, '314900': 0.53512767 },
  },
  ['1332']: {
    label: 'Rope, Cordage and Twine Manufacturing',
    BEA_CODES: { '314900': 1.0 },
  },
  ['1333']: {
    label: 'Cut and Sewn Textile Product Manufacturing',
    BEA_CODES: {
      '314120': 0.07525909,
      '314900': 0.21117779,
      '336360': 0.51853128,
      '337900': 0.19503184,
    },
  },
  ['1334']: {
    label: 'Textile Finishing and Other Textile Product Manufacturing',
    BEA_CODES: {
      '313300': 0.0708864,
      '314900': 0.08226032,
      '323110': 0.4896128,
      '561900': 0.35724048,
    },
  },
  ['1340']: {
    label: 'Knitted Product Manufacturing',
    BEA_CODES: { '313200': 1.0 },
  },
  ['1351']: {
    label: 'Clothing Manufacturing',
    BEA_CODES: {
      '313200': 0.09395987,
      '314900': 0.08322456,
      '315000': 0.11805012,
      '322220': 0.15698127,
      '335120': 0.09425812,
      '336360': 0.20435169,
      '339940': 0.02696231,
      '339990': 0.22221206,
    },
  },
  ['1352']: { label: 'Footwear Manufacturing', BEA_CODES: { '316000': 1.0 } },
  ['1411']: {
    label: 'Log Sawmilling',
    BEA_CODES: {
      '113000': 0.19108863,
      '321100': 0.33718851,
      '321910': 0.22501798,
      '3219A0': 0.24670489,
    },
  },
  ['1412']: {
    label: 'Wood Chipping',
    BEA_CODES: {
      '113000': 0.19108863,
      '321100': 0.33718851,
      '321910': 0.22501798,
      '3219A0': 0.24670489,
    },
  },
  ['1413']: {
    label: 'Timber Resawing and Dressing',
    BEA_CODES: {
      '113000': 0.19108863,
      '321100': 0.33718851,
      '321910': 0.22501798,
      '3219A0': 0.24670489,
    },
  },
  ['1491']: {
    label: 'Prefabricated Wooden Building Manufacturing',
    BEA_CODES: {
      '321100': 0.30982873,
      '321200': 0.20215478,
      '321910': 0.20675981,
      '3219A0': 0.22668703,
      '337215': 0.05456965,
    },
  },
  ['1492']: {
    label: 'Wooden Structural Fitting and Component Manufacturing',
    BEA_CODES: {
      '321100': 0.30982873,
      '321200': 0.20215478,
      '321910': 0.20675981,
      '3219A0': 0.22668703,
      '337215': 0.05456965,
    },
  },
  ['1493']: {
    label: 'Veneer and Plywood Manufacturing',
    BEA_CODES: { '321200': 1.0 },
  },
  ['1494']: {
    label: 'Reconstituted Wood Product Manufacturing',
    BEA_CODES: { '321200': 1.0 },
  },
  ['1499']: {
    label: 'Other Wood Product Manufacturing n.e.c.',
    BEA_CODES: { '3219A0': 1.0 },
  },
  ['1510']: {
    label: 'Pulp, Paper and Paperboard Manufacturing',
    BEA_CODES: {
      '322110': 0.06795457,
      '322120': 0.43039998,
      '322130': 0.25214769,
      '322220': 0.17342028,
      '322299': 0.04629168,
      '339940': 0.02978579,
    },
  },
  ['1521']: {
    label: 'Corrugated Paperboard and Paperboard Container Manufacturing',
    BEA_CODES: {
      '322120': 0.31479455,
      '322130': 0.18442082,
      '322210': 0.3400873,
      '322220': 0.12683959,
      '322299': 0.03385774,
    },
  },
  ['1522']: {
    label: 'Paper Bag Manufacturing',
    BEA_CODES: {
      '322120': 0.31479455,
      '322130': 0.18442082,
      '322210': 0.3400873,
      '322220': 0.12683959,
      '322299': 0.03385774,
    },
  },
  ['1523']: {
    label: 'Paper Stationery Manufacturing',
    BEA_CODES: {
      '322120': 0.17525146,
      '322130': 0.1026702,
      '322210': 0.18933237,
      '322220': 0.07061375,
      '322230': 0.02626037,
      '322291': 0.03810948,
      '322299': 0.01884918,
      '323110': 0.2228206,
      '33299A': 0.05613654,
      '339990': 0.09995605,
    },
  },
  ['1524']: {
    label: 'Sanitary Paper Product Manufacturing',
    BEA_CODES: {
      '322120': 0.17525146,
      '322130': 0.1026702,
      '322210': 0.18933237,
      '322220': 0.07061375,
      '322230': 0.02626037,
      '322291': 0.03810948,
      '322299': 0.01884918,
      '323110': 0.2228206,
      '33299A': 0.05613654,
      '339990': 0.09995605,
    },
  },
  ['1529']: {
    label: 'Other Converted Paper Product Manufacturing',
    BEA_CODES: {
      '322120': 0.17525146,
      '322130': 0.1026702,
      '322210': 0.18933237,
      '322220': 0.07061375,
      '322230': 0.02626037,
      '322291': 0.03810948,
      '322299': 0.01884918,
      '323110': 0.2228206,
      '33299A': 0.05613654,
      '339990': 0.09995605,
    },
  },
  ['1611']: {
    label: 'Printing',
    BEA_CODES: {
      '313300': 0.0708864,
      '314900': 0.08226032,
      '323110': 0.4896128,
      '561900': 0.35724048,
    },
  },
  ['1612']: {
    label: 'Printing Support Services',
    BEA_CODES: { '323120': 1.0 },
  },
  ['1620']: {
    label: 'Reproduction of Recorded Media',
    BEA_CODES: { '334610': 1.0 },
  },
  ['1701']: {
    label: 'Petroleum Refining and Petroleum Fuel Manufacturing',
    BEA_CODES: {
      '324110': 0.87346457,
      '324190': 0.04042722,
      '325110': 0.08610821,
    },
  },
  ['1709']: {
    label: 'Other Petroleum and Coal Product Manufacturing',
    BEA_CODES: { '324190': 0.27178284, '325190': 0.72821716 },
  },
  ['1811']: {
    label: 'Industrial Gas Manufacturing',
    BEA_CODES: { '324190': 0.27178284, '325190': 0.72821716 },
  },
  ['1812']: {
    label: 'Basic Organic Chemical Manufacturing',
    BEA_CODES: { '324190': 0.27178284, '325190': 0.72821716 },
  },
  ['1813']: {
    label: 'Basic Inorganic Chemical Manufacturing',
    BEA_CODES: {
      '312140': 0.04512505,
      '325110': 0.24880877,
      '325120': 0.01731627,
      '325130': 0.02111241,
      '325180': 0.09011759,
      '325190': 0.31299248,
      '325310': 0.06327999,
      '325610': 0.1090291,
      '3259A0': 0.09221834,
    },
  },
  ['1821']: {
    label: 'Synthetic Resin and Synthetic Rubber Manufacturing',
    BEA_CODES: {
      '325211': 0.58371099,
      '3252A0': 0.15586681,
      '3259A0': 0.26042221,
    },
  },
  ['1829']: {
    label: 'Other Basic Polymer Manufacturing',
    BEA_CODES: {
      '325211': 0.58371099,
      '3252A0': 0.15586681,
      '3259A0': 0.26042221,
    },
  },
  ['1831']: {
    label: 'Fertiliser Manufacturing',
    BEA_CODES: { '325180': 0.58747727, '325310': 0.41252273 },
  },
  ['1832']: {
    label: 'Pesticide Manufacturing',
    BEA_CODES: { '325320': 0.24627456, '325610': 0.75372544 },
  },
  ['1841']: {
    label: 'Human Pharmaceutical and Medicinal Product Manufacturing',
    BEA_CODES: {
      '325190': 0.39757993,
      '325411': 0.03696199,
      '325412': 0.3652522,
      '325413': 0.03576859,
      '325414': 0.07326973,
      '339113': 0.09116755,
    },
  },
  ['1842']: {
    label: 'Veterinary Pharmaceutical and Medicinal Product Manufacturing',
    BEA_CODES: {
      '325190': 0.39757993,
      '325411': 0.03696199,
      '325412': 0.3652522,
      '325413': 0.03576859,
      '325414': 0.07326973,
      '339113': 0.09116755,
    },
  },
  ['1851']: {
    label: 'Cleaning Compound Manufacturing',
    BEA_CODES: {
      '325610': 0.29817559,
      '325620': 0.199738,
      '3259A0': 0.2522011,
      '332999': 0.09333496,
      '339990': 0.15655035,
    },
  },
  ['1852']: {
    label: 'Cosmetic and Toiletry Preparation Manufacturing',
    BEA_CODES: {
      '325610': 0.29817559,
      '325620': 0.199738,
      '3259A0': 0.2522011,
      '332999': 0.09333496,
      '339990': 0.15655035,
    },
  },
  ['1891']: {
    label: 'Photographic Chemical Product Manufacturing',
    BEA_CODES: {
      '312140': 0.04512505,
      '325110': 0.24880877,
      '325120': 0.01731627,
      '325130': 0.02111241,
      '325180': 0.09011759,
      '325190': 0.31299248,
      '325310': 0.06327999,
      '325610': 0.1090291,
      '3259A0': 0.09221834,
    },
  },
  ['1892']: {
    label: 'Explosive Manufacturing',
    BEA_CODES: {
      '312140': 0.04512505,
      '325110': 0.24880877,
      '325120': 0.01731627,
      '325130': 0.02111241,
      '325180': 0.09011759,
      '325190': 0.31299248,
      '325310': 0.06327999,
      '325610': 0.1090291,
      '3259A0': 0.09221834,
    },
  },
  ['1899']: {
    label: 'Other Basic Chemical Product Manufacturing n.e.c.',
    BEA_CODES: {
      '312140': 0.04512505,
      '325110': 0.24880877,
      '325120': 0.01731627,
      '325130': 0.02111241,
      '325180': 0.09011759,
      '325190': 0.31299248,
      '325310': 0.06327999,
      '325610': 0.1090291,
      '3259A0': 0.09221834,
    },
  },
  ['1911']: {
    label: 'Polymer Film and Sheet Packaging Material Manufacturing',
    BEA_CODES: {
      '3219A0': 0.08810239,
      '322220': 0.0622973,
      '3252A0': 0.08502714,
      '326110': 0.14947717,
      '326120': 0.05631409,
      '326150': 0.03404071,
      '326160': 0.04575983,
      '326190': 0.3097015,
      '336360': 0.08109603,
      '339990': 0.08818384,
    },
  },
  ['1912']: {
    label: 'Rigid and Semi-Rigid Polymer Product Manufacturing',
    BEA_CODES: {
      '3219A0': 0.08810239,
      '322220': 0.0622973,
      '3252A0': 0.08502714,
      '326110': 0.14947717,
      '326120': 0.05631409,
      '326150': 0.03404071,
      '326160': 0.04575983,
      '326190': 0.3097015,
      '336360': 0.08109603,
      '339990': 0.08818384,
    },
  },
  ['1913']: {
    label: 'Polymer Foam Product Manufacturing',
    BEA_CODES: {
      '3219A0': 0.08810239,
      '322220': 0.0622973,
      '3252A0': 0.08502714,
      '326110': 0.14947717,
      '326120': 0.05631409,
      '326150': 0.03404071,
      '326160': 0.04575983,
      '326190': 0.3097015,
      '336360': 0.08109603,
      '339990': 0.08818384,
    },
  },
  ['1914']: { label: 'Tyre Manufacturing', BEA_CODES: { '326210': 1.0 } },
  ['1915']: {
    label: 'Adhesive Manufacturing',
    BEA_CODES: {
      '325190': 0.51939658,
      '325413': 0.04672793,
      '325520': 0.04656569,
      '325610': 0.18092875,
      '3259A0': 0.15303208,
      '33299A': 0.05334898,
    },
  },
  ['1916']: {
    label: 'Paint and Coatings Manufacturing',
    BEA_CODES: {
      '312140': 0.04512505,
      '325110': 0.24880877,
      '325120': 0.01731627,
      '325130': 0.02111241,
      '325180': 0.09011759,
      '325190': 0.31299248,
      '325310': 0.06327999,
      '325610': 0.1090291,
      '3259A0': 0.09221834,
    },
  },
  ['1919']: {
    label: 'Other Polymer Product Manufacturing',
    BEA_CODES: {
      '314900': 0.17106794,
      '315000': 0.24265182,
      '316000': 0.12952373,
      '339990': 0.45675651,
    },
  },
  ['1920']: {
    label: 'Natural Rubber Product Manufacturing',
    BEA_CODES: {
      '313300': 0.07413985,
      '315000': 0.12203772,
      '326210': 0.19165408,
      '326220': 0.05070638,
      '326290': 0.16529102,
      '337900': 0.07945778,
      '339920': 0.08699503,
      '339990': 0.22971814,
    },
  },
  ['2010']: {
    label: 'Glass and Glass Product Manufacturing',
    BEA_CODES: { '327200': 0.82517634, '327993': 0.17482366 },
  },
  ['2021']: { label: 'Clay Brick Manufacturing', BEA_CODES: { '327100': 1.0 } },
  ['2029']: {
    label: 'Other Ceramic Product Manufacturing',
    BEA_CODES: { '327100': 1.0 },
  },
  ['2031']: {
    label: 'Cement and Lime Manufacturing',
    BEA_CODES: { '327310': 0.49096074, '327400': 0.50903926 },
  },
  ['2032']: {
    label: 'Plaster Product Manufacturing',
    BEA_CODES: { '327310': 0.49096074, '327400': 0.50903926 },
  },
  ['2033']: {
    label: 'Ready-Mixed Concrete Manufacturing',
    BEA_CODES: {
      '327100': 0.14086953,
      '327320': 0.39304533,
      '327330': 0.10538572,
      '327390': 0.16597265,
      '327400': 0.11669397,
      '327999': 0.0780328,
    },
  },
  ['2034']: {
    label: 'Concrete Product Manufacturing',
    BEA_CODES: {
      '327100': 0.14086953,
      '327320': 0.39304533,
      '327330': 0.10538572,
      '327390': 0.16597265,
      '327400': 0.11669397,
      '327999': 0.0780328,
    },
  },
  ['2090']: {
    label: 'Other Non-Metallic Mineral Product Manufacturing',
    BEA_CODES: {
      '322120': 0.17525146,
      '322130': 0.1026702,
      '322210': 0.18933237,
      '322220': 0.07061375,
      '322230': 0.02626037,
      '322291': 0.03810948,
      '322299': 0.01884918,
      '323110': 0.2228206,
      '33299A': 0.05613654,
      '339990': 0.09995605,
    },
  },
  ['2110']: {
    label: 'Iron Smelting and Steel Manufacturing',
    BEA_CODES: {
      '331110': 0.76836049,
      '331200': 0.05766292,
      '33291A': 0.13235596,
      '332996': 0.04162063,
    },
  },
  ['2121']: {
    label: 'Iron and Steel Casting',
    BEA_CODES: {
      '331110': 0.76836049,
      '331200': 0.05766292,
      '33291A': 0.13235596,
      '332996': 0.04162063,
    },
  },
  ['2122']: {
    label: 'Steel Pipe and Tube Manufacturing',
    BEA_CODES: {
      '331110': 0.76836049,
      '331200': 0.05766292,
      '33291A': 0.13235596,
      '332996': 0.04162063,
    },
  },
  ['2131']: {
    label: 'Alumina Production',
    BEA_CODES: {
      '322220': 0.09306067,
      '331313': 0.23716639,
      '331410': 0.16955364,
      '331420': 0.1391628,
      '331490': 0.08102518,
      '332913': 0.01756678,
      '33291A': 0.13992606,
      '332996': 0.04400112,
      '332999': 0.07853736,
    },
  },
  ['2132']: {
    label: 'Aluminium Smelting',
    BEA_CODES: {
      '322220': 0.09306067,
      '331313': 0.23716639,
      '331410': 0.16955364,
      '331420': 0.1391628,
      '331490': 0.08102518,
      '332913': 0.01756678,
      '33291A': 0.13992606,
      '332996': 0.04400112,
      '332999': 0.07853736,
    },
  },
  ['2133']: {
    label: 'Copper, Silver, Lead and Zinc Smelting and Refining',
    BEA_CODES: {
      '322220': 0.09306067,
      '331313': 0.23716639,
      '331410': 0.16955364,
      '331420': 0.1391628,
      '331490': 0.08102518,
      '332913': 0.01756678,
      '33291A': 0.13992606,
      '332996': 0.04400112,
      '332999': 0.07853736,
    },
  },
  ['2139']: {
    label: 'Other Basic Non-Ferrous Metal Manufacturing',
    BEA_CODES: {
      '322220': 0.09306067,
      '331313': 0.23716639,
      '331410': 0.16955364,
      '331420': 0.1391628,
      '331490': 0.08102518,
      '332913': 0.01756678,
      '33291A': 0.13992606,
      '332996': 0.04400112,
      '332999': 0.07853736,
    },
  },
  ['2141']: {
    label: 'Non-Ferrous Metal Casting',
    BEA_CODES: { '331520': 1.0 },
  },
  ['2142']: {
    label: 'Aluminium Rolling, Drawing, Extruding',
    BEA_CODES: {
      '322220': 0.09306067,
      '331313': 0.23716639,
      '331410': 0.16955364,
      '331420': 0.1391628,
      '331490': 0.08102518,
      '332913': 0.01756678,
      '33291A': 0.13992606,
      '332996': 0.04400112,
      '332999': 0.07853736,
    },
  },
  ['2149']: {
    label: 'Other Basic Non-Ferrous Metal Product Manufacturing',
    BEA_CODES: {
      '322220': 0.09306067,
      '331313': 0.23716639,
      '331410': 0.16955364,
      '331420': 0.1391628,
      '331490': 0.08102518,
      '332913': 0.01756678,
      '33291A': 0.13992606,
      '332996': 0.04400112,
      '332999': 0.07853736,
    },
  },
  ['2210']: {
    label: 'Iron and Steel Forging',
    BEA_CODES: {
      '332114': 0.13061243,
      '332119': 0.18258404,
      '33211A': 0.24140573,
      '336370': 0.4453978,
    },
  },
  ['2221']: {
    label: 'Structural Steel Fabricating',
    BEA_CODES: {
      '332310': 0.48011956,
      '332320': 0.44598633,
      '337215': 0.07389411,
    },
  },
  ['2222']: {
    label: 'Prefabricated Metal Building Manufacturing',
    BEA_CODES: {
      '332310': 0.48011956,
      '332320': 0.44598633,
      '337215': 0.07389411,
    },
  },
  ['2223']: {
    label: 'Architectural Aluminium Product Manufacturing',
    BEA_CODES: {
      '332310': 0.48011956,
      '332320': 0.44598633,
      '337215': 0.07389411,
    },
  },
  ['2224']: {
    label: 'Metal Roof and Guttering Manufacturing (except Aluminium)',
    BEA_CODES: {
      '332310': 0.48011956,
      '332320': 0.44598633,
      '337215': 0.07389411,
    },
  },
  ['2229']: {
    label: 'Other Structural Metal Product Manufacturing',
    BEA_CODES: {
      '332310': 0.48011956,
      '332320': 0.44598633,
      '337215': 0.07389411,
    },
  },
  ['2231']: {
    label: 'Boiler, Tank and Other Heavy Gauge Metal Container Manufacturing',
    BEA_CODES: {
      '332310': 0.54512676,
      '332420': 0.12962363,
      '332430': 0.26130364,
      '333414': 0.06394597,
    },
  },
  ['2239']: {
    label: 'Other Metal Container Manufacturing',
    BEA_CODES: {
      '322220': 0.06126943,
      '323110': 0.19333475,
      '331313': 0.156146,
      '331420': 0.09162223,
      '331490': 0.05334549,
      '332320': 0.12589215,
      '332430': 0.06496425,
      '332720': 0.09845279,
      '33399A': 0.06824404,
      '339990': 0.08672887,
    },
  },
  ['2240']: {
    label:
      'Sheet Metal Product Manufacturing (except Metal Structural and Container Products)',
    BEA_CODES: {
      '332310': 0.48011956,
      '332320': 0.44598633,
      '337215': 0.07389411,
    },
  },
  ['2291']: {
    label: 'Spring and Wire Product Manufacturing',
    BEA_CODES: {
      '322220': 0.06126943,
      '323110': 0.19333475,
      '331313': 0.156146,
      '331420': 0.09162223,
      '331490': 0.05334549,
      '332320': 0.12589215,
      '332430': 0.06496425,
      '332720': 0.09845279,
      '33399A': 0.06824404,
      '339990': 0.08672887,
    },
  },
  ['2292']: {
    label: 'Nut, Bolt, Screw and Rivet Manufacturing',
    BEA_CODES: {
      '322220': 0.06126943,
      '323110': 0.19333475,
      '331313': 0.156146,
      '331420': 0.09162223,
      '331490': 0.05334549,
      '332320': 0.12589215,
      '332430': 0.06496425,
      '332720': 0.09845279,
      '33399A': 0.06824404,
      '339990': 0.08672887,
    },
  },
  ['2293']: {
    label: 'Metal Coating and Finishing',
    BEA_CODES: {
      '332710': 0.51131084,
      '332800': 0.35004052,
      '339910': 0.13864864,
    },
  },
  ['2299']: {
    label: 'Other Fabricated Metal Product Manufacturing n.e.c.',
    BEA_CODES: {
      '332310': 0.48011956,
      '332320': 0.44598633,
      '337215': 0.07389411,
    },
  },
  ['2311']: {
    label: 'Motor Vehicle Manufacturing',
    BEA_CODES: {
      '333618': 0.07716181,
      '336111': 0.16002822,
      '336112': 0.50224387,
      '336120': 0.09958664,
      '336211': 0.02017412,
      '336213': 0.00700843,
      '336310': 0.07905186,
      '336500': 0.03821237,
      '336999': 0.0165327,
    },
  },
  ['2312']: {
    label: 'Motor Vehicle Body and Trailer Manufacturing',
    BEA_CODES: {
      '326190': 0.26079721,
      '332420': 0.02759295,
      '332430': 0.05562364,
      '336211': 0.02145233,
      '336212': 0.02422285,
      '336214': 0.0306383,
      '811100': 0.57967272,
    },
  },
  ['2313']: {
    label: 'Automotive Electrical Component Manufacturing',
    BEA_CODES: { '335930': 1.0 },
  },
  ['2319']: {
    label: 'Other Motor Vehicle Parts Manufacturing',
    BEA_CODES: {
      '333611': 0.23612279,
      '333618': 0.37731748,
      '336310': 0.38655973,
    },
  },
  ['2391']: {
    label: 'Shipbuilding and Repair Services',
    BEA_CODES: {
      '333611': 0.23612279,
      '333618': 0.37731748,
      '336310': 0.38655973,
    },
  },
  ['2392']: {
    label: 'Boatbuilding and Repair Services',
    BEA_CODES: {
      '333611': 0.23612279,
      '333618': 0.37731748,
      '336310': 0.38655973,
    },
  },
  ['2393']: {
    label: 'Railway Rolling Stock Manufacturing and Repair Services',
    BEA_CODES: {
      '333611': 0.23612279,
      '333618': 0.37731748,
      '336310': 0.38655973,
    },
  },
  ['2394']: {
    label: 'Aircraft Manufacturing and Repair Services',
    BEA_CODES: {
      '33399A': 0.13435165,
      '334220': 0.18170453,
      '334290': 0.03006284,
      '334511': 0.28984944,
      '334512': 0.01647644,
      '334513': 0.06642661,
      '334514': 0.04194696,
      '334515': 0.07883158,
      '334516': 0.0867268,
      '33451A': 0.07362314,
    },
  },
  ['2399']: {
    label: 'Other Transport Equipment Manufacturing n.e.c.',
    BEA_CODES: {
      '333611': 0.23612279,
      '333618': 0.37731748,
      '336310': 0.38655973,
    },
  },
  ['2411']: {
    label: 'Photographic, Optical and Ophthalmic Equipment Manufacturing',
    BEA_CODES: {
      '334111': 0.29131751,
      '334112': 0.30540103,
      '334118': 0.40328146,
    },
  },
  ['2412']: {
    label: 'Medical and Surgical Equipment Manufacturing',
    BEA_CODES: {
      '325610': 0.29817559,
      '325620': 0.199738,
      '3259A0': 0.2522011,
      '332999': 0.09333496,
      '339990': 0.15655035,
    },
  },
  ['2419']: {
    label: 'Other Professional and Scientific Equipment Manufacturing',
    BEA_CODES: {
      '33399A': 0.13435165,
      '334220': 0.18170453,
      '334290': 0.03006284,
      '334511': 0.28984944,
      '334512': 0.01647644,
      '334513': 0.06642661,
      '334514': 0.04194696,
      '334515': 0.07883158,
      '334516': 0.0867268,
      '33451A': 0.07362314,
    },
  },
  ['2421']: {
    label: 'Computer and Electronic Office Equipment Manufacturing',
    BEA_CODES: {
      '334413': 0.53207883,
      '334418': 0.18862603,
      '33441A': 0.27929513,
    },
  },
  ['2422']: {
    label: 'Communication Equipment Manufacturing',
    BEA_CODES: {
      '334413': 0.53207883,
      '334418': 0.18862603,
      '33441A': 0.27929513,
    },
  },
  ['2429']: {
    label: 'Other Electronic Equipment Manufacturing',
    BEA_CODES: {
      '334413': 0.53207883,
      '334418': 0.18862603,
      '33441A': 0.27929513,
    },
  },
  ['2431']: {
    label: 'Electric Cable and Wire Manufacturing',
    BEA_CODES: {
      '334413': 0.53207883,
      '334418': 0.18862603,
      '33441A': 0.27929513,
    },
  },
  ['2432']: {
    label: 'Electric Lighting Equipment Manufacturing',
    BEA_CODES: {
      '335110': 0.05948545,
      '335120': 0.33546489,
      '336320': 0.60504966,
    },
  },
  ['2439']: {
    label: 'Other Electrical Equipment Manufacturing',
    BEA_CODES: {
      '334413': 0.53207883,
      '334418': 0.18862603,
      '33441A': 0.27929513,
    },
  },
  ['2441']: {
    label: 'Whiteware Appliance Manufacturing',
    BEA_CODES: {
      '335210': 0.16594275,
      '335221': 0.23067664,
      '335222': 0.18352609,
      '335224': 0.18902088,
      '335228': 0.23083364,
    },
  },
  ['2449']: {
    label: 'Other Domestic Appliance Manufacturing',
    BEA_CODES: {
      '33399A': 0.13435165,
      '334220': 0.18170453,
      '334290': 0.03006284,
      '334511': 0.28984944,
      '334512': 0.01647644,
      '334513': 0.06642661,
      '334514': 0.04194696,
      '334515': 0.07883158,
      '334516': 0.0867268,
      '33451A': 0.07362314,
    },
  },
  ['2451']: {
    label: 'Pump and Compressor Manufacturing',
    BEA_CODES: { '33291A': 0.7209972, '33399B': 0.2790028 },
  },
  ['2452']: {
    label:
      'Fixed Space Heating, Cooling and Ventilation Equipment Manufacturing',
    BEA_CODES: {
      '33399A': 0.13435165,
      '334220': 0.18170453,
      '334290': 0.03006284,
      '334511': 0.28984944,
      '334512': 0.01647644,
      '334513': 0.06642661,
      '334514': 0.04194696,
      '334515': 0.07883158,
      '334516': 0.0867268,
      '33451A': 0.07362314,
    },
  },
  ['2461']: {
    label: 'Agricultural Machinery and Equipment Manufacturing',
    BEA_CODES: {
      '332200': 0.08779015,
      '333111': 0.30946577,
      '333112': 0.06462355,
      '333120': 0.3492938,
      '33329A': 0.18882673,
    },
  },
  ['2462']: {
    label: 'Mining and Construction Machinery Manufacturing',
    BEA_CODES: {
      '333120': 0.39046409,
      '333130': 0.31484451,
      '333920': 0.2946914,
    },
  },
  ['2463']: {
    label: 'Machine Tool and Parts Manufacturing',
    BEA_CODES: {
      '332200': 0.07205846,
      '332500': 0.05749623,
      '332999': 0.1145884,
      '333120': 0.28670155,
      '333130': 0.23117724,
      '333511': 0.04814773,
      '333514': 0.0616048,
      '33351B': 0.06174552,
      '337900': 0.06648007,
    },
  },
  ['2469']: {
    label: 'Other Specialised Machinery and Equipment Manufacturing',
    BEA_CODES: {
      '332200': 0.07205846,
      '332500': 0.05749623,
      '332999': 0.1145884,
      '333120': 0.28670155,
      '333130': 0.23117724,
      '333511': 0.04814773,
      '333514': 0.0616048,
      '33351B': 0.06174552,
      '337900': 0.06648007,
    },
  },
  ['2491']: {
    label: 'Lifting and Material Handling Equipment Manufacturing',
    BEA_CODES: {
      '332200': 0.05486344,
      '332310': 0.22867104,
      '333120': 0.21828711,
      '333130': 0.17601234,
      '333920': 0.16474584,
      '33399A': 0.11514615,
      '336211': 0.04227409,
    },
  },
  ['2499']: {
    label: 'Other Machinery and Equipment Manufacturing n.e.c.',
    BEA_CODES: { '332310': 0.84710615, '332410': 0.15289385 },
  },
  ['2511']: {
    label: 'Wooden Furniture and Upholstered Seat Manufacturing',
    BEA_CODES: {
      '336211': 0.03638522,
      '336310': 0.14257469,
      '336320': 0.09635942,
      '336350': 0.18740053,
      '336360': 0.11582697,
      '336390': 0.27820746,
      '3363A0': 0.11342801,
      '336999': 0.0298177,
    },
  },
  ['2512']: {
    label: 'Metal Furniture Manufacturing',
    BEA_CODES: {
      '336211': 0.03638522,
      '336310': 0.14257469,
      '336320': 0.09635942,
      '336350': 0.18740053,
      '336360': 0.11582697,
      '336390': 0.27820746,
      '3363A0': 0.11342801,
      '336999': 0.0298177,
    },
  },
  ['2513']: {
    label: 'Mattress Manufacturing',
    BEA_CODES: {
      '313300': 0.07413985,
      '315000': 0.12203772,
      '326210': 0.19165408,
      '326220': 0.05070638,
      '326290': 0.16529102,
      '337900': 0.07945778,
      '339920': 0.08699503,
      '339990': 0.22971814,
    },
  },
  ['2519']: {
    label: 'Other Furniture Manufacturing',
    BEA_CODES: {
      '3259A0': 0.65231842,
      '333316': 0.02874099,
      '333318': 0.26980952,
      '339940': 0.04913107,
    },
  },
  ['2591']: {
    label: 'Jewellery and Silverware Manufacturing',
    BEA_CODES: {
      '322220': 0.06126943,
      '323110': 0.19333475,
      '331313': 0.156146,
      '331420': 0.09162223,
      '331490': 0.05334549,
      '332320': 0.12589215,
      '332430': 0.06496425,
      '332720': 0.09845279,
      '33399A': 0.06824404,
      '339990': 0.08672887,
    },
  },
  ['2592']: {
    label: 'Toy, Sporting and Recreational Product Manufacturing',
    BEA_CODES: { '339920': 0.84255617, '339930': 0.15744383 },
  },
  ['2599']: {
    label: 'Other Manufacturing n.e.c.',
    BEA_CODES: {
      '321910': 0.1590348,
      '3219A0': 0.17436234,
      '324190': 0.35614322,
      '337900': 0.06036638,
      '339940': 0.02117598,
      '339950': 0.05439374,
      '339990': 0.17452354,
    },
  },
  ['2611']: {
    label: 'Fossil Fuel Electricity Generation',
    BEA_CODES: {
      '22111A': 0.55847953,
      '22111B': 0.43274854,
      '22111C': 0.00877193,
    },
  },
  ['2612']: {
    label: 'Hydro-Electricity Generation',
    BEA_CODES: { '221111': 1.0 },
  },
  ['2619']: {
    label: 'Other Electricity Generation',
    BEA_CODES: {
      '221113': 0.76923077,
      '221114': 0.00641026,
      '221115': 0.13461538,
      '221116': 0.01282051,
      '221117': 0.07051282,
      '221118': 0.00641026,
    },
  },
  ['2620']: { label: 'Electricity Transmission', BEA_CODES: { '221121': 1.0 } },
  ['2630']: { label: 'Electricity Distribution', BEA_CODES: { '221122': 1.0 } },
  ['2640']: {
    label: 'On Selling Electricity and Electricity Market Operation',
    BEA_CODES: { '221100': 1.0 },
  },
  ['2700']: { label: 'Gas Supply', BEA_CODES: { '221200': 1.0 } },
  ['2811']: {
    label: 'Water Supply',
    BEA_CODES: { '221300': 0.64435778, '312110': 0.35564222 },
  },
  ['2812']: {
    label: 'Sewerage and Drainage Services',
    BEA_CODES: { '221300': 0.42811779, '562111': 0.57188221 },
  },
  ['2911']: {
    label: 'Solid Waste Collection Services',
    BEA_CODES: { '562111': 1.0 },
  },
  ['2919']: {
    label: 'Other Waste Collection Services',
    BEA_CODES: { '221300': 0.42811779, '562111': 0.57188221 },
  },
  ['2921']: {
    label: 'Waste Treatment and Disposal Services',
    BEA_CODES: { '221300': 0.42811779, '562111': 0.57188221 },
  },
  ['2922']: {
    label: 'Waste Remediation and Materials Recovery Services',
    BEA_CODES: { '562111': 1.0 },
  },
  ['3011']: {
    label: 'House Construction',
    BEA_CODES: {
      '230301': 0.21061925,
      '230302': 0.08747001,
      '233210': 0.055174,
      '233230': 0.05835996,
      '233262': 0.09750969,
      '2332A0': 0.09670154,
      '233411': 0.15899345,
      '233412': 0.03316393,
      '2334A0': 0.20200817,
    },
  },
  ['3019']: {
    label: 'Other Residential Building Construction',
    BEA_CODES: {
      '230301': 0.21061925,
      '230302': 0.08747001,
      '233210': 0.055174,
      '233230': 0.05835996,
      '233262': 0.09750969,
      '2332A0': 0.09670154,
      '233411': 0.15899345,
      '233412': 0.03316393,
      '2334A0': 0.20200817,
    },
  },
  ['3020']: {
    label: 'Non-Residential Building Construction',
    BEA_CODES: {
      '230301': 0.21061925,
      '230302': 0.08747001,
      '233210': 0.055174,
      '233230': 0.05835996,
      '233262': 0.09750969,
      '2332A0': 0.09670154,
      '233411': 0.15899345,
      '233412': 0.03316393,
      '2334A0': 0.20200817,
    },
  },
  ['3101']: {
    label: 'Road and Bridge Construction',
    BEA_CODES: {
      '230301': 0.16135041,
      '230302': 0.0670087,
      '233230': 0.04470818,
      '233240': 0.10401199,
      '233262': 0.07469986,
      '2332A0': 0.07408076,
      '2332C0': 0.11176542,
      '2332D0': 0.08581989,
      '233411': 0.12180111,
      '2334A0': 0.15475367,
    },
  },
  ['3109']: {
    label: 'Other Heavy and Civil Engineering Construction',
    BEA_CODES: {
      '230301': 0.17340146,
      '230302': 0.07201349,
      '233210': 0.04542439,
      '233230': 0.04804738,
      '233240': 0.1117805,
      '233262': 0.08027909,
      '2332A0': 0.07961375,
      '2332D0': 0.09222967,
      '233411': 0.13089827,
      '2334A0': 0.16631201,
    },
  },
  ['3211']: {
    label: 'Land Development and Subdivision',
    BEA_CODES: {
      '230301': 0.16544369,
      '233210': 0.04333977,
      '233230': 0.04584238,
      '233240': 0.10665065,
      '233262': 0.07659491,
      '2332A0': 0.0759601,
      '2332C0': 0.11460078,
      '2332D0': 0.08799705,
      '233411': 0.12489107,
      '2334A0': 0.1586796,
    },
  },
  ['3212']: {
    label: 'Site Preparation Services',
    BEA_CODES: {
      '230301': 0.17340146,
      '230302': 0.07201349,
      '233210': 0.04542439,
      '233230': 0.04804738,
      '233240': 0.1117805,
      '233262': 0.08027909,
      '2332A0': 0.07961375,
      '2332D0': 0.09222967,
      '233411': 0.13089827,
      '2334A0': 0.16631201,
    },
  },
  ['3221']: {
    label: 'Concreting Services',
    BEA_CODES: {
      '230301': 0.15698936,
      '230302': 0.06519756,
      '233230': 0.04349979,
      '233240': 0.10120071,
      '233262': 0.07268084,
      '2332A0': 0.07207847,
      '2332D0': 0.08350032,
      '233411': 0.11850902,
      '2334A0': 0.15057092,
      '561700': 0.13577301,
    },
  },
  ['3222']: {
    label: 'Bricklaying Services',
    BEA_CODES: {
      '230301': 0.15698936,
      '230302': 0.06519756,
      '233230': 0.04349979,
      '233240': 0.10120071,
      '233262': 0.07268084,
      '2332A0': 0.07207847,
      '2332D0': 0.08350032,
      '233411': 0.11850902,
      '2334A0': 0.15057092,
      '561700': 0.13577301,
    },
  },
  ['3223']: {
    label: 'Roofing Services',
    BEA_CODES: {
      '230301': 0.15698936,
      '230302': 0.06519756,
      '233230': 0.04349979,
      '233240': 0.10120071,
      '233262': 0.07268084,
      '2332A0': 0.07207847,
      '2332D0': 0.08350032,
      '233411': 0.11850902,
      '2334A0': 0.15057092,
      '561700': 0.13577301,
    },
  },
  ['3224']: {
    label: 'Structural Steel Erection Services',
    BEA_CODES: {
      '230301': 0.15698936,
      '230302': 0.06519756,
      '233230': 0.04349979,
      '233240': 0.10120071,
      '233262': 0.07268084,
      '2332A0': 0.07207847,
      '2332D0': 0.08350032,
      '233411': 0.11850902,
      '2334A0': 0.15057092,
      '561700': 0.13577301,
    },
  },
  ['3231']: {
    label: 'Plumbing Services',
    BEA_CODES: {
      '230301': 0.17340146,
      '230302': 0.07201349,
      '233210': 0.04542439,
      '233230': 0.04804738,
      '233240': 0.1117805,
      '233262': 0.08027909,
      '2332A0': 0.07961375,
      '2332D0': 0.09222967,
      '233411': 0.13089827,
      '2334A0': 0.16631201,
    },
  },
  ['3232']: {
    label: 'Electrical Services',
    BEA_CODES: {
      '230301': 0.17340146,
      '230302': 0.07201349,
      '233210': 0.04542439,
      '233230': 0.04804738,
      '233240': 0.1117805,
      '233262': 0.08027909,
      '2332A0': 0.07961375,
      '2332D0': 0.09222967,
      '233411': 0.13089827,
      '2334A0': 0.16631201,
    },
  },
  ['3233']: {
    label: 'Air Conditioning and Heating Services',
    BEA_CODES: {
      '230301': 0.17340146,
      '230302': 0.07201349,
      '233210': 0.04542439,
      '233230': 0.04804738,
      '233240': 0.1117805,
      '233262': 0.08027909,
      '2332A0': 0.07961375,
      '2332D0': 0.09222967,
      '233411': 0.13089827,
      '2334A0': 0.16631201,
    },
  },
  ['3234']: {
    label: 'Fire and Security Alarm Installation Services',
    BEA_CODES: {
      '230301': 0.16135041,
      '230302': 0.0670087,
      '233230': 0.04470818,
      '233240': 0.10401199,
      '233262': 0.07469986,
      '2332A0': 0.07408076,
      '2332C0': 0.11176542,
      '2332D0': 0.08581989,
      '233411': 0.12180111,
      '2334A0': 0.15475367,
    },
  },
  ['3239']: {
    label: 'Other Building Installation Services',
    BEA_CODES: {
      '230301': 0.17340146,
      '230302': 0.07201349,
      '233210': 0.04542439,
      '233230': 0.04804738,
      '233240': 0.1117805,
      '233262': 0.08027909,
      '2332A0': 0.07961375,
      '2332D0': 0.09222967,
      '233411': 0.13089827,
      '2334A0': 0.16631201,
    },
  },
  ['3241']: {
    label: 'Plastering and Ceiling Services',
    BEA_CODES: {
      '230301': 0.17340146,
      '230302': 0.07201349,
      '233210': 0.04542439,
      '233230': 0.04804738,
      '233240': 0.1117805,
      '233262': 0.08027909,
      '2332A0': 0.07961375,
      '2332D0': 0.09222967,
      '233411': 0.13089827,
      '2334A0': 0.16631201,
    },
  },
  ['3242']: {
    label: 'Carpentry Services',
    BEA_CODES: {
      '230301': 0.17340146,
      '230302': 0.07201349,
      '233210': 0.04542439,
      '233230': 0.04804738,
      '233240': 0.1117805,
      '233262': 0.08027909,
      '2332A0': 0.07961375,
      '2332D0': 0.09222967,
      '233411': 0.13089827,
      '2334A0': 0.16631201,
    },
  },
  ['3243']: {
    label: 'Tiling and Carpeting Services',
    BEA_CODES: {
      '230301': 0.17340146,
      '230302': 0.07201349,
      '233210': 0.04542439,
      '233230': 0.04804738,
      '233240': 0.1117805,
      '233262': 0.08027909,
      '2332A0': 0.07961375,
      '2332D0': 0.09222967,
      '233411': 0.13089827,
      '2334A0': 0.16631201,
    },
  },
  ['3244']: {
    label: 'Painting and Decorating Services',
    BEA_CODES: {
      '230301': 0.17340146,
      '230302': 0.07201349,
      '233210': 0.04542439,
      '233230': 0.04804738,
      '233240': 0.1117805,
      '233262': 0.08027909,
      '2332A0': 0.07961375,
      '2332D0': 0.09222967,
      '233411': 0.13089827,
      '2334A0': 0.16631201,
    },
  },
  ['3245']: {
    label: 'Glazing Services',
    BEA_CODES: {
      '230301': 0.17340146,
      '230302': 0.07201349,
      '233210': 0.04542439,
      '233230': 0.04804738,
      '233240': 0.1117805,
      '233262': 0.08027909,
      '2332A0': 0.07961375,
      '2332D0': 0.09222967,
      '233411': 0.13089827,
      '2334A0': 0.16631201,
    },
  },
  ['3291']: {
    label: 'Landscape Construction Services',
    BEA_CODES: {
      '230301': 0.17340146,
      '230302': 0.07201349,
      '233210': 0.04542439,
      '233230': 0.04804738,
      '233240': 0.1117805,
      '233262': 0.08027909,
      '2332A0': 0.07961375,
      '2332D0': 0.09222967,
      '233411': 0.13089827,
      '2334A0': 0.16631201,
    },
  },
  ['3292']: {
    label: 'Hire of Construction Machinery with Operator',
    BEA_CODES: {
      '21311A': 0.08471078,
      '230301': 0.16626497,
      '230302': 0.06904971,
      '233230': 0.04606995,
      '233240': 0.10718008,
      '233262': 0.07697513,
      '2332A0': 0.07633717,
      '2332D0': 0.08843387,
      '233411': 0.12551104,
      '2334A0': 0.1594673,
    },
  },
  ['3299']: {
    label: 'Other Construction Services n.e.c.',
    BEA_CODES: {
      '230301': 0.17340146,
      '230302': 0.07201349,
      '233210': 0.04542439,
      '233230': 0.04804738,
      '233240': 0.1117805,
      '233262': 0.08027909,
      '2332A0': 0.07961375,
      '2332D0': 0.09222967,
      '233411': 0.13089827,
      '2334A0': 0.16631201,
    },
  },
  ['3311']: {
    label: 'Wool Wholesaling',
    BEA_CODES: { '424400': 0.30744764, '424A00': 0.69255236 },
  },
  ['3312']: {
    label: 'Cereal Grain Wholesaling',
    BEA_CODES: { '424400': 0.30744764, '424A00': 0.69255236 },
  },
  ['3319']: {
    label: 'Other Agricultural Product Wholesaling',
    BEA_CODES: { '424400': 0.30744764, '424A00': 0.69255236 },
  },
  ['3321']: {
    label: 'Petroleum Product Wholesaling',
    BEA_CODES: {
      '423A00': 0.33741309,
      '424700': 0.23156043,
      '424A00': 0.43102648,
    },
  },
  ['3322']: {
    label: 'Metal and Mineral Wholesaling',
    BEA_CODES: {
      '423A00': 0.33741309,
      '424700': 0.23156043,
      '424A00': 0.43102648,
    },
  },
  ['3323']: {
    label: 'Industrial and Agricultural Chemical Product Wholesaling',
    BEA_CODES: {
      '423800': 0.19226344,
      '423A00': 0.28395978,
      '424400': 0.16103393,
      '424A00': 0.36274285,
    },
  },
  ['3331']: {
    label: 'Timber Wholesaling',
    BEA_CODES: {
      '423100': 0.13009976,
      '423800': 0.19935253,
      '423A00': 0.29442988,
      '424A00': 0.37611783,
    },
  },
  ['3332']: {
    label: 'Plumbing Goods Wholesaling',
    BEA_CODES: {
      '423100': 0.13009976,
      '423800': 0.19935253,
      '423A00': 0.29442988,
      '424A00': 0.37611783,
    },
  },
  ['3339']: {
    label: 'Other Hardware Goods Wholesaling',
    BEA_CODES: {
      '423A00': 0.33741309,
      '424700': 0.23156043,
      '424A00': 0.43102648,
    },
  },
  ['3411']: {
    label: 'Agricultural and Construction Machinery Wholesaling',
    BEA_CODES: { '423800': 1.0 },
  },
  ['3419']: {
    label: 'Other Specialised Industrial Machinery and Equipment Wholesaling',
    BEA_CODES: {
      '423100': 0.12463266,
      '423400': 0.2285438,
      '423600': 0.17379103,
      '423800': 0.19097527,
      '423A00': 0.28205724,
    },
  },
  ['3491']: {
    label: 'Professional and Scientific Goods Wholesaling',
    BEA_CODES: {
      '423100': 0.12463266,
      '423400': 0.2285438,
      '423600': 0.17379103,
      '423800': 0.19097527,
      '423A00': 0.28205724,
    },
  },
  ['3492']: {
    label: 'Computer and Computer Peripheral Wholesaling',
    BEA_CODES: {
      '423400': 0.16327231,
      '423600': 0.12415679,
      '423800': 0.13643325,
      '423A00': 0.20150246,
      '424200': 0.11722697,
      '424A00': 0.25740821,
    },
  },
  ['3493']: {
    label: 'Telecommunication Goods Wholesaling',
    BEA_CODES: { '423600': 1.0 },
  },
  ['3494']: {
    label: 'Other Electrical and Electronic Goods Wholesaling',
    BEA_CODES: {
      '423400': 0.16327231,
      '423600': 0.12415679,
      '423800': 0.13643325,
      '423A00': 0.20150246,
      '424200': 0.11722697,
      '424A00': 0.25740821,
    },
  },
  ['3499']: {
    label: 'Other Machinery and Equipment Wholesaling n.e.c.',
    BEA_CODES: {
      '423400': 0.16327231,
      '423600': 0.12415679,
      '423800': 0.13643325,
      '423A00': 0.20150246,
      '424200': 0.11722697,
      '424A00': 0.25740821,
    },
  },
  ['3501']: {
    label: 'Car Wholesaling',
    BEA_CODES: {
      '423100': 0.18771889,
      '423A00': 0.42482823,
      '425000': 0.10588643,
      '441000': 0.28156645,
    },
  },
  ['3502']: {
    label: 'Commercial Vehicle Wholesaling',
    BEA_CODES: {
      '423100': 0.18771889,
      '423A00': 0.42482823,
      '425000': 0.10588643,
      '441000': 0.28156645,
    },
  },
  ['3503']: {
    label: 'Trailer and Other Motor Vehicle Wholesaling',
    BEA_CODES: {
      '423100': 0.18771889,
      '423A00': 0.42482823,
      '425000': 0.10588643,
      '441000': 0.28156645,
    },
  },
  ['3504']: {
    label: 'Motor Vehicle New Parts Wholesaling',
    BEA_CODES: {
      '423100': 0.13891979,
      '423A00': 0.31439057,
      '425000': 0.07836037,
      '441000': 0.20837089,
      '452000': 0.25995837,
    },
  },
  ['3505']: {
    label: 'Motor Vehicle Dismantling and Used Parts Wholesaling',
    BEA_CODES: {
      '423100': 0.13891979,
      '423A00': 0.31439057,
      '425000': 0.07836037,
      '441000': 0.20837089,
      '452000': 0.25995837,
    },
  },
  ['3601']: {
    label: 'General Line Grocery Wholesaling',
    BEA_CODES: { '424400': 0.30744764, '424A00': 0.69255236 },
  },
  ['3602']: {
    label: 'Meat, Poultry and Smallgoods Wholesaling',
    BEA_CODES: { '424400': 0.30744764, '424A00': 0.69255236 },
  },
  ['3603']: {
    label: 'Dairy Produce Wholesaling',
    BEA_CODES: { '424400': 0.30744764, '424A00': 0.69255236 },
  },
  ['3604']: {
    label: 'Fish and Seafood Wholesaling',
    BEA_CODES: { '424400': 0.30744764, '424A00': 0.69255236 },
  },
  ['3605']: {
    label: 'Fruit and Vegetable Wholesaling',
    BEA_CODES: { '424400': 0.30744764, '424A00': 0.69255236 },
  },
  ['3606']: {
    label: 'Liquor and Tobacco Product Wholesaling',
    BEA_CODES: { '424400': 0.30744764, '424A00': 0.69255236 },
  },
  ['3609']: {
    label: 'Other Grocery Wholesaling',
    BEA_CODES: { '424400': 0.30744764, '424A00': 0.69255236 },
  },
  ['3711']: {
    label: 'Textile Product Wholesaling',
    BEA_CODES: { '423A00': 0.43908864, '424A00': 0.56091136 },
  },
  ['3712']: {
    label: 'Clothing and Footwear Wholesaling',
    BEA_CODES: { '423A00': 0.43908864, '424A00': 0.56091136 },
  },
  ['3720']: {
    label: 'Pharmaceutical and Toiletry Goods Wholesaling',
    BEA_CODES: {
      '423400': 0.16327231,
      '423600': 0.12415679,
      '423800': 0.13643325,
      '423A00': 0.20150246,
      '424200': 0.11722697,
      '424A00': 0.25740821,
    },
  },
  ['3731']: {
    label: 'Furniture and Floor Covering Wholesaling',
    BEA_CODES: {
      '423400': 0.16327231,
      '423600': 0.12415679,
      '423800': 0.13643325,
      '423A00': 0.20150246,
      '424200': 0.11722697,
      '424A00': 0.25740821,
    },
  },
  ['3732']: {
    label: 'Jewellery and Watch Wholesaling',
    BEA_CODES: {
      '423400': 0.16327231,
      '423600': 0.12415679,
      '423800': 0.13643325,
      '423A00': 0.20150246,
      '424200': 0.11722697,
      '424A00': 0.25740821,
    },
  },
  ['3733']: {
    label: 'Kitchen and Diningware Wholesaling',
    BEA_CODES: {
      '423400': 0.16327231,
      '423600': 0.12415679,
      '423800': 0.13643325,
      '423A00': 0.20150246,
      '424200': 0.11722697,
      '424A00': 0.25740821,
    },
  },
  ['3734']: {
    label: 'Toy and Sporting Goods Wholesaling',
    BEA_CODES: {
      '423400': 0.16327231,
      '423600': 0.12415679,
      '423800': 0.13643325,
      '423A00': 0.20150246,
      '424200': 0.11722697,
      '424A00': 0.25740821,
    },
  },
  ['3735']: {
    label: 'Book and Magazine Wholesaling',
    BEA_CODES: {
      '423400': 0.16327231,
      '423600': 0.12415679,
      '423800': 0.13643325,
      '423A00': 0.20150246,
      '424200': 0.11722697,
      '424A00': 0.25740821,
    },
  },
  ['3736']: {
    label: 'Paper Product Wholesaling',
    BEA_CODES: {
      '423400': 0.16327231,
      '423600': 0.12415679,
      '423800': 0.13643325,
      '423A00': 0.20150246,
      '424200': 0.11722697,
      '424A00': 0.25740821,
    },
  },
  ['3739']: {
    label: 'Other Goods Wholesaling n.e.c.',
    BEA_CODES: { '424400': 0.30744764, '424A00': 0.69255236 },
  },
  ['3800']: {
    label: 'Commission-Based Wholesaling',
    BEA_CODES: {
      '423100': 0.18771889,
      '423A00': 0.42482823,
      '425000': 0.10588643,
      '441000': 0.28156645,
    },
  },
  ['3911']: {
    label: 'Car Retailing',
    BEA_CODES: {
      '423100': 0.18771889,
      '423A00': 0.42482823,
      '425000': 0.10588643,
      '441000': 0.28156645,
    },
  },
  ['3912']: {
    label: 'Motorcycle Retailing',
    BEA_CODES: {
      '423100': 0.29805431,
      '425000': 0.16812323,
      '441000': 0.44706258,
      '811400': 0.08675988,
    },
  },
  ['3913']: {
    label: 'Trailer and Other Motor Vehicle Retailing',
    BEA_CODES: {
      '423100': 0.18771889,
      '423A00': 0.42482823,
      '425000': 0.10588643,
      '441000': 0.28156645,
    },
  },
  ['3921']: {
    label: 'Motor Vehicle Parts Retailing',
    BEA_CODES: {
      '423100': 0.13891979,
      '423A00': 0.31439057,
      '425000': 0.07836037,
      '441000': 0.20837089,
      '452000': 0.25995837,
    },
  },
  ['3922']: {
    label: 'Tyre Retailing',
    BEA_CODES: {
      '423100': 0.13891979,
      '423A00': 0.31439057,
      '425000': 0.07836037,
      '441000': 0.20837089,
      '452000': 0.25995837,
    },
  },
  ['4000']: { label: 'Fuel Retailing', BEA_CODES: { '447000': 1.0 } },
  ['4110']: {
    label: 'Supermarket and Grocery Stores',
    BEA_CODES: {
      '445000': 0.40857882,
      '447000': 0.17027283,
      '452000': 0.42114835,
    },
  },
  ['4121']: {
    label: 'Fresh Meat, Fish and Poultry Retailing',
    BEA_CODES: { '445000': 0.6307252, '446000': 0.3692748 },
  },
  ['4122']: {
    label: 'Fruit and Vegetable Retailing',
    BEA_CODES: { '445000': 0.6307252, '446000': 0.3692748 },
  },
  ['4123']: { label: 'Liquor Retailing', BEA_CODES: { '445000': 1.0 } },
  ['4129']: {
    label: 'Other Specialised Food Retailing',
    BEA_CODES: { '445000': 0.6307252, '446000': 0.3692748 },
  },
  ['4211']: {
    label: 'Furniture Retailing',
    BEA_CODES: { '444000': 0.37539166, '4B0000': 0.62460834 },
  },
  ['4212']: {
    label: 'Floor Coverings Retailing',
    BEA_CODES: { '444000': 0.37539166, '4B0000': 0.62460834 },
  },
  ['4213']: {
    label: 'Houseware Retailing',
    BEA_CODES: { '444000': 0.37539166, '4B0000': 0.62460834 },
  },
  ['4214']: {
    label: 'Manchester and Other Textile Goods Retailing',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['4221']: {
    label: 'Electrical, Electronic and Gas Appliance Retailing',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['4222']: {
    label: 'Computer and Computer Peripheral Retailing',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['4229']: {
    label: 'Other Electrical and Electronic Goods Retailing',
    BEA_CODES: { '444000': 0.37539166, '4B0000': 0.62460834 },
  },
  ['4231']: {
    label: 'Hardware and Building Supplies Retailing',
    BEA_CODES: { '444000': 0.37539166, '4B0000': 0.62460834 },
  },
  ['4232']: {
    label: 'Garden Supplies Retailing',
    BEA_CODES: { '444000': 0.37539166, '4B0000': 0.62460834 },
  },
  ['4241']: {
    label: 'Sport and Camping Equipment Retailing',
    BEA_CODES: { '441000': 0.42186793, '4B0000': 0.57813207 },
  },
  ['4242']: {
    label: 'Entertainment Media Retailing',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['4243']: { label: 'Toy and Game Retailing', BEA_CODES: { '4B0000': 1.0 } },
  ['4244']: {
    label: 'Newspaper and Book Retailing',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['4245']: {
    label: 'Marine Equipment Retailing',
    BEA_CODES: {
      '423100': 0.18771889,
      '423A00': 0.42482823,
      '425000': 0.10588643,
      '441000': 0.28156645,
    },
  },
  ['4251']: { label: 'Clothing Retailing', BEA_CODES: { '448000': 1.0 } },
  ['4252']: { label: 'Footwear Retailing', BEA_CODES: { '448000': 1.0 } },
  ['4253']: {
    label: 'Watch and Jewellery Retailing',
    BEA_CODES: {
      '441000': 0.20762834,
      '444000': 0.17100705,
      '446000': 0.14713081,
      '448000': 0.18969782,
      '4B0000': 0.28453597,
    },
  },
  ['4259']: {
    label: 'Other Personal Accessory Retailing',
    BEA_CODES: { '448000': 1.0 },
  },
  ['4260']: { label: 'Department Stores', BEA_CODES: { '452000': 1.0 } },
  ['4271']: {
    label: 'Pharmaceutical, Cosmetic and Toiletry Goods Retailing',
    BEA_CODES: { '446000': 1.0 },
  },
  ['4272']: {
    label: 'Stationery Goods Retailing',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['4273']: {
    label: 'Antique and Used Goods Retailing',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['4274']: {
    label: 'Flower Retailing',
    BEA_CODES: {
      '441000': 0.20762834,
      '444000': 0.17100705,
      '446000': 0.14713081,
      '448000': 0.18969782,
      '4B0000': 0.28453597,
    },
  },
  ['4279']: {
    label: 'Other Store-Based Retailing n.e.c.',
    BEA_CODES: {
      '445000': 0.40857882,
      '447000': 0.17027283,
      '452000': 0.42114835,
    },
  },
  ['4310']: { label: 'Non-Store Retailing', BEA_CODES: { '454000': 1.0 } },
  ['4320']: {
    label: 'Retail Commission-Based Buying and/or Selling',
    BEA_CODES: {
      '423100': 0.18771889,
      '423A00': 0.42482823,
      '425000': 0.10588643,
      '441000': 0.28156645,
    },
  },
  ['4400']: { label: 'Accommodation', BEA_CODES: { '721000': 1.0 } },
  ['4511']: {
    label: 'Cafes and Restaurants',
    BEA_CODES: {
      '722110': 0.38500025,
      '722211': 0.40996607,
      '722A00': 0.20503367,
    },
  },
  ['4512']: {
    label: 'Takeaway Food Services',
    BEA_CODES: {
      '722110': 0.38500025,
      '722211': 0.40996607,
      '722A00': 0.20503367,
    },
  },
  ['4513']: {
    label: 'Catering Services',
    BEA_CODES: {
      '722110': 0.38500025,
      '722211': 0.40996607,
      '722A00': 0.20503367,
    },
  },
  ['4520']: { label: 'Pubs, Taverns and Bars', BEA_CODES: { '722A00': 1.0 } },
  ['4530']: {
    label: 'Clubs (Hospitality)',
    BEA_CODES: {
      '722110': 0.38500025,
      '722211': 0.40996607,
      '722A00': 0.20503367,
    },
  },
  ['4610']: { label: 'Road Freight Transport', BEA_CODES: { '484000': 1.0 } },
  ['4621']: {
    label: 'Interurban and Rural Bus Transport',
    BEA_CODES: {
      '485000': 0.220601,
      '48A000': 0.55640795,
      '713900': 0.22299105,
    },
  },
  ['4622']: {
    label: 'Urban Bus Transport (Including Tramway)',
    BEA_CODES: { '485000': 1.0 },
  },
  ['4623']: {
    label: 'Taxi and Other Road Transport',
    BEA_CODES: {
      '485000': 0.220601,
      '48A000': 0.55640795,
      '713900': 0.22299105,
    },
  },
  ['4710']: { label: 'Rail Freight Transport', BEA_CODES: { '482000': 1.0 } },
  ['4720']: {
    label: 'Rail Passenger Transport',
    BEA_CODES: { '482000': 0.30860867, '48A000': 0.69139133 },
  },
  ['4810']: { label: 'Water Freight Transport', BEA_CODES: { '483000': 1.0 } },
  ['4820']: {
    label: 'Water Passenger Transport',
    BEA_CODES: { '483000': 0.18060463, '48A000': 0.81939537 },
  },
  ['4900']: {
    label: 'Air and Space Transport',
    BEA_CODES: { '481000': 0.55185734, '48A000': 0.44814266 },
  },
  ['5010']: {
    label: 'Scenic and Sightseeing Transport',
    BEA_CODES: { '485000': 1.0 },
  },
  ['5021']: {
    label: 'Pipeline Transport',
    BEA_CODES: { '221300': 0.63173377, '486000': 0.36826623 },
  },
  ['5029']: { label: 'Other Transport n.e.c.', BEA_CODES: { '485000': 1.0 } },
  ['5101']: { label: 'Postal Services', BEA_CODES: { '491000': 1.0 } },
  ['5102']: {
    label: 'Courier Pick-up and Delivery Services',
    BEA_CODES: { '492000': 1.0 },
  },
  ['5211']: { label: 'Stevedoring Services', BEA_CODES: { '48A000': 1.0 } },
  ['5212']: {
    label: 'Port and Water Transport Terminal Operations',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['5219']: {
    label: 'Other Water Transport Support Services',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['5220']: {
    label: 'Airport Operations and Other Air Transport Support Services',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['5291']: { label: 'Customs Agency Services', BEA_CODES: { '48A000': 1.0 } },
  ['5292']: {
    label: 'Freight Forwarding Services',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['5299']: {
    label: 'Other Transport Support Services n.e.c.',
    BEA_CODES: { '482000': 0.30860867, '48A000': 0.69139133 },
  },
  ['5301']: { label: 'Grain Storage Services', BEA_CODES: { '493000': 1.0 } },
  ['5309']: {
    label: 'Other Warehousing and Storage Services',
    BEA_CODES: { '493000': 1.0 },
  },
  ['5411']: {
    label: 'Newspaper Publishing',
    BEA_CODES: {
      '511110': 0.10792661,
      '511120': 0.14126238,
      '519130': 0.75081101,
    },
  },
  ['5412']: {
    label: 'Magazine and Other Periodical Publishing',
    BEA_CODES: {
      '511110': 0.10792661,
      '511120': 0.14126238,
      '519130': 0.75081101,
    },
  },
  ['5413']: {
    label: 'Book Publishing',
    BEA_CODES: {
      '511130': 0.30359549,
      '5111A0': 0.10411715,
      '519130': 0.59228737,
    },
  },
  ['5414']: {
    label: 'Directory and Mailing List Publishing',
    BEA_CODES: { '5111A0': 0.14950671, '519130': 0.85049329 },
  },
  ['5419']: {
    label: 'Other Publishing (except Software, Music and Internet)',
    BEA_CODES: { '5111A0': 0.14950671, '519130': 0.85049329 },
  },
  ['5420']: { label: 'Software Publishing', BEA_CODES: { '511200': 1.0 } },
  ['5511']: {
    label: 'Motion Picture and Video Production',
    BEA_CODES: { '512100': 1.0 },
  },
  ['5512']: {
    label: 'Motion Picture and Video Distribution',
    BEA_CODES: { '512100': 1.0 },
  },
  ['5513']: {
    label: 'Motion Picture Exhibition',
    BEA_CODES: { '512100': 1.0 },
  },
  ['5514']: {
    label:
      'Post-production Services and Other Motion Picture and Video Activities',
    BEA_CODES: { '512100': 1.0 },
  },
  ['5521']: {
    label: 'Music Publishing',
    BEA_CODES: { '512200': 0.18390439, '519130': 0.81609561 },
  },
  ['5522']: {
    label: 'Music and Other Sound Recording Activities',
    BEA_CODES: { '512200': 0.18390439, '519130': 0.81609561 },
  },
  ['5610']: {
    label: 'Radio Broadcasting',
    BEA_CODES: { '515100': 0.38290541, '519130': 0.61709459 },
  },
  ['5621']: {
    label: 'Free-to-Air Television Broadcasting',
    BEA_CODES: {
      '515100': 0.33136267,
      '515200': 0.13460958,
      '519130': 0.53402775,
    },
  },
  ['5622']: {
    label: 'Cable and Other Subscription Broadcasting',
    BEA_CODES: {
      '515100': 0.33136267,
      '515200': 0.13460958,
      '519130': 0.53402775,
    },
  },
  ['5700']: {
    label: 'Internet Publishing and Broadcasting',
    BEA_CODES: {
      '511130': 0.30359549,
      '5111A0': 0.10411715,
      '519130': 0.59228737,
    },
  },
  ['5801']: {
    label: 'Wired Telecommunications Network Operation',
    BEA_CODES: { '517110': 1.0 },
  },
  ['5802']: {
    label: 'Other Telecommunications Network Operation',
    BEA_CODES: {
      '515100': 0.18770505,
      '515200': 0.0762515,
      '517210': 0.73604345,
    },
  },
  ['5809']: {
    label: 'Other Telecommunications Services',
    BEA_CODES: { '517A00': 0.25492567, '812900': 0.74507433 },
  },
  ['5910']: {
    label: 'Internet Service Providers and Web Search Portals',
    BEA_CODES: { '517110': 1.0 },
  },
  ['5921']: {
    label: 'Data Processing and Web Hosting Services',
    BEA_CODES: { '541512': 0.66337622, '54151A': 0.33662378 },
  },
  ['5922']: {
    label: 'Electronic Information Storage Services',
    BEA_CODES: { '541512': 0.66337622, '54151A': 0.33662378 },
  },
  ['6010']: { label: 'Libraries and Archives', BEA_CODES: { '5191A0': 1.0 } },
  ['6020']: {
    label: 'Other Information Services',
    BEA_CODES: { '5191A0': 1.0 },
  },
  ['6210']: {
    label: 'Central Banking',
    BEA_CODES: { '522A00': 0.41827414, '52A000': 0.58172586 },
  },
  ['6221']: {
    label: 'Banking',
    BEA_CODES: { '522A00': 0.41827414, '52A000': 0.58172586 },
  },
  ['6222']: {
    label: 'Building Society Operation',
    BEA_CODES: { '522A00': 0.41827414, '52A000': 0.58172586 },
  },
  ['6223']: {
    label: 'Credit Union Operation',
    BEA_CODES: { '522A00': 0.41827414, '52A000': 0.58172586 },
  },
  ['6229']: {
    label: 'Other Depository Financial Intermediation',
    BEA_CODES: { '522A00': 0.41827414, '52A000': 0.58172586 },
  },
  ['6230']: { label: 'Non-Depository Financing', BEA_CODES: { '522A00': 1.0 } },
  ['6240']: {
    label: 'Financial Asset Investing',
    BEA_CODES: { '550000': 1.0 },
  },
  ['6310']: { label: 'Life Insurance', BEA_CODES: { '524113': 1.0 } },
  ['6321']: { label: 'Health Insurance', BEA_CODES: { '5241XX': 1.0 } },
  ['6322']: { label: 'General Insurance', BEA_CODES: { '5241XX': 1.0 } },
  ['6330']: { label: 'Superannuation Funds', BEA_CODES: { '525000': 1.0 } },
  ['6411']: {
    label: 'Financial Asset Broking Services',
    BEA_CODES: { '523900': 0.68137411, '523A00': 0.31862589 },
  },
  ['6419']: {
    label: 'Other Auxiliary Finance and Investment Services',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['6420']: {
    label: 'Auxiliary Insurance Services',
    BEA_CODES: { '524200': 1.0 },
  },
  ['6611']: {
    label: 'Passenger Car Rental and Hiring',
    BEA_CODES: { '532100': 1.0 },
  },
  ['6619']: {
    label: 'Other Motor Vehicle and Transport Equipment Rental and Hiring',
    BEA_CODES: { '532100': 1.0 },
  },
  ['6620']: {
    label: 'Farm Animal and Bloodstock Leasing',
    BEA_CODES: {
      '532100': 0.45596952,
      '532400': 0.38441538,
      '532A00': 0.1596151,
    },
  },
  ['6631']: {
    label: 'Heavy Machinery and Scaffolding Rental and Hiring',
    BEA_CODES: {
      '532100': 0.45596952,
      '532400': 0.38441538,
      '532A00': 0.1596151,
    },
  },
  ['6632']: {
    label: 'Video and Other Electronic Media Rental and Hiring',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['6639']: {
    label: 'Other Goods and Equipment Rental and Hiring n.e.c.',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['6640']: {
    label: 'Non-Financial Intangible Assets (Except Copyrights) Leasing',
    BEA_CODES: { '533000': 1.0 },
  },
  ['6711']: {
    label: 'Residential Property Operators',
    BEA_CODES: {
      '523900': 0.11292919,
      '531HSO': 0.42184299,
      '531HST': 0.1407905,
      '531ORE': 0.32443732,
    },
  },
  ['6712']: {
    label: 'Non-Residential Property Operators',
    BEA_CODES: {
      '523900': 0.11292919,
      '531HSO': 0.42184299,
      '531HST': 0.1407905,
      '531ORE': 0.32443732,
    },
  },
  ['6720']: { label: 'Real Estate Services', BEA_CODES: { '531ORE': 1.0 } },
  ['6910']: {
    label: 'Scientific Research Services',
    BEA_CODES: { '541700': 1.0 },
  },
  ['6921']: { label: 'Architectural Services', BEA_CODES: { '541300': 1.0 } },
  ['6922']: {
    label: 'Surveying and Mapping Services',
    BEA_CODES: { '541300': 1.0 },
  },
  ['6923']: {
    label: 'Engineering Design and Engineering Consulting Services',
    BEA_CODES: { '541300': 1.0 },
  },
  ['6924']: {
    label: 'Other Specialised Design Services',
    BEA_CODES: { '541800': 1.0 },
  },
  ['6925']: {
    label: 'Scientific Testing and Analysis Services',
    BEA_CODES: { '541300': 0.6418688, '811100': 0.3581312 },
  },
  ['6931']: {
    label: 'Legal Services',
    BEA_CODES: { '541100': 0.75824716, '5419A0': 0.24175284 },
  },
  ['6932']: { label: 'Accounting Services', BEA_CODES: { '541200': 1.0 } },
  ['6940']: { label: 'Advertising Services', BEA_CODES: { '541800': 1.0 } },
  ['6950']: {
    label: 'Market Research and Statistical Services',
    BEA_CODES: { '5419A0': 1.0 },
  },
  ['6961']: {
    label: 'Corporate Head Office Management Services',
    BEA_CODES: { '550000': 1.0 },
  },
  ['6962']: {
    label: 'Management Advice and Related Consulting Services',
    BEA_CODES: { '113000': 0.3895254, '115000': 0.6104746 },
  },
  ['6970']: {
    label: 'Veterinary Services',
    BEA_CODES: { '115000': 0.49359115, '541940': 0.50640885 },
  },
  ['6991']: {
    label: 'Professional Photographic Services',
    BEA_CODES: {
      '518200': 0.58890874,
      '541920': 0.04830593,
      '711500': 0.12766762,
      '812900': 0.23511771,
    },
  },
  ['6999']: {
    label: 'Other Professional, Scientific and Technical Services n.e.c.',
    BEA_CODES: { '211000': 0.9257065, '212100': 0.0742935 },
  },
  ['7000']: {
    label: 'Computer System Design and Related Services',
    BEA_CODES: {
      '230301': 0.17340146,
      '230302': 0.07201349,
      '233210': 0.04542439,
      '233230': 0.04804738,
      '233240': 0.1117805,
      '233262': 0.08027909,
      '2332A0': 0.07961375,
      '2332D0': 0.09222967,
      '233411': 0.13089827,
      '2334A0': 0.16631201,
    },
  },
  ['7211']: {
    label: 'Employment Placement and Recruitment Services',
    BEA_CODES: {
      '541610': 0.57094386,
      '5416A0': 0.13065678,
      '5419A0': 0.23514177,
      '711A00': 0.06325759,
    },
  },
  ['7212']: { label: 'Labour Supply Services', BEA_CODES: { '561300': 1.0 } },
  ['7220']: {
    label: 'Travel Agency and Tour Arrangement Services',
    BEA_CODES: { '561500': 1.0 },
  },
  ['7291']: {
    label: 'Office Administrative Services',
    BEA_CODES: { '561300': 1.0 },
  },
  ['7292']: {
    label: 'Document Preparation Services',
    BEA_CODES: { '323110': 0.39226199, '561400': 0.60773801 },
  },
  ['7293']: {
    label: 'Credit Reporting and Debt Collection Services',
    BEA_CODES: { '561400': 1.0 },
  },
  ['7294']: { label: 'Call Centre Operation', BEA_CODES: { '561400': 1.0 } },
  ['7299']: {
    label: 'Other Administrative Services n.e.c.',
    BEA_CODES: { '561500': 1.0 },
  },
  ['7311']: {
    label: 'Building and Other Industrial Cleaning Services',
    BEA_CODES: {
      '230301': 0.17340146,
      '230302': 0.07201349,
      '233210': 0.04542439,
      '233230': 0.04804738,
      '233240': 0.1117805,
      '233262': 0.08027909,
      '2332A0': 0.07961375,
      '2332D0': 0.09222967,
      '233411': 0.13089827,
      '2334A0': 0.16631201,
    },
  },
  ['7312']: {
    label: 'Building Pest Control Services',
    BEA_CODES: {
      '230301': 0.14354632,
      '230302': 0.05961467,
      '233240': 0.09253486,
      '233262': 0.06645716,
      '2332A0': 0.06590637,
      '2332D0': 0.07635016,
      '233411': 0.10836106,
      '2334A0': 0.1376775,
      '48A000': 0.12540516,
      '561700': 0.12414673,
    },
  },
  ['7313']: { label: 'Gardening Services', BEA_CODES: { '561700': 1.0 } },
  ['7320']: {
    label: 'Packaging Services',
    BEA_CODES: {
      '3259A0': 0.24076236,
      '561400': 0.51615731,
      '561900': 0.24308033,
    },
  },
  ['7510']: {
    label: 'Central Government Administration',
    BEA_CODES: {
      '561400': 0.03992198,
      GSLGE: 0.37093947,
      GSLGH: 0.03086603,
      GSLGO: 0.38099538,
      S00600: 0.17727714,
    },
  },
  ['7520']: {
    label: 'State Government Administration',
    BEA_CODES: {
      '561400': 0.03992198,
      GSLGE: 0.37093947,
      GSLGH: 0.03086603,
      GSLGO: 0.38099538,
      S00600: 0.17727714,
    },
  },
  ['7530']: {
    label: 'Local Government Administration',
    BEA_CODES: {
      '561400': 0.03992198,
      GSLGE: 0.37093947,
      GSLGH: 0.03086603,
      GSLGO: 0.38099538,
      S00600: 0.17727714,
    },
  },
  ['7540']: {
    label: 'Justice',
    BEA_CODES: {
      '561200': 0.01551078,
      '561900': 0.02004541,
      GSLGO: 0.40621396,
      S00102: 0.00660663,
      S00203: 0.04094267,
      S00500: 0.32166921,
      S00600: 0.18901134,
    },
  },
  ['7551']: {
    label: 'Domestic Government Representation',
    BEA_CODES: { GSLGO: 0.44303238, S00500: 0.35082467, S00600: 0.20614295 },
  },
  ['7552']: {
    label: 'Foreign Government Representation',
    BEA_CODES: { S00600: 1.0 },
  },
  ['7600']: {
    label: 'Defence',
    BEA_CODES: { GSLGO: 0.44303238, S00500: 0.35082467, S00600: 0.20614295 },
  },
  ['7711']: {
    label: 'Police Services',
    BEA_CODES: {
      '561200': 0.01551078,
      '561900': 0.02004541,
      GSLGO: 0.40621396,
      S00102: 0.00660663,
      S00203: 0.04094267,
      S00500: 0.32166921,
      S00600: 0.18901134,
    },
  },
  ['7712']: {
    label: 'Investigation and Security Services',
    BEA_CODES: { '327999': 0.2647065, '339910': 0.7352935 },
  },
  ['7713']: {
    label: 'Fire Protection and Other Emergency Services',
    BEA_CODES: { '113000': 0.3895254, '115000': 0.6104746 },
  },
  ['7714']: {
    label: 'Correctional and Detention Services',
    BEA_CODES: {
      '561200': 0.01551078,
      '561900': 0.02004541,
      GSLGO: 0.40621396,
      S00102: 0.00660663,
      S00203: 0.04094267,
      S00500: 0.32166921,
      S00600: 0.18901134,
    },
  },
  ['7719']: {
    label: 'Other Public Order and Safety Services',
    BEA_CODES: {
      '561200': 0.01551078,
      '561900': 0.02004541,
      GSLGO: 0.40621396,
      S00102: 0.00660663,
      S00203: 0.04094267,
      S00500: 0.32166921,
      S00600: 0.18901134,
    },
  },
  ['7720']: {
    label: 'Regulatory Services',
    BEA_CODES: { '541300': 0.6418688, '811100': 0.3581312 },
  },
  ['8010']: {
    label: 'Preschool Education',
    BEA_CODES: { '611100': 0.36243333, '611B00': 0.63756667 },
  },
  ['8021']: {
    label: 'Primary Education',
    BEA_CODES: { '611100': 0.36243333, '611B00': 0.63756667 },
  },
  ['8022']: { label: 'Secondary Education', BEA_CODES: { '611100': 1.0 } },
  ['8023']: {
    label: 'Combined Primary and Secondary Education',
    BEA_CODES: { '611100': 0.36243333, '611B00': 0.63756667 },
  },
  ['8024']: {
    label: 'Special School Education',
    BEA_CODES: { '611100': 0.36243333, '611B00': 0.63756667 },
  },
  ['8101']: {
    label: 'Technical and Vocational Education and Training',
    BEA_CODES: { '611100': 0.36243333, '611B00': 0.63756667 },
  },
  ['8102']: { label: 'Higher Education', BEA_CODES: { '611A00': 1.0 } },
  ['8211']: {
    label: 'Sports and Physical Recreation Instruction',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['8212']: { label: 'Arts Education', BEA_CODES: { '611B00': 1.0 } },
  ['8219']: {
    label: 'Adult, Community and Other Education n.e.c.',
    BEA_CODES: { '611100': 0.36243333, '611B00': 0.63756667 },
  },
  ['8220']: {
    label: 'Educational Support Services',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['8401']: {
    label: 'Hospitals (Except Psychiatric Hospitals)',
    BEA_CODES: { '622000': 1.0 },
  },
  ['8402']: { label: 'Psychiatric Hospitals', BEA_CODES: { '622000': 1.0 } },
  ['8511']: {
    label: 'General Practice Medical Services',
    BEA_CODES: {
      '621100': 0.66416673,
      '621200': 0.17146172,
      '621400': 0.16437155,
    },
  },
  ['8512']: {
    label: 'Specialist Medical Services',
    BEA_CODES: {
      '621100': 0.66416673,
      '621200': 0.17146172,
      '621400': 0.16437155,
    },
  },
  ['8520']: {
    label: 'Pathology and Diagnostic Imaging Services',
    BEA_CODES: {
      '621300': 0.2294508,
      '621400': 0.28679675,
      '621500': 0.14542531,
      '621600': 0.24376495,
      '621900': 0.09456219,
    },
  },
  ['8531']: {
    label: 'Dental Services',
    BEA_CODES: {
      '621100': 0.66416673,
      '621200': 0.17146172,
      '621400': 0.16437155,
    },
  },
  ['8532']: {
    label: 'Optometry and Optical Dispensing',
    BEA_CODES: {
      '621300': 0.2294508,
      '621400': 0.28679675,
      '621500': 0.14542531,
      '621600': 0.24376495,
      '621900': 0.09456219,
    },
  },
  ['8533']: {
    label: 'Physiotherapy Services',
    BEA_CODES: {
      '621300': 0.2294508,
      '621400': 0.28679675,
      '621500': 0.14542531,
      '621600': 0.24376495,
      '621900': 0.09456219,
    },
  },
  ['8534']: {
    label: 'Chiropractic and Osteopathic Services',
    BEA_CODES: {
      '621300': 0.2294508,
      '621400': 0.28679675,
      '621500': 0.14542531,
      '621600': 0.24376495,
      '621900': 0.09456219,
    },
  },
  ['8539']: {
    label: 'Other Allied Health Services',
    BEA_CODES: {
      '621300': 0.2294508,
      '621400': 0.28679675,
      '621500': 0.14542531,
      '621600': 0.24376495,
      '621900': 0.09456219,
    },
  },
  ['8591']: {
    label: 'Ambulance Services',
    BEA_CODES: {
      '621300': 0.2294508,
      '621400': 0.28679675,
      '621500': 0.14542531,
      '621600': 0.24376495,
      '621900': 0.09456219,
    },
  },
  ['8599']: {
    label: 'Other Health Care Services n.e.c.',
    BEA_CODES: {
      '621300': 0.2294508,
      '621400': 0.28679675,
      '621500': 0.14542531,
      '621600': 0.24376495,
      '621900': 0.09456219,
    },
  },
  ['8601']: {
    label: 'Aged Care Residential Services',
    BEA_CODES: { '623A00': 1.0 },
  },
  ['8609']: {
    label: 'Other Residential Care Services',
    BEA_CODES: { '623A00': 1.0 },
  },
  ['8710']: {
    label: 'Child Care Services',
    BEA_CODES: {
      '5419A0': 0.24884107,
      '621400': 0.26944508,
      '624100': 0.22637946,
      '624400': 0.13258115,
      '624A00': 0.12275324,
    },
  },
  ['8790']: {
    label: 'Other Social Assistance Services',
    BEA_CODES: {
      '722110': 0.38500025,
      '722211': 0.40996607,
      '722A00': 0.20503367,
    },
  },
  ['8910']: {
    label: 'Museum Operation',
    BEA_CODES: {
      '711100': 0.33565195,
      '711500': 0.38525127,
      '711A00': 0.27909677,
    },
  },
  ['8921']: {
    label: 'Zoological and Botanical Gardens Operation',
    BEA_CODES: { '712000': 1.0 },
  },
  ['8922']: {
    label: 'Nature Reserves and Conservation Parks Operation',
    BEA_CODES: { '712000': 1.0 },
  },
  ['9001']: {
    label: 'Performing Arts Operation',
    BEA_CODES: {
      '711100': 0.33565195,
      '711500': 0.38525127,
      '711A00': 0.27909677,
    },
  },
  ['9002']: {
    label: 'Creative Artists, Musicians, Writers and Performers',
    BEA_CODES: {
      '518200': 0.58890874,
      '541920': 0.04830593,
      '711500': 0.12766762,
      '812900': 0.23511771,
    },
  },
  ['9003']: {
    label: 'Performing Arts Venue Operation',
    BEA_CODES: {
      '711100': 0.33565195,
      '711500': 0.38525127,
      '711A00': 0.27909677,
    },
  },
  ['9111']: {
    label: 'Health and Fitness Centres and Gymnasia Operation',
    BEA_CODES: {
      '711200': 0.27323775,
      '711A00': 0.20117213,
      '713900': 0.52559011,
    },
  },
  ['9112']: {
    label: 'Sports and Physical Recreation Clubs and Sports Professionals',
    BEA_CODES: { '711200': 0.34204835, '713900': 0.65795165 },
  },
  ['9113']: {
    label:
      'Sports and Physical Recreation Venues, Grounds and Facilities Operation',
    BEA_CODES: {
      '711200': 0.27323775,
      '711A00': 0.20117213,
      '713900': 0.52559011,
    },
  },
  ['9114']: {
    label: 'Sports and Physical Recreation Administrative Service',
    BEA_CODES: {
      '711200': 0.17254877,
      '711A00': 0.12703956,
      '713900': 0.33190848,
      '813B00': 0.3685032,
    },
  },
  ['9121']: {
    label: 'Horse and Dog Racing Administration and Track Operation',
    BEA_CODES: {
      '711200': 0.27323775,
      '711A00': 0.20117213,
      '713900': 0.52559011,
    },
  },
  ['9129']: {
    label: 'Other Horse and Dog Racing Activities',
    BEA_CODES: {
      '711200': 0.17254877,
      '711A00': 0.12703956,
      '713900': 0.33190848,
      '813B00': 0.3685032,
    },
  },
  ['9131']: {
    label: 'Amusement Parks and Centres Operation',
    BEA_CODES: { '713100': 1.0 },
  },
  ['9139']: {
    label: 'Amusement and Other Recreational Activities n.e.c.',
    BEA_CODES: {
      '711200': 0.27323775,
      '711A00': 0.20117213,
      '713900': 0.52559011,
    },
  },
  ['9201']: {
    label: 'Casino Operation',
    BEA_CODES: { '713200': 0.36491892, '721000': 0.63508108 },
  },
  ['9202']: {
    label: 'Lottery Operation',
    BEA_CODES: { '713200': 0.36491892, '721000': 0.63508108 },
  },
  ['9209']: {
    label: 'Other Gambling Activities',
    BEA_CODES: { '713200': 0.36491892, '721000': 0.63508108 },
  },
  ['9411']: {
    label: 'Automotive Electrical Services',
    BEA_CODES: { '811100': 0.75685377, '811300': 0.24314623 },
  },
  ['9412']: {
    label: 'Automotive Body, Paint and Interior Repair',
    BEA_CODES: { '811100': 0.75685377, '811300': 0.24314623 },
  },
  ['9419']: {
    label: 'Other Automotive Repair and Maintenance',
    BEA_CODES: { '811100': 0.75685377, '811300': 0.24314623 },
  },
  ['9421']: {
    label: 'Domestic Appliance Repair and Maintenance',
    BEA_CODES: { '4B0000': 0.82775029, '811200': 0.17224971 },
  },
  ['9422']: {
    label:
      'Electronic (except Domestic Appliance) and Precision Equipment Repair and Maintenance',
    BEA_CODES: {
      '811200': 0.33989093,
      '811300': 0.42880648,
      '811400': 0.23130259,
    },
  },
  ['9429']: {
    label: 'Other Machinery and Equipment Repair and Maintenance',
    BEA_CODES: { '811300': 0.64959944, '811400': 0.35040056 },
  },
  ['9491']: {
    label: 'Clothing and Footwear Repair',
    BEA_CODES: { '448000': 0.82480351, '811400': 0.17519649 },
  },
  ['9499']: {
    label: 'Other Repair and Maintenance n.e.c.',
    BEA_CODES: { '811300': 0.64959944, '811400': 0.35040056 },
  },
  ['9511']: {
    label: 'Hairdressing and Beauty Services',
    BEA_CODES: { '812100': 1.0 },
  },
  ['9512']: {
    label: 'Diet and Weight Reduction Centre Operation',
    BEA_CODES: { '812100': 0.51539785, '812900': 0.48460215 },
  },
  ['9520']: {
    label: 'Funeral, Crematorium and Cemetery Services',
    BEA_CODES: { '812200': 1.0 },
  },
  ['9531']: {
    label: 'Laundry and Dry-Cleaning Services',
    BEA_CODES: { '561700': 0.86290394, '812300': 0.13709606 },
  },
  ['9532']: {
    label: 'Photographic Film Processing',
    BEA_CODES: {
      '518200': 0.58890874,
      '541920': 0.04830593,
      '711500': 0.12766762,
      '812900': 0.23511771,
    },
  },
  ['9533']: {
    label: 'Parking Services',
    BEA_CODES: { '48A000': 0.71945049, '812900': 0.28054951 },
  },
  ['9534']: {
    label: 'Brothel Keeping and Prostitution Services',
    BEA_CODES: { '812100': 0.51539785, '812900': 0.48460215 },
  },
  ['9539']: {
    label: 'Other Personal Services n.e.c.',
    BEA_CODES: { '812100': 0.51539785, '812900': 0.48460215 },
  },
  ['9540']: { label: 'Religious Services', BEA_CODES: { '813100': 1.0 } },
  ['9551']: {
    label: 'Business and Professional Association Services',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['9552']: {
    label: 'Labour Association Services',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['9559']: {
    label: 'Other Interest Group Services n.e.c.',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['9601']: {
    label: 'Private Households Employing Staff',
    BEA_CODES: { '814000': 1.0 },
  },
};
