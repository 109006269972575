import {
  GQCancelFootprintReviewRequestMutation,
  GQFootprintsForFootprintSelectorQuery,
} from '@watershed/app-dashboard/generated/graphql-operations';

export function mergeCancelWatershedFootprintReviewRequestIntoFootprintSelector(
  data: GQFootprintsForFootprintSelectorQuery | null,
  payload: GQCancelFootprintReviewRequestMutation['deleteWatershedFootprintReviewRequest']
): GQFootprintsForFootprintSelectorQuery | null {
  if (data === null) {
    return null;
  }

  const updatedFootprints = data.footprints.edges.map((edge) => {
    if (!edge?.node) {
      return edge;
    }

    const updatedSnapshots = edge.node.footprintSnapshots.map((snapshot) => {
      if (snapshot.id === payload.footprintSnapshot.id) {
        return {
          ...snapshot,
          watershedFootprintReviewRequest: null,
        };
      }
      return snapshot;
    });

    return {
      ...edge,
      node: {
        ...edge.node,
        footprintSnapshots: updatedSnapshots,
      },
    };
  });

  return {
    ...data,
    footprints: {
      ...data.footprints,
      edges: updatedFootprints,
    },
  };
}
