import { Box, Checkbox, Stack, Typography } from '@mui/material';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';
import { IconProps } from '@watershed/icons/Icon';
import { InvertedIndexBuilder } from '@watershed/shared-universal/utils/InvertedIndex';
import {
  ComponentType,
  forwardRef,
  memo,
  ReactNode,
  useDeferredValue,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';
import useKeydown from '../../../hooks/useKeydown';
import groupBy from 'lodash/groupBy';
import { isPromise } from '@watershed/shared-universal/utils/helpers';
import { BadInputError } from '@watershed/errors/BadInputError';
import { Searcher, sortKind } from 'fast-fuzzy';
import { useVirtualizer } from '@tanstack/react-virtual';

import AddIcon from '@watershed/icons/components/Add';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useCommandPalettePageContext } from '../CommandPaletteContext';
import useScheduleLayoutEffect from '../../../hooks/useScheduleLayoutEffect';

import useEffectEventShim from '../../../hooks/useEffectEvent';
import { CommandPalettePageContextShape } from '../types';

interface CommandListItemOnSelectArgs<P> {
  command: CommandListItem<P>;
}

export interface CommandListItem<P> {
  id: string;
  title: string;
  titleAffix?: ReactNode;
  group?: string;
  description?: string;
  keywords?: Array<string>;
  icon?: ComponentType<IconProps>;
  rightAdornment?: JSX.Element;
  onSelect: (
    params: CommandListItemOnSelectArgs<P>,
    palette: CommandPalettePageContextShape
  ) => void | Promise<void>;
  render?: () => React.ReactNode;
  disabled?: boolean;
}

interface CommandListItemProps<P> {
  command: CommandListItem<P>;
  isHighlighted: boolean;
  enableMultiSelection?: boolean;
  isInSelection?: boolean;
  toggleSelection?: (id: string) => void;
}

export interface CommandListCommandRef {
  execute: () => void;
}

export const CommandListCommand = memo(
  forwardRef(function CommandListCommand<P>(
    {
      command,
      isHighlighted,
      enableMultiSelection = false,
      isInSelection = false,
      toggleSelection = () => {},
    }: CommandListItemProps<P>,
    ref: React.Ref<CommandListCommandRef>
  ) {
    const palette = useCommandPalettePageContext();
    const [isAsyncPending, setIsAsyncPending] = useState<boolean>(false);
    const [isPending, startTransition] = useTransition();
    const {
      onSelect,
      title,
      titleAffix,
      description,
      icon: Icon,
      rightAdornment,
      disabled,
    } = command;

    const execute = () => {
      startTransition(() => {
        const returnVal = onSelect({ command }, palette);

        if (isPromise(returnVal)) {
          // If onSelect returns a promise, we'll show a spinner until it
          setIsAsyncPending(true);
          void returnVal.finally(() => {
            setIsAsyncPending(false);
          });
        }
        // We have to return void, because startTransition doesn't work with
        // promises.
        return;
      });
    };

    useImperativeHandle(ref, () => ({
      execute,
    }));

    return (
      <Stack
        data-is-highlighted={isHighlighted && !disabled}
        tabIndex={-1}
        component="button"
        direction="row"
        alignItems="flex-start"
        flexWrap="nowrap"
        gap={1}
        py={1}
        px={1.5}
        color={(theme) => theme.palette.grey50}
        sx={{
          borderWidth: 0,
          backgroundColor: 'transparent',
          borderRadius: 1,
          '&[data-is-highlighted=true]': {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        }}
        onClick={
          disabled
            ? undefined
            : enableMultiSelection
              ? (event) => {
                  event.currentTarget.blur();
                  toggleSelection(command.id);
                }
              : execute
        }
      >
        {enableMultiSelection ? (
          <Box
            // Add some padding to the checkbox to make it easier to click
            sx={{ alignSelf: 'center', padding: 1, margin: -1 }}
          >
            <Checkbox
              sx={{
                marginRight: 0.5,
                alignSelf: 'center',
                boxShadow: 'none',
                borderStyle: 'solid',
                borderWidth: 0.5,
                borderColor: 'grey.400',
              }}
              checked={isInSelection}
            />
          </Box>
        ) : null}
        {command.render ? (
          command.render()
        ) : (
          <>
            {Icon ? <Icon size={16} mt="4px" sx={{ flexShrink: 0 }} /> : null}
            <Stack flexGrow={1}>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography variant="body1" textAlign="left">
                  {title}
                </Typography>
                {titleAffix}
              </Stack>
              {description ? (
                <Typography variant="body3" textAlign="left">
                  {description}
                </Typography>
              ) : null}
            </Stack>
            <Stack
              alignSelf="center"
              direction="row"
              gap={1}
              alignItems="center"
            >
              {isPending || isAsyncPending ? (
                <Stack mx={0.75}>
                  <CircularProgress color="inherit" size="16px" />
                </Stack>
              ) : (
                rightAdornment
              )}
            </Stack>
          </>
        )}
      </Stack>
    );
  })
);

type CommandListProps<P> = {
  searchTerm: string;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  commands:
    | Array<CommandListItem<P>>
    | (() => Promise<Array<CommandListItem<P>>> | Array<CommandListItem<P>>)
    | ((
        search: string
      ) => Promise<Array<CommandListItem<P>>> | Array<CommandListItem<P>>);
  fetchCommandsKey?: string;
  newValueCommand?: Omit<CommandListItem<P>, 'onSelect'> & {
    onSelect: (value: string, palette: CommandPalettePageContextShape) => void;
  };
  searchMode?: 'prefix' | 'fuzzy';
  enableMultiSelection?: boolean;
  multiSelection?: Array<string>;
  toggleSelection?: (id: string) => void;
  onSelectionAction?: (selection: Array<string>) => void;
};

export interface CommandListRef {
  executeHighlighted: () => void;
}

type GroupWrapper = { type: 'group'; object: string };
type CommandWrapper = { type: 'command'; object: CommandListItem<unknown> };

const commandFilter = (
  item: GroupWrapper | CommandWrapper
): item is CommandWrapper => item.type === 'command';

export default forwardRef(function CommandList<P>(
  {
    commands,
    scrollContainerRef,
    searchTerm,
    fetchCommandsKey,
    newValueCommand,
    searchMode = 'prefix',
    enableMultiSelection = false,
    multiSelection = [],
    toggleSelection = () => {},
    onSelectionAction = () => {},
  }: CommandListProps<P>,
  ref: React.Ref<CommandListRef>
) {
  const schedule = useScheduleLayoutEffect();
  const palette = useCommandPalettePageContext();

  if (!Array.isArray(commands) && !fetchCommandsKey) {
    throw new BadInputError(
      'Must provide fetchCommandsKey if commands is callable'
    );
  }

  const { data: resolvedCommands } = useSuspenseQuery({
    queryKey: [
      !Array.isArray(commands) && commands.length === 1
        ? `getCommands-${fetchCommandsKey}-${searchTerm}`
        : `getCommands-${fetchCommandsKey}`,
    ],
    queryFn: Array.isArray(commands)
      ? () => commands
      : commands.length === 0
        ? () =>
            // Typescript doesn't narrow on Function.length, so we have to cast
            (
              commands as () =>
                | Promise<Array<CommandListItem<P>>>
                | Array<CommandListItem<P>>
            )()
        : () => commands(searchTerm),
    // Don't cache non-callables, but cache callables for the duration of the
    // command palette's open/close lifecycle
    staleTime: Array.isArray(commands) ? 0 : Infinity,
  });

  const deferredResolvedCommands = useDeferredValue(resolvedCommands);

  const itemsRef = useRef<Map<string, CommandListCommandRef> | null>(null);

  function getMap() {
    if (!itemsRef.current) {
      // Initialize the Map on first usage.
      itemsRef.current = new Map<string, CommandListCommandRef>();
    }
    return itemsRef.current;
  }

  const search = useMemo(() => {
    switch (searchMode) {
      case 'fuzzy': {
        const searcher = new Searcher(deferredResolvedCommands, {
          sortBy: sortKind.insertOrder,
          threshold: 0.8,
          keySelector: (command) =>
            [
              command.title.replace(/[\(\)._-]/g, ' ').replace(/\'/g, ''),
              command.title,
              ...(command.keywords ?? []),
            ].join(' '),
        });
        return (input: string) => searcher.search(input);
      }
      default: {
        const builder = new InvertedIndexBuilder<
          (typeof deferredResolvedCommands)[0]
        >();
        for (const command of deferredResolvedCommands) {
          const cleanedTitle = command.title
            .replace(/[\(\)._-]/g, ' ')
            .replace(/\'/g, '');
          builder.add(
            command,
            [command.title, cleanedTitle, ...(command.keywords ?? [])].join(' ')
          );
        }
        const index = builder.compile();
        return (input: string) => index.findAllPrefix(input);
      }
    }
  }, [searchMode, deferredResolvedCommands]);

  let filteredCommands = useMemo(() => {
    return (Array.isArray(commands) || commands.length === 0) && searchTerm
      ? Array.from(search(searchTerm))
      : deferredResolvedCommands;
  }, [commands, searchTerm, search, deferredResolvedCommands]);

  // If we have an exact match, we want to hide the option to add a new command
  // as we prefer selecting the existing value.
  const isExact = useMemo(
    () =>
      deferredResolvedCommands.find(
        (cmd) => cmd.title.trim() === searchTerm.trim()
      ) !== undefined,
    [deferredResolvedCommands, searchTerm]
  );

  if (!isExact && newValueCommand && searchTerm.length > 0) {
    filteredCommands = [
      ...filteredCommands,
      {
        id: newValueCommand.id,
        title: newValueCommand.title,
        render: () => (
          <>
            <AddIcon size={16} mt="4px" />
            <Stack flexGrow={1}>
              <Typography variant="body1" textAlign="left">
                {newValueCommand.title}:{' '}
                <Typography
                  component="strong"
                  sx={{
                    textDecoration: 'underline',
                    textDecorationThickness: '1px',
                    textUnderlineOffset: '3px',
                  }}
                >
                  {searchTerm}
                </Typography>
              </Typography>
            </Stack>
          </>
        ),
        onSelect: () => newValueCommand.onSelect(searchTerm, palette),
      },
    ];
  }

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const groupsAndCommands = useMemo(() => {
    const commandGroups = groupBy(filteredCommands, (cmd) => cmd.group ?? '');
    return Object.entries(commandGroups).flatMap(([group, commands]) => {
      return [
        ...(group ? [{ type: 'group' as const, object: group }] : []),
        ...commands.map((command) => ({
          type: 'command' as const,
          object: command,
        })),
      ].filter(Boolean);
    });
  }, [filteredCommands]);

  const commandsOnly = useMemo(
    () => groupsAndCommands.filter(commandFilter).map((item) => item.object),
    [groupsAndCommands]
  );

  const highlightedCommand = commandsOnly[currentIndex] ?? null;

  const rootRef = useRef<HTMLDivElement | null>(null);

  const rowVirtualizer = useVirtualizer({
    count: groupsAndCommands.length,
    // This is awkward, but we can't pass this in as a ref because the
    // ref won't be available until after the component mounts which causes
    // some ugly jank on first load.
    getScrollElement: () =>
      rootRef.current?.parentElement?.parentElement ?? null,
    estimateSize: (i) => 45,
    paddingStart: 12,
    paddingEnd: 12,
    scrollPaddingStart: 12,
    scrollPaddingEnd: 12,
    overscan: 5,
  });

  const scrollSelectedIntoView = useEffectEventShim(() => {
    const containerEl = scrollContainerRef.current;
    if (!highlightedCommand || !containerEl) {
      return;
    }

    if (currentIndex === 0) {
      // If the first command is highlighted, scroll to the top of the list
      rowVirtualizer.scrollToOffset(0);
      return;
    }

    // Index of the highlighted command in the virtualized list
    const virtualIndex = groupsAndCommands.findIndex(
      (item) =>
        item.type === 'command' && item.object.id === highlightedCommand.id
    );

    if (!virtualIndex) {
      return;
    }

    rowVirtualizer.scrollToIndex(virtualIndex);
  });

  useImperativeHandle(ref, () => ({
    executeHighlighted() {
      const map = itemsRef.current;
      if (map) {
        const node = map.get(highlightedCommand?.id ?? '');
        if (node) {
          node.execute();
        } else {
          palette.shake();
        }
      }
    },
  }));

  useKeydown((event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        // Decrement current index, but loop around
        setCurrentIndex((currentIndex) => {
          if (filteredCommands.length === 0) return 0;
          const nextIndex = currentIndex - 1;
          return nextIndex < 0 ? filteredCommands.length - 1 : nextIndex;
        });
        schedule('update-scroll', scrollSelectedIntoView);
        break;
      case 'ArrowDown':
        event.preventDefault();
        // Increment current index, but loop around
        setCurrentIndex((currentIndex) => {
          if (filteredCommands.length === 0) return 0;
          const nextIndex = currentIndex + 1;
          return nextIndex >= filteredCommands.length ? 0 : nextIndex;
        });
        schedule('update-scroll', scrollSelectedIntoView);
        break;
      case 'PageUp':
        // Decrement current index by 10, but don't loop around
        setCurrentIndex((currentIndex) => {
          if (filteredCommands.length === 0) return 0;
          const nextIndex = currentIndex - 10;
          return nextIndex < 0 ? 0 : nextIndex;
        });
        schedule('update-scroll', scrollSelectedIntoView);
        break;
      case 'PageDown':
        // Increment current index by 10, but don't loop around
        setCurrentIndex((currentIndex) => {
          if (filteredCommands.length === 0) return 0;
          const nextIndex = currentIndex + 10;
          return nextIndex >= filteredCommands.length
            ? filteredCommands.length - 1
            : nextIndex;
        });
        schedule('update-scroll', scrollSelectedIntoView);
        break;
      case ' ':
        if (!enableMultiSelection || !highlightedCommand) {
          break;
        }
        toggleSelection(highlightedCommand.id);
        break;
      case 'Enter':
        // Execute the highlighted command
        if (enableMultiSelection) {
          onSelectionAction(multiSelection);
          break;
        }

        if (highlightedCommand) {
          const map = getMap();
          const node = map.get(highlightedCommand.id);
          if (node && !highlightedCommand.disabled) {
            node.execute();
          } else {
            palette.shake();
          }
        } else {
          palette.shake();
        }
        break;
    }
  });

  if (filteredCommands.length === 0) {
    return null;
  }

  const items = rowVirtualizer.getVirtualItems();

  return (
    <Stack
      ref={rootRef}
      px={1.5}
      position="relative"
      style={{
        height: rowVirtualizer.getTotalSize(),
      }}
    >
      <Stack
        position="absolute"
        top={0}
        left={12}
        right={12}
        style={{
          transform: `translateY(${items[0]?.start ?? 0}px)`,
        }}
      >
        {items.map((virtualRow) => {
          const row = groupsAndCommands[virtualRow.index];

          return (
            <Stack
              key={virtualRow.index}
              ref={rowVirtualizer.measureElement}
              data-index={virtualRow.index}
            >
              {row.type === 'group' ? (
                <Typography
                  variant="body3"
                  py={0.5}
                  sx={{
                    'div:not(:first-of-type):has(&)': {
                      pt: 0.75,
                    },
                  }}
                >
                  {row.object}
                </Typography>
              ) : (
                <Stack
                  key={row.object.id}
                  data-testid={`command-${row.object.id}`}
                  data-command-id={row.object.id}
                  onMouseMove={(event) => {
                    // We're using onMouseMove rather than onMouseEnter or
                    // onMouseOver because we want access to movementX and
                    // movementY. This lets us avoid highlighting a
                    // command when the cursor is moved over it as a result
                    // of scrolling the list, e.g. via scroll wheel
                    if (
                      (event.movementX !== 0 || event.movementY !== 0) &&
                      !(
                        highlightedCommand &&
                        row.object.id === highlightedCommand?.id
                      )
                    ) {
                      setCurrentIndex(commandsOnly.indexOf(row.object));
                    }
                  }}
                >
                  <CommandListCommand
                    command={row.object}
                    ref={(node) => {
                      const map = getMap();
                      if (node) {
                        map.set(row.object.id, node);
                      } else {
                        map.delete(row.object.id);
                      }
                    }}
                    isHighlighted={
                      highlightedCommand &&
                      row.object.id === highlightedCommand?.id
                    }
                    enableMultiSelection={enableMultiSelection}
                    isInSelection={multiSelection.includes(row.object.id)}
                    toggleSelection={toggleSelection}
                  />
                </Stack>
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
});
