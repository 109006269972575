import { useState, useCallback } from 'react';
import { useLingui } from '@lingui/react/macro';
import { Message, PrecannedMessage, RichHiddenContext } from './types';
import { queryDust } from './api';
import { GQDustBot } from '@watershed/shared-universal/generated/graphql-schema-types';
import { useClient } from 'urql';
import { QuestionCache } from './questionCache';
import { WatershedError } from '@watershed/errors/WatershedError';

// Initialize a single instance of QuestionCache for the application
const questionCache = new QuestionCache();

async function getResponseFromCacheOrNetwork(
  question: string,
  fetchFn: () => Promise<string>,
  hiddenContext?: string | RichHiddenContext
): Promise<string> {
  const cached = questionCache.get(question, hiddenContext);
  if (cached) {
    if (cached.error) {
      throw cached.error;
    }
    if (cached.response) {
      return cached.response;
    }
  }

  try {
    const response = await fetchFn();
    questionCache.set(question, response, hiddenContext);
    return response;
  } catch (error) {
    if (error instanceof WatershedError) {
      questionCache.setError(question, error, hiddenContext);
    }
    throw error;
  }
}

export function useQuestionHandler(
  dustBot?: GQDustBot,
  precannedMessages?: Array<PrecannedMessage>
) {
  const { t } = useLingui();
  const [activeMessages, setActiveMessages] = useState<Array<Message>>([]);
  const [isTyping, setIsTyping] = useState(false);
  const client = useClient();

  const handleQuestionSubmit = useCallback(
    async (question: string, hiddenContext?: string | RichHiddenContext) => {
      if (!dustBot) {
        console.error('Dust configuration is missing.');
        return;
      }

      setActiveMessages((prev) => [
        ...prev,
        {
          content: question,
          complete: true,
          isUser: true,
          timestamp: Date.now(),
        } as Message,
      ]);

      setIsTyping(true);

      try {
        const answer = await getResponseFromCacheOrNetwork(
          question,
          () => queryDust(client, question, dustBot, hiddenContext),
          hiddenContext
        );

        const startTime = Date.now();
        setActiveMessages((prev) => [
          ...prev,
          {
            content: '',
            complete: false,
            isUser: false,
            timestamp: startTime,
          } as Message,
        ]);

        const words = answer.split(' ');
        let currentContent = '';

        for (const word of words) {
          currentContent += (currentContent ? ' ' : '') + word;
          await new Promise((resolve) => setTimeout(resolve, 50));
          setActiveMessages((prev) => [
            ...prev.slice(0, -1),
            {
              content: currentContent,
              complete: false,
              isUser: false,
              timestamp: startTime,
            } as Message,
          ]);
        }

        setActiveMessages((prev) => [
          ...prev.slice(0, -1),
          {
            content: answer,
            complete: true,
            isUser: false,
            timestamp: startTime,
          } as Message,
        ]);
      } catch (error) {
        console.error('Error handling question:', error);
        setActiveMessages((prev) => [
          ...prev,
          {
            content: t({
              message: "I'm sorry, I couldn't process your question right now.",
              context: 'Error message when Dust query fails',
            }),
            complete: true,
            isUser: false,
            timestamp: Date.now(),
          } as Message,
        ]);
      } finally {
        setIsTyping(false);
      }
    },
    [dustBot, t, client]
  );

  const resetChat = useCallback(() => {
    setActiveMessages([]);
    setIsTyping(false);
    // Clear the cache when resetting the chat
    questionCache.clear();
  }, []);

  return {
    activeMessages,
    isTyping,
    handleQuestionSubmit,
    precannedMessages,
    resetChat,
  };
}
