import { Input } from '@mui/material';
import { useLingui } from '@lingui/react/macro';

import React, {
  ComponentPropsWithRef,
  ElementRef,
  Suspense,
  startTransition,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import useKeydown from '../../../hooks/useKeydown';

import { useCommandPalettePageContext } from '../CommandPaletteContext';
import CommandPalettePageLayout from '../CommandPalettePageLayout';

import CommandList from './CommandList';

export type CommandListPageProps = Omit<
  ComponentPropsWithRef<typeof CommandList>,
  'searchTerm' | 'scrollContainerRef'
> & {
  searchIcon?: React.ReactNode;
  searchPlaceholder?: string;
  headerAttachment?: React.ReactNode;
  enterActionLabel?: string;
  enableMultiSelection?: boolean;
  onSelectionAction?: (selected: Array<string>) => void;
};

export default function CommandListPage({
  commands,
  searchPlaceholder,
  headerAttachment,
  enterActionLabel,
  fetchCommandsKey,
  newValueCommand,
  searchMode,
  enableMultiSelection = false,
  onSelectionAction = () => {},
}: CommandListPageProps) {
  const { t } = useLingui();
  const listRef = useRef<ElementRef<typeof CommandList> | null>(null);
  const palette = useCommandPalettePageContext();
  const [multiSelection, setMultiSelection] = useState<Array<string>>([]);

  const toggleSelection = useCallback((id: string) => {
    setMultiSelection((prev) =>
      prev.includes(id) ? prev.filter((x) => x !== id) : [...prev, id]
    );
  }, []);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useKeydown((event: KeyboardEvent) => {
    switch (event.key) {
      case 'Backspace':
        if (searchTerm.length === 0 && palette.location.length > 1) {
          palette.pop();
        }
        break;
    }
  });

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <CommandPalettePageLayout
      scrollContainerRef={scrollContainerRef}
      enterActionLabel={enterActionLabel ?? t`Open`}
      onEnterAction={
        enableMultiSelection
          ? multiSelection.length > 0
            ? () => onSelectionAction(multiSelection)
            : undefined
          : () => listRef.current?.executeHighlighted()
      }
      slots={{
        headerAttachment,
        header: (
          <Input
            ref={inputRef}
            placeholder={searchPlaceholder ?? t`Type or search a command…`}
            autoFocus
            sx={{
              gap: 1,
            }}
            value={searchTerm}
            onChange={(e) =>
              startTransition(() => setSearchTerm(e.target.value.trimStart()))
            }
          />
        ),
        content:
          palette.location.length > 1 ? (
            <CommandList
              ref={listRef}
              scrollContainerRef={scrollContainerRef}
              searchTerm={searchTerm}
              commands={commands}
              fetchCommandsKey={fetchCommandsKey}
              newValueCommand={newValueCommand}
              searchMode={searchMode}
              enableMultiSelection={enableMultiSelection}
              multiSelection={multiSelection}
              toggleSelection={toggleSelection}
              onSelectionAction={(selection) => {
                if (selection.length > 0) {
                  onSelectionAction(selection);
                }
              }}
            />
          ) : (
            <Suspense fallback="">
              <CommandList
                ref={listRef}
                scrollContainerRef={scrollContainerRef}
                searchTerm={searchTerm}
                commands={commands}
                fetchCommandsKey={fetchCommandsKey}
                newValueCommand={newValueCommand}
                searchMode={searchMode}
                enableMultiSelection={enableMultiSelection}
                multiSelection={multiSelection}
                toggleSelection={toggleSelection}
                onSelectionAction={(selection) => {
                  if (selection.length > 0) {
                    onSelectionAction(selection);
                  }
                }}
              />
            </Suspense>
          ),
      }}
    />
  );
}
