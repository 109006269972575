import { useFeatureFlagContext as _useFeatureFlagContext } from '../../utils/FeatureFlag';
import { Plural } from '@lingui/react/macro';
import { useUserContext } from '../../utils/UserContext';
import Chip from '@mui/material/Chip';
import { useCommandPaletteTrigger } from '@watershed/shared-frontend/components/CommandPalette';
import FeatureFlagOverrideListPage from '../commandPalette/FeatureFlagOverrideListPage';

/**
 * For employees and login as users, show a chip with the number of feature
 * flags overridden (if any), and allow you to edit them on click.
 */
export function FlagOverridesIndicator({
  deps = {
    useFeatureFlagContext: _useFeatureFlagContext,
  },
}: {
  deps?: {
    useFeatureFlagContext: typeof _useFeatureFlagContext;
  };
}) {
  const { userIsWatershedEmployee, loginAsUser } = useUserContext();
  const isVisible = userIsWatershedEmployee || !!loginAsUser;

  const { flagOverrides } = deps.useFeatureFlagContext();
  const openCommandPalette = useCommandPaletteTrigger();

  // If this shouldn't be visible, or there are no overrides, don't render anything
  if (!isVisible || flagOverrides.size === 0) {
    return null;
  }

  return (
    <Chip
      label={
        <Plural
          value={flagOverrides.size}
          one="# flag overridden"
          other="# flags overridden"
        />
      }
      sx={(theme) => ({
        backgroundColor: theme.palette.error.main,
        color: theme.palette.primary.contrastText,
      })}
      onClick={() =>
        openCommandPalette({
          component: FeatureFlagOverrideListPage,
          props: {},
        })
      }
    />
  );
}
