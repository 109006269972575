import { nullishDivide, nullishMultiply } from './math';

type OptionalNumber = number | '' | null | undefined;

export function dollarToCents(dollars: number): number {
  return Math.round(dollars * 100);
}

export function optionalDollarToCents(dollars: OptionalNumber): number | null {
  if (dollars === '' || dollars === null || dollars === undefined) return null;
  const value = typeof dollars === 'string' ? Number(dollars) : dollars;
  return Math.round(value * 100);
}

export function optionalCentsToDollar(cents: OptionalNumber): number | null;
export function optionalCentsToDollar<T>(
  cents: OptionalNumber,
  fallback: T
): number | T;
export function optionalCentsToDollar<T = null>(
  cents: OptionalNumber,
  fallback?: T
): number | T | null {
  if (cents === '' || cents === null || cents === undefined) {
    return fallback === undefined ? null : fallback;
  }
  return cents / 100;
}

export function optionalNativeCurrencyToUsd({
  nativeCurrencyAmount,
  currencyConversionRate,
}: {
  nativeCurrencyAmount: number | null | undefined;
  currencyConversionRate: number | null | undefined;
}): number | null {
  return nullishDivide(nativeCurrencyAmount, currencyConversionRate);
}

export function nativeCurrencyToUsd({
  nativeCurrencyAmount,
  currencyConversionRate,
}: {
  nativeCurrencyAmount: number;
  currencyConversionRate: number;
}): number {
  return nativeCurrencyAmount / currencyConversionRate;
}

export function optionalUsdToNativeCurrency({
  usdAmount,
  currencyConversionRate,
}: {
  usdAmount: number | null | undefined;
  currencyConversionRate: number | null | undefined;
}): number | null {
  return nullishMultiply(usdAmount, currencyConversionRate);
}

export function usdToNativeCurrency({
  usdAmount,
  currencyConversionRate,
}: {
  usdAmount: number;
  currencyConversionRate: number;
}): number {
  return usdAmount * currencyConversionRate;
}
