/*
  This file is semi-automatically generated from
  cliq/taxonomy/scripts/generate_naics_sectors_2022__ceda_5_weighted_mapping.py in the cliq repo
  Do not manually modify this file
*/
import type { WeightedBeaCodes } from './industryCodeUtils';

export const NAICS_SECTOR_CODES: {
  [key: string]: {
    label: string;
    BEA_CODES: WeightedBeaCodes;
  };
} = {
  ['110000']: {
    label: 'Agriculture, Forestry, Fishing and Hunting',
    BEA_CODES: {
      '1111A0': 0.09299347,
      '1111B0': 0.20140078,
      '111200': 0.03155379,
      '111300': 0.05602009,
      '111400': 0.04082189,
      '111900': 0.03828483,
      '112120': 0.08010791,
      '1121A0': 0.17075135,
      '112300': 0.08177581,
      '112A00': 0.08740445,
      '113000': 0.03890754,
      '114000': 0.01901369,
      '115000': 0.06096439,
    },
  },
  ['111000']: {
    label: 'Crop Production',
    BEA_CODES: {
      '1111A0': 0.20168845,
      '1111B0': 0.43680712,
      '111200': 0.06843529,
      '111300': 0.1214989,
      '111400': 0.08853637,
      '111900': 0.08303387,
    },
  },
  ['111100']: {
    label: 'Oilseed and Grain Farming',
    BEA_CODES: { '1111A0': 0.31588074, '1111B0': 0.68411926 },
  },
  ['111110']: { label: 'Soybean Farming', BEA_CODES: { '1111A0': 1.0 } },
  ['111120']: {
    label: 'Oilseed (except Soybean) Farming',
    BEA_CODES: { '1111A0': 1.0 },
  },
  ['111130']: {
    label: 'Dry Pea and Bean Farming',
    BEA_CODES: { '1111B0': 1.0 },
  },
  ['111140']: { label: 'Wheat Farming', BEA_CODES: { '1111B0': 1.0 } },
  ['111150']: { label: 'Corn Farming', BEA_CODES: { '1111B0': 1.0 } },
  ['111160']: { label: 'Rice Farming', BEA_CODES: { '1111B0': 1.0 } },
  ['111190']: { label: 'Other Grain Farming', BEA_CODES: { '1111B0': 1.0 } },
  ['111200']: {
    label: 'Vegetable and Melon Farming',
    BEA_CODES: { '111200': 1.0 },
  },
  ['111210']: {
    label: 'Vegetable and Melon Farming',
    BEA_CODES: { '111200': 1.0 },
  },
  ['111300']: {
    label: 'Fruit and Tree Nut Farming',
    BEA_CODES: { '111300': 1.0 },
  },
  ['111310']: { label: 'Orange Groves', BEA_CODES: { '111300': 1.0 } },
  ['111320']: {
    label: 'Citrus (except Orange) Groves',
    BEA_CODES: { '111300': 1.0 },
  },
  ['111330']: {
    label: 'Noncitrus Fruit and Tree Nut Farming',
    BEA_CODES: { '111300': 1.0 },
  },
  ['111400']: {
    label: 'Greenhouse, Nursery, and Floriculture Production',
    BEA_CODES: { '111400': 1.0 },
  },
  ['111410']: {
    label: 'Food Crops Grown Under Cover',
    BEA_CODES: { '111400': 1.0 },
  },
  ['111420']: {
    label: 'Nursery and Floriculture Production',
    BEA_CODES: { '111400': 1.0 },
  },
  ['111900']: { label: 'Other Crop Farming', BEA_CODES: { '111900': 1.0 } },
  ['111910']: { label: 'Tobacco Farming', BEA_CODES: { '111900': 1.0 } },
  ['111920']: { label: 'Cotton Farming', BEA_CODES: { '111900': 1.0 } },
  ['111930']: { label: 'Sugarcane Farming', BEA_CODES: { '111900': 1.0 } },
  ['111940']: { label: 'Hay Farming', BEA_CODES: { '111900': 1.0 } },
  ['111990']: { label: 'All Other Crop Farming', BEA_CODES: { '111900': 1.0 } },
  ['112000']: {
    label: 'Animal Production and Aquaculture',
    BEA_CODES: {
      '112120': 0.19071517,
      '1121A0': 0.40651258,
      '112300': 0.194686,
      '112A00': 0.20808625,
    },
  },
  ['112100']: {
    label: 'Cattle Ranching and Farming',
    BEA_CODES: { '112120': 0.31933407, '1121A0': 0.68066593 },
  },
  ['112110']: {
    label: 'Beef Cattle Ranching and Farming, including Feedlots',
    BEA_CODES: { '1121A0': 1.0 },
  },
  ['112120']: {
    label: 'Dairy Cattle and Milk Production',
    BEA_CODES: { '112120': 1.0 },
  },
  ['112130']: {
    label: 'Dual-Purpose Cattle Ranching and Farming',
    BEA_CODES: { '1121A0': 1.0 },
  },
  ['112200']: { label: 'Hog and Pig Farming', BEA_CODES: { '112A00': 1.0 } },
  ['112210']: { label: 'Hog and Pig Farming', BEA_CODES: { '112A00': 1.0 } },
  ['112300']: {
    label: 'Poultry and Egg Production',
    BEA_CODES: { '112300': 1.0 },
  },
  ['112310']: { label: 'Chicken Egg Production', BEA_CODES: { '112300': 1.0 } },
  ['112320']: {
    label: 'Broilers and Other Meat Type Chicken Production',
    BEA_CODES: { '112300': 1.0 },
  },
  ['112330']: { label: 'Turkey Production', BEA_CODES: { '112300': 1.0 } },
  ['112340']: { label: 'Poultry Hatcheries', BEA_CODES: { '112300': 1.0 } },
  ['112390']: {
    label: 'Other Poultry Production',
    BEA_CODES: { '112300': 1.0 },
  },
  ['112400']: { label: 'Sheep and Goat Farming', BEA_CODES: { '112A00': 1.0 } },
  ['112410']: { label: 'Sheep Farming', BEA_CODES: { '112A00': 1.0 } },
  ['112420']: { label: 'Goat Farming', BEA_CODES: { '112A00': 1.0 } },
  ['112500']: { label: 'Aquaculture', BEA_CODES: { '112A00': 1.0 } },
  ['112510']: { label: 'Aquaculture', BEA_CODES: { '112A00': 1.0 } },
  ['112900']: {
    label: 'Other Animal Production',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['112910']: { label: 'Apiculture', BEA_CODES: { '112A00': 1.0 } },
  ['112920']: {
    label: 'Horses and Other Equine Production',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['112930']: {
    label: 'Fur-Bearing Animal and Rabbit Production',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['112990']: {
    label: 'All Other Animal Production',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['113000']: { label: 'Forestry and Logging', BEA_CODES: { '113000': 1.0 } },
  ['113100']: {
    label: 'Timber Tract Operations',
    BEA_CODES: { '113000': 1.0 },
  },
  ['113110']: {
    label: 'Timber Tract Operations',
    BEA_CODES: { '113000': 1.0 },
  },
  ['113200']: {
    label: 'Forest Nurseries and Gathering of Forest Products',
    BEA_CODES: { '113000': 1.0 },
  },
  ['113210']: {
    label: 'Forest Nurseries and Gathering of Forest Products',
    BEA_CODES: { '113000': 1.0 },
  },
  ['113300']: { label: 'Logging', BEA_CODES: { '113000': 1.0 } },
  ['113310']: { label: 'Logging', BEA_CODES: { '113000': 1.0 } },
  ['114000']: {
    label: 'Fishing, Hunting and Trapping',
    BEA_CODES: { '114000': 1.0 },
  },
  ['114100']: { label: 'Fishing', BEA_CODES: { '114000': 1.0 } },
  ['114110']: { label: 'Fishing', BEA_CODES: { '114000': 1.0 } },
  ['114200']: { label: 'Hunting and Trapping', BEA_CODES: { '114000': 1.0 } },
  ['114210']: { label: 'Hunting and Trapping', BEA_CODES: { '114000': 1.0 } },
  ['115000']: {
    label: 'Support Activities for Agriculture and Forestry',
    BEA_CODES: { '115000': 1.0 },
  },
  ['115100']: {
    label: 'Support Activities for Crop Production',
    BEA_CODES: { '115000': 1.0 },
  },
  ['115110']: {
    label: 'Support Activities for Crop Production',
    BEA_CODES: { '115000': 1.0 },
  },
  ['115200']: {
    label: 'Support Activities for Animal Production',
    BEA_CODES: { '115000': 1.0 },
  },
  ['115210']: {
    label: 'Support Activities for Animal Production',
    BEA_CODES: { '115000': 1.0 },
  },
  ['115300']: {
    label: 'Support Activities for Forestry',
    BEA_CODES: { '115000': 1.0 },
  },
  ['115310']: {
    label: 'Support Activities for Forestry',
    BEA_CODES: { '115000': 1.0 },
  },
  ['210000']: {
    label: 'Mining, Quarrying, and Oil and Gas Extraction',
    BEA_CODES: {
      '211000': 0.510305,
      '212100': 0.07739348,
      '212230': 0.01958165,
      '2122A0': 0.03812449,
      '212310': 0.0222475,
      '2123A0': 0.02503658,
      '213111': 0.08373582,
      '21311A': 0.22357549,
    },
  },
  ['211000']: { label: 'Oil and Gas Extraction', BEA_CODES: { '211000': 1.0 } },
  ['211100']: { label: 'Oil and Gas Extraction', BEA_CODES: { '211000': 1.0 } },
  ['211120']: {
    label: 'Crude Petroleum Extraction',
    BEA_CODES: { '211000': 1.0 },
  },
  ['211130']: { label: 'Natural Gas Extraction', BEA_CODES: { '211000': 1.0 } },
  ['212000']: {
    label: 'Mining (except Oil and Gas)',
    BEA_CODES: {
      '212100': 0.42434429,
      '212230': 0.10736511,
      '2122A0': 0.20903451,
      '212310': 0.12198185,
      '2123A0': 0.13727423,
    },
  },
  ['212100']: { label: 'Coal Mining', BEA_CODES: { '212100': 1.0 } },
  ['212110']: { label: 'Coal Mining', BEA_CODES: { '212100': 1.0 } },
  ['212200']: {
    label: 'Metal Ore Mining',
    BEA_CODES: { '212230': 0.33933389, '2122A0': 0.66066611 },
  },
  ['212210']: { label: 'Iron Ore Mining', BEA_CODES: { '2122A0': 1.0 } },
  ['212220']: {
    label: 'Gold Ore and Silver Ore Mining',
    BEA_CODES: { '2122A0': 1.0 },
  },
  ['212230']: {
    label: 'Copper, Nickel, Lead, and Zinc Mining',
    BEA_CODES: { '212230': 1.0 },
  },
  ['212290']: { label: 'Other Metal Ore Mining', BEA_CODES: { '2122A0': 1.0 } },
  ['212300']: {
    label: 'Nonmetallic Mineral Mining and Quarrying',
    BEA_CODES: { '212310': 0.47050719, '2123A0': 0.52949281 },
  },
  ['212310']: {
    label: 'Stone Mining and Quarrying',
    BEA_CODES: { '212310': 1.0 },
  },
  ['212320']: {
    label:
      'Sand, Gravel, Clay, and Ceramic and Refractory Minerals Mining and Quarrying',
    BEA_CODES: { '2123A0': 1.0 },
  },
  ['212390']: {
    label: 'Other Nonmetallic Mineral Mining and Quarrying',
    BEA_CODES: { '2123A0': 1.0 },
  },
  ['213000']: {
    label: 'Support Activities for Mining',
    BEA_CODES: { '213111': 0.27247881, '21311A': 0.72752119 },
  },
  ['213100']: {
    label: 'Support Activities for Mining',
    BEA_CODES: { '213111': 0.27247881, '21311A': 0.72752119 },
  },
  ['213110']: {
    label: 'Support Activities for Mining',
    BEA_CODES: { '213111': 0.27247881, '21311A': 0.72752119 },
  },
  ['220000']: {
    label: 'Utilities',
    BEA_CODES: {
      '221100': 0.72383543,
      '221200': 0.15873695,
      '221300': 0.11742762,
    },
  },
  ['221000']: {
    label: 'Utilities',
    BEA_CODES: {
      '221100': 0.72383543,
      '221200': 0.15873695,
      '221300': 0.11742762,
    },
  },
  ['221100']: {
    label: 'Electric Power Generation, Transmission and Distribution',
    BEA_CODES: { '221100': 1.0 },
  },
  ['221110']: {
    label: 'Electric Power Generation',
    BEA_CODES: {
      '221111': 0.07777778,
      '221113': 0.22222222,
      '221114': 0.00185185,
      '221115': 0.03888889,
      '221116': 0.0037037,
      '221117': 0.02037037,
      '221118': 0.00185185,
      '22111A': 0.3537037,
      '22111B': 0.27407407,
      '22111C': 0.00555556,
    },
  },
  ['221120']: {
    label: 'Electric Power Transmission, Control, and Distribution',
    BEA_CODES: { '221121': 0.26143791, '221122': 0.73856209 },
  },
  ['221200']: {
    label: 'Natural Gas Distribution',
    BEA_CODES: { '221200': 1.0 },
  },
  ['221210']: {
    label: 'Natural Gas Distribution',
    BEA_CODES: { '221200': 1.0 },
  },
  ['221300']: {
    label: 'Water, Sewage and Other Systems',
    BEA_CODES: { '221300': 1.0 },
  },
  ['221310']: {
    label: 'Water Supply and Irrigation Systems',
    BEA_CODES: { '221300': 1.0 },
  },
  ['221320']: {
    label: 'Sewage Treatment Facilities',
    BEA_CODES: { '221300': 1.0 },
  },
  ['221330']: {
    label: 'Steam and Air-Conditioning Supply',
    BEA_CODES: { '221300': 1.0 },
  },
  ['230000']: {
    label: 'Construction',
    BEA_CODES: {
      '230301': 0.15112043,
      '230302': 0.06276163,
      '233210': 0.0395894,
      '233230': 0.04187454,
      '233240': 0.09741961,
      '233262': 0.06996531,
      '2332A0': 0.06938545,
      '2332C0': 0.10468163,
      '2332D0': 0.08038055,
      '233411': 0.11408124,
      '233412': 0.02379498,
      '2334A0': 0.14494524,
    },
  },
  ['236000']: {
    label: 'Construction of Buildings',
    BEA_CODES: {
      '230301': 0.21061546,
      '230302': 0.08747043,
      '233210': 0.05517546,
      '233230': 0.05836024,
      '233262': 0.09751015,
      '2332A0': 0.096702,
      '233411': 0.15899421,
      '233412': 0.0331629,
      '2334A0': 0.20200914,
    },
  },
  ['236100']: {
    label: 'Residential Building Construction',
    BEA_CODES: {
      '230302': 0.18161081,
      '233411': 0.33011234,
      '233412': 0.06885459,
      '2334A0': 0.41942226,
    },
  },
  ['236110']: {
    label: 'Residential Building Construction',
    BEA_CODES: {
      '230302': 0.18161081,
      '233411': 0.33011234,
      '233412': 0.06885459,
      '2334A0': 0.41942226,
    },
  },
  ['236200']: {
    label: 'Nonresidential Building Construction',
    BEA_CODES: {
      '230301': 0.40630858,
      '233210': 0.10644167,
      '233230': 0.11258559,
      '233262': 0.1881116,
      '2332A0': 0.18655256,
    },
  },
  ['236210']: {
    label: 'Industrial Building Construction',
    BEA_CODES: { '230301': 0.78302783, '233230': 0.21697217 },
  },
  ['236220']: {
    label: 'Commercial and Institutional Building Construction',
    BEA_CODES: {
      '230301': 0.45785664,
      '233210': 0.11994584,
      '233262': 0.21197718,
      '2332A0': 0.21022034,
    },
  },
  ['237000']: {
    label: 'Heavy and Civil Engineering Construction',
    BEA_CODES: {
      '233210': 0.05036063,
      '233230': 0.0532675,
      '233240': 0.12392493,
      '233262': 0.08900104,
      '2332A0': 0.08826341,
      '2332C0': 0.13316275,
      '2332D0': 0.10224999,
      '233411': 0.14511976,
      '233412': 0.03026897,
      '2334A0': 0.18438104,
    },
  },
  ['237100']: {
    label: 'Utility System Construction',
    BEA_CODES: { '233240': 1.0 },
  },
  ['237110']: {
    label: 'Water and Sewer Line and Related Structures Construction',
    BEA_CODES: { '233240': 1.0 },
  },
  ['237120']: {
    label: 'Oil and Gas Pipeline and Related Structures Construction',
    BEA_CODES: { '233240': 1.0 },
  },
  ['237130']: {
    label: 'Power and Communication Line and Related Structures Construction',
    BEA_CODES: { '233240': 1.0 },
  },
  ['237200']: {
    label: 'Land Subdivision',
    BEA_CODES: {
      '233210': 0.05036063,
      '233230': 0.0532675,
      '233240': 0.12392493,
      '233262': 0.08900104,
      '2332A0': 0.08826341,
      '2332C0': 0.13316275,
      '2332D0': 0.10224999,
      '233411': 0.14511976,
      '233412': 0.03026897,
      '2334A0': 0.18438104,
    },
  },
  ['237210']: {
    label: 'Land Subdivision',
    BEA_CODES: {
      '233210': 0.05036063,
      '233230': 0.0532675,
      '233240': 0.12392493,
      '233262': 0.08900104,
      '2332A0': 0.08826341,
      '2332C0': 0.13316275,
      '2332D0': 0.10224999,
      '233411': 0.14511976,
      '233412': 0.03026897,
      '2334A0': 0.18438104,
    },
  },
  ['237300']: {
    label: 'Highway, Street, and Bridge Construction',
    BEA_CODES: { '2332C0': 1.0 },
  },
  ['237310']: {
    label: 'Highway, Street, and Bridge Construction',
    BEA_CODES: { '2332C0': 1.0 },
  },
  ['237900']: {
    label: 'Other Heavy and Civil Engineering Construction',
    BEA_CODES: { '2332D0': 1.0 },
  },
  ['237990']: {
    label: 'Other Heavy and Civil Engineering Construction',
    BEA_CODES: { '2332D0': 1.0 },
  },
  ['238000']: {
    label: 'Specialty Trade Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238100']: {
    label: 'Foundation, Structure, and Building Exterior Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238110']: {
    label: 'Poured Concrete Foundation and Structure Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238120']: {
    label: 'Structural Steel and Precast Concrete Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238130']: {
    label: 'Framing Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238140']: {
    label: 'Masonry Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238150']: {
    label: 'Glass and Glazing Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238160']: {
    label: 'Roofing Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238170']: {
    label: 'Siding Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238190']: {
    label: 'Other Foundation, Structure, and Building Exterior Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238200']: {
    label: 'Building Equipment Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238210']: {
    label: 'Electrical Contractors and Other Wiring Installation Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238220']: {
    label: 'Plumbing, Heating, and Air-Conditioning Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238290']: {
    label: 'Other Building Equipment Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238300']: {
    label: 'Building Finishing Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238310']: {
    label: 'Drywall and Insulation Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238320']: {
    label: 'Painting and Wall Covering Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238330']: {
    label: 'Flooring Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238340']: {
    label: 'Tile and Terrazzo Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238350']: {
    label: 'Finish Carpentry Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238390']: {
    label: 'Other Building Finishing Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238900']: {
    label: 'Other Specialty Trade Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238910']: {
    label: 'Site Preparation Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['238990']: {
    label: 'All Other Specialty Trade Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.07009978,
      '233210': 0.04421823,
      '233230': 0.04677055,
      '233240': 0.10881002,
      '233262': 0.07814573,
      '2332A0': 0.07749807,
      '2332D0': 0.08977873,
      '233411': 0.12741975,
      '233412': 0.02657712,
      '2334A0': 0.1618924,
    },
  },
  ['310000']: {
    label: 'Manufacturing',
    BEA_CODES: {
      '311119': 0.05396455,
      '311224': 0.05366652,
      '311300': 0.0494331,
      '311410': 0.04410352,
      '311420': 0.05547939,
      '311513': 0.05439196,
      '31151A': 0.05466527,
      '311615': 0.08413498,
      '31161A': 0.20438927,
      '311810': 0.06744405,
      '3118A0': 0.03837269,
      '311910': 0.04696747,
      '312110': 0.06549453,
      '312120': 0.04514007,
      '312200': 0.08235263,
    },
  },
  ['311000']: {
    label: 'Food Manufacturing',
    BEA_CODES: {
      '311119': 0.06006064,
      '311210': 0.03165157,
      '311224': 0.05972895,
      '311300': 0.0550173,
      '311410': 0.04908566,
      '311420': 0.06174661,
      '311513': 0.06053634,
      '311514': 0.03252044,
      '31151A': 0.06084053,
      '311615': 0.09363927,
      '31161A': 0.22747807,
      '311810': 0.07506286,
      '3118A0': 0.04270745,
      '311910': 0.05227315,
      '311990': 0.03765116,
    },
  },
  ['311100']: {
    label: 'Animal Food Manufacturing',
    BEA_CODES: { '311111': 0.33827724, '311119': 0.66172276 },
  },
  ['311110']: {
    label: 'Animal Food Manufacturing',
    BEA_CODES: { '311111': 0.33827724, '311119': 0.66172276 },
  },
  ['311200']: {
    label: 'Grain and Oilseed Milling',
    BEA_CODES: {
      '311210': 0.1946952,
      '311221': 0.15390893,
      '311224': 0.36740483,
      '311225': 0.18802687,
      '311230': 0.09596417,
    },
  },
  ['311210']: {
    label: 'Flour Milling and Malt Manufacturing',
    BEA_CODES: { '311210': 1.0 },
  },
  ['311220']: {
    label: 'Starch and Vegetable Fats and Oils Manufacturing',
    BEA_CODES: {
      '311221': 0.21697465,
      '311224': 0.5179526,
      '311225': 0.26507275,
    },
  },
  ['311230']: {
    label: 'Breakfast Cereal Manufacturing',
    BEA_CODES: { '311230': 1.0 },
  },
  ['311300']: {
    label: 'Sugar and Confectionery Product Manufacturing',
    BEA_CODES: { '311300': 1.0 },
  },
  ['311310']: { label: 'Sugar Manufacturing', BEA_CODES: { '311300': 1.0 } },
  ['311340']: {
    label: 'Nonchocolate Confectionery Manufacturing',
    BEA_CODES: { '311300': 1.0 },
  },
  ['311350']: {
    label: 'Chocolate and Confectionery Manufacturing',
    BEA_CODES: { '311300': 1.0 },
  },
  ['311400']: {
    label: 'Fruit and Vegetable Preserving and Specialty Food Manufacturing',
    BEA_CODES: { '311410': 0.44288238, '311420': 0.55711762 },
  },
  ['311410']: {
    label: 'Frozen Food Manufacturing',
    BEA_CODES: { '311410': 1.0 },
  },
  ['311420']: {
    label: 'Fruit and Vegetable Canning, Pickling, and Drying',
    BEA_CODES: { '311420': 1.0 },
  },
  ['311500']: {
    label: 'Dairy Product Manufacturing',
    BEA_CODES: {
      '311513': 0.3655603,
      '311514': 0.19638094,
      '31151A': 0.36739719,
      '311520': 0.07066157,
    },
  },
  ['311510']: {
    label: 'Dairy Product (except Frozen) Manufacturing',
    BEA_CODES: {
      '311513': 0.39335541,
      '311514': 0.21131262,
      '31151A': 0.39533197,
    },
  },
  ['311520']: {
    label: 'Ice Cream and Frozen Dessert Manufacturing',
    BEA_CODES: { '311520': 1.0 },
  },
  ['311600']: {
    label: 'Animal Slaughtering and Processing',
    BEA_CODES: { '311615': 0.29160452, '31161A': 0.70839548 },
  },
  ['311610']: {
    label: 'Animal Slaughtering and Processing',
    BEA_CODES: { '311615': 0.29160452, '31161A': 0.70839548 },
  },
  ['311700']: {
    label: 'Seafood Product Preparation and Packaging',
    BEA_CODES: { '311700': 1.0 },
  },
  ['311710']: {
    label: 'Seafood Product Preparation and Packaging',
    BEA_CODES: { '311700': 1.0 },
  },
  ['311800']: {
    label: 'Bakeries and Tortilla Manufacturing',
    BEA_CODES: { '311810': 0.63736656, '3118A0': 0.36263344 },
  },
  ['311810']: {
    label: 'Bread and Bakery Product Manufacturing',
    BEA_CODES: { '311810': 1.0 },
  },
  ['311820']: {
    label: 'Cookie, Cracker, and Pasta Manufacturing',
    BEA_CODES: { '3118A0': 1.0 },
  },
  ['311830']: { label: 'Tortilla Manufacturing', BEA_CODES: { '3118A0': 1.0 } },
  ['311900']: {
    label: 'Other Food Manufacturing',
    BEA_CODES: {
      '311910': 0.33262636,
      '311920': 0.13001534,
      '311930': 0.09853079,
      '311940': 0.19924429,
      '311990': 0.23958323,
    },
  },
  ['311910']: {
    label: 'Snack Food Manufacturing',
    BEA_CODES: { '311910': 1.0 },
  },
  ['311920']: {
    label: 'Coffee and Tea Manufacturing',
    BEA_CODES: { '311920': 1.0 },
  },
  ['311930']: {
    label: 'Flavoring Syrup and Concentrate Manufacturing',
    BEA_CODES: { '311930': 1.0 },
  },
  ['311940']: {
    label: 'Seasoning and Dressing Manufacturing',
    BEA_CODES: { '311940': 1.0 },
  },
  ['311990']: {
    label: 'All Other Food Manufacturing',
    BEA_CODES: { '311990': 1.0 },
  },
  ['312000']: {
    label: 'Beverage and Tobacco Product Manufacturing',
    BEA_CODES: {
      '312110': 0.26745705,
      '312120': 0.18433645,
      '312130': 0.09618741,
      '312140': 0.11571936,
      '312200': 0.33629974,
    },
  },
  ['312100']: {
    label: 'Beverage Manufacturing',
    BEA_CODES: {
      '312110': 0.40297867,
      '312120': 0.27774051,
      '312130': 0.14492598,
      '312140': 0.17435485,
    },
  },
  ['312110']: {
    label: 'Soft Drink and Ice Manufacturing',
    BEA_CODES: { '312110': 1.0 },
  },
  ['312120']: { label: 'Breweries', BEA_CODES: { '312120': 1.0 } },
  ['312130']: { label: 'Wineries', BEA_CODES: { '312130': 1.0 } },
  ['312140']: { label: 'Distilleries', BEA_CODES: { '312140': 1.0 } },
  ['312200']: { label: 'Tobacco Manufacturing', BEA_CODES: { '312200': 1.0 } },
  ['312230']: { label: 'Tobacco Manufacturing', BEA_CODES: { '312200': 1.0 } },
  ['313000']: {
    label: 'Textile Mills',
    BEA_CODES: {
      '313100': 0.1882775,
      '313200': 0.46035955,
      '313300': 0.35136295,
    },
  },
  ['313100']: {
    label: 'Fiber, Yarn, and Thread Mills',
    BEA_CODES: { '313100': 1.0 },
  },
  ['313110']: {
    label: 'Fiber, Yarn, and Thread Mills',
    BEA_CODES: { '313100': 1.0 },
  },
  ['313200']: { label: 'Fabric Mills', BEA_CODES: { '313200': 1.0 } },
  ['313210']: {
    label: 'Broadwoven Fabric Mills',
    BEA_CODES: { '313200': 1.0 },
  },
  ['313220']: {
    label: 'Narrow Fabric Mills and Schiffli Machine Embroidery',
    BEA_CODES: { '313200': 1.0 },
  },
  ['313230']: { label: 'Nonwoven Fabric Mills', BEA_CODES: { '313200': 1.0 } },
  ['313240']: { label: 'Knit Fabric Mills', BEA_CODES: { '313200': 1.0 } },
  ['313300']: {
    label: 'Textile and Fabric Finishing and Fabric Coating Mills',
    BEA_CODES: { '313300': 1.0 },
  },
  ['313310']: {
    label: 'Textile and Fabric Finishing Mills',
    BEA_CODES: { '313300': 1.0 },
  },
  ['313320']: { label: 'Fabric Coating Mills', BEA_CODES: { '313300': 1.0 } },
  ['314000']: {
    label: 'Textile Product Mills',
    BEA_CODES: {
      '314110': 0.3903722,
      '314120': 0.16011192,
      '314900': 0.44951588,
    },
  },
  ['314100']: {
    label: 'Textile Furnishings Mills',
    BEA_CODES: { '314110': 0.70914344, '314120': 0.29085656 },
  },
  ['314110']: { label: 'Carpet and Rug Mills', BEA_CODES: { '314110': 1.0 } },
  ['314120']: {
    label: 'Curtain and Linen Mills',
    BEA_CODES: { '314120': 1.0 },
  },
  ['314900']: {
    label: 'Other Textile Product Mills',
    BEA_CODES: { '314900': 1.0 },
  },
  ['314910']: {
    label: 'Textile Bag and Canvas Mills',
    BEA_CODES: { '314900': 1.0 },
  },
  ['314990']: {
    label: 'All Other Textile Product Mills',
    BEA_CODES: { '314900': 1.0 },
  },
  ['315000']: { label: 'Apparel Manufacturing', BEA_CODES: { '315000': 1.0 } },
  ['315100']: { label: 'Apparel Knitting Mills', BEA_CODES: { '315000': 1.0 } },
  ['315120']: { label: 'Apparel Knitting Mills', BEA_CODES: { '315000': 1.0 } },
  ['315200']: {
    label: 'Cut and Sew Apparel Manufacturing',
    BEA_CODES: { '315000': 1.0 },
  },
  ['315210']: {
    label: 'Cut and Sew Apparel Contractors',
    BEA_CODES: { '315000': 1.0 },
  },
  ['315250']: {
    label: 'Cut and Sew Apparel Manufacturing (except Contractors)',
    BEA_CODES: { '315000': 1.0 },
  },
  ['315900']: {
    label: 'Apparel Accessories and Other Apparel Manufacturing',
    BEA_CODES: { '315000': 1.0 },
  },
  ['315990']: {
    label: 'Apparel Accessories and Other Apparel Manufacturing',
    BEA_CODES: { '315000': 1.0 },
  },
  ['316000']: {
    label: 'Leather and Allied Product Manufacturing',
    BEA_CODES: { '316000': 1.0 },
  },
  ['316100']: {
    label: 'Leather and Hide Tanning and Finishing',
    BEA_CODES: { '316000': 1.0 },
  },
  ['316110']: {
    label: 'Leather and Hide Tanning and Finishing',
    BEA_CODES: { '316000': 1.0 },
  },
  ['316200']: { label: 'Footwear Manufacturing', BEA_CODES: { '316000': 1.0 } },
  ['316210']: { label: 'Footwear Manufacturing', BEA_CODES: { '316000': 1.0 } },
  ['316900']: {
    label: 'Other Leather and Allied Product Manufacturing',
    BEA_CODES: { '316000': 1.0 },
  },
  ['316990']: {
    label: 'Other Leather and Allied Product Manufacturing',
    BEA_CODES: { '316000': 1.0 },
  },
  ['320000']: {
    label: 'Manufacturing',
    BEA_CODES: {
      '322120': 0.02857332,
      '322210': 0.03087476,
      '323110': 0.03771448,
      '324110': 0.45130464,
      '324190': 0.02088725,
      '325110': 0.06245704,
      '325180': 0.02261946,
      '325190': 0.07856231,
      '325211': 0.05188772,
      '325412': 0.07217881,
      '325610': 0.02736902,
      '325620': 0.01833321,
      '3259A0': 0.02315339,
      '326110': 0.02411826,
      '326190': 0.04996634,
    },
  },
  ['321000']: {
    label: 'Wood Product Manufacturing',
    BEA_CODES: {
      '321100': 0.32776702,
      '321200': 0.21382217,
      '321910': 0.21864209,
      '3219A0': 0.23976872,
    },
  },
  ['321100']: {
    label: 'Sawmills and Wood Preservation',
    BEA_CODES: { '321100': 1.0 },
  },
  ['321110']: {
    label: 'Sawmills and Wood Preservation',
    BEA_CODES: { '321100': 1.0 },
  },
  ['321200']: {
    label: 'Veneer, Plywood, and Engineered Wood Product Manufacturing',
    BEA_CODES: { '321200': 1.0 },
  },
  ['321210']: {
    label: 'Veneer, Plywood, and Engineered Wood Product Manufacturing',
    BEA_CODES: { '321200': 1.0 },
  },
  ['321900']: {
    label: 'Other Wood Product Manufacturing',
    BEA_CODES: { '321910': 0.47695665, '3219A0': 0.52304335 },
  },
  ['321910']: { label: 'Millwork', BEA_CODES: { '321910': 1.0 } },
  ['321920']: {
    label: 'Wood Container and Pallet Manufacturing',
    BEA_CODES: { '3219A0': 1.0 },
  },
  ['321990']: {
    label: 'All Other Wood Product Manufacturing',
    BEA_CODES: { '3219A0': 1.0 },
  },
  ['322000']: {
    label: 'Paper Manufacturing',
    BEA_CODES: {
      '322110': 0.04264927,
      '322120': 0.27009129,
      '322130': 0.15827405,
      '322210': 0.29184585,
      '322220': 0.10885225,
      '322230': 0.04046532,
      '322291': 0.05874035,
      '322299': 0.02908162,
    },
  },
  ['322100']: {
    label: 'Pulp, Paper, and Paperboard Mills',
    BEA_CODES: {
      '322110': 0.09054767,
      '322120': 0.57342444,
      '322130': 0.33602789,
    },
  },
  ['322110']: { label: 'Pulp Mills', BEA_CODES: { '322110': 1.0 } },
  ['322120']: { label: 'Paper Mills', BEA_CODES: { '322120': 1.0 } },
  ['322130']: { label: 'Paperboard Mills', BEA_CODES: { '322130': 1.0 } },
  ['322200']: {
    label: 'Converted Paper Product Manufacturing',
    BEA_CODES: {
      '322210': 0.55170871,
      '322220': 0.20577552,
      '322230': 0.07649611,
      '322291': 0.11104343,
      '322299': 0.05497623,
    },
  },
  ['322210']: {
    label: 'Paperboard Container Manufacturing',
    BEA_CODES: { '322210': 1.0 },
  },
  ['322220']: {
    label: 'Paper Bag and Coated and Treated Paper Manufacturing',
    BEA_CODES: { '322220': 1.0 },
  },
  ['322230']: {
    label: 'Stationery Product Manufacturing',
    BEA_CODES: { '322230': 1.0 },
  },
  ['322290']: {
    label: 'Other Converted Paper Product Manufacturing',
    BEA_CODES: { '322291': 0.66885711, '322299': 0.33114289 },
  },
  ['323000']: {
    label: 'Printing and Related Support Activities',
    BEA_CODES: { '323110': 0.93186523, '323120': 0.06813477 },
  },
  ['323100']: {
    label: 'Printing and Related Support Activities',
    BEA_CODES: { '323110': 0.93186523, '323120': 0.06813477 },
  },
  ['323110']: { label: 'Printing', BEA_CODES: { '323110': 1.0 } },
  ['323120']: {
    label: 'Support Activities for Printing',
    BEA_CODES: { '323120': 1.0 },
  },
  ['324000']: {
    label: 'Petroleum and Coal Products Manufacturing',
    BEA_CODES: {
      '324110': 0.92821943,
      '324121': 0.01608407,
      '324122': 0.01273672,
      '324190': 0.04295978,
    },
  },
  ['324100']: {
    label: 'Petroleum and Coal Products Manufacturing',
    BEA_CODES: {
      '324110': 0.92821943,
      '324121': 0.01608407,
      '324122': 0.01273672,
      '324190': 0.04295978,
    },
  },
  ['324110']: { label: 'Petroleum Refineries', BEA_CODES: { '324110': 1.0 } },
  ['324120']: {
    label: 'Asphalt Paving, Roofing, and Saturated Materials Manufacturing',
    BEA_CODES: { '324121': 0.55807166, '324122': 0.44192834 },
  },
  ['324190']: {
    label: 'Other Petroleum and Coal Products Manufacturing',
    BEA_CODES: { '324190': 1.0 },
  },
  ['325000']: {
    label: 'Chemical Manufacturing',
    BEA_CODES: {
      '325110': 0.14257161,
      '325180': 0.05163378,
      '325190': 0.17933537,
      '325211': 0.11844488,
      '3252A0': 0.03162656,
      '325310': 0.03625981,
      '325320': 0.02041578,
      '325411': 0.01667339,
      '325412': 0.16476365,
      '325413': 0.01613505,
      '325414': 0.03305029,
      '325510': 0.03191206,
      '325610': 0.06247568,
      '325620': 0.04184949,
      '3259A0': 0.05285258,
    },
  },
  ['325100']: {
    label: 'Basic Chemical Manufacturing',
    BEA_CODES: {
      '325110': 0.36042553,
      '325120': 0.02509078,
      '325130': 0.0305864,
      '325180': 0.13053183,
      '325190': 0.45336546,
    },
  },
  ['325110']: {
    label: 'Petrochemical Manufacturing',
    BEA_CODES: { '325110': 1.0 },
  },
  ['325120']: {
    label: 'Industrial Gas Manufacturing',
    BEA_CODES: { '325120': 1.0 },
  },
  ['325130']: {
    label: 'Synthetic Dye and Pigment Manufacturing',
    BEA_CODES: { '325130': 1.0 },
  },
  ['325180']: {
    label: 'Other Basic Inorganic Chemical Manufacturing',
    BEA_CODES: { '325180': 1.0 },
  },
  ['325190']: {
    label: 'Other Basic Organic Chemical Manufacturing',
    BEA_CODES: { '325190': 1.0 },
  },
  ['325200']: {
    label:
      'Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing',
    BEA_CODES: { '325211': 0.78925661, '3252A0': 0.21074339 },
  },
  ['325210']: {
    label: 'Resin and Synthetic Rubber Manufacturing',
    BEA_CODES: { '325211': 0.78925661, '3252A0': 0.21074339 },
  },
  ['325220']: {
    label: 'Artificial and Synthetic Fibers and Filaments Manufacturing',
    BEA_CODES: { '3252A0': 1.0 },
  },
  ['325300']: {
    label:
      'Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing',
    BEA_CODES: { '325310': 0.63977821, '325320': 0.36022179 },
  },
  ['325310']: {
    label: 'Fertilizer and Compost Manufacturing',
    BEA_CODES: { '325310': 1.0 },
  },
  ['325320']: {
    label: 'Pesticide and Other Agricultural Chemical Manufacturing',
    BEA_CODES: { '325320': 1.0 },
  },
  ['325400']: {
    label: 'Pharmaceutical and Medicine Manufacturing',
    BEA_CODES: {
      '325411': 0.07229737,
      '325412': 0.71443044,
      '325413': 0.06996309,
      '325414': 0.1433091,
    },
  },
  ['325410']: {
    label: 'Pharmaceutical and Medicine Manufacturing',
    BEA_CODES: {
      '325411': 0.07229737,
      '325412': 0.71443044,
      '325413': 0.06996309,
      '325414': 0.1433091,
    },
  },
  ['325500']: {
    label: 'Paint, Coating, and Adhesive Manufacturing',
    BEA_CODES: { '325510': 0.66493901, '325520': 0.33506099 },
  },
  ['325510']: {
    label: 'Paint and Coating Manufacturing',
    BEA_CODES: { '325510': 1.0 },
  },
  ['325520']: { label: 'Adhesive Manufacturing', BEA_CODES: { '325520': 1.0 } },
  ['325600']: {
    label: 'Soap, Cleaning Compound, and Toilet Preparation Manufacturing',
    BEA_CODES: { '325610': 0.59885532, '325620': 0.40114468 },
  },
  ['325610']: {
    label: 'Soap and Cleaning Compound Manufacturing',
    BEA_CODES: { '325610': 1.0 },
  },
  ['325620']: {
    label: 'Toilet Preparation Manufacturing',
    BEA_CODES: { '325620': 1.0 },
  },
  ['325900']: {
    label: 'Other Chemical Product and Preparation Manufacturing',
    BEA_CODES: { '325910': 0.11652659, '3259A0': 0.88347341 },
  },
  ['325910']: {
    label: 'Printing Ink Manufacturing',
    BEA_CODES: { '325910': 1.0 },
  },
  ['325920']: {
    label: 'Explosives Manufacturing',
    BEA_CODES: { '3259A0': 1.0 },
  },
  ['325990']: {
    label: 'All Other Chemical Product and Preparation Manufacturing',
    BEA_CODES: { '3259A0': 1.0 },
  },
  ['326000']: {
    label: 'Plastics and Rubber Products Manufacturing',
    BEA_CODES: {
      '326110': 0.18827481,
      '326120': 0.07095229,
      '326130': 0.01687717,
      '326140': 0.03628684,
      '326150': 0.04287511,
      '326160': 0.05761693,
      '326190': 0.39005318,
      '326210': 0.09263744,
      '326220': 0.02451676,
      '326290': 0.07990946,
    },
  },
  ['326100']: {
    label: 'Plastics Product Manufacturing',
    BEA_CODES: {
      '326110': 0.23448286,
      '326120': 0.08836603,
      '326130': 0.02101932,
      '326140': 0.04519268,
      '326150': 0.0533979,
      '326160': 0.07175778,
      '326190': 0.48578344,
    },
  },
  ['326110']: {
    label:
      'Plastics Packaging Materials and Unlaminated Film and Sheet Manufacturing',
    BEA_CODES: { '326110': 1.0 },
  },
  ['326120']: {
    label:
      'Plastics Pipe, Pipe Fitting, and Unlaminated Profile Shape Manufacturing',
    BEA_CODES: { '326120': 1.0 },
  },
  ['326130']: {
    label:
      'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing',
    BEA_CODES: { '326130': 1.0 },
  },
  ['326140']: {
    label: 'Polystyrene Foam Product Manufacturing',
    BEA_CODES: { '326140': 1.0 },
  },
  ['326150']: {
    label: 'Urethane and Other Foam Product (except Polystyrene) Manufacturing',
    BEA_CODES: { '326150': 1.0 },
  },
  ['326160']: {
    label: 'Plastics Bottle Manufacturing',
    BEA_CODES: { '326160': 1.0 },
  },
  ['326190']: {
    label: 'Other Plastics Product Manufacturing',
    BEA_CODES: { '326190': 1.0 },
  },
  ['326200']: {
    label: 'Rubber Product Manufacturing',
    BEA_CODES: {
      '326210': 0.4700889,
      '326220': 0.12441034,
      '326290': 0.40550076,
    },
  },
  ['326210']: { label: 'Tire Manufacturing', BEA_CODES: { '326210': 1.0 } },
  ['326220']: {
    label: 'Rubber and Plastics Hoses and Belting Manufacturing',
    BEA_CODES: { '326220': 1.0 },
  },
  ['326290']: {
    label: 'Other Rubber Product Manufacturing',
    BEA_CODES: { '326290': 1.0 },
  },
  ['327000']: {
    label: 'Nonmetallic Mineral Product Manufacturing',
    BEA_CODES: {
      '327100': 0.07461098,
      '327200': 0.23969781,
      '327310': 0.05959263,
      '327320': 0.20811435,
      '327330': 0.05577794,
      '327390': 0.08784203,
      '327400': 0.06181872,
      '327910': 0.04956995,
      '327991': 0.03709124,
      '327992': 0.03375731,
      '327993': 0.05079263,
      '327999': 0.04133442,
    },
  },
  ['327100']: {
    label: 'Clay Product and Refractory Manufacturing',
    BEA_CODES: { '327100': 1.0 },
  },
  ['327110']: {
    label: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing',
    BEA_CODES: { '327100': 1.0 },
  },
  ['327120']: {
    label: 'Clay Building Material and Refractories Manufacturing',
    BEA_CODES: { '327100': 1.0 },
  },
  ['327200']: {
    label: 'Glass and Glass Product Manufacturing',
    BEA_CODES: { '327200': 1.0 },
  },
  ['327210']: {
    label: 'Glass and Glass Product Manufacturing',
    BEA_CODES: { '327200': 1.0 },
  },
  ['327300']: {
    label: 'Cement and Concrete Product Manufacturing',
    BEA_CODES: {
      '327310': 0.14487898,
      '327320': 0.50595846,
      '327330': 0.13560488,
      '327390': 0.21355768,
    },
  },
  ['327310']: { label: 'Cement Manufacturing', BEA_CODES: { '327310': 1.0 } },
  ['327320']: {
    label: 'Ready-Mix Concrete Manufacturing',
    BEA_CODES: { '327320': 1.0 },
  },
  ['327330']: {
    label: 'Concrete Pipe, Brick, and Block Manufacturing',
    BEA_CODES: { '327330': 1.0 },
  },
  ['327390']: {
    label: 'Other Concrete Product Manufacturing',
    BEA_CODES: { '327390': 1.0 },
  },
  ['327400']: {
    label: 'Lime and Gypsum Product Manufacturing',
    BEA_CODES: { '327400': 1.0 },
  },
  ['327410']: { label: 'Lime Manufacturing', BEA_CODES: { '327400': 1.0 } },
  ['327420']: {
    label: 'Gypsum Product Manufacturing',
    BEA_CODES: { '327400': 1.0 },
  },
  ['327900']: {
    label: 'Other Nonmetallic Mineral Product Manufacturing',
    BEA_CODES: {
      '327910': 0.23322036,
      '327991': 0.1745096,
      '327992': 0.1588239,
      '327993': 0.23897291,
      '327999': 0.19447323,
    },
  },
  ['327910']: {
    label: 'Abrasive Product Manufacturing',
    BEA_CODES: { '327910': 1.0 },
  },
  ['327990']: {
    label: 'All Other Nonmetallic Mineral Product Manufacturing',
    BEA_CODES: {
      '327991': 0.22758769,
      '327992': 0.20713109,
      '327993': 0.31165787,
      '327999': 0.25362336,
    },
  },
  ['330000']: {
    label: 'Manufacturing',
    BEA_CODES: {
      '331110': 0.13994365,
      '332310': 0.04181661,
      '332320': 0.03883731,
      '332710': 0.04399941,
      '333120': 0.04269895,
      '334413': 0.05202327,
      '334511': 0.05033027,
      '336111': 0.06117212,
      '336112': 0.19198802,
      '336120': 0.03806893,
      '336350': 0.03971889,
      '336390': 0.05896276,
      '336411': 0.11579171,
      '336412': 0.04391339,
      '339112': 0.04073473,
    },
  },
  ['331000']: {
    label: 'Primary Metal Manufacturing',
    BEA_CODES: {
      '331110': 0.47755469,
      '331200': 0.03581955,
      '331313': 0.04659511,
      '33131B': 0.09284781,
      '331410': 0.09967148,
      '331420': 0.08180122,
      '331490': 0.04761513,
      '331510': 0.07084872,
      '331520': 0.04724628,
    },
  },
  ['331100']: {
    label: 'Iron and Steel Mills and Ferroalloy Manufacturing',
    BEA_CODES: { '331110': 1.0 },
  },
  ['331110']: {
    label: 'Iron and Steel Mills and Ferroalloy Manufacturing',
    BEA_CODES: { '331110': 1.0 },
  },
  ['331200']: {
    label: 'Steel Product Manufacturing from Purchased Steel',
    BEA_CODES: { '331200': 1.0 },
  },
  ['331210']: {
    label: 'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel',
    BEA_CODES: { '331200': 1.0 },
  },
  ['331220']: {
    label: 'Rolling and Drawing of Purchased Steel',
    BEA_CODES: { '331200': 1.0 },
  },
  ['331300']: {
    label: 'Alumina and Aluminum Production and Processing',
    BEA_CODES: { '331313': 0.33415186, '33131B': 0.66584814 },
  },
  ['331310']: {
    label: 'Alumina and Aluminum Production and Processing',
    BEA_CODES: { '331313': 0.33415186, '33131B': 0.66584814 },
  },
  ['331400']: {
    label: 'Nonferrous Metal (except Aluminum) Production and Processing',
    BEA_CODES: {
      '331410': 0.43507976,
      '331420': 0.35707362,
      '331490': 0.20784662,
    },
  },
  ['331410']: {
    label: 'Nonferrous Metal (except Aluminum) Smelting and Refining',
    BEA_CODES: { '331410': 1.0 },
  },
  ['331420']: {
    label: 'Copper Rolling, Drawing, Extruding, and Alloying',
    BEA_CODES: { '331420': 1.0 },
  },
  ['331490']: {
    label:
      'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, Extruding, and Alloying',
    BEA_CODES: { '331490': 1.0 },
  },
  ['331500']: {
    label: 'Foundries',
    BEA_CODES: { '331510': 0.59992988, '331520': 0.40007012 },
  },
  ['331510']: {
    label: 'Ferrous Metal Foundries',
    BEA_CODES: { '331510': 1.0 },
  },
  ['331520']: {
    label: 'Nonferrous Metal Foundries',
    BEA_CODES: { '331520': 1.0 },
  },
  ['332000']: {
    label: 'Fabricated Metal Product Manufacturing',
    BEA_CODES: {
      '332114': 0.02732068,
      '332119': 0.03815726,
      '33211A': 0.0504588,
      '332200': 0.03022748,
      '332310': 0.12595254,
      '332320': 0.11697882,
      '332420': 0.02995173,
      '332430': 0.06036526,
      '332710': 0.13252719,
      '332720': 0.09148638,
      '332800': 0.09073893,
      '33291A': 0.0855962,
      '332996': 0.02692535,
      '332999': 0.04805023,
      '33299A': 0.04526316,
    },
  },
  ['332100']: {
    label: 'Forging and Stamping',
    BEA_CODES: {
      '332114': 0.23565159,
      '332119': 0.32912137,
      '33211A': 0.43522705,
    },
  },
  ['332110']: {
    label: 'Forging and Stamping',
    BEA_CODES: {
      '332114': 0.23565159,
      '332119': 0.32912137,
      '33211A': 0.43522705,
    },
  },
  ['332200']: {
    label: 'Cutlery and Handtool Manufacturing',
    BEA_CODES: { '332200': 1.0 },
  },
  ['332210']: {
    label: 'Cutlery and Handtool Manufacturing',
    BEA_CODES: { '332200': 1.0 },
  },
  ['332300']: {
    label: 'Architectural and Structural Metals Manufacturing',
    BEA_CODES: { '332310': 0.51846966, '332320': 0.48153034 },
  },
  ['332310']: {
    label: 'Plate Work and Fabricated Structural Product Manufacturing',
    BEA_CODES: { '332310': 1.0 },
  },
  ['332320']: {
    label: 'Ornamental and Architectural Metal Products Manufacturing',
    BEA_CODES: { '332320': 1.0 },
  },
  ['332400']: {
    label: 'Boiler, Tank, and Shipping Container Manufacturing',
    BEA_CODES: {
      '332410': 0.20106234,
      '332420': 0.26495088,
      '332430': 0.53398678,
    },
  },
  ['332410']: {
    label: 'Power Boiler and Heat Exchanger Manufacturing',
    BEA_CODES: { '332410': 1.0 },
  },
  ['332420']: {
    label: 'Metal Tank (Heavy Gauge) Manufacturing',
    BEA_CODES: { '332420': 1.0 },
  },
  ['332430']: {
    label:
      'Metal Can, Box, and Other Metal Container (Light Gauge) Manufacturing',
    BEA_CODES: { '332430': 1.0 },
  },
  ['332500']: { label: 'Hardware Manufacturing', BEA_CODES: { '332500': 1.0 } },
  ['332510']: { label: 'Hardware Manufacturing', BEA_CODES: { '332500': 1.0 } },
  ['332600']: {
    label: 'Spring and Wire Product Manufacturing',
    BEA_CODES: { '332600': 1.0 },
  },
  ['332610']: {
    label: 'Spring and Wire Product Manufacturing',
    BEA_CODES: { '332600': 1.0 },
  },
  ['332700']: {
    label:
      'Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing',
    BEA_CODES: { '332710': 0.59160341, '332720': 0.40839659 },
  },
  ['332710']: { label: 'Machine Shops', BEA_CODES: { '332710': 1.0 } },
  ['332720']: {
    label: 'Turned Product and Screw, Nut, and Bolt Manufacturing',
    BEA_CODES: { '332720': 1.0 },
  },
  ['332800']: {
    label: 'Coating, Engraving, Heat Treating, and Allied Activities',
    BEA_CODES: { '332800': 1.0 },
  },
  ['332810']: {
    label: 'Coating, Engraving, Heat Treating, and Allied Activities',
    BEA_CODES: { '332800': 1.0 },
  },
  ['332900']: {
    label: 'Other Fabricated Metal Product Manufacturing',
    BEA_CODES: {
      '332913': 0.04496774,
      '33291A': 0.35793557,
      '332991': 0.09429724,
      '332996': 0.11259309,
      '332999': 0.20093049,
      '33299A': 0.18927586,
    },
  },
  ['332910']: {
    label: 'Metal Valve Manufacturing',
    BEA_CODES: { '332913': 0.11160925, '33291A': 0.88839075 },
  },
  ['332990']: {
    label: 'All Other Fabricated Metal Product Manufacturing',
    BEA_CODES: {
      '332991': 0.15792625,
      '332996': 0.1885676,
      '332999': 0.33651249,
      '33299A': 0.31699366,
    },
  },
  ['333000']: {
    label: 'Machinery Manufacturing',
    BEA_CODES: {
      '333111': 0.10632464,
      '333120': 0.12000722,
      '333130': 0.09676474,
      '333242': 0.03086209,
      '33329A': 0.06485812,
      '333318': 0.05359327,
      '333415': 0.09116932,
      '33351B': 0.02584653,
      '333611': 0.0556236,
      '333618': 0.0888819,
      '333912': 0.03023592,
      '33391A': 0.04892178,
      '333920': 0.0905614,
      '33399A': 0.0632741,
      '33399B': 0.03307538,
    },
  },
  ['333100']: {
    label: 'Agriculture, Construction, and Mining Machinery Manufacturing',
    BEA_CODES: {
      '333111': 0.30792165,
      '333112': 0.06429557,
      '333120': 0.34754711,
      '333130': 0.28023568,
    },
  },
  ['333110']: {
    label: 'Agricultural Implement Manufacturing',
    BEA_CODES: { '333111': 0.82726332, '333112': 0.17273668 },
  },
  ['333120']: {
    label: 'Construction Machinery Manufacturing',
    BEA_CODES: { '333120': 1.0 },
  },
  ['333130']: {
    label: 'Mining and Oil and Gas Field Machinery Manufacturing',
    BEA_CODES: { '333130': 1.0 },
  },
  ['333200']: {
    label: 'Industrial Machinery Manufacturing',
    BEA_CODES: { '333242': 0.32241977, '33329A': 0.67758023 },
  },
  ['333240']: {
    label: 'Industrial Machinery Manufacturing',
    BEA_CODES: { '333242': 0.32241977, '33329A': 0.67758023 },
  },
  ['333300']: {
    label: 'Commercial and Service Industry Machinery Manufacturing',
    BEA_CODES: {
      '333314': 0.20913601,
      '333316': 0.07614717,
      '333318': 0.71471683,
    },
  },
  ['333310']: {
    label: 'Commercial and Service Industry Machinery Manufacturing',
    BEA_CODES: {
      '333314': 0.20913601,
      '333316': 0.07614717,
      '333318': 0.71471683,
    },
  },
  ['333400']: {
    label:
      'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing',
    BEA_CODES: {
      '333413': 0.14783349,
      '333414': 0.11857701,
      '333415': 0.73358951,
    },
  },
  ['333410']: {
    label:
      'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing',
    BEA_CODES: {
      '333413': 0.14783349,
      '333414': 0.11857701,
      '333415': 0.73358951,
    },
  },
  ['333500']: {
    label: 'Metalworking Machinery Manufacturing',
    BEA_CODES: {
      '333511': 0.21192658,
      '333514': 0.2711682,
      '333517': 0.24505233,
      '33351B': 0.27185289,
    },
  },
  ['333510']: {
    label: 'Metalworking Machinery Manufacturing',
    BEA_CODES: {
      '333511': 0.21192658,
      '333514': 0.2711682,
      '333517': 0.24505233,
      '33351B': 0.27185289,
    },
  },
  ['333600']: {
    label: 'Engine, Turbine, and Power Transmission Equipment Manufacturing',
    BEA_CODES: {
      '333611': 0.32354394,
      '333612': 0.08158769,
      '333613': 0.07787195,
      '333618': 0.51699642,
    },
  },
  ['333610']: {
    label: 'Engine, Turbine, and Power Transmission Equipment Manufacturing',
    BEA_CODES: {
      '333611': 0.32354394,
      '333612': 0.08158769,
      '333613': 0.07787195,
      '333618': 0.51699642,
    },
  },
  ['333900']: {
    label: 'Other General Purpose Machinery Manufacturing',
    BEA_CODES: {
      '333912': 0.10001253,
      '33391A': 0.16182049,
      '333920': 0.29955349,
      '333991': 0.03803019,
      '333993': 0.05520478,
      '333994': 0.02667958,
      '33399A': 0.20929421,
      '33399B': 0.10940473,
    },
  },
  ['333910']: {
    label: 'Pump and Compressor Manufacturing',
    BEA_CODES: { '333912': 0.38197065, '33391A': 0.61802935 },
  },
  ['333920']: {
    label: 'Material Handling Equipment Manufacturing',
    BEA_CODES: { '333920': 1.0 },
  },
  ['333990']: {
    label: 'All Other General Purpose Machinery Manufacturing',
    BEA_CODES: {
      '333991': 0.08670547,
      '333993': 0.12586203,
      '333994': 0.06082708,
      '33399A': 0.47717231,
      '33399B': 0.24943311,
    },
  },
  ['334000']: {
    label: 'Computer and Electronic Product Manufacturing',
    BEA_CODES: {
      '334111': 0.02900746,
      '334112': 0.0304063,
      '334118': 0.04015247,
      '334210': 0.03343186,
      '334220': 0.10031306,
      '334413': 0.16536429,
      '334418': 0.05861443,
      '33441A': 0.08681905,
      '334510': 0.09702461,
      '334511': 0.15998279,
      '334513': 0.03667117,
      '334515': 0.0435112,
      '334516': 0.04786896,
      '334517': 0.03018894,
      '33451A': 0.04064341,
    },
  },
  ['334100']: {
    label: 'Computer and Peripheral Equipment Manufacturing',
    BEA_CODES: {
      '334111': 0.29133836,
      '334112': 0.30538768,
      '334118': 0.40327396,
    },
  },
  ['334110']: {
    label: 'Computer and Peripheral Equipment Manufacturing',
    BEA_CODES: {
      '334111': 0.29133836,
      '334112': 0.30538768,
      '334118': 0.40327396,
    },
  },
  ['334200']: {
    label: 'Communications Equipment Manufacturing',
    BEA_CODES: {
      '334210': 0.22237257,
      '334220': 0.66723406,
      '334290': 0.11039337,
    },
  },
  ['334210']: {
    label: 'Telephone Apparatus Manufacturing',
    BEA_CODES: { '334210': 1.0 },
  },
  ['334220']: {
    label:
      'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing',
    BEA_CODES: { '334220': 1.0 },
  },
  ['334290']: {
    label: 'Other Communications Equipment Manufacturing',
    BEA_CODES: { '334290': 1.0 },
  },
  ['334300']: {
    label: 'Audio and Video Equipment Manufacturing',
    BEA_CODES: { '334300': 1.0 },
  },
  ['334310']: {
    label: 'Audio and Video Equipment Manufacturing',
    BEA_CODES: { '334300': 1.0 },
  },
  ['334400']: {
    label: 'Semiconductor and Other Electronic Component Manufacturing',
    BEA_CODES: {
      '334413': 0.53206394,
      '334418': 0.18859348,
      '33441A': 0.27934258,
    },
  },
  ['334410']: {
    label: 'Semiconductor and Other Electronic Component Manufacturing',
    BEA_CODES: {
      '334413': 0.53206394,
      '334418': 0.18859348,
      '33441A': 0.27934258,
    },
  },
  ['334500']: {
    label:
      'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing',
    BEA_CODES: {
      '334510': 0.19876214,
      '334511': 0.32773668,
      '334512': 0.01863726,
      '334513': 0.07512363,
      '334514': 0.04743588,
      '334515': 0.08913593,
      '334516': 0.09806313,
      '334517': 0.06184429,
      '33451A': 0.08326105,
    },
  },
  ['334510']: {
    label:
      'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing',
    BEA_CODES: { '334510': 1.0 },
  },
  ['334600']: {
    label: 'Manufacturing and Reproducing Magnetic and Optical Media',
    BEA_CODES: { '334610': 1.0 },
  },
  ['334610']: {
    label: 'Manufacturing and Reproducing Magnetic and Optical Media',
    BEA_CODES: { '334610': 1.0 },
  },
  ['335000']: {
    label: 'Electrical Equipment, Appliance, and Component Manufacturing',
    BEA_CODES: {
      '335120': 0.08933909,
      '335221': 0.03880159,
      '335222': 0.03086871,
      '335224': 0.03180199,
      '335228': 0.03882801,
      '335311': 0.05041478,
      '335312': 0.09625112,
      '335313': 0.09426134,
      '335314': 0.08549202,
      '335911': 0.05526515,
      '335912': 0.03877518,
      '335920': 0.1302367,
      '335930': 0.10413126,
      '335991': 0.02917817,
      '335999': 0.0863549,
    },
  },
  ['335100']: {
    label: 'Electric Lighting Equipment Manufacturing',
    BEA_CODES: { '335110': 0.1507373, '335120': 0.8492627 },
  },
  ['335130']: {
    label: 'Electric Lighting Equipment Manufacturing',
    BEA_CODES: { '335110': 0.1507373, '335120': 0.8492627 },
  },
  ['335200']: {
    label: 'Household Appliance Manufacturing',
    BEA_CODES: {
      '335210': 0.16601246,
      '335221': 0.23064845,
      '335222': 0.18349296,
      '335224': 0.18904067,
      '335228': 0.23080546,
    },
  },
  ['335210']: {
    label: 'Small Electrical Appliance Manufacturing',
    BEA_CODES: { '335210': 1.0 },
  },
  ['335220']: {
    label: 'Major Household Appliance Manufacturing',
    BEA_CODES: {
      '335221': 0.27656103,
      '335222': 0.22001883,
      '335224': 0.22667085,
      '335228': 0.27674929,
    },
  },
  ['335300']: {
    label: 'Electrical Equipment Manufacturing',
    BEA_CODES: {
      '335311': 0.15444792,
      '335312': 0.29486962,
      '335313': 0.28877383,
      '335314': 0.26190863,
    },
  },
  ['335310']: {
    label: 'Electrical Equipment Manufacturing',
    BEA_CODES: {
      '335311': 0.15444792,
      '335312': 0.29486962,
      '335313': 0.28877383,
      '335314': 0.26190863,
    },
  },
  ['335900']: {
    label: 'Other Electrical Equipment and Component Manufacturing',
    BEA_CODES: {
      '335911': 0.12448751,
      '335912': 0.08734302,
      '335920': 0.29336466,
      '335930': 0.23456084,
      '335991': 0.06572527,
      '335999': 0.1945187,
    },
  },
  ['335910']: {
    label: 'Battery Manufacturing',
    BEA_CODES: { '335911': 0.587675, '335912': 0.412325 },
  },
  ['335920']: {
    label: 'Communication and Energy Wire and Cable Manufacturing',
    BEA_CODES: { '335920': 1.0 },
  },
  ['335930']: {
    label: 'Wiring Device Manufacturing',
    BEA_CODES: { '335930': 1.0 },
  },
  ['335990']: {
    label: 'All Other Electrical Equipment and Component Manufacturing',
    BEA_CODES: { '335991': 0.25255252, '335999': 0.74744748 },
  },
  ['336000']: {
    label: 'Transportation Equipment Manufacturing',
    BEA_CODES: {
      '336111': 0.08038202,
      '336112': 0.25227805,
      '336120': 0.05002372,
      '336310': 0.03971187,
      '336320': 0.02683794,
      '336350': 0.05219182,
      '336360': 0.03225832,
      '336370': 0.04051927,
      '336390': 0.07747884,
      '3363A0': 0.03160317,
      '336411': 0.1521538,
      '336412': 0.05770352,
      '336413': 0.04945415,
      '336414': 0.02597564,
      '336611': 0.03142787,
    },
  },
  ['336100']: {
    label: 'Motor Vehicle Manufacturing',
    BEA_CODES: {
      '336111': 0.21004814,
      '336112': 0.6592337,
      '336120': 0.13071816,
    },
  },
  ['336110']: {
    label: 'Automobile and Light Duty Motor Vehicle Manufacturing',
    BEA_CODES: { '336111': 0.24163411, '336112': 0.75836589 },
  },
  ['336120']: {
    label: 'Heavy Duty Truck Manufacturing',
    BEA_CODES: { '336120': 1.0 },
  },
  ['336200']: {
    label: 'Motor Vehicle Body and Trailer Manufacturing',
    BEA_CODES: {
      '336211': 0.25600752,
      '336212': 0.28915773,
      '336213': 0.08901106,
      '336214': 0.36582369,
    },
  },
  ['336210']: {
    label: 'Motor Vehicle Body and Trailer Manufacturing',
    BEA_CODES: {
      '336211': 0.25600752,
      '336212': 0.28915773,
      '336213': 0.08901106,
      '336214': 0.36582369,
    },
  },
  ['336300']: {
    label: 'Motor Vehicle Parts Manufacturing',
    BEA_CODES: {
      '336310': 0.13210813,
      '336320': 0.08928088,
      '336350': 0.17362477,
      '336360': 0.10731266,
      '336370': 0.13479411,
      '336390': 0.25774626,
      '3363A0': 0.1051332,
    },
  },
  ['336310']: {
    label: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing',
    BEA_CODES: { '336310': 1.0 },
  },
  ['336320']: {
    label: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing',
    BEA_CODES: { '336320': 1.0 },
  },
  ['336330']: {
    label:
      'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing',
    BEA_CODES: { '3363A0': 1.0 },
  },
  ['336340']: {
    label: 'Motor Vehicle Brake System Manufacturing',
    BEA_CODES: { '3363A0': 1.0 },
  },
  ['336350']: {
    label: 'Motor Vehicle Transmission and Power Train Parts Manufacturing',
    BEA_CODES: { '336350': 1.0 },
  },
  ['336360']: {
    label: 'Motor Vehicle Seating and Interior Trim Manufacturing',
    BEA_CODES: { '336360': 1.0 },
  },
  ['336370']: {
    label: 'Motor Vehicle Metal Stamping',
    BEA_CODES: { '336370': 1.0 },
  },
  ['336390']: {
    label: 'Other Motor Vehicle Parts Manufacturing',
    BEA_CODES: { '336390': 1.0 },
  },
  ['336400']: {
    label: 'Aerospace Product and Parts Manufacturing',
    BEA_CODES: {
      '336411': 0.51936776,
      '336412': 0.19696745,
      '336413': 0.16880875,
      '336414': 0.08866627,
      '33641A': 0.02618977,
    },
  },
  ['336410']: {
    label: 'Aerospace Product and Parts Manufacturing',
    BEA_CODES: {
      '336411': 0.51936776,
      '336412': 0.19696745,
      '336413': 0.16880875,
      '336414': 0.08866627,
      '33641A': 0.02618977,
    },
  },
  ['336500']: {
    label: 'Railroad Rolling Stock Manufacturing',
    BEA_CODES: { '336500': 1.0 },
  },
  ['336510']: {
    label: 'Railroad Rolling Stock Manufacturing',
    BEA_CODES: { '336500': 1.0 },
  },
  ['336600']: {
    label: 'Ship and Boat Building',
    BEA_CODES: { '336611': 0.76318012, '336612': 0.23681988 },
  },
  ['336610']: {
    label: 'Ship and Boat Building',
    BEA_CODES: { '336611': 0.76318012, '336612': 0.23681988 },
  },
  ['336900']: {
    label: 'Other Transportation Equipment Manufacturing',
    BEA_CODES: {
      '336991': 0.31668296,
      '336992': 0.3121566,
      '336999': 0.37116044,
    },
  },
  ['336990']: {
    label: 'Other Transportation Equipment Manufacturing',
    BEA_CODES: {
      '336991': 0.31668296,
      '336992': 0.3121566,
      '336999': 0.37116044,
    },
  },
  ['337000']: {
    label: 'Furniture and Related Product Manufacturing',
    BEA_CODES: {
      '337110': 0.17387163,
      '337121': 0.14059598,
      '337122': 0.06521774,
      '337127': 0.07231421,
      '33712N': 0.04105479,
      '337215': 0.10554224,
      '33721A': 0.24956738,
      '337900': 0.15183603,
    },
  },
  ['337100']: {
    label:
      'Household and Institutional Furniture and Kitchen Cabinet Manufacturing',
    BEA_CODES: {
      '337110': 0.35264192,
      '337121': 0.28515311,
      '337122': 0.13227292,
      '337127': 0.14666581,
      '33712N': 0.08326625,
    },
  },
  ['337110']: {
    label: 'Wood Kitchen Cabinet and Countertop Manufacturing',
    BEA_CODES: { '337110': 1.0 },
  },
  ['337120']: {
    label: 'Household and Institutional Furniture Manufacturing',
    BEA_CODES: {
      '337121': 0.44048744,
      '337122': 0.20432728,
      '337127': 0.22656056,
      '33712N': 0.12862472,
    },
  },
  ['337200']: {
    label: 'Office Furniture (including Fixtures) Manufacturing',
    BEA_CODES: { '337215': 0.2972103, '33721A': 0.7027897 },
  },
  ['337210']: {
    label: 'Office Furniture (including Fixtures) Manufacturing',
    BEA_CODES: { '337215': 0.2972103, '33721A': 0.7027897 },
  },
  ['337900']: {
    label: 'Other Furniture Related Product Manufacturing',
    BEA_CODES: { '337900': 1.0 },
  },
  ['337910']: { label: 'Mattress Manufacturing', BEA_CODES: { '337900': 1.0 } },
  ['337920']: {
    label: 'Blind and Shade Manufacturing',
    BEA_CODES: { '337900': 1.0 },
  },
  ['339000']: {
    label: 'Miscellaneous Manufacturing',
    BEA_CODES: {
      '339112': 0.25628895,
      '339113': 0.22143203,
      '339114': 0.02956139,
      '339115': 0.0497478,
      '339116': 0.03178891,
      '339910': 0.07876793,
      '339920': 0.0668601,
      '339930': 0.01249073,
      '339940': 0.02139383,
      '339950': 0.05503554,
      '339990': 0.17663279,
    },
  },
  ['339100']: {
    label: 'Medical Equipment and Supplies Manufacturing',
    BEA_CODES: {
      '339112': 0.43525925,
      '339113': 0.37606124,
      '339114': 0.05020454,
      '339115': 0.08448741,
      '339116': 0.05398756,
    },
  },
  ['339110']: {
    label: 'Medical Equipment and Supplies Manufacturing',
    BEA_CODES: {
      '339112': 0.43525925,
      '339113': 0.37606124,
      '339114': 0.05020454,
      '339115': 0.08448741,
      '339116': 0.05398756,
    },
  },
  ['339900']: {
    label: 'Other Miscellaneous Manufacturing',
    BEA_CODES: {
      '339910': 0.19156514,
      '339920': 0.16260506,
      '339930': 0.0303777,
      '339940': 0.05203022,
      '339950': 0.13384751,
      '339990': 0.42957438,
    },
  },
  ['339910']: {
    label: 'Jewelry and Silverware Manufacturing',
    BEA_CODES: { '339910': 1.0 },
  },
  ['339920']: {
    label: 'Sporting and Athletic Goods Manufacturing',
    BEA_CODES: { '339920': 1.0 },
  },
  ['339930']: {
    label: 'Doll, Toy, and Game Manufacturing',
    BEA_CODES: { '339930': 1.0 },
  },
  ['339940']: {
    label: 'Office Supplies (except Paper) Manufacturing',
    BEA_CODES: { '339940': 1.0 },
  },
  ['339950']: { label: 'Sign Manufacturing', BEA_CODES: { '339950': 1.0 } },
  ['339990']: {
    label: 'All Other Miscellaneous Manufacturing',
    BEA_CODES: { '339990': 1.0 },
  },
  ['420000']: {
    label: 'Wholesale Trade',
    BEA_CODES: {
      '423100': 0.0639746,
      '423400': 0.11731236,
      '423600': 0.08920368,
      '423800': 0.09802853,
      '423A00': 0.14478733,
      '424200': 0.08421045,
      '424400': 0.08209803,
      '424700': 0.09935652,
      '424A00': 0.1849431,
      '425000': 0.03608541,
    },
  },
  ['423000']: {
    label: 'Merchant Wholesalers, Durable Goods',
    BEA_CODES: {
      '423100': 0.12463236,
      '423400': 0.22854252,
      '423600': 0.17378249,
      '423800': 0.19097464,
      '423A00': 0.28206798,
    },
  },
  ['423100']: {
    label:
      'Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers',
    BEA_CODES: { '423100': 1.0 },
  },
  ['423110']: {
    label: 'Automobile and Other Motor Vehicle Merchant Wholesalers',
    BEA_CODES: { '423100': 1.0 },
  },
  ['423120']: {
    label: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers',
    BEA_CODES: { '423100': 1.0 },
  },
  ['423130']: {
    label: 'Tire and Tube Merchant Wholesalers',
    BEA_CODES: { '423100': 1.0 },
  },
  ['423140']: {
    label: 'Motor Vehicle Parts (Used) Merchant Wholesalers',
    BEA_CODES: { '423100': 1.0 },
  },
  ['423200']: {
    label: 'Furniture and Home Furnishing Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423210']: {
    label: 'Furniture Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423220']: {
    label: 'Home Furnishing Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423300']: {
    label: 'Lumber and Other Construction Materials Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423310']: {
    label: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423320']: {
    label:
      'Brick, Stone, and Related Construction Material Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423330']: {
    label: 'Roofing, Siding, and Insulation Material Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423390']: {
    label: 'Other Construction Material Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423400']: {
    label:
      'Professional and Commercial Equipment and Supplies Merchant Wholesalers',
    BEA_CODES: { '423400': 1.0 },
  },
  ['423410']: {
    label: 'Photographic Equipment and Supplies Merchant Wholesalers',
    BEA_CODES: { '423400': 1.0 },
  },
  ['423420']: {
    label: 'Office Equipment Merchant Wholesalers',
    BEA_CODES: { '423400': 1.0 },
  },
  ['423430']: {
    label:
      'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers',
    BEA_CODES: { '423400': 1.0 },
  },
  ['423440']: {
    label: 'Other Commercial Equipment Merchant Wholesalers',
    BEA_CODES: { '423400': 1.0 },
  },
  ['423450']: {
    label:
      'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers',
    BEA_CODES: { '423400': 1.0 },
  },
  ['423460']: {
    label: 'Ophthalmic Goods Merchant Wholesalers',
    BEA_CODES: { '423400': 1.0 },
  },
  ['423490']: {
    label: 'Other Professional Equipment and Supplies Merchant Wholesalers',
    BEA_CODES: { '423400': 1.0 },
  },
  ['423500']: {
    label: 'Metal and Mineral (except Petroleum) Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423510']: {
    label: 'Metal Service Centers and Other Metal Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423520']: {
    label: 'Coal and Other Mineral and Ore Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423600']: {
    label:
      'Household Appliances and Electrical and Electronic Goods Merchant Wholesalers',
    BEA_CODES: { '423600': 1.0 },
  },
  ['423610']: {
    label:
      'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers',
    BEA_CODES: { '423600': 1.0 },
  },
  ['423620']: {
    label:
      'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers',
    BEA_CODES: { '423600': 1.0 },
  },
  ['423690']: {
    label: 'Other Electronic Parts and Equipment Merchant Wholesalers',
    BEA_CODES: { '423600': 1.0 },
  },
  ['423700']: {
    label:
      'Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423710']: {
    label: 'Hardware Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423720']: {
    label:
      'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423730']: {
    label:
      'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423740']: {
    label: 'Refrigeration Equipment and Supplies Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423800']: {
    label: 'Machinery, Equipment, and Supplies Merchant Wholesalers',
    BEA_CODES: { '423800': 1.0 },
  },
  ['423810']: {
    label:
      'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers',
    BEA_CODES: { '423800': 1.0 },
  },
  ['423820']: {
    label: 'Farm and Garden Machinery and Equipment Merchant Wholesalers',
    BEA_CODES: { '423800': 1.0 },
  },
  ['423830']: {
    label: 'Industrial Machinery and Equipment Merchant Wholesalers',
    BEA_CODES: { '423800': 1.0 },
  },
  ['423840']: {
    label: 'Industrial Supplies Merchant Wholesalers',
    BEA_CODES: { '423800': 1.0 },
  },
  ['423850']: {
    label: 'Service Establishment Equipment and Supplies Merchant Wholesalers',
    BEA_CODES: { '423800': 1.0 },
  },
  ['423860']: {
    label:
      'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers',
    BEA_CODES: { '423800': 1.0 },
  },
  ['423900']: {
    label: 'Miscellaneous Durable Goods Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423910']: {
    label: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423920']: {
    label: 'Toy and Hobby Goods and Supplies Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423930']: {
    label: 'Recyclable Material Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423940']: {
    label:
      'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423990']: {
    label: 'Other Miscellaneous Durable Goods Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['424000']: {
    label: 'Merchant Wholesalers, Nondurable Goods',
    BEA_CODES: {
      '424200': 0.18688179,
      '424400': 0.18219387,
      '424700': 0.2204943,
      '424A00': 0.41043004,
    },
  },
  ['424100']: {
    label: 'Paper and Paper Product Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424110']: {
    label: 'Printing and Writing Paper Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424120']: {
    label: 'Stationery and Office Supplies Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424130']: {
    label: 'Industrial and Personal Service Paper Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424200']: {
    label: "Drugs and Druggists' Sundries Merchant Wholesalers",
    BEA_CODES: { '424200': 1.0 },
  },
  ['424210']: {
    label: "Drugs and Druggists' Sundries Merchant Wholesalers",
    BEA_CODES: { '424200': 1.0 },
  },
  ['424300']: {
    label: 'Apparel, Piece Goods, and Notions Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424310']: {
    label: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424340']: {
    label: 'Footwear Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424350']: {
    label: 'Clothing and Clothing Accessories Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424400']: {
    label: 'Grocery and Related Product Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424410']: {
    label: 'General Line Grocery Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424420']: {
    label: 'Packaged Frozen Food Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424430']: {
    label: 'Dairy Product (except Dried or Canned) Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424440']: {
    label: 'Poultry and Poultry Product Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424450']: {
    label: 'Confectionery Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424460']: {
    label: 'Fish and Seafood Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424470']: {
    label: 'Meat and Meat Product Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424480']: {
    label: 'Fresh Fruit and Vegetable Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424490']: {
    label: 'Other Grocery and Related Products Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424500']: {
    label: 'Farm Product Raw Material Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424510']: {
    label: 'Grain and Field Bean Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424520']: {
    label: 'Livestock Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424590']: {
    label: 'Other Farm Product Raw Material Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424600']: {
    label: 'Chemical and Allied Products Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424610']: {
    label: 'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424690']: {
    label: 'Other Chemical and Allied Products Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424700']: {
    label: 'Petroleum and Petroleum Products Merchant Wholesalers',
    BEA_CODES: { '424700': 1.0 },
  },
  ['424710']: {
    label: 'Petroleum Bulk Stations and Terminals',
    BEA_CODES: { '424700': 1.0 },
  },
  ['424720']: {
    label:
      'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)',
    BEA_CODES: { '424700': 1.0 },
  },
  ['424800']: {
    label: 'Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424810']: {
    label: 'Beer and Ale Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424820']: {
    label: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424900']: {
    label: 'Miscellaneous Nondurable Goods Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424910']: {
    label: 'Farm Supplies Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424920']: {
    label: 'Book, Periodical, and Newspaper Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424930']: {
    label: "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers",
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424940']: {
    label: 'Tobacco Product and Electronic Cigarette Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424950']: {
    label: 'Paint, Varnish, and Supplies Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424990']: {
    label: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['425000']: {
    label: 'Wholesale Trade Agents and Brokers',
    BEA_CODES: { '425000': 1.0 },
  },
  ['425100']: {
    label: 'Wholesale Trade Agents and Brokers',
    BEA_CODES: { '425000': 1.0 },
  },
  ['425120']: {
    label: 'Wholesale Trade Agents and Brokers',
    BEA_CODES: { '425000': 1.0 },
  },
  ['440000']: {
    label: 'Retail Trade',
    BEA_CODES: {
      '441000': 0.19226721,
      '444000': 0.13942255,
      '445000': 0.23722603,
      '454000': 0.19911333,
      '4B0000': 0.23197088,
    },
  },
  ['441000']: {
    label: 'Motor Vehicle and Parts Dealers',
    BEA_CODES: { '441000': 0.49125389, '454000': 0.50874611 },
  },
  ['441100']: { label: 'Automobile Dealers', BEA_CODES: { '441000': 1.0 } },
  ['441110']: { label: 'New Car Dealers', BEA_CODES: { '441000': 1.0 } },
  ['441120']: { label: 'Used Car Dealers', BEA_CODES: { '441000': 1.0 } },
  ['441200']: {
    label: 'Other Motor Vehicle Dealers',
    BEA_CODES: { '441000': 0.49125389, '454000': 0.50874611 },
  },
  ['441210']: {
    label: 'Recreational Vehicle Dealers',
    BEA_CODES: { '441000': 1.0 },
  },
  ['441220']: {
    label: 'Motorcycle, Boat, and Other Motor Vehicle Dealers',
    BEA_CODES: { '441000': 0.49125389, '454000': 0.50874611 },
  },
  ['441300']: {
    label: 'Automotive Parts, Accessories, and Tire Retailers',
    BEA_CODES: { '441000': 0.49125389, '454000': 0.50874611 },
  },
  ['441330']: {
    label: 'Automotive Parts and Accessories Retailers',
    BEA_CODES: { '441000': 0.49125389, '454000': 0.50874611 },
  },
  ['441340']: {
    label: 'Tire Dealers',
    BEA_CODES: { '441000': 0.49125389, '454000': 0.50874611 },
  },
  ['444000']: {
    label: 'Building Material and Garden Equipment and Supplies Dealers',
    BEA_CODES: { '444000': 0.41183981, '454000': 0.58816019 },
  },
  ['444100']: {
    label: 'Building Material and Supplies Dealers',
    BEA_CODES: { '444000': 0.41183981, '454000': 0.58816019 },
  },
  ['444110']: { label: 'Home Centers', BEA_CODES: { '444000': 1.0 } },
  ['444120']: {
    label: 'Paint and Wallpaper Retailers',
    BEA_CODES: { '444000': 1.0 },
  },
  ['444140']: {
    label: 'Hardware Retailers',
    BEA_CODES: { '444000': 0.41183981, '454000': 0.58816019 },
  },
  ['444180']: {
    label: 'Other Building Material Dealers',
    BEA_CODES: { '444000': 0.41183981, '454000': 0.58816019 },
  },
  ['444200']: {
    label: 'Lawn and Garden Equipment and Supplies Retailers',
    BEA_CODES: { '444000': 0.41183981, '454000': 0.58816019 },
  },
  ['444230']: {
    label: 'Outdoor Power Equipment Retailers',
    BEA_CODES: { '444000': 0.41183981, '454000': 0.58816019 },
  },
  ['444240']: {
    label: 'Nursery, Garden Center, and Farm Supply Retailers',
    BEA_CODES: { '444000': 0.41183981, '454000': 0.58816019 },
  },
  ['445000']: {
    label: 'Food and Beverage Retailers',
    BEA_CODES: { '445000': 0.54367324, '454000': 0.45632676 },
  },
  ['445100']: {
    label: 'Grocery and Convenience Retailers',
    BEA_CODES: { '445000': 0.54367324, '454000': 0.45632676 },
  },
  ['445110']: {
    label:
      'Supermarkets and Other Grocery Retailers (except Convenience Retailers)',
    BEA_CODES: { '445000': 1.0 },
  },
  ['445130']: {
    label: 'Convenience Retailers and Vending Machine Operators',
    BEA_CODES: { '445000': 0.54367324, '454000': 0.45632676 },
  },
  ['445200']: {
    label: 'Specialty Food Retailers',
    BEA_CODES: { '445000': 0.54367324, '454000': 0.45632676 },
  },
  ['445230']: {
    label: 'Fruit and Vegetable Retailers',
    BEA_CODES: { '445000': 1.0 },
  },
  ['445240']: {
    label: 'Meat Retailers',
    BEA_CODES: { '445000': 0.54367324, '454000': 0.45632676 },
  },
  ['445250']: {
    label: 'Fish and Seafood Retailers',
    BEA_CODES: { '445000': 0.54367324, '454000': 0.45632676 },
  },
  ['445290']: {
    label: 'Other Specialty Food Retailers',
    BEA_CODES: { '445000': 0.54367324, '454000': 0.45632676 },
  },
  ['445300']: {
    label: 'Beer, Wine, and Liquor Retailers',
    BEA_CODES: { '445000': 0.54367324, '454000': 0.45632676 },
  },
  ['445320']: {
    label: 'Beer, Wine, and Liquor Retailers',
    BEA_CODES: { '445000': 0.54367324, '454000': 0.45632676 },
  },
  ['449000']: {
    label: 'Furniture, Home Furnishings, Electronics, and Appliance Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['449100']: {
    label: 'Furniture and Home Furnishings Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['449110']: {
    label: 'Furniture Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['449120']: {
    label: 'Home Furnishings Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['449200']: {
    label: 'Electronics and Appliance Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['449210']: {
    label: 'Electronics and Appliance Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['450000']: {
    label: 'Retail Trade',
    BEA_CODES: {
      '446000': 0.11527996,
      '447000': 0.08203805,
      '448000': 0.14864107,
      '452000': 0.23972824,
      '454000': 0.19136673,
      '4B0000': 0.22294595,
    },
  },
  ['455000']: {
    label: 'General Merchandise Retailers',
    BEA_CODES: {
      '452000': 0.366534,
      '454000': 0.29259138,
      '4B0000': 0.34087462,
    },
  },
  ['455100']: {
    label: 'Department Stores',
    BEA_CODES: { '452000': 0.55609147, '454000': 0.44390853 },
  },
  ['455110']: {
    label: 'Department Stores',
    BEA_CODES: { '452000': 0.55609147, '454000': 0.44390853 },
  },
  ['455200']: {
    label:
      'Warehouse Clubs, Supercenters, and Other General Merchandise Retailers',
    BEA_CODES: {
      '452000': 0.366534,
      '454000': 0.29259138,
      '4B0000': 0.34087462,
    },
  },
  ['455210']: {
    label:
      'Warehouse Clubs, Supercenters, and Other General Merchandise Retailers',
    BEA_CODES: {
      '452000': 0.366534,
      '454000': 0.29259138,
      '4B0000': 0.34087462,
    },
  },
  ['456000']: {
    label: 'Health and Personal Care Retailers',
    BEA_CODES: { '446000': 0.3759374, '454000': 0.6240626 },
  },
  ['456100']: {
    label: 'Health and Personal Care Retailers',
    BEA_CODES: { '446000': 0.3759374, '454000': 0.6240626 },
  },
  ['456110']: {
    label: 'Pharmacies and Drug Retailers',
    BEA_CODES: { '446000': 0.3759374, '454000': 0.6240626 },
  },
  ['456120']: {
    label: 'Cosmetics, Beauty Supplies, and Perfume Retailers',
    BEA_CODES: { '446000': 0.3759374, '454000': 0.6240626 },
  },
  ['456130']: {
    label: 'Optical Goods Retailers',
    BEA_CODES: { '446000': 0.3759374, '454000': 0.6240626 },
  },
  ['456190']: {
    label: 'Other Health and Personal Care Retailers',
    BEA_CODES: { '446000': 0.3759374, '454000': 0.6240626 },
  },
  ['457000']: {
    label: 'Gasoline Stations and Fuel Dealers',
    BEA_CODES: { '447000': 0.30006077, '454000': 0.69993923 },
  },
  ['457100']: { label: 'Gasoline Stations', BEA_CODES: { '447000': 1.0 } },
  ['457110']: {
    label: 'Gasoline Stations with Convenience Stores',
    BEA_CODES: { '447000': 1.0 },
  },
  ['457120']: {
    label: 'Other Gasoline Stations',
    BEA_CODES: { '447000': 1.0 },
  },
  ['457200']: { label: 'Fuel Dealers', BEA_CODES: { '454000': 1.0 } },
  ['457210']: { label: 'Fuel Dealers', BEA_CODES: { '454000': 1.0 } },
  ['458000']: {
    label: 'Clothing, Clothing Accessories, Shoe, and Jewelry Retailers',
    BEA_CODES: { '448000': 0.43716958, '454000': 0.56283042 },
  },
  ['458100']: {
    label: 'Clothing and Clothing Accessories Retailers',
    BEA_CODES: { '448000': 0.43716958, '454000': 0.56283042 },
  },
  ['458110']: {
    label: 'Clothing and Clothing Accessories Retailers',
    BEA_CODES: { '448000': 0.43716958, '454000': 0.56283042 },
  },
  ['458200']: {
    label: 'Shoe Retailers',
    BEA_CODES: { '448000': 0.43716958, '454000': 0.56283042 },
  },
  ['458210']: {
    label: 'Shoe Retailers',
    BEA_CODES: { '448000': 0.43716958, '454000': 0.56283042 },
  },
  ['458300']: {
    label: 'Jewelry, Luggage, and Leather Goods Retailers',
    BEA_CODES: { '448000': 0.43716958, '454000': 0.56283042 },
  },
  ['458310']: {
    label: 'Jewelry Retailers',
    BEA_CODES: { '448000': 0.43716958, '454000': 0.56283042 },
  },
  ['458320']: {
    label: 'Luggage and Leather Goods Retailers',
    BEA_CODES: { '448000': 0.43716958, '454000': 0.56283042 },
  },
  ['459000']: {
    label:
      'Sporting Goods, Hobby, Musical Instrument, Book, and Miscellaneous Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459100']: {
    label: 'Sporting Goods, Hobby, and Musical Instrument Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459110']: {
    label: 'Sporting Goods Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459120']: {
    label: 'Hobby, Toy, and Game Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459130']: {
    label: 'Sewing, Needlework, and Piece Goods Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459140']: {
    label: 'Musical Instrument and Supplies Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459200']: {
    label: 'Book Retailers and News Dealers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459210']: {
    label: 'Book Retailers and News Dealers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459300']: {
    label: 'Florists',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459310']: {
    label: 'Florists',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459400']: {
    label: 'Office Supplies, Stationery, and Gift Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459410']: {
    label: 'Office Supplies and Stationery Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459420']: {
    label: 'Gift, Novelty, and Souvenir Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459500']: {
    label: 'Used Merchandise Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459510']: {
    label: 'Used Merchandise Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459900']: {
    label: 'Other Miscellaneous Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459910']: {
    label: 'Pet and Pet Supplies Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459920']: {
    label: 'Art Dealers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['459930']: {
    label: 'Manufactured (Mobile) Home Dealers',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['459990']: {
    label: 'All Other Miscellaneous Retailers',
    BEA_CODES: { '454000': 0.46188964, '4B0000': 0.53811036 },
  },
  ['480000']: {
    label: 'Transportation and Warehousing',
    BEA_CODES: {
      '481000': 0.22991341,
      '482000': 0.09257395,
      '483000': 0.05435582,
      '484000': 0.34647951,
      '485000': 0.07836449,
      '486000': 0.04717863,
      '48A000': 0.15113418,
    },
  },
  ['481000']: { label: 'Air Transportation', BEA_CODES: { '481000': 1.0 } },
  ['481100']: {
    label: 'Scheduled Air Transportation',
    BEA_CODES: { '481000': 1.0 },
  },
  ['481110']: {
    label: 'Scheduled Air Transportation',
    BEA_CODES: { '481000': 1.0 },
  },
  ['481200']: {
    label: 'Nonscheduled Air Transportation',
    BEA_CODES: { '481000': 1.0 },
  },
  ['481210']: {
    label: 'Nonscheduled Air Transportation',
    BEA_CODES: { '481000': 1.0 },
  },
  ['482000']: { label: 'Rail Transportation', BEA_CODES: { '482000': 1.0 } },
  ['482100']: { label: 'Rail Transportation', BEA_CODES: { '482000': 1.0 } },
  ['482110']: { label: 'Rail Transportation', BEA_CODES: { '482000': 1.0 } },
  ['483000']: { label: 'Water Transportation', BEA_CODES: { '483000': 1.0 } },
  ['483100']: {
    label: 'Deep Sea, Coastal, and Great Lakes Water Transportation',
    BEA_CODES: { '483000': 1.0 },
  },
  ['483110']: {
    label: 'Deep Sea, Coastal, and Great Lakes Water Transportation',
    BEA_CODES: { '483000': 1.0 },
  },
  ['483200']: {
    label: 'Inland Water Transportation',
    BEA_CODES: { '483000': 1.0 },
  },
  ['483210']: {
    label: 'Inland Water Transportation',
    BEA_CODES: { '483000': 1.0 },
  },
  ['484000']: { label: 'Truck Transportation', BEA_CODES: { '484000': 1.0 } },
  ['484100']: {
    label: 'General Freight Trucking',
    BEA_CODES: { '484000': 1.0 },
  },
  ['484110']: {
    label: 'General Freight Trucking, Local',
    BEA_CODES: { '484000': 1.0 },
  },
  ['484120']: {
    label: 'General Freight Trucking, Long-Distance',
    BEA_CODES: { '484000': 1.0 },
  },
  ['484200']: {
    label: 'Specialized Freight Trucking',
    BEA_CODES: { '484000': 1.0 },
  },
  ['484210']: {
    label: 'Used Household and Office Goods Moving',
    BEA_CODES: { '484000': 1.0 },
  },
  ['484220']: {
    label: 'Specialized Freight (except Used Goods) Trucking, Local',
    BEA_CODES: { '484000': 1.0 },
  },
  ['484230']: {
    label: 'Specialized Freight (except Used Goods) Trucking, Long-Distance',
    BEA_CODES: { '484000': 1.0 },
  },
  ['485000']: {
    label: 'Transit and Ground Passenger Transportation',
    BEA_CODES: { '485000': 1.0 },
  },
  ['485100']: { label: 'Urban Transit Systems', BEA_CODES: { '485000': 1.0 } },
  ['485110']: { label: 'Urban Transit Systems', BEA_CODES: { '485000': 1.0 } },
  ['485200']: {
    label: 'Interurban and Rural Bus Transportation',
    BEA_CODES: { '485000': 1.0 },
  },
  ['485210']: {
    label: 'Interurban and Rural Bus Transportation',
    BEA_CODES: { '485000': 1.0 },
  },
  ['485300']: {
    label: 'Taxi and Limousine Service',
    BEA_CODES: { '485000': 1.0 },
  },
  ['485310']: {
    label: 'Taxi and Ridesharing Services',
    BEA_CODES: { '485000': 1.0 },
  },
  ['485320']: { label: 'Limousine Service', BEA_CODES: { '485000': 1.0 } },
  ['485400']: {
    label: 'School and Employee Bus Transportation',
    BEA_CODES: { '485000': 1.0 },
  },
  ['485410']: {
    label: 'School and Employee Bus Transportation',
    BEA_CODES: { '485000': 1.0 },
  },
  ['485500']: { label: 'Charter Bus Industry', BEA_CODES: { '485000': 1.0 } },
  ['485510']: { label: 'Charter Bus Industry', BEA_CODES: { '485000': 1.0 } },
  ['485900']: {
    label: 'Other Transit and Ground Passenger Transportation',
    BEA_CODES: { '485000': 1.0 },
  },
  ['485990']: {
    label: 'Other Transit and Ground Passenger Transportation',
    BEA_CODES: { '485000': 1.0 },
  },
  ['486000']: {
    label: 'Pipeline Transportation',
    BEA_CODES: { '486000': 1.0 },
  },
  ['486100']: {
    label: 'Pipeline Transportation of Crude Oil',
    BEA_CODES: { '486000': 1.0 },
  },
  ['486110']: {
    label: 'Pipeline Transportation of Crude Oil',
    BEA_CODES: { '486000': 1.0 },
  },
  ['486200']: {
    label: 'Pipeline Transportation of Natural Gas',
    BEA_CODES: { '486000': 1.0 },
  },
  ['486210']: {
    label: 'Pipeline Transportation of Natural Gas',
    BEA_CODES: { '486000': 1.0 },
  },
  ['486900']: {
    label: 'Other Pipeline Transportation',
    BEA_CODES: { '486000': 1.0 },
  },
  ['486910']: {
    label: 'Pipeline Transportation of Refined Petroleum Products',
    BEA_CODES: { '486000': 1.0 },
  },
  ['486990']: {
    label: 'All Other Pipeline Transportation',
    BEA_CODES: { '486000': 1.0 },
  },
  ['487000']: {
    label: 'Scenic and Sightseeing Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['487100']: {
    label: 'Scenic and Sightseeing Transportation, Land',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['487110']: {
    label: 'Scenic and Sightseeing Transportation, Land',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['487200']: {
    label: 'Scenic and Sightseeing Transportation, Water',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['487210']: {
    label: 'Scenic and Sightseeing Transportation, Water',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['487900']: {
    label: 'Scenic and Sightseeing Transportation, Other',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['487990']: {
    label: 'Scenic and Sightseeing Transportation, Other',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488000']: {
    label: 'Support Activities for Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488100']: {
    label: 'Support Activities for Air Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488110']: { label: 'Airport Operations', BEA_CODES: { '48A000': 1.0 } },
  ['488190']: {
    label: 'Other Support Activities for Air Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488200']: {
    label: 'Support Activities for Rail Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488210']: {
    label: 'Support Activities for Rail Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488300']: {
    label: 'Support Activities for Water Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488310']: {
    label: 'Port and Harbor Operations',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488320']: { label: 'Marine Cargo Handling', BEA_CODES: { '48A000': 1.0 } },
  ['488330']: {
    label: 'Navigational Services to Shipping',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488390']: {
    label: 'Other Support Activities for Water Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488400']: {
    label: 'Support Activities for Road Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488410']: { label: 'Motor Vehicle Towing', BEA_CODES: { '48A000': 1.0 } },
  ['488490']: {
    label: 'Other Support Activities for Road Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488500']: {
    label: 'Freight Transportation Arrangement',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488510']: {
    label: 'Freight Transportation Arrangement',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488900']: {
    label: 'Other Support Activities for Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488990']: {
    label: 'Other Support Activities for Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['490000']: {
    label: 'Transportation and Warehousing',
    BEA_CODES: {
      '491000': 0.24886044,
      '492000': 0.29929749,
      '493000': 0.45184207,
    },
  },
  ['491000']: { label: 'Postal Service', BEA_CODES: { '491000': 1.0 } },
  ['491100']: { label: 'Postal Service', BEA_CODES: { '491000': 1.0 } },
  ['491110']: { label: 'Postal Service', BEA_CODES: { '491000': 1.0 } },
  ['492000']: {
    label: 'Couriers and Messengers',
    BEA_CODES: { '492000': 1.0 },
  },
  ['492100']: {
    label: 'Couriers and Express Delivery Services',
    BEA_CODES: { '492000': 1.0 },
  },
  ['492110']: {
    label: 'Couriers and Express Delivery Services',
    BEA_CODES: { '492000': 1.0 },
  },
  ['492200']: {
    label: 'Local Messengers and Local Delivery',
    BEA_CODES: { '492000': 1.0 },
  },
  ['492210']: {
    label: 'Local Messengers and Local Delivery',
    BEA_CODES: { '492000': 1.0 },
  },
  ['493000']: {
    label: 'Warehousing and Storage',
    BEA_CODES: { '493000': 1.0 },
  },
  ['493100']: {
    label: 'Warehousing and Storage',
    BEA_CODES: { '493000': 1.0 },
  },
  ['493110']: {
    label: 'General Warehousing and Storage',
    BEA_CODES: { '493000': 1.0 },
  },
  ['493120']: {
    label: 'Refrigerated Warehousing and Storage',
    BEA_CODES: { '493000': 1.0 },
  },
  ['493130']: {
    label: 'Farm Product Warehousing and Storage',
    BEA_CODES: { '493000': 1.0 },
  },
  ['493190']: {
    label: 'Other Warehousing and Storage',
    BEA_CODES: { '493000': 1.0 },
  },
  ['510000']: {
    label: 'Information',
    BEA_CODES: {
      '511110': 0.00762817,
      '511120': 0.00998343,
      '511130': 0.02719925,
      '5111A0': 0.00932851,
      '511200': 0.13056646,
      '512100': 0.10112918,
      '512200': 0.0138839,
      '515100': 0.04493337,
      '515200': 0.01825418,
      '517110': 0.28916069,
      '517210': 0.17618994,
      '517A00': 0.02194133,
      '518200': 0.09817708,
      '519130': 0.04427047,
      '5191A0': 0.00735403,
    },
  },
  ['512000']: {
    label: 'Motion Picture and Sound Recording Industries',
    BEA_CODES: { '512100': 0.87928418, '512200': 0.12071582 },
  },
  ['512100']: {
    label: 'Motion Picture and Video Industries',
    BEA_CODES: { '512100': 1.0 },
  },
  ['512110']: {
    label: 'Motion Picture and Video Production',
    BEA_CODES: { '512100': 1.0 },
  },
  ['512120']: {
    label: 'Motion Picture and Video Distribution',
    BEA_CODES: { '512100': 1.0 },
  },
  ['512130']: {
    label: 'Motion Picture and Video Exhibition',
    BEA_CODES: { '512100': 1.0 },
  },
  ['512190']: {
    label:
      'Postproduction Services and Other Motion Picture and Video Industries',
    BEA_CODES: { '512100': 1.0 },
  },
  ['512200']: {
    label: 'Sound Recording Industries',
    BEA_CODES: { '512200': 1.0 },
  },
  ['512230']: { label: 'Music Publishers', BEA_CODES: { '512200': 1.0 } },
  ['512240']: {
    label: 'Sound Recording Studios',
    BEA_CODES: { '512200': 1.0 },
  },
  ['512250']: {
    label: 'Record Production and Distribution',
    BEA_CODES: { '512200': 1.0 },
  },
  ['512290']: {
    label: 'Other Sound Recording Industries',
    BEA_CODES: { '512200': 1.0 },
  },
  ['513000']: {
    label: 'Publishing Industries',
    BEA_CODES: {
      '511110': 0.03331422,
      '511120': 0.04360028,
      '511130': 0.11878633,
      '5111A0': 0.04074005,
      '511200': 0.57021825,
      '519130': 0.19334087,
    },
  },
  ['513100']: {
    label: 'Newspaper, Periodical, Book, and Directory Publishers',
    BEA_CODES: {
      '511110': 0.07751427,
      '511120': 0.1014475,
      '511130': 0.27638756,
      '5111A0': 0.09479241,
      '519130': 0.44985826,
    },
  },
  ['513110']: {
    label: 'Newspaper Publishers',
    BEA_CODES: { '511110': 0.14698201, '519130': 0.85301799 },
  },
  ['513120']: {
    label: 'Periodical Publishers',
    BEA_CODES: { '511120': 0.18401313, '519130': 0.81598687 },
  },
  ['513130']: {
    label: 'Book Publishers',
    BEA_CODES: { '511130': 0.38057026, '519130': 0.61942974 },
  },
  ['513140']: {
    label: 'Directory and Mailing List Publishers',
    BEA_CODES: { '5111A0': 0.17404259, '519130': 0.82595741 },
  },
  ['513190']: {
    label: 'Other Publishers',
    BEA_CODES: { '5111A0': 0.17404259, '519130': 0.82595741 },
  },
  ['513200']: { label: 'Software Publishers', BEA_CODES: { '511200': 1.0 } },
  ['513210']: { label: 'Software Publishers', BEA_CODES: { '511200': 1.0 } },
  ['516000']: {
    label: 'Broadcasting and Content Providers',
    BEA_CODES: {
      '515100': 0.39136457,
      '515200': 0.15899186,
      '519130': 0.3855908,
      '5191A0': 0.06405278,
    },
  },
  ['516100']: {
    label: 'Radio and Television Broadcasting Stations',
    BEA_CODES: { '515100': 1.0 },
  },
  ['516110']: {
    label: 'Radio Broadcasting Stations',
    BEA_CODES: { '515100': 1.0 },
  },
  ['516120']: {
    label: 'Television Broadcasting Stations',
    BEA_CODES: { '515100': 1.0 },
  },
  ['516200']: {
    label:
      'Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers',
    BEA_CODES: {
      '515100': 0.39136457,
      '515200': 0.15899186,
      '519130': 0.3855908,
      '5191A0': 0.06405278,
    },
  },
  ['516210']: {
    label:
      'Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers',
    BEA_CODES: {
      '515100': 0.39136457,
      '515200': 0.15899186,
      '519130': 0.3855908,
      '5191A0': 0.06405278,
    },
  },
  ['517000']: {
    label: 'Telecommunications',
    BEA_CODES: {
      '517110': 0.59340336,
      '517210': 0.36156956,
      '517A00': 0.04502708,
    },
  },
  ['517100']: {
    label: 'Wired and Wireless Telecommunications (except Satellite)',
    BEA_CODES: {
      '517110': 0.59340336,
      '517210': 0.36156956,
      '517A00': 0.04502708,
    },
  },
  ['517110']: {
    label: 'Wired and Wireless Telecommunications Carriers (except Satellite)',
    BEA_CODES: { '517110': 0.62138239, '517210': 0.37861761 },
  },
  ['517120']: {
    label:
      'Telecommunications Resellers and Agents for Wireless Telecommunication Services',
    BEA_CODES: { '517210': 0.88925861, '517A00': 0.11074139 },
  },
  ['517400']: {
    label: 'Satellite Telecommunications',
    BEA_CODES: { '517A00': 1.0 },
  },
  ['517410']: {
    label: 'Satellite Telecommunications',
    BEA_CODES: { '517A00': 1.0 },
  },
  ['517800']: {
    label: 'All Other Telecommunications',
    BEA_CODES: { '517A00': 1.0 },
  },
  ['517810']: {
    label: 'All Other Telecommunications',
    BEA_CODES: { '517A00': 1.0 },
  },
  ['518000']: {
    label:
      'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services',
    BEA_CODES: { '518200': 1.0 },
  },
  ['518200']: {
    label:
      'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services',
    BEA_CODES: { '518200': 1.0 },
  },
  ['518210']: {
    label:
      'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services',
    BEA_CODES: { '518200': 1.0 },
  },
  ['519000']: {
    label:
      'Web Search Portals, Libraries, Archives, and Other Information Services',
    BEA_CODES: { '519130': 0.85754766, '5191A0': 0.14245234 },
  },
  ['519200']: {
    label:
      'Web Search Portals, Libraries, Archives, and Other Information Services',
    BEA_CODES: { '519130': 0.85754766, '5191A0': 0.14245234 },
  },
  ['519210']: { label: 'Libraries and Archives', BEA_CODES: { '5191A0': 1.0 } },
  ['519290']: {
    label: 'Web Search Portals and All Other Information Services',
    BEA_CODES: { '519130': 0.85754766, '5191A0': 0.14245234 },
  },
  ['520000']: {
    label: 'Finance and Insurance',
    BEA_CODES: {
      '522A00': 0.13595048,
      '523900': 0.15644078,
      '523A00': 0.07315672,
      '524113': 0.0374747,
      '5241XX': 0.20113725,
      '524200': 0.14065528,
      '525000': 0.06611229,
      '52A000': 0.1890725,
    },
  },
  ['521000']: {
    label: 'Monetary Authorities-Central Bank',
    BEA_CODES: { '52A000': 1.0 },
  },
  ['521100']: {
    label: 'Monetary Authorities-Central Bank',
    BEA_CODES: { '52A000': 1.0 },
  },
  ['521110']: {
    label: 'Monetary Authorities-Central Bank',
    BEA_CODES: { '52A000': 1.0 },
  },
  ['522000']: {
    label: 'Credit Intermediation and Related Activities',
    BEA_CODES: { '522A00': 0.41827959, '52A000': 0.58172041 },
  },
  ['522100']: {
    label: 'Depository Credit Intermediation',
    BEA_CODES: { '52A000': 1.0 },
  },
  ['522110']: { label: 'Commercial Banking', BEA_CODES: { '52A000': 1.0 } },
  ['522130']: { label: 'Credit Unions', BEA_CODES: { '52A000': 1.0 } },
  ['522180']: {
    label: 'Savings Institutions and Other Depository Credit Intermediation',
    BEA_CODES: { '52A000': 1.0 },
  },
  ['522200']: {
    label: 'Nondepository Credit Intermediation',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['522210']: { label: 'Credit Card Issuing', BEA_CODES: { '522A00': 1.0 } },
  ['522220']: { label: 'Sales Financing', BEA_CODES: { '522A00': 1.0 } },
  ['522290']: {
    label: 'Other Nondepository Credit Intermediation',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['522300']: {
    label: 'Activities Related to Credit Intermediation',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['522310']: {
    label: 'Mortgage and Nonmortgage Loan Brokers',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['522320']: {
    label:
      'Financial Transactions Processing, Reserve, and Clearinghouse Activities',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['522390']: {
    label: 'Other Activities Related to Credit Intermediation',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['523000']: {
    label:
      'Securities, Commodity Contracts, and Other Financial Investments and Related Activities',
    BEA_CODES: { '523900': 0.68136971, '523A00': 0.31863029 },
  },
  ['523100']: {
    label: 'Securities and Commodity Contracts Intermediation and Brokerage',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['523150']: {
    label: 'Investment Banking and Securities Intermediation',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['523160']: {
    label: 'Commodity Contracts Intermediation',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['523200']: {
    label: 'Securities and Commodity Exchanges',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['523210']: {
    label: 'Securities and Commodity Exchanges',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['523900']: {
    label: 'Other Financial Investment Activities',
    BEA_CODES: { '523900': 1.0 },
  },
  ['523910']: {
    label: 'Miscellaneous Intermediation',
    BEA_CODES: { '523900': 1.0 },
  },
  ['523940']: {
    label: 'Portfolio Management and Investment Advice',
    BEA_CODES: { '523900': 1.0 },
  },
  ['523990']: {
    label: 'All Other Financial Investment Activities',
    BEA_CODES: { '523900': 1.0 },
  },
  ['524000']: {
    label: 'Insurance Carriers and Related Activities',
    BEA_CODES: {
      '524113': 0.09880818,
      '5241XX': 0.53033121,
      '524200': 0.37086062,
    },
  },
  ['524100']: {
    label: 'Insurance Carriers',
    BEA_CODES: { '524113': 0.15705292, '5241XX': 0.84294708 },
  },
  ['524110']: {
    label: 'Direct Life, Health, and Medical Insurance Carriers',
    BEA_CODES: { '524113': 0.15705292, '5241XX': 0.84294708 },
  },
  ['524120']: {
    label: 'Direct Insurance (except Life, Health, and Medical) Carriers',
    BEA_CODES: { '5241XX': 1.0 },
  },
  ['524130']: { label: 'Reinsurance Carriers', BEA_CODES: { '5241XX': 1.0 } },
  ['524200']: {
    label: 'Agencies, Brokerages, and Other Insurance Related Activities',
    BEA_CODES: { '524200': 1.0 },
  },
  ['524210']: {
    label: 'Insurance Agencies and Brokerages',
    BEA_CODES: { '524200': 1.0 },
  },
  ['524290']: {
    label: 'Other Insurance Related Activities',
    BEA_CODES: { '524200': 1.0 },
  },
  ['525000']: {
    label: 'Funds, Trusts, and Other Financial Vehicles',
    BEA_CODES: { '525000': 1.0 },
  },
  ['525100']: {
    label: 'Insurance and Employee Benefit Funds',
    BEA_CODES: { '525000': 1.0 },
  },
  ['525110']: { label: 'Pension Funds', BEA_CODES: { '525000': 1.0 } },
  ['525120']: {
    label: 'Health and Welfare Funds',
    BEA_CODES: { '525000': 1.0 },
  },
  ['525190']: { label: 'Other Insurance Funds', BEA_CODES: { '525000': 1.0 } },
  ['525900']: {
    label: 'Other Investment Pools and Funds',
    BEA_CODES: { '525000': 1.0 },
  },
  ['525910']: {
    label: 'Open-End Investment Funds',
    BEA_CODES: { '525000': 1.0 },
  },
  ['525920']: {
    label: 'Trusts, Estates, and Agency Accounts',
    BEA_CODES: { '525000': 1.0 },
  },
  ['525990']: {
    label: 'Other Financial Vehicles',
    BEA_CODES: { '525000': 1.0 },
  },
  ['530000']: {
    label: 'Real Estate and Rental and Leasing',
    BEA_CODES: {
      '531HSO': 0.42626203,
      '531HST': 0.14226536,
      '531ORE': 0.30670299,
      '532100': 0.03309077,
      '532400': 0.02790028,
      '532A00': 0.01158602,
      '533000': 0.05219254,
    },
  },
  ['531000']: {
    label: 'Real Estate',
    BEA_CODES: {
      '531HSO': 0.48702838,
      '531HST': 0.16254619,
      '531ORE': 0.35042544,
    },
  },
  ['531100']: {
    label: 'Lessors of Real Estate',
    BEA_CODES: {
      '531HSO': 0.48702838,
      '531HST': 0.16254619,
      '531ORE': 0.35042544,
    },
  },
  ['531110']: {
    label: 'Lessors of Residential Buildings and Dwellings',
    BEA_CODES: { '531HSO': 0.74976516, '531HST': 0.25023484 },
  },
  ['531120']: {
    label: 'Lessors of Nonresidential Buildings (except Miniwarehouses)',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['531130']: {
    label: 'Lessors of Miniwarehouses and Self-Storage Units',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['531190']: {
    label: 'Lessors of Other Real Estate Property',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['531200']: {
    label: 'Offices of Real Estate Agents and Brokers',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['531210']: {
    label: 'Offices of Real Estate Agents and Brokers',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['531300']: {
    label: 'Activities Related to Real Estate',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['531310']: {
    label: 'Real Estate Property Managers',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['531320']: {
    label: 'Offices of Real Estate Appraisers',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['531390']: {
    label: 'Other Activities Related to Real Estate',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['532000']: {
    label: 'Rental and Leasing Services',
    BEA_CODES: {
      '532100': 0.4559397,
      '532400': 0.38442279,
      '532A00': 0.15963751,
    },
  },
  ['532100']: {
    label: 'Automotive Equipment Rental and Leasing',
    BEA_CODES: { '532100': 1.0 },
  },
  ['532110']: {
    label: 'Passenger Car Rental and Leasing',
    BEA_CODES: { '532100': 1.0 },
  },
  ['532120']: {
    label:
      'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing',
    BEA_CODES: { '532100': 1.0 },
  },
  ['532200']: { label: 'Consumer Goods Rental', BEA_CODES: { '532A00': 1.0 } },
  ['532210']: {
    label: 'Consumer Electronics and Appliances Rental',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['532280']: {
    label: 'Other Consumer Goods Rental',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['532300']: { label: 'General Rental Centers', BEA_CODES: { '532A00': 1.0 } },
  ['532310']: { label: 'General Rental Centers', BEA_CODES: { '532A00': 1.0 } },
  ['532400']: {
    label:
      'Commercial and Industrial Machinery and Equipment Rental and Leasing',
    BEA_CODES: { '532400': 1.0 },
  },
  ['532410']: {
    label:
      'Construction, Transportation, Mining, and Forestry Machinery and Equipment Rental and Leasing',
    BEA_CODES: { '532400': 1.0 },
  },
  ['532420']: {
    label: 'Office Machinery and Equipment Rental and Leasing',
    BEA_CODES: { '532400': 1.0 },
  },
  ['532490']: {
    label:
      'Other Commercial and Industrial Machinery and Equipment Rental and Leasing',
    BEA_CODES: { '532400': 1.0 },
  },
  ['533000']: {
    label:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
    BEA_CODES: { '533000': 1.0 },
  },
  ['533100']: {
    label:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
    BEA_CODES: { '533000': 1.0 },
  },
  ['533110']: {
    label:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
    BEA_CODES: { '533000': 1.0 },
  },
  ['540000']: {
    label: 'Professional, Scientific, and Technical Services',
    BEA_CODES: {
      '541100': 0.13059669,
      '541200': 0.06768567,
      '541300': 0.12210819,
      '541400': 0.01130691,
      '541511': 0.08590657,
      '541512': 0.06051473,
      '54151A': 0.03070483,
      '541610': 0.08290123,
      '5416A0': 0.01897066,
      '541700': 0.20246861,
      '541800': 0.13703615,
      '541920': 0.00479724,
      '541940': 0.01086008,
      '5419A0': 0.03414244,
    },
  },
  ['541000']: {
    label: 'Professional, Scientific, and Technical Services',
    BEA_CODES: {
      '541100': 0.13059669,
      '541200': 0.06768567,
      '541300': 0.12210819,
      '541400': 0.01130691,
      '541511': 0.08590657,
      '541512': 0.06051473,
      '54151A': 0.03070483,
      '541610': 0.08290123,
      '5416A0': 0.01897066,
      '541700': 0.20246861,
      '541800': 0.13703615,
      '541920': 0.00479724,
      '541940': 0.01086008,
      '5419A0': 0.03414244,
    },
  },
  ['541100']: { label: 'Legal Services', BEA_CODES: { '541100': 1.0 } },
  ['541110']: { label: 'Offices of Lawyers', BEA_CODES: { '541100': 1.0 } },
  ['541120']: { label: 'Offices of Notaries', BEA_CODES: { '541100': 1.0 } },
  ['541190']: { label: 'Other Legal Services', BEA_CODES: { '541100': 1.0 } },
  ['541200']: {
    label: 'Accounting, Tax Preparation, Bookkeeping, and Payroll Services',
    BEA_CODES: { '541200': 1.0 },
  },
  ['541210']: {
    label: 'Accounting, Tax Preparation, Bookkeeping, and Payroll Services',
    BEA_CODES: { '541200': 1.0 },
  },
  ['541300']: {
    label: 'Architectural, Engineering, and Related Services',
    BEA_CODES: { '541300': 1.0 },
  },
  ['541310']: { label: 'Architectural Services', BEA_CODES: { '541300': 1.0 } },
  ['541320']: {
    label: 'Landscape Architectural Services',
    BEA_CODES: { '541300': 1.0 },
  },
  ['541330']: { label: 'Engineering Services', BEA_CODES: { '541300': 1.0 } },
  ['541340']: { label: 'Drafting Services', BEA_CODES: { '541300': 1.0 } },
  ['541350']: {
    label: 'Building Inspection Services',
    BEA_CODES: { '541300': 1.0 },
  },
  ['541360']: {
    label: 'Geophysical Surveying and Mapping Services',
    BEA_CODES: { '541300': 1.0 },
  },
  ['541370']: {
    label: 'Surveying and Mapping (except Geophysical) Services',
    BEA_CODES: { '541300': 1.0 },
  },
  ['541380']: {
    label: 'Testing Laboratories and Services',
    BEA_CODES: { '541300': 1.0 },
  },
  ['541400']: {
    label: 'Specialized Design Services',
    BEA_CODES: { '541400': 1.0 },
  },
  ['541410']: {
    label: 'Interior Design Services',
    BEA_CODES: { '541400': 1.0 },
  },
  ['541420']: {
    label: 'Industrial Design Services',
    BEA_CODES: { '541400': 1.0 },
  },
  ['541430']: {
    label: 'Graphic Design Services',
    BEA_CODES: { '541400': 1.0 },
  },
  ['541490']: {
    label: 'Other Specialized Design Services',
    BEA_CODES: { '541400': 1.0 },
  },
  ['541500']: {
    label: 'Computer Systems Design and Related Services',
    BEA_CODES: {
      '541511': 0.48500224,
      '541512': 0.34164767,
      '54151A': 0.17335009,
    },
  },
  ['541510']: {
    label: 'Computer Systems Design and Related Services',
    BEA_CODES: {
      '541511': 0.48500224,
      '541512': 0.34164767,
      '54151A': 0.17335009,
    },
  },
  ['541600']: {
    label: 'Management, Scientific, and Technical Consulting Services',
    BEA_CODES: { '541610': 0.81377927, '5416A0': 0.18622073 },
  },
  ['541610']: {
    label: 'Management Consulting Services',
    BEA_CODES: { '541610': 1.0 },
  },
  ['541620']: {
    label: 'Environmental Consulting Services',
    BEA_CODES: { '5416A0': 1.0 },
  },
  ['541690']: {
    label: 'Other Scientific and Technical Consulting Services',
    BEA_CODES: { '5416A0': 1.0 },
  },
  ['541700']: {
    label: 'Scientific Research and Development Services',
    BEA_CODES: { '541700': 1.0 },
  },
  ['541710']: {
    label:
      'Research and Development in the Physical, Engineering, and Life Sciences',
    BEA_CODES: { '541700': 1.0 },
  },
  ['541720']: {
    label: 'Research and Development in the Social Sciences and Humanities',
    BEA_CODES: { '541700': 1.0 },
  },
  ['541800']: {
    label: 'Advertising, Public Relations, and Related Services',
    BEA_CODES: { '541800': 1.0 },
  },
  ['541810']: { label: 'Advertising Agencies', BEA_CODES: { '541800': 1.0 } },
  ['541820']: {
    label: 'Public Relations Agencies',
    BEA_CODES: { '541800': 1.0 },
  },
  ['541830']: { label: 'Media Buying Agencies', BEA_CODES: { '541800': 1.0 } },
  ['541840']: { label: 'Media Representatives', BEA_CODES: { '541800': 1.0 } },
  ['541850']: {
    label: 'Indoor and Outdoor Display Advertising',
    BEA_CODES: { '541800': 1.0 },
  },
  ['541860']: {
    label: 'Direct Mail Advertising',
    BEA_CODES: { '541800': 1.0 },
  },
  ['541870']: {
    label: 'Advertising Material Distribution Services',
    BEA_CODES: { '541800': 1.0 },
  },
  ['541890']: {
    label: 'Other Services Related to Advertising',
    BEA_CODES: { '541800': 1.0 },
  },
  ['541900']: {
    label: 'Other Professional, Scientific, and Technical Services',
    BEA_CODES: {
      '541920': 0.09633067,
      '541940': 0.21807497,
      '5419A0': 0.68559435,
    },
  },
  ['541910']: {
    label: 'Marketing Research and Public Opinion Polling',
    BEA_CODES: { '5419A0': 1.0 },
  },
  ['541920']: { label: 'Photographic Services', BEA_CODES: { '541920': 1.0 } },
  ['541930']: {
    label: 'Translation and Interpretation Services',
    BEA_CODES: { '5419A0': 1.0 },
  },
  ['541940']: { label: 'Veterinary Services', BEA_CODES: { '541940': 1.0 } },
  ['541990']: {
    label: 'All Other Professional, Scientific, and Technical Services',
    BEA_CODES: { '5419A0': 1.0 },
  },
  ['550000']: {
    label: 'Management of Companies and Enterprises',
    BEA_CODES: { '550000': 1.0 },
  },
  ['551000']: {
    label: 'Management of Companies and Enterprises',
    BEA_CODES: { '550000': 1.0 },
  },
  ['551100']: {
    label: 'Management of Companies and Enterprises',
    BEA_CODES: { '550000': 1.0 },
  },
  ['551110']: {
    label: 'Management of Companies and Enterprises',
    BEA_CODES: { '550000': 1.0 },
  },
  ['560000']: {
    label:
      'Administrative and Support and Waste Management and Remediation Services',
    BEA_CODES: {
      '561100': 0.06071289,
      '561200': 0.03487402,
      '561300': 0.32707357,
      '561400': 0.09570896,
      '561500': 0.05001177,
      '561600': 0.07001489,
      '561700': 0.18821225,
      '561900': 0.04508799,
      '562111': 0.05948047,
      '562212': 0.00957494,
      '562213': 0.00596166,
      '562910': 0.01765329,
      '562920': 0.00789157,
      '562HAZ': 0.01659155,
      '562OTH': 0.01115017,
    },
  },
  ['561000']: {
    label: 'Administrative and Support Services',
    BEA_CODES: {
      '561100': 0.06964913,
      '561200': 0.04000707,
      '561300': 0.37521502,
      '561400': 0.10979621,
      '561500': 0.05737293,
      '561600': 0.08032028,
      '561700': 0.21591493,
      '561900': 0.05172443,
    },
  },
  ['561100']: {
    label: 'Office Administrative Services',
    BEA_CODES: { '561100': 1.0 },
  },
  ['561110']: {
    label: 'Office Administrative Services',
    BEA_CODES: { '561100': 1.0 },
  },
  ['561200']: {
    label: 'Facilities Support Services',
    BEA_CODES: { '561200': 1.0 },
  },
  ['561210']: {
    label: 'Facilities Support Services',
    BEA_CODES: { '561200': 1.0 },
  },
  ['561300']: { label: 'Employment Services', BEA_CODES: { '561300': 1.0 } },
  ['561310']: {
    label: 'Employment Placement Agencies and Executive Search Services',
    BEA_CODES: { '561300': 1.0 },
  },
  ['561320']: {
    label: 'Temporary Help Services',
    BEA_CODES: { '561300': 1.0 },
  },
  ['561330']: {
    label: 'Professional Employer Organizations',
    BEA_CODES: { '561300': 1.0 },
  },
  ['561400']: {
    label: 'Business Support Services',
    BEA_CODES: { '561400': 1.0 },
  },
  ['561410']: {
    label: 'Document Preparation Services',
    BEA_CODES: { '561400': 1.0 },
  },
  ['561420']: { label: 'Telephone Call Centers', BEA_CODES: { '561400': 1.0 } },
  ['561430']: {
    label: 'Business Service Centers',
    BEA_CODES: { '561400': 1.0 },
  },
  ['561440']: { label: 'Collection Agencies', BEA_CODES: { '561400': 1.0 } },
  ['561450']: { label: 'Credit Bureaus', BEA_CODES: { '561400': 1.0 } },
  ['561490']: {
    label: 'Other Business Support Services',
    BEA_CODES: { '561400': 1.0 },
  },
  ['561500']: {
    label: 'Travel Arrangement and Reservation Services',
    BEA_CODES: { '561500': 1.0 },
  },
  ['561510']: { label: 'Travel Agencies', BEA_CODES: { '561500': 1.0 } },
  ['561520']: { label: 'Tour Operators', BEA_CODES: { '561500': 1.0 } },
  ['561590']: {
    label: 'Other Travel Arrangement and Reservation Services',
    BEA_CODES: { '561500': 1.0 },
  },
  ['561600']: {
    label: 'Investigation and Security Services',
    BEA_CODES: { '561600': 1.0 },
  },
  ['561610']: {
    label: 'Investigation, Guard, and Armored Car Services',
    BEA_CODES: { '561600': 1.0 },
  },
  ['561620']: {
    label: 'Security Systems Services',
    BEA_CODES: { '561600': 1.0 },
  },
  ['561700']: {
    label: 'Services to Buildings and Dwellings',
    BEA_CODES: { '561700': 1.0 },
  },
  ['561710']: {
    label: 'Exterminating and Pest Control Services',
    BEA_CODES: { '561700': 1.0 },
  },
  ['561720']: { label: 'Janitorial Services', BEA_CODES: { '561700': 1.0 } },
  ['561730']: { label: 'Landscaping Services', BEA_CODES: { '561700': 1.0 } },
  ['561740']: {
    label: 'Carpet and Upholstery Cleaning Services',
    BEA_CODES: { '561700': 1.0 },
  },
  ['561790']: {
    label: 'Other Services to Buildings and Dwellings',
    BEA_CODES: { '561700': 1.0 },
  },
  ['561900']: { label: 'Other Support Services', BEA_CODES: { '561900': 1.0 } },
  ['561910']: {
    label: 'Packaging and Labeling Services',
    BEA_CODES: { '561900': 1.0 },
  },
  ['561920']: {
    label: 'Convention and Trade Show Organizers',
    BEA_CODES: { '561900': 1.0 },
  },
  ['561990']: {
    label: 'All Other Support Services',
    BEA_CODES: { '561900': 1.0 },
  },
  ['562000']: {
    label: 'Waste Management and Remediation Services',
    BEA_CODES: {
      '562111': 0.46359142,
      '562212': 0.07462718,
      '562213': 0.04646524,
      '562910': 0.13758992,
      '562920': 0.06150698,
      '562HAZ': 0.12931473,
      '562OTH': 0.08690453,
    },
  },
  ['562100']: {
    label: 'Waste Collection',
    BEA_CODES: {
      '562111': 0.68194195,
      '562HAZ': 0.19022167,
      '562OTH': 0.12783637,
    },
  },
  ['562110']: {
    label: 'Waste Collection',
    BEA_CODES: {
      '562111': 0.68194195,
      '562HAZ': 0.19022167,
      '562OTH': 0.12783637,
    },
  },
  ['562200']: {
    label: 'Waste Treatment and Disposal',
    BEA_CODES: {
      '562212': 0.22124104,
      '562213': 0.13775164,
      '562HAZ': 0.38336866,
      '562OTH': 0.25763867,
    },
  },
  ['562210']: {
    label: 'Waste Treatment and Disposal',
    BEA_CODES: {
      '562212': 0.22124104,
      '562213': 0.13775164,
      '562HAZ': 0.38336866,
      '562OTH': 0.25763867,
    },
  },
  ['562900']: {
    label: 'Remediation and Other Waste Management Services',
    BEA_CODES: {
      '562910': 0.48108123,
      '562920': 0.2150583,
      '562OTH': 0.30386047,
    },
  },
  ['562910']: { label: 'Remediation Services', BEA_CODES: { '562910': 1.0 } },
  ['562920']: {
    label: 'Materials Recovery Facilities',
    BEA_CODES: { '562920': 1.0 },
  },
  ['562990']: {
    label: 'All Other Waste Management Services',
    BEA_CODES: { '562OTH': 1.0 },
  },
  ['610000']: {
    label: 'Educational Services',
    BEA_CODES: {
      '611100': 0.12347016,
      '611A00': 0.65933,
      '611B00': 0.21719984,
    },
  },
  ['611000']: {
    label: 'Educational Services',
    BEA_CODES: {
      '611100': 0.12347016,
      '611A00': 0.65933,
      '611B00': 0.21719984,
    },
  },
  ['611100']: {
    label: 'Elementary and Secondary Schools',
    BEA_CODES: { '611100': 1.0 },
  },
  ['611110']: {
    label: 'Elementary and Secondary Schools',
    BEA_CODES: { '611100': 1.0 },
  },
  ['611200']: { label: 'Junior Colleges', BEA_CODES: { '611A00': 1.0 } },
  ['611210']: { label: 'Junior Colleges', BEA_CODES: { '611A00': 1.0 } },
  ['611300']: {
    label: 'Colleges, Universities, and Professional Schools',
    BEA_CODES: { '611A00': 1.0 },
  },
  ['611310']: {
    label: 'Colleges, Universities, and Professional Schools',
    BEA_CODES: { '611A00': 1.0 },
  },
  ['611400']: {
    label: 'Business Schools and Computer and Management Training',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611410']: {
    label: 'Business and Secretarial Schools',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611420']: { label: 'Computer Training', BEA_CODES: { '611B00': 1.0 } },
  ['611430']: {
    label: 'Professional and Management Development Training',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611500']: {
    label: 'Technical and Trade Schools',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611510']: {
    label: 'Technical and Trade Schools',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611600']: {
    label: 'Other Schools and Instruction',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611610']: { label: 'Fine Arts Schools', BEA_CODES: { '611B00': 1.0 } },
  ['611620']: {
    label: 'Sports and Recreation Instruction',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611630']: { label: 'Language Schools', BEA_CODES: { '611B00': 1.0 } },
  ['611690']: {
    label: 'All Other Schools and Instruction',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611700']: {
    label: 'Educational Support Services',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611710']: {
    label: 'Educational Support Services',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['620000']: {
    label: 'Health Care and Social Assistance',
    BEA_CODES: {
      '621100': 0.19644462,
      '621200': 0.05071439,
      '621300': 0.0388961,
      '621400': 0.04861681,
      '621500': 0.02465272,
      '621600': 0.04132261,
      '621900': 0.01603002,
      '622000': 0.40315707,
      '623A00': 0.07964983,
      '623B00': 0.01946272,
      '624100': 0.03809058,
      '624400': 0.02230809,
      '624A00': 0.02065444,
    },
  },
  ['621000']: {
    label: 'Ambulatory Health Care Services',
    BEA_CODES: {
      '621100': 0.47145509,
      '621200': 0.12171145,
      '621300': 0.09334827,
      '621400': 0.11667738,
      '621500': 0.05916502,
      '621600': 0.09917174,
      '621900': 0.03847106,
    },
  },
  ['621100']: { label: 'Offices of Physicians', BEA_CODES: { '621100': 1.0 } },
  ['621110']: { label: 'Offices of Physicians', BEA_CODES: { '621100': 1.0 } },
  ['621200']: { label: 'Offices of Dentists', BEA_CODES: { '621200': 1.0 } },
  ['621210']: { label: 'Offices of Dentists', BEA_CODES: { '621200': 1.0 } },
  ['621300']: {
    label: 'Offices of Other Health Practitioners',
    BEA_CODES: { '621300': 1.0 },
  },
  ['621310']: {
    label: 'Offices of Chiropractors',
    BEA_CODES: { '621300': 1.0 },
  },
  ['621320']: {
    label: 'Offices of Optometrists',
    BEA_CODES: { '621300': 1.0 },
  },
  ['621330']: {
    label: 'Offices of Mental Health Practitioners (except Physicians)',
    BEA_CODES: { '621300': 1.0 },
  },
  ['621340']: {
    label:
      'Offices of Physical, Occupational and Speech Therapists, and Audiologists',
    BEA_CODES: { '621300': 1.0 },
  },
  ['621390']: {
    label: 'Offices of All Other Health Practitioners',
    BEA_CODES: { '621300': 1.0 },
  },
  ['621400']: {
    label: 'Outpatient Care Centers',
    BEA_CODES: { '621400': 1.0 },
  },
  ['621410']: {
    label: 'Family Planning Centers',
    BEA_CODES: { '621400': 1.0 },
  },
  ['621420']: {
    label: 'Outpatient Mental Health and Substance Abuse Centers',
    BEA_CODES: { '621400': 1.0 },
  },
  ['621490']: {
    label: 'Other Outpatient Care Centers',
    BEA_CODES: { '621400': 1.0 },
  },
  ['621500']: {
    label: 'Medical and Diagnostic Laboratories',
    BEA_CODES: { '621500': 1.0 },
  },
  ['621510']: {
    label: 'Medical and Diagnostic Laboratories',
    BEA_CODES: { '621500': 1.0 },
  },
  ['621600']: {
    label: 'Home Health Care Services',
    BEA_CODES: { '621600': 1.0 },
  },
  ['621610']: {
    label: 'Home Health Care Services',
    BEA_CODES: { '621600': 1.0 },
  },
  ['621900']: {
    label: 'Other Ambulatory Health Care Services',
    BEA_CODES: { '621900': 1.0 },
  },
  ['621910']: { label: 'Ambulance Services', BEA_CODES: { '621900': 1.0 } },
  ['621990']: {
    label: 'All Other Ambulatory Health Care Services',
    BEA_CODES: { '621900': 1.0 },
  },
  ['622000']: { label: 'Hospitals', BEA_CODES: { '622000': 1.0 } },
  ['622100']: {
    label: 'General Medical and Surgical Hospitals',
    BEA_CODES: { '622000': 1.0 },
  },
  ['622110']: {
    label: 'General Medical and Surgical Hospitals',
    BEA_CODES: { '622000': 1.0 },
  },
  ['622200']: {
    label: 'Psychiatric and Substance Abuse Hospitals',
    BEA_CODES: { '622000': 1.0 },
  },
  ['622210']: {
    label: 'Psychiatric and Substance Abuse Hospitals',
    BEA_CODES: { '622000': 1.0 },
  },
  ['622300']: {
    label: 'Specialty (except Psychiatric and Substance Abuse) Hospitals',
    BEA_CODES: { '622000': 1.0 },
  },
  ['622310']: {
    label: 'Specialty (except Psychiatric and Substance Abuse) Hospitals',
    BEA_CODES: { '622000': 1.0 },
  },
  ['623000']: {
    label: 'Nursing and Residential Care Facilities',
    BEA_CODES: { '623A00': 0.8036301, '623B00': 0.1963699 },
  },
  ['623100']: {
    label: 'Nursing Care Facilities (Skilled Nursing Facilities)',
    BEA_CODES: { '623A00': 1.0 },
  },
  ['623110']: {
    label: 'Nursing Care Facilities (Skilled Nursing Facilities)',
    BEA_CODES: { '623A00': 1.0 },
  },
  ['623200']: {
    label:
      'Residential Intellectual and Developmental Disability, Mental Health, and Substance Abuse Facilities',
    BEA_CODES: { '623B00': 1.0 },
  },
  ['623210']: {
    label: 'Residential Intellectual and Developmental Disability Facilities',
    BEA_CODES: { '623B00': 1.0 },
  },
  ['623220']: {
    label: 'Residential Mental Health and Substance Abuse Facilities',
    BEA_CODES: { '623B00': 1.0 },
  },
  ['623300']: {
    label:
      'Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly',
    BEA_CODES: { '623A00': 1.0 },
  },
  ['623310']: {
    label:
      'Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly',
    BEA_CODES: { '623A00': 1.0 },
  },
  ['623900']: {
    label: 'Other Residential Care Facilities',
    BEA_CODES: { '623B00': 1.0 },
  },
  ['623990']: {
    label: 'Other Residential Care Facilities',
    BEA_CODES: { '623B00': 1.0 },
  },
  ['624000']: {
    label: 'Social Assistance',
    BEA_CODES: {
      '624100': 0.46994593,
      '624400': 0.27522802,
      '624A00': 0.25482605,
    },
  },
  ['624100']: {
    label: 'Individual and Family Services',
    BEA_CODES: { '624100': 1.0 },
  },
  ['624110']: {
    label: 'Child and Youth Services',
    BEA_CODES: { '624100': 1.0 },
  },
  ['624120']: {
    label: 'Services for the Elderly and Persons with Disabilities',
    BEA_CODES: { '624100': 1.0 },
  },
  ['624190']: {
    label: 'Other Individual and Family Services',
    BEA_CODES: { '624100': 1.0 },
  },
  ['624200']: {
    label:
      'Community Food and Housing, and Emergency and Other Relief Services',
    BEA_CODES: { '624A00': 1.0 },
  },
  ['624210']: {
    label: 'Community Food Services',
    BEA_CODES: { '624A00': 1.0 },
  },
  ['624220']: {
    label: 'Community Housing Services',
    BEA_CODES: { '624A00': 1.0 },
  },
  ['624230']: {
    label: 'Emergency and Other Relief Services',
    BEA_CODES: { '624A00': 1.0 },
  },
  ['624300']: {
    label: 'Vocational Rehabilitation Services',
    BEA_CODES: { '624A00': 1.0 },
  },
  ['624310']: {
    label: 'Vocational Rehabilitation Services',
    BEA_CODES: { '624A00': 1.0 },
  },
  ['624400']: { label: 'Child Care Services', BEA_CODES: { '624400': 1.0 } },
  ['624410']: { label: 'Child Care Services', BEA_CODES: { '624400': 1.0 } },
  ['710000']: {
    label: 'Arts, Entertainment, and Recreation',
    BEA_CODES: {
      '711100': 0.09638848,
      '711200': 0.10885051,
      '711500': 0.11064636,
      '711A00': 0.08014988,
      '712000': 0.04763304,
      '713100': 0.04014261,
      '713200': 0.29724682,
      '713900': 0.21894229,
    },
  },
  ['711000']: {
    label: 'Performing Arts, Spectator Sports, and Related Industries',
    BEA_CODES: {
      '711100': 0.24338361,
      '711200': 0.27485057,
      '711500': 0.27938514,
      '711A00': 0.20238068,
    },
  },
  ['711100']: {
    label: 'Performing Arts Companies',
    BEA_CODES: { '711100': 1.0 },
  },
  ['711110']: {
    label: 'Theater Companies and Dinner Theaters',
    BEA_CODES: { '711100': 1.0 },
  },
  ['711120']: { label: 'Dance Companies', BEA_CODES: { '711100': 1.0 } },
  ['711130']: {
    label: 'Musical Groups and Artists',
    BEA_CODES: { '711100': 1.0 },
  },
  ['711190']: {
    label: 'Other Performing Arts Companies',
    BEA_CODES: { '711100': 1.0 },
  },
  ['711200']: { label: 'Spectator Sports', BEA_CODES: { '711200': 1.0 } },
  ['711210']: { label: 'Spectator Sports', BEA_CODES: { '711200': 1.0 } },
  ['711300']: {
    label: 'Promoters of Performing Arts, Sports, and Similar Events',
    BEA_CODES: { '711A00': 1.0 },
  },
  ['711310']: {
    label:
      'Promoters of Performing Arts, Sports, and Similar Events with Facilities',
    BEA_CODES: { '711A00': 1.0 },
  },
  ['711320']: {
    label:
      'Promoters of Performing Arts, Sports, and Similar Events without Facilities',
    BEA_CODES: { '711A00': 1.0 },
  },
  ['711400']: {
    label:
      'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
    BEA_CODES: { '711A00': 1.0 },
  },
  ['711410']: {
    label:
      'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
    BEA_CODES: { '711A00': 1.0 },
  },
  ['711500']: {
    label: 'Independent Artists, Writers, and Performers',
    BEA_CODES: { '711500': 1.0 },
  },
  ['711510']: {
    label: 'Independent Artists, Writers, and Performers',
    BEA_CODES: { '711500': 1.0 },
  },
  ['712000']: {
    label: 'Museums, Historical Sites, and Similar Institutions',
    BEA_CODES: { '712000': 1.0 },
  },
  ['712100']: {
    label: 'Museums, Historical Sites, and Similar Institutions',
    BEA_CODES: { '712000': 1.0 },
  },
  ['712110']: { label: 'Museums', BEA_CODES: { '712000': 1.0 } },
  ['712120']: { label: 'Historical Sites', BEA_CODES: { '712000': 1.0 } },
  ['712130']: {
    label: 'Zoos and Botanical Gardens',
    BEA_CODES: { '712000': 1.0 },
  },
  ['712190']: {
    label: 'Nature Parks and Other Similar Institutions',
    BEA_CODES: { '712000': 1.0 },
  },
  ['713000']: {
    label: 'Amusement, Gambling, and Recreation Industries',
    BEA_CODES: {
      '713100': 0.0721559,
      '713200': 0.53429781,
      '713900': 0.3935463,
    },
  },
  ['713100']: {
    label: 'Amusement Parks and Arcades',
    BEA_CODES: { '713100': 1.0 },
  },
  ['713110']: {
    label: 'Amusement and Theme Parks',
    BEA_CODES: { '713100': 1.0 },
  },
  ['713120']: { label: 'Amusement Arcades', BEA_CODES: { '713100': 1.0 } },
  ['713200']: { label: 'Gambling Industries', BEA_CODES: { '713200': 1.0 } },
  ['713210']: {
    label: 'Casinos (except Casino Hotels)',
    BEA_CODES: { '713200': 1.0 },
  },
  ['713290']: {
    label: 'Other Gambling Industries',
    BEA_CODES: { '713200': 1.0 },
  },
  ['713900']: {
    label: 'Other Amusement and Recreation Industries',
    BEA_CODES: { '713900': 1.0 },
  },
  ['713910']: {
    label: 'Golf Courses and Country Clubs',
    BEA_CODES: { '713900': 1.0 },
  },
  ['713920']: { label: 'Skiing Facilities', BEA_CODES: { '713900': 1.0 } },
  ['713930']: { label: 'Marinas', BEA_CODES: { '713900': 1.0 } },
  ['713940']: {
    label: 'Fitness and Recreational Sports Centers',
    BEA_CODES: { '713900': 1.0 },
  },
  ['713950']: { label: 'Bowling Centers', BEA_CODES: { '713900': 1.0 } },
  ['713990']: {
    label: 'All Other Amusement and Recreation Industries',
    BEA_CODES: { '713900': 1.0 },
  },
  ['720000']: {
    label: 'Accommodation and Food Services',
    BEA_CODES: {
      '721000': 0.1931423,
      '722110': 0.31064172,
      '722211': 0.3307904,
      '722A00': 0.16542557,
    },
  },
  ['721000']: { label: 'Accommodation', BEA_CODES: { '721000': 1.0 } },
  ['721100']: { label: 'Traveler Accommodation', BEA_CODES: { '721000': 1.0 } },
  ['721110']: {
    label: 'Hotels (except Casino Hotels) and Motels',
    BEA_CODES: { '721000': 1.0 },
  },
  ['721120']: { label: 'Casino Hotels', BEA_CODES: { '721000': 1.0 } },
  ['721190']: {
    label: 'Other Traveler Accommodation',
    BEA_CODES: { '721000': 1.0 },
  },
  ['721200']: {
    label: 'RV (Recreational Vehicle) Parks and Recreational Camps',
    BEA_CODES: { '721000': 1.0 },
  },
  ['721210']: {
    label: 'RV (Recreational Vehicle) Parks and Recreational Camps',
    BEA_CODES: { '721000': 1.0 },
  },
  ['721300']: {
    label: "Rooming and Boarding Houses, Dormitories, and Workers' Camps",
    BEA_CODES: { '721000': 1.0 },
  },
  ['721310']: {
    label: "Rooming and Boarding Houses, Dormitories, and Workers' Camps",
    BEA_CODES: { '721000': 1.0 },
  },
  ['722000']: {
    label: 'Food Services and Drinking Places',
    BEA_CODES: {
      '722110': 0.38500187,
      '722211': 0.40997366,
      '722A00': 0.20502447,
    },
  },
  ['722300']: { label: 'Special Food Services', BEA_CODES: { '722A00': 1.0 } },
  ['722310']: {
    label: 'Food Service Contractors',
    BEA_CODES: { '722A00': 1.0 },
  },
  ['722320']: { label: 'Caterers', BEA_CODES: { '722A00': 1.0 } },
  ['722330']: { label: 'Mobile Food Services', BEA_CODES: { '722A00': 1.0 } },
  ['722400']: {
    label: 'Drinking Places (Alcoholic Beverages)',
    BEA_CODES: { '722A00': 1.0 },
  },
  ['722410']: {
    label: 'Drinking Places (Alcoholic Beverages)',
    BEA_CODES: { '722A00': 1.0 },
  },
  ['722500']: {
    label: 'Restaurants and Other Eating Places',
    BEA_CODES: {
      '722110': 0.38500187,
      '722211': 0.40997366,
      '722A00': 0.20502447,
    },
  },
  ['722510']: {
    label: 'Restaurants and Other Eating Places',
    BEA_CODES: {
      '722110': 0.38500187,
      '722211': 0.40997366,
      '722A00': 0.20502447,
    },
  },
  ['810000']: {
    label: 'Other Services (except Public Administration)',
    BEA_CODES: {
      '811100': 0.25895533,
      '811200': 0.06592738,
      '811300': 0.08320364,
      '811400': 0.04486091,
      '812100': 0.09438894,
      '812200': 0.02679005,
      '812300': 0.03579626,
      '812900': 0.08875187,
      '813100': 0.11484495,
      '813A00': 0.06108225,
      '813B00': 0.10124152,
      '814000': 0.0241569,
    },
  },
  ['811000']: {
    label: 'Repair and Maintenance',
    BEA_CODES: {
      '811100': 0.57171187,
      '811200': 0.145552,
      '811300': 0.18369388,
      '811400': 0.09904224,
    },
  },
  ['811100']: {
    label: 'Automotive Repair and Maintenance',
    BEA_CODES: { '811100': 1.0 },
  },
  ['811110']: {
    label: 'Automotive Mechanical and Electrical Repair and Maintenance',
    BEA_CODES: { '811100': 1.0 },
  },
  ['811120']: {
    label: 'Automotive Body, Paint, Interior, and Glass Repair',
    BEA_CODES: { '811100': 1.0 },
  },
  ['811190']: {
    label: 'Other Automotive Repair and Maintenance',
    BEA_CODES: { '811100': 1.0 },
  },
  ['811200']: {
    label: 'Electronic and Precision Equipment Repair and Maintenance',
    BEA_CODES: { '811200': 1.0 },
  },
  ['811210']: {
    label: 'Electronic and Precision Equipment Repair and Maintenance',
    BEA_CODES: { '811200': 1.0 },
  },
  ['811300']: {
    label:
      'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
    BEA_CODES: { '811300': 1.0 },
  },
  ['811310']: {
    label:
      'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
    BEA_CODES: { '811300': 1.0 },
  },
  ['811400']: {
    label: 'Personal and Household Goods Repair and Maintenance',
    BEA_CODES: { '811400': 1.0 },
  },
  ['811410']: {
    label: 'Home and Garden Equipment and Appliance Repair and Maintenance',
    BEA_CODES: { '811400': 1.0 },
  },
  ['811420']: {
    label: 'Reupholstery and Furniture Repair',
    BEA_CODES: { '811400': 1.0 },
  },
  ['811430']: {
    label: 'Footwear and Leather Goods Repair',
    BEA_CODES: { '811400': 1.0 },
  },
  ['811490']: {
    label: 'Other Personal and Household Goods Repair and Maintenance',
    BEA_CODES: { '811400': 1.0 },
  },
  ['812000']: {
    label: 'Personal and Laundry Services',
    BEA_CODES: {
      '812100': 0.38412099,
      '812200': 0.10902356,
      '812300': 0.14567484,
      '812900': 0.36118061,
    },
  },
  ['812100']: { label: 'Personal Care Services', BEA_CODES: { '812100': 1.0 } },
  ['812110']: {
    label: 'Hair, Nail, and Skin Care Services',
    BEA_CODES: { '812100': 1.0 },
  },
  ['812190']: {
    label: 'Other Personal Care Services',
    BEA_CODES: { '812100': 1.0 },
  },
  ['812200']: { label: 'Death Care Services', BEA_CODES: { '812200': 1.0 } },
  ['812210']: {
    label: 'Funeral Homes and Funeral Services',
    BEA_CODES: { '812200': 1.0 },
  },
  ['812220']: {
    label: 'Cemeteries and Crematories',
    BEA_CODES: { '812200': 1.0 },
  },
  ['812300']: {
    label: 'Drycleaning and Laundry Services',
    BEA_CODES: { '812300': 1.0 },
  },
  ['812310']: {
    label: 'Coin-Operated Laundries and Drycleaners',
    BEA_CODES: { '812300': 1.0 },
  },
  ['812320']: {
    label: 'Drycleaning and Laundry Services (except Coin-Operated)',
    BEA_CODES: { '812300': 1.0 },
  },
  ['812330']: {
    label: 'Linen and Uniform Supply',
    BEA_CODES: { '812300': 1.0 },
  },
  ['812900']: {
    label: 'Other Personal Services',
    BEA_CODES: { '812900': 1.0 },
  },
  ['812910']: {
    label: 'Pet Care (except Veterinary) Services',
    BEA_CODES: { '812900': 1.0 },
  },
  ['812920']: { label: 'Photofinishing', BEA_CODES: { '812900': 1.0 } },
  ['812930']: {
    label: 'Parking Lots and Garages',
    BEA_CODES: { '812900': 1.0 },
  },
  ['812990']: {
    label: 'All Other Personal Services',
    BEA_CODES: { '812900': 1.0 },
  },
  ['813000']: {
    label:
      'Religious, Grantmaking, Civic, Professional, and Similar Organizations',
    BEA_CODES: {
      '813100': 0.41435032,
      '813A00': 0.22037931,
      '813B00': 0.36527037,
    },
  },
  ['813100']: {
    label: 'Religious Organizations',
    BEA_CODES: { '813100': 1.0 },
  },
  ['813110']: {
    label: 'Religious Organizations',
    BEA_CODES: { '813100': 1.0 },
  },
  ['813200']: {
    label: 'Grantmaking and Giving Services',
    BEA_CODES: { '813A00': 1.0 },
  },
  ['813210']: {
    label: 'Grantmaking and Giving Services',
    BEA_CODES: { '813A00': 1.0 },
  },
  ['813300']: {
    label: 'Social Advocacy Organizations',
    BEA_CODES: { '813A00': 1.0 },
  },
  ['813310']: {
    label: 'Social Advocacy Organizations',
    BEA_CODES: { '813A00': 1.0 },
  },
  ['813400']: {
    label: 'Civic and Social Organizations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['813410']: {
    label: 'Civic and Social Organizations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['813900']: {
    label:
      'Business, Professional, Labor, Political, and Similar Organizations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['813910']: { label: 'Business Associations', BEA_CODES: { '813B00': 1.0 } },
  ['813920']: {
    label: 'Professional Organizations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['813930']: {
    label: 'Labor Unions and Similar Labor Organizations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['813940']: {
    label: 'Political Organizations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['813990']: {
    label:
      'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['814000']: { label: 'Private Households', BEA_CODES: { '814000': 1.0 } },
  ['814100']: { label: 'Private Households', BEA_CODES: { '814000': 1.0 } },
  ['814110']: { label: 'Private Households', BEA_CODES: { '814000': 1.0 } },
  ['920000']: {
    label: 'Public Administration',
    BEA_CODES: {
      GSLGE: 0.2748692,
      GSLGH: 0.02287198,
      GSLGO: 0.28232071,
      S00102: 0.00525897,
      S00203: 0.03252292,
      S00500: 0.248717,
      S00600: 0.13343922,
    },
  },
  ['921000']: {
    label: 'Executive, Legislative, and Other General Government Support',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['921100']: {
    label: 'Executive, Legislative, and Other General Government Support',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['921110']: {
    label: 'Executive Offices',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['921120']: {
    label: 'Legislative Bodies',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['921130']: {
    label: 'Public Finance Activities',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['921140']: {
    label: 'Executive and Legislative Offices, Combined',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['921150']: {
    label: 'American Indian and Alaska Native Tribal Governments',
    BEA_CODES: { GSLGO: 1.0 },
  },
  ['921190']: {
    label: 'Other General Government Support',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['922000']: {
    label: 'Justice, Public Order, and Safety Activities',
    BEA_CODES: {
      GSLGO: 0.62248,
      S00102: 0.01159533,
      S00203: 0.07170875,
      S00600: 0.29421591,
    },
  },
  ['922100']: {
    label: 'Justice, Public Order, and Safety Activities',
    BEA_CODES: {
      GSLGO: 0.62248,
      S00102: 0.01159533,
      S00203: 0.07170875,
      S00600: 0.29421591,
    },
  },
  ['922110']: {
    label: 'Courts',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['922120']: {
    label: 'Police Protection',
    BEA_CODES: {
      GSLGO: 0.62248,
      S00102: 0.01159533,
      S00203: 0.07170875,
      S00600: 0.29421591,
    },
  },
  ['922130']: {
    label: 'Legal Counsel and Prosecution',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['922140']: {
    label: 'Correctional Institutions',
    BEA_CODES: {
      GSLGO: 0.62248,
      S00102: 0.01159533,
      S00203: 0.07170875,
      S00600: 0.29421591,
    },
  },
  ['922150']: {
    label: 'Parole Offices and Probation Offices',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['922160']: {
    label: 'Fire Protection',
    BEA_CODES: { GSLGO: 0.62978254, S00203: 0.07254999, S00600: 0.29766747 },
  },
  ['922190']: {
    label: 'Other Justice, Public Order, and Safety Activities',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['923000']: {
    label: 'Administration of Human Resource Programs',
    BEA_CODES: {
      GSLGE: 0.38524004,
      GSLGH: 0.03205599,
      GSLGO: 0.39568363,
      S00600: 0.18702034,
    },
  },
  ['923100']: {
    label: 'Administration of Human Resource Programs',
    BEA_CODES: {
      GSLGE: 0.38524004,
      GSLGH: 0.03205599,
      GSLGO: 0.39568363,
      S00600: 0.18702034,
    },
  },
  ['923110']: {
    label: 'Administration of Education Programs',
    BEA_CODES: { GSLGE: 0.67319013, S00600: 0.32680987 },
  },
  ['923120']: {
    label: 'Administration of Public Health Programs',
    BEA_CODES: { GSLGH: 0.14632338, S00600: 0.85367662 },
  },
  ['923130']: {
    label:
      "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)",
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['923140']: {
    label: "Administration of Veterans' Affairs",
    BEA_CODES: { S00600: 1.0 },
  },
  ['924000']: {
    label: 'Administration of Environmental Quality Programs',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['924100']: {
    label: 'Administration of Environmental Quality Programs',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['924110']: {
    label:
      'Administration of Air and Water Resource and Solid Waste Management Programs',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['924120']: {
    label: 'Administration of Conservation Programs',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['925000']: {
    label:
      'Administration of Housing Programs, Urban Planning, and Community Development',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['925100']: {
    label:
      'Administration of Housing Programs, Urban Planning, and Community Development',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['925110']: {
    label: 'Administration of Housing Programs',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['925120']: {
    label:
      'Administration of Urban Planning and Community and Rural Development',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['926000']: {
    label: 'Administration of Economic Programs',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['926100']: {
    label: 'Administration of Economic Programs',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['926110']: {
    label: 'Administration of General Economic Programs',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['926120']: {
    label: 'Regulation and Administration of Transportation Programs',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['926130']: {
    label:
      'Regulation and Administration of Communications, Electric, Gas, and Other Utilities',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['926140']: {
    label: 'Regulation of Agricultural Marketing and Commodities',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['926150']: {
    label:
      'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors',
    BEA_CODES: { GSLGO: 0.67904743, S00600: 0.32095257 },
  },
  ['927000']: {
    label: 'Space Research and Technology',
    BEA_CODES: { S00102: 1.0 },
  },
  ['927100']: {
    label: 'Space Research and Technology',
    BEA_CODES: { S00102: 1.0 },
  },
  ['927110']: {
    label: 'Space Research and Technology',
    BEA_CODES: { S00102: 1.0 },
  },
  ['928000']: {
    label: 'National Security and International Affairs',
    BEA_CODES: { S00500: 0.65082547, S00600: 0.34917453 },
  },
  ['928100']: {
    label: 'National Security and International Affairs',
    BEA_CODES: { S00500: 0.65082547, S00600: 0.34917453 },
  },
  ['928110']: { label: 'National Security', BEA_CODES: { S00500: 1.0 } },
  ['928120']: { label: 'International Affairs', BEA_CODES: { S00600: 1.0 } },
};
