import { UnexpectedError } from '@watershed/errors/UnexpectedError';
import { BiFilterOperator } from '@watershed/constants/bi';

import { GQBiQueryFilter } from '@watershed/shared-universal/generated/graphql-schema-types';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import isNullish from '@watershed/shared-util/isNullish';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export type SupplierFilterMetric = {
  dimension: string;
  label: string;
  familyLabel?: string;
  biFilterOperators: Array<BiFilterOperator>;
  valueInputProps?: {
    useNumberInput?: boolean;
    useSingleSelectInput?: boolean;
    unit?: string;
  };
};

export type ParsedSupplierFilterDimension = {
  dimension: string;
  nestedDimensions: Array<string>;
};

export function parseSupplierFilterDimension(
  dimension: string
): ParsedSupplierFilterDimension {
  const [parentDimension, ...childrenDimensions] = dimension.split('.');
  return {
    dimension: parentDimension,
    nestedDimensions: childrenDimensions,
  };
}

export type ParsedSupplierFilterMetric = GQBiQueryFilter &
  ParsedSupplierFilterDimension;

export type SupplierFilterOption = string | number | boolean | null;

export function getDimension<I, R>(
  value: I,
  nestedDimensions: Array<string>
): R {
  return isEmpty(nestedDimensions) ? value : get(value, nestedDimensions);
}

export const existenceOperators = [
  BiFilterOperator.NotEmpty,
  BiFilterOperator.Empty,
];

export const selectAutocompleteOperators = [
  BiFilterOperator.In,
  BiFilterOperator.NotIn,
];

export const nullableSelectAutocompleteOperators = [
  ...selectAutocompleteOperators,
  ...existenceOperators,
];

export const numericOperators = [
  BiFilterOperator.Equal,
  BiFilterOperator.NotEqual,
  BiFilterOperator.GreaterThan,
  BiFilterOperator.GreaterThanOrEqual,
  BiFilterOperator.LessThan,
  BiFilterOperator.LessThanOrEqual,
];

export const nullableNumericOperators = [
  ...numericOperators,
  ...existenceOperators,
];

export const getOperatorLabel = (operator: BiFilterOperator): string => {
  switch (operator) {
    case BiFilterOperator.In:
      return 'is';
    case BiFilterOperator.NotIn:
      return 'is not';
    case BiFilterOperator.Empty:
      return 'is not available';
    case BiFilterOperator.NotEmpty:
      return 'is available';
    case BiFilterOperator.Equal:
      return '=';
    case BiFilterOperator.NotEqual:
      return '!=';
    case BiFilterOperator.LessThan:
      return '<';
    case BiFilterOperator.LessThanOrEqual:
      return '<=';
    case BiFilterOperator.GreaterThan:
      return '>';
    case BiFilterOperator.GreaterThanOrEqual:
      return '>=';
    case BiFilterOperator.Contains:
    case BiFilterOperator.DoesNotContain:
      throw new UnexpectedError(
        'Contains and DoesNotContain operators are not implemented',
        { data: { operator } }
      );
  }
};

/**
 * Converts cursor-based pagination arguments to offset-based
 * pagination ones by parsing the cursors as row numbers/integers"
 */
export function convertCursorToOffsetPaginationArgs({
  paginationArgs,
}: {
  paginationArgs: {
    first?: number | null;
    last?: number | null;
    before?: string | null;
    after?: string | null;
  };
}): {
  offset: number;
  limit: number;
} {
  const { first, last, before, after } = paginationArgs;
  const beforeInt = before != null ? parseInt(before) : null;
  const afterInt = after != null ? parseInt(after) : null;
  if (isNotNullish(beforeInt) && isNaN(beforeInt)) {
    throw new Error('Invalid before cursor');
  }
  if (isNotNullish(afterInt) && isNaN(afterInt)) {
    throw new Error('Invalid after cursor');
  }

  if (first != null && last != null) {
    throw new Error('Pagination with both first and last not supported');
  }

  if (isNullish(first) && isNullish(last)) {
    throw new Error('Pagination with neither first or last not supported');
  }

  if (first) {
    return { offset: afterInt != null ? afterInt + 1 : 0, limit: first };
  }

  if (isNullish(beforeInt) || isNullish(last)) {
    throw new Error('Pagination with last and no before is not supported');
  }
  return { offset: Math.max(beforeInt - last, 0), limit: last };
}
