import { GQFilterExpressionGroup } from '@watershed/shared-universal/generated/graphql-schema-types';
import { YM } from '../utils/YearMonth';
export const REQUIRED_SCOPE_3_EMISSIONS_PCT_FOR_SBT_TARGETS = 0.4;
export const NEAR_TERM_YOY_REDUCTION_PCT = 0.93;
export const NEAR_TERM_SCOPE_3_REQUIRED_COVERAGE_FRACTION = 0.67;
export const NEAR_TERM_SCOPE_1_2_LINEAR_ANNUAL_REDUCTION = 4.2;
export const NEAR_TERM_WELL_BELOW_2_SCOPE_3_LINEAR_ANNUAL_REDUCTION = 2.5;
export const NEAR_TERM_1_POINT_5_SCOPE_3_LINEAR_ANNUAL_REDUCTION = 4.2;
export const LONG_TERM_ABSOLUTE_REQUIRED_REDUCTION_PCT = 0.9;
export const LONG_TERM_INTENSITY_REQUIRED_REDUCTION_PCT = 0.97;

interface RenewableEnergyTarget {
  year: number;
  pct: number;
}

export const SBT_RENEWABLE_ENERGY_INTERIM_TARGET: RenewableEnergyTarget = {
  year: 2025,
  pct: 80,
};

export const SBT_RENEWABLE_ENERGY_TARGET: RenewableEnergyTarget = {
  year: 2030,
  pct: 100,
};

export const PLAN_END_MAX_YEAR = 2050;
export const SBTI_FORWARD_LOOKING_AMBITION_START_YEAR = 2020;
// When we compare a baseline year to SBTI, we need to check if the first year is in majority calendar year 2020.
export const SBTI_FORWARD_LOOKING_AMBITION_START_YEAR_MONTH = YM.make(
  SBTI_FORWARD_LOOKING_AMBITION_START_YEAR,
  6
);

// Organisations that have a long-term plan must have a forecast at least this
// long.
export const LONG_TERM_PLAN_MIN_FORECAST_END_YEAR = 2041; // Exclusive

export const DEFAULT_ASSUMPTION_GROWTH_PERCENT = 0;

// Display label here matches Drilldown
export const UNSPECIFIED_SUPPLIER_DISPLAY_NAME = 'Unspecified supplier';

// Forecast interval restrictions
export const MAX_VALID_YEAR_FOR_INTERVAL_END = 2051; // Exclusive
export const MIN_FORECAST_DURATION_YEARS = 5;

export const FILTER_EXPRESSION_GROUP_SCOPE_2: GQFilterExpressionGroup = {
  expressions: [
    {
      field: 'ghgScope',
      operator: 'in',
      value: ['scope 2'],
    },
  ],
  conjunction: 'andConjunction',
};

// Floating point math is imprecise -- when comparing numbers for certain SBT requirements, apply this precision threshold
export const PRECISION_THESHOLD = 1e-8;
