import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { useCurrentPrivilegedSession } from '@watershed/shared-frontend/utils/privilege/privilegeStore';
import { Trans } from '@lingui/react/macro';

/**
 * If the user is in a privileged session where mutations are
 * enabled for some period of time, this component renders a chip to enable
 * ending that override session, and also shows the when it ends.
 */
export function PrivilegedSessionIndicator() {
  const session = useCurrentPrivilegedSession();
  const mutationsEnabledUntilString = new Date(
    session.endMs
  ).toLocaleTimeString();

  if (session.endMs <= Date.now()) {
    return null;
  }

  return (
    <Tooltip
      title={
        <Trans context="A employee only button to end a login session where you have elevated permissions">
          End session
        </Trans>
      }
    >
      <Chip
        label={
          <Trans context="A employee only label describing when sending changes to the server are enabled for a session with elevated permsissions">
            Mutations enabled until {mutationsEnabledUntilString}
          </Trans>
        }
        color="warning"
        clickable
        onClick={() => session.end()}
      />
    </Tooltip>
  );
}
