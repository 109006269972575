import {
  // eslint-disable-next-line no-restricted-imports
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
} from '@mui/material';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import { AVATAR_COLORS } from '@watershed/style/palette';
import { CODE_FONT_FAMILY, mixinSx } from '@watershed/style/styleUtils';
import Logo from './Logo';

export type AvatarVariant = 'person' | 'entity';

export type AvatarSize = 'small' | 'medium' | 'large' | 'xlarge';

const variantMap: Record<AvatarVariant, MuiAvatarProps['variant']> = {
  person: 'circular',
  entity: 'rounded',
};

const sizes: Record<AvatarSize, string> = {
  small: '16px',
  medium: '24px',
  large: '32px',
  xlarge: '48px',
};

const fontSizes: Record<AvatarSize, string> = {
  small: '10px',
  medium: '12px',
  large: '14px',
  xlarge: '16px',
};

/**
 * Override of MUI's Avatar component to add `name` convenience prop.
 *
 * See also the `MuiAvatar` style overrides in `theme.tsx`.
 *
 * For avatar-ish designs with icons, see <IconBadge />.
 */
export default function Avatar({
  variant,
  name,
  size = 'medium',
  color,
  ...props
}: {
  variant: AvatarVariant;
  name?: string;
  size?: AvatarSize;
} & Omit<MuiAvatarProps, 'variant'>) {
  if (name === 'Watershed') {
    return <Logo size={sizes[size]} title={name} {...props} color="blue" />;
  }

  const nameChildren = name
    ?.split(' ')
    .slice(
      0,
      // Only one letter on small
      size === 'small' ? 1 : 2
    )
    .filter(isNotNullish)
    .map((part) => part[0]);

  return (
    <MuiAvatar
      {...props}
      variant={variantMap[variant]}
      title={name}
      sx={mixinSx(
        {
          width: sizes[size],
          height: sizes[size],
          fontSize: fontSizes[size],
          backgroundColor: color ?? (name ? nameToColor(name) : undefined),
          color: (theme) => theme.palette.white,
          fontFamily: CODE_FONT_FAMILY,
          boxShadow: (theme) =>
            `inset 0 0 0 0.5px ${theme.palette.cobalt100}10, inset 0 -1px 4px 0px ${theme.palette.cobalt100}16`,
        },
        props.sx
      )}
    >
      {nameChildren}
    </MuiAvatar>
  );
}

function nameToColor(name: string) {
  const hash = hashStr(name);
  const index = hash % AVATAR_COLORS.length;
  return AVATAR_COLORS[index];
}

/**
 * Simple hash function copied from:
 * https://stackoverflow.com/a/17087158
 */
function hashStr(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    hash += charCode;
  }
  return hash;
}
