import { t } from '@lingui/core/macro';
import {
  GQSupportCaseIssueType,
  GQSupportCasePriority,
  GQSupportCaseStatus,
} from '@watershed/shared-universal/generated/graphql-schema-types';
import assertNever from '@watershed/shared-util/assertNever';

export function getSupportCaseConfirmationMsgWithSla(
  priority: GQSupportCasePriority
): string {
  if (priority === GQSupportCasePriority.High) {
    return t({
      message:
        'Thanks for your submission! Watershed Support will reach out to you within the next 4 hours.',
    });
  }
  if (priority === GQSupportCasePriority.Medium) {
    return t({
      message:
        'Thanks for your submission! Watershed Support will reach out to you within the next business day.',
    });
  }
  return t({
    message:
      'Thanks for your submission! Watershed Support will reach out to you within the next 2 business days.',
  });
}

export enum SalesforceCaseIssueType {
  // These are the values available for new support cases:
  GeneralPlatform = 'General Platform',
  DataUpload = 'Data Upload',
  DatasetGuidance = 'Dataset Guidance',
  Measurement = 'Measurement',
  Methodology = 'Methodology',
  SupplyChain = 'Supply Chain',
  Footprint = 'Footprint',
  Finance = 'Finance',
  Reporting = 'Reporting',

  // Old but not technically deprecated. Support 4eva.
  GettingStarted = 'Getting Started',
  Measurements = 'Measurements',
  ReportingAndRegulations = 'Reporting and Regulations',
  ReductionsAndTargets = 'Reductions and Targets',
  Marketplace = 'Marketplace',
  FinancedEmissions = 'Financed Emissions',
  PhysicalProducts = 'Physical Products',
  // Note all caps to match Salesforce formatting
  Ceda = 'CEDA',

  // These don't appear in product but do appear in Salesforce
  Cdp = 'Cdp',
  CompanyDatabase = 'Company Database',
  CSRD = 'CSRD',
}

// We need to convert from the GQL enum to the salesforce version for use in the Salesforce API
export const ISSUE_TYPES: {
  [key in GQSupportCaseIssueType]: SalesforceCaseIssueType;
} = {
  // These are the values available for new support cases:
  [GQSupportCaseIssueType.GeneralPlatform]:
    SalesforceCaseIssueType.GeneralPlatform,
  [GQSupportCaseIssueType.DataUpload]: SalesforceCaseIssueType.DataUpload,
  [GQSupportCaseIssueType.DatasetGuidance]:
    SalesforceCaseIssueType.DatasetGuidance,
  [GQSupportCaseIssueType.Measurement]: SalesforceCaseIssueType.Measurement,
  [GQSupportCaseIssueType.Methodology]: SalesforceCaseIssueType.Methodology,
  [GQSupportCaseIssueType.SupplyChain]: SalesforceCaseIssueType.SupplyChain,
  [GQSupportCaseIssueType.Footprint]: SalesforceCaseIssueType.Footprint,
  [GQSupportCaseIssueType.Finance]: SalesforceCaseIssueType.Finance,
  [GQSupportCaseIssueType.Reporting]: SalesforceCaseIssueType.Reporting,

  // Old but not technically deprecated. Support 4eva.
  [GQSupportCaseIssueType.GettingStarted]:
    SalesforceCaseIssueType.GettingStarted,
  [GQSupportCaseIssueType.Measurements]: SalesforceCaseIssueType.Measurements,
  [GQSupportCaseIssueType.ReportingAndRegulations]:
    SalesforceCaseIssueType.ReportingAndRegulations,
  [GQSupportCaseIssueType.ReductionsAndTargets]:
    SalesforceCaseIssueType.ReductionsAndTargets,
  [GQSupportCaseIssueType.Marketplace]: SalesforceCaseIssueType.Marketplace,
  [GQSupportCaseIssueType.FinancedEmissions]:
    SalesforceCaseIssueType.FinancedEmissions,
  [GQSupportCaseIssueType.PhysicalProducts]:
    SalesforceCaseIssueType.PhysicalProducts,
  [GQSupportCaseIssueType.Ceda]: SalesforceCaseIssueType.Ceda,
};

// We need the internationalized version of the issue type for display in the UI
export function getIssueTypeLabel(issueType: GQSupportCaseIssueType): string {
  switch (issueType) {
    case GQSupportCaseIssueType.GeneralPlatform:
      return t({
        message: 'General platform',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.DataUpload:
      return t({
        message: 'Data upload',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.DatasetGuidance:
      return t({
        message: 'Dataset guidance',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.Measurement:
      return t({
        message: 'Measurement',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.Methodology:
      return t({
        message: 'Methodology',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.Footprint:
      return t({
        message: 'Footprint',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.Finance:
      return t({
        message: 'Finance',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.Reporting:
      return t({
        message: 'Reporting',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.SupplyChain:
      return t({
        message: 'Supply chain',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.GettingStarted:
      return t({
        message: 'Getting started',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.Measurements:
      return t({
        message: 'Measurements',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.ReportingAndRegulations:
      return t({
        message: 'Reporting and regulations',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.ReductionsAndTargets:
      return t({
        message: 'Reductions and targets',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.Marketplace:
      return t({
        message: 'Marketplace',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.FinancedEmissions:
      return t({
        message: 'Financed emissions',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.PhysicalProducts:
      return t({
        message: 'Physical products',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.Ceda:
      return t({
        message: 'CEDA',
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    default:
      assertNever(issueType);
  }
}

export function getStatusLabel(status: GQSupportCaseStatus): string {
  switch (status) {
    case GQSupportCaseStatus.PendingWatershedReply:
      return t({
        message: `Awaiting reply from Watershed`,
        context: 'User-facing description of a support case status',
      });
    case GQSupportCaseStatus.Complete:
      return t({
        message: `Complete`,
        context: 'User-facing description of a support case status',
      });
    case GQSupportCaseStatus.PendingCustomerReply:
      return t({
        message: `Needs customer input`,
        context: 'User-facing description of a support case status',
      });
    case GQSupportCaseStatus.OnHoldPendingWatershedAction:
      return t({
        message: `On hold - needs Watershed action`,
        context: 'User-facing description of a support case status',
      });
    default:
      assertNever(status);
  }
}

export function getPriorityLabel(priority: GQSupportCasePriority): string {
  switch (priority) {
    case GQSupportCasePriority.Low:
      return t({
        message: `General`,
        context: 'User-facing description of a support case priority',
      });
    case GQSupportCasePriority.Medium:
      return t({
        message: `High`,
        context: 'User-facing description of a support case priority',
      });
    case GQSupportCasePriority.High:
      return t({
        message: `Critical`,
        context: 'User-facing description of a support case priority',
      });
    default:
      assertNever(priority);
  }
}

export function getPriorityDescription(
  priority: GQSupportCasePriority | null
): string {
  switch (priority) {
    case GQSupportCasePriority.High:
      return t({
        message:
          'Use for services outage or loss of access; critical business support requests.',
      });
    case GQSupportCasePriority.Medium:
      return t({
        message:
          'Use for services degradation; high-priority business support requests.',
      });
    case GQSupportCasePriority.Low:
      return t({
        message:
          'Use for minor bugs or product questions; general business support requests.',
      });
    default:
      return t({
        message: 'Select a priority based on how impactful your issue is.',
      });
  }
}
