import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps, Theme, keyframes } from '@mui/material';
import { Trans } from '@lingui/react/macro';
import { CODE_FONT_FAMILY } from '@watershed/style/styleUtils';
import StatusMissingIcon from '@watershed/icons/components/StatusMissing';

interface ThinkingTimeProps {
  startTime?: number;
  endTime?: number;
}

const STYLES = {
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    px: 1.5,
  } as SxProps<Theme>,
};

const dots = keyframes`
  0%, 20% { content: '' }
  40% { content: '.' }
  60% { content: '..' }
  80%, 100% { content: '...' }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

function formatDuration(milliseconds: number): string {
  if (milliseconds < 1000) {
    return `${milliseconds}ms`;
  }
  const seconds = Math.floor(milliseconds / 1000);
  const remainingMs = milliseconds % 1000;
  return `${seconds}.${Math.floor(remainingMs / 100)}s`;
}

export function ThinkingTime({ startTime, endTime }: ThinkingTimeProps) {
  if (!startTime) return null;

  const duration = endTime ? endTime - startTime : undefined;

  return (
    <Stack>
      <Box
        component="span"
        sx={{
          height: '16px',
          ml: 2.5,
          width: '1px',
          borderRadius: '50%',
          backgroundColor: 'grey20',
        }}
      />
      <Stack direction="row" alignItems="center" gap={1} sx={STYLES.container}>
        <Box sx={{ pl: '2px', mt: '1px' }}>
          <StatusMissingIcon
            sx={{
              color: !endTime ? 'grey50' : 'cobalt50',
              animation: !endTime ? `${rotate} 2s linear infinite` : 'none',
            }}
            size={13}
          />
        </Box>
        <Typography variant="body2">
          <Trans comment="Name of the AI assistant / product">Watershed</Trans>
        </Typography>
        {!endTime && (
          <Typography
            variant="body2"
            fontSize={12}
            fontFamily={CODE_FONT_FAMILY}
            color="grey50"
            sx={{
              mt: 0.25,
              '&::after': {
                content: '"."',
                animation: `${dots} 2s infinite`,
              },
            }}
          >
            <Trans comment="Label for when the AI is thinking">Thinking</Trans>
          </Typography>
        )}
        {endTime && duration !== undefined && (
          <Typography
            variant="body2"
            fontSize={12}
            fontFamily={CODE_FONT_FAMILY}
            color="grey50"
            sx={{ mt: 0.25 }}
          >
            <Trans comment="Duration indicator showing how long the AI took to respond">
              Thought for {formatDuration(duration)}:
            </Trans>
          </Typography>
        )}
      </Stack>
      {endTime && (
        <Box
          component="span"
          sx={{
            height: '16px',
            ml: 2.5,
            width: '1px',
            borderRadius: '50%',
            backgroundColor: 'grey20',
          }}
        />
      )}
    </Stack>
  );
}
