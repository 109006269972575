import type { LottieComponentProps } from 'lottie-react';
import { useAsync } from 'react-use';

// Dynamically import `lottie-react` as soon as this module is imported.
const importLottieReactPromise = import('lottie-react').then(
  (module) => module.default
);

export function LottieReact(props: LottieComponentProps) {
  const state = useAsync(() => importLottieReactPromise, []);
  if (state.loading || !state.value) return null;
  const LottieReactModule = state.value;
  return <LottieReactModule {...props} />;
}
