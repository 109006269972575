import React, { createContext, useContext, useState, useCallback } from 'react';
import { Flags } from '@watershed/constants/flags';
import { useFeatureFlag } from '@watershed/app-dashboard/utils/FeatureFlag';

/**
 * Interface defining the shape of the GlobalHelp context.
 * This context provides methods to control the global help panel from anywhere in the app.
 */
interface GlobalHelpContextType {
  /**
   * Function to expand/open the help panel. This is called by components
   * that want to trigger the help panel to open.
   */
  expandHelp: () => void;

  /**
   * Function to register a handler for expanding the help panel.
   * The GlobalHelpWrapper component uses this to register the actual
   * implementation for opening itself.
   *
   * @param handler - Function that will be called when expandHelp is invoked
   */
  registerExpandHandler: (handler: () => void) => void;

  /**
   * Function to unregister the expand handler.
   * Used by the GlobalHelpWrapper when unmounting to clean up.
   */
  unregisterExpandHandler: () => void;
}

/**
 * Default context value with placeholder implementations.
 * These provide fallbacks and warnings if the context is used
 * before the provider is initialized.
 */
const defaultContext: GlobalHelpContextType = {
  expandHelp: () => {
    console.warn('GlobalHelp: No handler registered for expandHelp');
  },
  registerExpandHandler: () => {},
  unregisterExpandHandler: () => {},
};

/**
 * Create the context with default values
 */
const GlobalHelpContext = createContext<GlobalHelpContextType>(defaultContext);

/**
 * Custom hook to access the GlobalHelp context.
 * Components can use this to get access to expandHelp() and other methods.
 */
export const useGlobalHelp = () => useContext(GlobalHelpContext);

/**
 * Provider component for the GlobalHelp context.
 * This should be placed high in the component tree (e.g., in _app.tsx)
 * to make help functionality available throughout the application.
 */
export function GlobalHelpProvider({
  children,
}: { children: React.ReactNode }) {
  const isEnabled = useFeatureFlag(Flags.DustBotInProductPrototype);

  // Store the actual implementation of the expandHelp function
  // This gets set when the GlobalHelpWrapper mounts and registers its handler
  const [expandHandler, setExpandHandler] = useState<(() => void) | null>(null);

  /**
   * Function to expand the help panel.
   * Calls the registered handler if one exists, otherwise shows a warning.
   * This follows the "command pattern" where the function acts as a command
   * that gets dispatched to the appropriate handler.
   */
  const expandHelp = useCallback(() => {
    if (expandHandler) {
      expandHandler();
    } else {
      console.warn('GlobalHelp: No handler registered for expandHelp');
    }
  }, [expandHandler]);

  /**
   * Registers a new handler function for the expandHelp command.
   * The GlobalHelpWrapper calls this during its initialization to register
   * the function that actually opens the help panel UI.
   */
  const registerExpandHandler = useCallback((handler: () => void) => {
    setExpandHandler(() => handler);
  }, []);

  /**
   * Unregisters the current handler.
   * Called by the GlobalHelpWrapper when unmounting to prevent memory leaks
   * and ensure the expandHelp function doesn't call a handler for a component
   * that no longer exists.
   */
  const unregisterExpandHandler = useCallback(() => {
    setExpandHandler(null);
  }, []);

  if (!isEnabled) {
    return <>{children}</>;
  }

  return (
    <GlobalHelpContext.Provider
      value={{
        expandHelp,
        registerExpandHandler,
        unregisterExpandHandler,
      }}
    >
      {children}
    </GlobalHelpContext.Provider>
  );
}
