import { Pill } from '@watershed/ui-core/components/Pill';
import { useUserContext } from '../../utils/UserContext';
import { Trans } from '@lingui/react/macro';

/**
 * Shows either a small pill, or full length warning bar that the session has a
 * login as user override (i.e. the user is logged in as someone else). Shows
 * the larger warning bar if the user we're logged in as is not a Watershed
 * employee. Renders nothing if there is no override.
 */
export function LoginAsUserIndicator() {
  const { orgName, userName, loginAsUser, demoOrg, userIsWatershedEmployee } =
    useUserContext();

  // Nothing without a loginAs user
  if (!loginAsUser) {
    return null;
  }

  const label = (
    <Trans>
      Logged in to {orgName} as {userName}
    </Trans>
  );

  // A small pill if logged in on a demo org or the user is a Watershed employee
  if (demoOrg || userIsWatershedEmployee) {
    return <Pill label={label} color="secondary" />;
  }

  // A full width warning bar if logged in as a customer
  return (
    <Pill
      label={label}
      sx={(theme) => ({
        width: `${window.innerWidth * 0.85}px`,
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.primary.contrastText,
      })}
    />
  );
}
