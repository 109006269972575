import { makeBetterEnum } from './betterEnum';

export const GrowthFactor = makeBetterEnum('Headcount', 'Revenue');

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type GrowthFactor = (typeof GrowthFactor)[keyof typeof GrowthFactor];

export const GrowthFactorType = makeBetterEnum(
  'Custom',
  'GrossProfit',
  'Headcount',
  'NightsStayed',
  'Orders',
  'Patients',
  'Revenue',
  'SupplierSpend'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type GrowthFactorType =
  (typeof GrowthFactorType)[keyof typeof GrowthFactorType];
