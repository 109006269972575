import { useState, useRef, useEffect } from 'react';
import { Trans } from '@lingui/react/macro';
import { SxProps, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@watershed/ui-core/components/Button';
import ChevronDownIcon from '@watershed/icons/components/ChevronDown';
import { PrecannedMessage, RichHiddenContext } from '../../utils/types';

interface PrecannedMessageSuggestionsProps {
  intro?: string;
  messages?: Array<PrecannedMessage>;
  onSelect: (
    content: string,
    hiddenContext?: string | RichHiddenContext
  ) => void;
}

// Styles
const STYLES: Record<string, SxProps<Theme>> = {
  accordionWrapper: {
    position: 'relative',
    width: '100%',
    borderRadius: '10px',
    overflow: 'clip',
    border: (theme) => `0.5px solid ${theme.palette.grey40}`,
  },

  accordion: {
    width: '100%',
    background: (theme) =>
      `linear-gradient(to bottom right, ${theme.palette.grey20}50, ${theme.palette.grey05})`,
    border: 'none',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 2,
    '&:before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: '36px',
      mr: 1.5,
    },
    '& .MuiAccordionSummary-content': {
      px: 1.5,
      margin: 0,
    },
    '& .MuiCollapse-root': {
      ml: 1.5,
    },
  },

  accordionSummary: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  scrollContainerWrapper: {
    position: 'relative',
    width: '100%',
  },

  scrollContainer: {
    overflowX: 'auto',
    width: '100%',
    pb: 1,
    // Hide scrollbar in all browsers while maintaining scroll functionality
    msOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none' /* Chrome, Safari and Opera */,
    },
  },

  gradientOverlayRight: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '40px',
    height: '100%',
    background: (theme: Theme) =>
      `linear-gradient(to right, transparent, ${theme.palette.grey05}50 20%, ${theme.palette.grey05})`,
    pointerEvents: 'none',
    zIndex: 1,
  },

  gradientOverlayLeft: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '24px',
    height: '100%',
    background: (theme: Theme) =>
      `linear-gradient(to left, transparent, ${theme.palette.grey05}50 20%, ${theme.palette.grey05})`,
    pointerEvents: 'none',
    zIndex: 1,
  },

  messageStack: {
    minWidth: 'min-content',
    mr: 2,
  },

  messageButton: {
    color: 'grey70',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '4px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    overflowX: 'visible',
    border: (theme: Theme) => `0.5px solid ${theme.palette.grey30}`,
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    height: 'auto',
    py: 1,
    flexShrink: 0,
    '&:hover': {
      background: (theme: Theme) =>
        `linear-gradient(to bottom right, ${theme.palette.grey10}, ${theme.palette.white})`,
      color: 'cobalt50',
    },
  },
};

export function PrecannedMessageSuggestions({
  intro,
  messages,
  onSelect,
}: PrecannedMessageSuggestionsProps) {
  const [expanded, setExpanded] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      setIsScrolled(scrollContainerRef.current.scrollLeft > 0);
    }
  };

  // Check initial scroll position when component mounts or messages change
  useEffect(() => {
    if (scrollContainerRef.current) {
      handleScroll();
    }
  }, [messages, expanded]);

  if (!messages || messages.length === 0) return null;

  return (
    <Box sx={STYLES.accordionWrapper}>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        disableGutters
        elevation={0}
        sx={STYLES.accordion}
      >
        <AccordionSummary
          expandIcon={<ChevronDownIcon size={14} color="grey50" />}
          sx={STYLES.accordionSummary}
        >
          <Typography variant="body3" color="grey50">
            {intro || (
              <Trans context="Heading for suggested questions in help chat">
                I can help with…
              </Trans>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Box sx={STYLES.scrollContainerWrapper}>
            <Box
              sx={STYLES.scrollContainer}
              ref={scrollContainerRef}
              onScroll={handleScroll}
            >
              <Stack direction="row" gap={1} sx={STYLES.messageStack}>
                {messages.map((message, index) => (
                  <Button
                    key={index}
                    variant="text"
                    color="secondary"
                    size="small"
                    onClick={() =>
                      onSelect(message.content, message.hiddenContext)
                    }
                    sx={STYLES.messageButton}
                  >
                    {message.title}
                  </Button>
                ))}
              </Stack>
            </Box>
            {isScrolled && <Box sx={STYLES.gradientOverlayLeft} />}
            <Box sx={STYLES.gradientOverlayRight} />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
