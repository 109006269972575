import { routeForHome } from '@watershed/shared-universal/dashboardRoutes';
import { useRouter } from 'next/router';

let lastAppRoute: string | null = null;

/**
 * This adorable little hook allows us to take note of the last route the user was on prior to navigating to the settings page.
 * This allows us to navigate back to that route after the user has finished configuring their settings.
 */
export default function useBackToAppFromSettings() {
  const router = useRouter();

  function setLastAppRoute(route: string) {
    console.info('[useBackToAppFromSettings] setting last app route: ', route);
    lastAppRoute = route;
  }

  async function navigateToLastRoute() {
    if (!lastAppRoute) {
      console.info(
        '[useBackToAppFromSettings] no last app route found; navigating to home'
      );
      await router.push(routeForHome());
      return;
    }
    console.info(
      '[useBackToAppFromSettings] navigating to last app route: ',
      lastAppRoute
    );
    try {
      await router.push(lastAppRoute);
    } finally {
      lastAppRoute = null;
    }
  }

  return {
    setLastAppRoute,
    navigateToLastRoute,
  };
}
