import { useCallback, useState } from 'react';
import type { LottieComponentProps } from 'lottie-react';
import invariant from 'invariant';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import { useAsync } from 'react-use';
import { LottieReact } from './lottieUtils';

type AnimationData = unknown;

type LottieAnimationSpec = {
  load: () => Promise<AnimationData>;
  loop: boolean;
};

type AnimationKey = 'in' | 'ambient';

type LottieAnimationSpecs = {
  in?: LottieAnimationSpec;
  ambient: LottieAnimationSpec;
};

type AnimationLoad = {
  in?: AnimationData;
  ambient: AnimationData;
};

async function loadAnimations(
  animations: LottieAnimationSpecs
): Promise<AnimationLoad> {
  const loadEntries = await Promise.all(
    [
      animations.in?.load().then((data) => ['in', data] as const),
      animations.ambient.load().then((data) => ['ambient', data] as const),
    ].filter(isNotNullish)
  );
  return Object.fromEntries(loadEntries) as Record<AnimationKey, AnimationData>;
}

export default function Lottie({
  animation,
  ...props
}: {
  animation: LottieAnimationSpecs;
} & Omit<LottieComponentProps, 'animationData' | 'loop'>) {
  const [currentAnimationKey, setCurrentAnimationKey] = useState<AnimationKey>(
    animation.in ? 'in' : 'ambient'
  );
  const loadState = useAsync(
    () => loadAnimations(animation),
    // Note: We're ignoring the `animation` dep in order to avoid re-renders,
    // but it means that if it changes, the animation won't update. If that
    // becomes an issue, probably the easiest solution is for the caller to add
    // a `key` prop to `<Lottie>`, which will force it to remount.
    []
  );

  const onComplete = useCallback(() => {
    if (currentAnimationKey === 'in') {
      setCurrentAnimationKey('ambient');
    }
  }, [currentAnimationKey]);

  if (loadState.error) {
    console.error(loadState.error);
  }
  if (loadState.loading || loadState.value === undefined) return null;

  const currentAnimation = animation[currentAnimationKey];
  invariant(
    currentAnimation,
    'Animation key not found: %s',
    currentAnimationKey
  );

  return (
    <LottieReact
      animationData={loadState.value[currentAnimationKey]}
      {...props}
      loop={currentAnimation.loop}
      onComplete={onComplete}
    />
  );
}
