import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const GroupsIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M9 2a5 5 0 1 0 0 10A5 5 0 0 0 9 2ZM6 7a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
      clipRule="evenodd"
    />
    <path d="M6 14a5 5 0 0 0-5 5v2a1 1 0 1 0 2 0v-2a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v2a1 1 0 1 0 2 0v-2a5 5 0 0 0-5-5H6Zm12.032.88a1 1 0 0 1 1.218-.718A5 5 0 0 1 23 18.999V21a1 1 0 1 1-2 0v-2a3 3 0 0 0-2.25-2.902 1 1 0 0 1-.718-1.218ZM16.248 2.161a1 1 0 1 0-.496 1.938 3 3 0 0 1 0 5.812 1 1 0 1 0 .496 1.938 5 5 0 0 0 0-9.688Z" />
  </Icon>
));
export default GroupsIcon;
